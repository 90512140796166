import React from 'react'
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import { usePrevious } from '../../hooks/usePrevious'

import componentStyles from '../../assets/theme/components/card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import config from '../../config'

import { CREATE_NOTION_AUTHORIZATION } from '../../mutations'

const useStyles = makeStyles(componentStyles);

import {useMutation} from '@apollo/client'
import {useSelector} from 'react-redux'
import { useLocation, useHistory } from "react-router-dom"
import AuthorizationsTable from '../Tables/AuthorizationsTable'
import {selectUser} from "../../views/auth/selectors";

const Authorizations = ({
  redirectPath,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const code = new URLSearchParams(location.search).get('code')
  const previousCode = usePrevious(code)
  const errorCode = new URLSearchParams(location.search).get('error')

  const user = useSelector(selectUser)

  const organizationId = useSelector(state => state.auth?.user?.lastOpenedProject?.organization?.id)

  const redirectUri = encodeURIComponent(config.siteBaseUrl + redirectPath)

  const [createAuthorization, { loading, error }] = useMutation(CREATE_NOTION_AUTHORIZATION, {
    variables: {
      organizationId,
      clientId: '3b4a5f1c-2fe5-4078-a456-c9c61bcd2e58',
      code,
      redirectUri: config.siteBaseUrl + redirectPath,
    },
    onCompleted: async (data) => {
      console.log(data)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  React.useEffect(async () => {
    if (code && organizationId && code !== previousCode) {
      history.replace({ pathname: location.pathname, search: '' })
      await createAuthorization()
    }
    if (errorCode) {
      history.replace({ pathname: location.pathname, search: '' })
    }
  }, [errorCode, code, organizationId])

  return (
    /*<CreateNotionAuthorization open={openCreateIntegration} onClose={() => setOpenCreateIntegration(false)}/>*/
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        className={classes.cardHeader}
        titleTypographyProps={{
          component: Box,
          marginBottom: "0!important",
          variant: "h3",
        }}
        subheader={
          <Grid
            container
            component={Box}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs="auto">
              <Box
                component={Typography}
                variant="h3"
                marginBottom="0!important"
              >
                Authorizations
              </Box>
            </Grid>
            <Grid item xs="auto">
              <Box
                justifyContent="flex-end"
                display="flex"
                flexWrap="wrap"
              >
                <Button
                  disabled={user?.readOnly}
                  variant="contained"
                  color="primary"
                  size="small"
                  href={`https://api.notion.com/v1/oauth/authorize?client_id=3b4a5f1c-2fe5-4078-a456-c9c61bcd2e58&redirect_uri=${redirectUri}&response_type=code`}
                >
                  Add new
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
      />
      <AuthorizationsTable
        onReconnectClicked={() => {
          window.open(`https://api.notion.com/v1/oauth/authorize?client_id=3b4a5f1c-2fe5-4078-a456-c9c61bcd2e58&redirect_uri=${redirectUri}&response_type=code`, '_self')
        }}
      />
    </Card>
  )
}

export default Authorizations
