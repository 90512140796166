import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardContent,
  Modal,
  TextField,
  Box,
  CardHeader,
  useTheme,
  Divider,
  FormControl,
  CircularProgress,
  FormHelperText
} from "@material-ui/core"
import {setEditProfileModalVisible} from "./actions"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import componentStyles from "../../assets/theme/views/admin/profile"
import FormGroup from "@material-ui/core/FormGroup"
import FormLabel from "@material-ui/core/FormLabel"
import FilledInput from "@material-ui/core/FilledInput"
import EditPasswordModal from "./EditPasswordModal"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import {useMutation} from "@apollo/client"
import {UPDATE_USER_PROFILE} from "../../mutations"
import {validateEmail} from "../../utils/inputValidation"

const useStyles = makeStyles(componentStyles)

const EditProfileModal = () => {
  const dispatch = useDispatch()
  const profileState = useSelector(state => state.profile)
  const userState = useSelector(state => state.auth?.user)

  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [message, setMessage] = useState(null)

  const theme = useTheme()
  const classes = useStyles(theme)

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': 'Profile'
    })
  },[])

  useEffect(() => {
    setName(userState?.name)
    setEmail(userState?.email)
  }, [userState])

  useEffect(() => {
    if(!profileState.isEditModalVisible) {
      setName(null)
      setEmail(null)
      setShowChangePassword(false)
      setMessage(null)
    } else {
      setName(userState?.name)
      setEmail(userState?.email)
    }
  }, [profileState.isEditModalVisible])

  const defaultName = () => userState?.name || null

  const defaultEmail = () => userState?.email || null

  const canSave = () => {
    return (defaultName() !== name
        || defaultEmail() !== email
      ) && name
      && email && validateEmail(email)
  }

  const [updateUserProfile, { loading: saving }] = useMutation(UPDATE_USER_PROFILE, {
    variables: {
      id: userState?.id,
      name,
      email,
    }
    })

  const onSave = () => {
    updateUserProfile().catch(e => {
      console.error(e)
      setName(defaultName())
      setEmail(defaultEmail())
      setMessage({ text: e.graphQLErrors[0]?.message, isError: true })
    }).then(res => {
      if(res) {
        console.log(res)
        setMessage({text: 'Successfully updated user profile', isError: false})
      }
    })
  }

  return (
    <>
      <EditPasswordModal setMessage={m => setMessage(m)} userId={userState?.id} open={showChangePassword} onClose={() => setShowChangePassword(false)} />
      <Modal open={profileState.isEditModalVisible} onClose={() => dispatch(setEditProfileModalVisible(false))}>
        <Card
          classes={{
            root: classes.editProfileModalWrapper + " " + classes.cardRoot + " " + classes.cardRootSecondary,
          }}
        >
          <CardHeader
            subheader={
              <>
                <Grid
                  container
                  component={Box}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    xs="auto"
                    className={classes.modalHeaderLeftWrapper}>
                    <Box
                      component={CloseRoundedIcon}
                      width="1.5rem!important"
                      height="1.5rem!important"
                      onClick={() => dispatch(setEditProfileModalVisible(false))}
                      cursor="pointer"
                    />
                    <Box
                      component={Typography}
                      variant="h3"
                      marginBottom="0!important"
                      marginLeft="1rem!important"
                    >
                      Profile
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    <Box
                      justifyContent="flex-end"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!canSave()}
                        onClick={onSave}
                      >
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            }
            classes={{ root: classes.cardHeaderRoot }}
          />
          <CardContent classes={{ root: classes.editProfileCardContent }}>
            { !userState?.name ? <Box classes={{ root: classes.centerContent }}><CircularProgress className={ classes.centerCardContent }/></Box> :
              <>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormLabel>Name</FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        margin="normal"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          onFocus={() => setMessage(null)}
                          autoComplete="off"
                          type="text"
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormLabel>Email</FormLabel>
                      <FormControl
                        component={Box}
                        width="100%"
                        margin="normal"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          variant="filled"
                          id="filled-error"
                          component={TextField}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          onBlur={() => validateEmail(email)}
                          onFocus={() => setMessage(null)}
                          autoComplete="off"
                          type="text"
                        />
                        <FormHelperText marginLeft="14px!important" error={!validateEmail(email)} className={classes.modalMessageError}>
                          {validateEmail(email) ? ' ' : 'Please enter a valid email'}
                        </FormHelperText>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item>
                    <div className={classes.changePasswordText} onClick={() => {
                      setMessage(null)
                      setShowChangePassword(true)
                    }}>
                      Change Password
                    </div>
                  </Grid>
                  <Grid className={classes.modalMessage} item>
                    <Box
                      component={Typography}
                      variant="h5"
                      marginBottom="0!important"
                      className={message?.isError ? classes.modalMessageError : null}
                    >
                      {message?.text}
                    </Box>
                  </Grid>
                </Grid>
              </>
            }
          </CardContent>
        </Card>
      </Modal>
    </>
  )

}

export default EditProfileModal
