import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom'
import {useLazyQuery, useMutation} from '@apollo/client'
import { SIGN_IN } from '../../mutations'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from '@material-ui/lab/Alert'
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import { setUserData } from './actions'
import { useDispatch } from 'react-redux'
import Avatar from "@material-ui/core/Avatar";
import {GET_ORG_BY_ID} from "../../queries";

const useStyles = makeStyles(componentStyles);

function OrgAuth() {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const [authIcon, setAuthIcon] = useState(null)
  const [authText, setAuthText] = useState(null)

  const classes = useStyles();
  const theme = useTheme();

  const [formState, setFormState] = useState({
    email: '',
    password: '',
  })

  const [getOrgById] = useLazyQuery(GET_ORG_BY_ID, {
    onCompleted: (data) => {
      const redirectPath = location?.state?.redirectPath
      const redirectOrg = data?.organization
      if(redirectOrg) {
        setAuthText(redirectOrg.name)
        let newProjectName = null
        const pathNameSplit = redirectPath.split('/')
        if (pathNameSplit?.length > 2) {
          newProjectName = pathNameSplit[2]
        }
        for (const project of redirectOrg.projects) {
          const projectName = project.name.toLowerCase().replace(/ /g, '')
          if (projectName === newProjectName) {
            setAuthIcon(project.notionAuthorization?.workspaceIcon)
          }
        }
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [signIn, { loading: mutationLoading, error: mutationError }] = useMutation(SIGN_IN, {
    variables: {
      email: formState.email,
      password: formState.password,
    },
    onCompleted: async ({ login }) => {
      const prevPath = history?.location?.state?.prevPath
      await localStorage.setItem('loginToken', login.token)
      history.push(prevPath ? prevPath : '/index')
      if (login?.user) {
        dispatch(setUserData(login.user))
        const redirectPath = location?.state?.redirectPath
        if(redirectPath) {
          history.push(redirectPath, { fromOrgLogin: true})
        }
        if (!(!!login.user.projects.length)) {
          history.push('/setup/1')
        }
      }
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const submitSignIn = async (e) => {
    if (e.keyCode === 13) await signIn()
  }

  const getOrgDetails = async () => {
    let redirectOrgId = location?.state?.redirectOrg
    if(redirectOrgId) {
      await getOrgById({variables: {id: Number(redirectOrgId)}})
    }
  }

  useEffect(() => {
    getOrgDetails()
  }, [])

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          {authIcon || authText ?
            <CardHeader
            className={classes.cardHeader}
            title={
              <Box textAlign="center">
                <Box
                  component={Avatar}
                  width={75}
                  height={75}
                  alt="..."
                  src={authIcon}
                />
              </Box>
            }
            titleTypographyProps={{
              component: Box,
              textAlign: "center",
              marginBottom: "1rem!important",
              marginTop: ".5rem!important",
            }}
            subheader={
              <Box textAlign="center">
                <Box
                  fontSize="22px"
                  fontWeight="400"
                  component="small"
                  color={theme.palette.gray[600]}
                  >
                {authText}
                </Box>
              </Box>
            }
          ></CardHeader> : null}
          <CardContent classes={{ root: classes.cardContent }}>
            <Box
              color={theme.palette.gray[600]}
              textAlign="center"
              marginBottom="1rem"
              marginTop=".5rem"
              fontSize="1rem"
            >
              <Box fontSize="80%" fontWeight="400" component="small">
                Sign in with credentials
              </Box>
            </Box>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FilledInput
                autoComplete="off"
                type="email"
                placeholder="Email"
                value={formState.email}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    email: e.target.value,
                  })
                }
                onKeyDown={submitSignIn}
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FilledInput
                autoComplete="off"
                type="password"
                placeholder="Password"
                value={formState.password}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    password: e.target.value,
                  })
                }
                onKeyDown={submitSignIn}
                startAdornment={
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                }
              />
            </FormControl>
            {mutationError ? (
              <Alert severity="error" style={{ alignItems: 'center' }}>
                {mutationError.message ? mutationError.message : 'An error occurred signing in'}
              </Alert>
            ) : (
              ''
            )}
            {/*<FormControlLabel
              value="end"
              control={<Checkbox color="primary" />}
              label="Remeber me"
              labelPlacement="end"
              classes={{
                root: classes.formControlLabelRoot,
              }}
            />*/}
            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
              <Button color="primary" variant="contained" disabled={mutationLoading} onClick={signIn}>
                Sign in
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Grid container component={Box} marginTop="1rem">
          <Grid item xs={6} component={Box} textAlign="left">
            <a
              onClick={() => history.push('/auth/forgot-password')}
              className={classes.footerLinks}
            >
              Forgot password
            </a>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default OrgAuth;
