import React from 'react'
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { GET_ORGANIZATION_ID_BY_DOMAIN, ME, ORGANIZATION_DOMAIN} from '../queries'
import {useDispatch, useSelector} from 'react-redux'
import {logout, setUserData} from '../views/auth/actions'
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import CongratsNotification from "../components/Modals/CongratsNotification"
import Confetti from "react-confetti"
import { runConfetti } from "../views/admin/Confetti/action";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";
import { admin }  from 'routes.js';
import componentStyles from "assets/theme/layouts/admin.js";
import {useApolloClient, useLazyQuery, useMutation, useQuery} from '@apollo/client'
import equal from 'deep-equal'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles(componentStyles)

import { getProjectPath } from '../utils/projectPath'
import ProjectSelector from "../views/admin/ProjectSelector";
import VersionNumber from '../views/admin/VersionNumber'
import {resetState as resetNewProjectState } from "../views/admin/NewProjectSetup/actions";
import {UPDATE_LAST_OPENED_PROJECT} from "../mutations";
import useWindowSize from "../hooks/useWindowSize";
import NotificationsDropdown from '../components/Dropdowns/NotificationsDropdown'
import NotificationController from "../utils/NotificationController";
import { initNewProjectSetupData } from '../utils/projectSetup'
import InviteModal from "../components/Modals/InviteModal";
import AchievementsDropdown from "../components/Dropdowns/AchievementsDropdown";

const PollMe = ({ setUrl }) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const history = useHistory()
  const client = useApolloClient()
  const user = useSelector(state => state.auth?.user)
  const [finalPath, setFinalPath] = React.useState(null)

  const [getOrgIdByDomain] = useLazyQuery(GET_ORGANIZATION_ID_BY_DOMAIN, {
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      let validOrgId = data?.organization?.id
      if(!validOrgId) {
        setUrl(finalPath)
        history.replace({ pathname: finalPath})
      }
      else if(!location?.state?.fromOrgLogin) {
        history.push('/org/login/', { redirectOrg: validOrgId, redirectPath: location.pathname })
      }
    },
  })

  const [updateLastOpenedProject] = useMutation(UPDATE_LAST_OPENED_PROJECT, {
    refetchQueries: [
      'Me'
    ],
    onCompleted: (data) => {
      dispatch(setUserData(data?.updateOneUser))
    },
    onError: err => {
      console.log(err)
    }
  })

  useQuery(ME, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data) {
        return
      }
      const me = data.me
      if (!equal(me, user)) {
        dispatch(setUserData(me))
      }
      if (!(!!me.projects.length)) {
        history.push('/setup/1')
      }
    },
    onError: (e) => {
      if(e.toString().includes('Invalid login token')) {
        localStorage.removeItem('loginToken')
        history.push('/auth/login', { prevPath: location.pathname })
      }
    },
  })

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src="//js.hs-scripts.com/21375735.js";
    script.async = true;

    document.body.appendChild(script);
  }, [])

  const checkOrgName = async (finalPath) => {
    const pathNameSplit = location.pathname.split('/')
    let newOrgName = null
    if(pathNameSplit?.length > 1) {
      newOrgName = pathNameSplit[1]
    }
    if(newOrgName === 'index') {
      newOrgName = null
    }
    const finalPathSplit = finalPath.split('/')
    let finalPathOrgName = null
    if(finalPathSplit?.length > 1) {
      finalPathOrgName = finalPathSplit[1]
    }

    if(newOrgName && finalPathOrgName && newOrgName !== finalPathOrgName) {
      await getOrgIdByDomain({ variables: { orgDomain: newOrgName } })
    } else {
      if (finalPath !== location.pathname) {
        setUrl(finalPath)
        history.replace({pathname: finalPath})
      }
    }
  }

  React.useEffect(() => {
    if(user) {
      let project = null
      if(!user.lastOpenedProject || user.lastOpenedProject?.deleted) {
        const userProjects = user.projects?.filter(p => !p.deleted)
        if(userProjects?.length > 0) {
          project = userProjects.find(p => p.requirementTable && p.sprintTable) || userProjects[0]
          updateLastOpenedProject({
            variables: {
              userId: user.id,
              projectId: project?.id,
            }
          })
          const organization = user.organizations.find(o => o.projects.find(p => p.id === project.id))
          dispatch(setUserData({
            ...user,
            lastOpenedProject: {
              ...project,
              organization
            }
          }))
          if (!project?.requirementTable || !project.sprintTable) {
            history.push(`/new-project/pid${project?.id}/0`)
          }
        } else {
          history.push('/setup')
        }
      } else {
        project = user.lastOpenedProject
        const projectPath = getProjectPath(user)
        if (projectPath && project?.requirementTable && project?.sprintTable) {
          const search = location?.search || ''
          const pathnameSplit = location?.pathname?.split('/')
          const restOfPath = pathnameSplit[pathnameSplit.length - 1]
          const finalPath = '/' + projectPath + restOfPath + search
          if ((location.pathname !== finalPath) && !location?.state?.fromOrgLogin) {
            setFinalPath(finalPath)
            checkOrgName(finalPath)
          }
        } else {
          history.push(`/new-project/pid${project?.id}/0`)
        }
      }
    }
  }, [user, location.pathname])

  return null
}

const Admin = () => {
  const [url, setUrl] = React.useState('/index')
  const history = useHistory()
  const user = useSelector(state => state.auth?.user)
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch()
  const { width, height } = useWindowSize();
  const confetti = useSelector(state => state.confetti?.run);
  const loginToken = localStorage.getItem('loginToken')

  const [getOrgIdByDomain] = useLazyQuery(GET_ORGANIZATION_ID_BY_DOMAIN, {
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      let validOrgId = data?.organization?.id
      if(!validOrgId) {
        history.replace({ pathname: '/product' })
      }
      else if(!location?.state?.fromOrgLogin) {
        history.push('/org/login/', { redirectOrg: validOrgId, redirectPath: location.pathname })
      }
    },
  })

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  React.useEffect(() => {
    dispatch(resetNewProjectState())

    if(!loginToken) {
      checkOrgName()
    }
  }, [])

  const checkOrgName = async () => {
    const pathNameSplit = location.pathname.split('/')
    let newOrgName = null
    if(pathNameSplit?.length > 1) {
      newOrgName = pathNameSplit[1]
    }

    if(newOrgName) {
      await getOrgIdByDomain({ variables: { orgDomain: newOrgName } })
    } else {
      history.replace({ pathname: '/product' })
    }
  }

  const getAdminRoutes = (admin) => {
    const routes = []
    admin.forEach((prop, key) => {
      if (prop.layout === "/") {
        let projectPath = ''
        if (prop.includeProjectPath) {
          projectPath = getProjectPath(user)
          if(!projectPath) {
            projectPath = ''
          }
          routes.push(<Route
            path={prop.layout + projectPath + prop.path}
            component={prop.component}
            key={key}
          />)
          /*if (prop.optionalParams) {
            routes.push(<Route
              path={prop.layout + projectPath + prop.path + prop.optionalParams}
              component={prop.component}
              key={key}
            />)
          }*/
        }
        if (prop.optionalParams) {
          /*routes.push(<Route
            path={prop.layout + '*' + prop.path + prop.optionalParams}
            component={prop.component}
            key={key}
          />)*/
        }
        routes.push(<Route
          exact
          path={prop.layout + '*' + prop.path}
          component={prop.component}
          key={key}
        />)
      }
    });
    return routes
  };



  const getBrandText = () => {
    for (let i = 0; i < admin.length; i++) {
      if (location.pathname.indexOf(admin[i].layout + admin[i].path) !== -1) {
        return admin[i].name;
      }
    }
    return "Brand";
  };

  return !loginToken ? null : (
    <>
      <Container>
        <CongratsNotification />
        <InviteModal />
      </Container>
      {
        confetti ? (
          <Confetti width={width} height={height} style={{ zIndex: 1500 }} recycle={false} onConfettiComplete={runConfetti(false)}/>
        ) : null
      }
      {loginToken && <PollMe setUrl={setUrl}/>}
      <NotificationController />
      <Sidebar
        admin={admin}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/notionops-logo-color.png").default,
          imgAlt: "...",
        }}
        additionalComponents={[<VersionNumber key={'version-number'}/>, <ProjectSelector key={'project-selector'} />]}
        dropdowns={[<NotificationsDropdown/>, <AchievementsDropdown/>, <NavbarDropdown />]}
        input={<ProjectSelector mobile={true}/>}
      />
      <Box position="relative" className={classes.mainContent}>
        <AdminNavbar brandText={getBrandText(location.pathname)} />
        <Switch>
          {getAdminRoutes(admin)}
          <Redirect from="*" to={url} />
        </Switch>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <AdminFooter />
        </Container>
      </Box>
    </>
  );
};
export default Admin;
