const themeColors = {
  white: {
    main: "#FFFFFF",
  },
  black: {
    light: "#12263F",
    main: "#000000",
  },
  transparent: {
    main: "transparent",
  },
  gray: {
    gradient: "#F8F9FA",
    100: "#f7f8f8",
    200: "#f0f1f2",
    300: "#e3e5e6",
    400: "#cacfd1",
    500: "#9ca4a8",
    600: "#727d83",
    700: "#4d5b61",
    800: "#2e3d44",
    900: "#212529",
  },
  primary: {
    main: "#ff664d",
    dark: "#ff3210",
    snackbar: "#ff9180",
    badgeBg: "#eaecfb",
    badgeBgHover: "#ff3210",
    badge: "#ff664d",
  },
  secondary: {
    main: "#f7fafc",
    snackbar: "#f8fbfc",
    badgeBgHover: "#9CA4A8",
    btnOutline: "#9CA4A8",
    btnActive: "#FF664D",
  },
  warning: {
    light: "#ffd600",
    main: "#fb6340",
    snackbar: "#fc7c5f",
    badgeBg: "#fee6e0",
    badgeBgHover: "#f93305",
    badge: "#ff3709",
  },
  error: {
    light: "#f3a4b5",
    main: "#f5365c",
    snackbar: "#f75676",
    badgeBg: "#fdd1da",
    badgeBgHover: "#e30b36",
    badge: "#f80031",
    dialogNotification: "#f56036",
  },
  info: {
    main: "#67e0c6",
    mainGradient: "#00b7b0",
    snackbar: "#ff9180",
    badgeBg: "#67e0c6",
    badgeBgHover: "#00b7b0",
    badge: "#67e0c6",
  },
  background: {
    default: "#f8f9fe",
    overlay: '#FEF4F1',
  },
  text: {
    primary: "#2e3d44",
  },
  dark: {
    tableBorder: "#00403a",
    heading: '#3D4D55',
    tableHeadColor: "#67e0c6",
    tableHeadBgColor: "#004d45",
    main: "#00403a",
    mainGradient: "#003833",
    dark: "#161b1f",
    snackbar: "#2e3d44",
    badgeBg: "#00b7b0",
    badgeBgHover: "#00403a",
  },
  success: {
    main: "#2dce89",
    snackbar: "#4fd69c",
    badgeBg: "#67e0c6",
    badgeBgHover: "#00b7b0",
    badge: "#00928d",
  },
};

export default themeColors;
