import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Box, Container, Typography } from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableCell } from '@mui/material'
import TableBody from '@material-ui/core/TableBody'
import { Check } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import componentStyles from '../../../assets/theme/views/product/home'

const useStyles = makeStyles(componentStyles)

const ComparisonTable = () => {
  const classes = useStyles()

  return (
    <Box paddingTop="1.0rem" paddingBottom="4.5rem" paddingLeft="1.0rem" paddingRight="1.0rem" width="100%" component="section">
      <Grid container alignItems="center" justify="center">
        <Container maxWidth="xl">
          <Box>
            <Typography variant="h2" className={[classes.sectionHeader, classes.tableSectionHeader].join(' ')}>Get more done with fewer separate workspaces.</Typography>
          </Box>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader} align="center"></TableCell>
                  <TableCell className={classes.tableHeader} align="center">TRELLO</TableCell>
                  <TableCell className={classes.tableHeader} align="center">JIRA</TableCell>
                  <TableCell className={classes.tableHeader} align="center">CONFLUENCE</TableCell>
                  <TableCell className={classes.tableHeader} align="center">NOTION</TableCell>
                  <TableCell className={classes.tableHeader} align="center">NOTIONOPS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={'comparison-1'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Number Sequencing</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
                <TableRow key={'comparison-2'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Burndown</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
                <TableRow key={'comparison-3'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Velocity</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
                <TableRow key={'comparison-4'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Cumulative Flow</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
                <TableRow key={'comparison-5'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Sprint Planning</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
                <TableRow key={'comparison-6'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Integrations</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
                <TableRow key={'comparison-7'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Create Documentation</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
                <TableRow key={'comparison-8'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Team Collaboration</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
                <TableRow key={'comparison-9'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Custom Workflows</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
                <TableRow key={'comparison-10'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" className={classes.tableCell + " " + classes.tableCellSubject}>Roadmap / Epics</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>–</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Box component={Check} className={classes.checkIcon} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Grid>
    </Box>
  )
}

export default ComparisonTable
