import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Header from 'components/Headers/Header.js'
import Authorizations from 'components/Cards/Authorizations'
import OrganizationSetup from './components/OrganizationSetup'
import componentStyles from 'assets/theme/views/admin/organization.js'
import OrganizationProjects from '../../../components/Cards/OrganizationProjects'

const useStyles = makeStyles(componentStyles)

const Tables = () => {
  const classes = useStyles()

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': 'Organization'
    })
  },[])

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop='-6rem'
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={6}
            component={Box}
            marginBottom='3rem !important'
            classes={{ root: classes.gridItemRoot + ' ' + classes.order2 }}
          >
            <OrganizationSetup/>
            <OrganizationProjects redirectPath={'/organization'}/>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            component={Box}
            marginBottom='3rem !important'
            classes={{ root: classes.gridItemRoot + ' ' + classes.order2 }}
          >
            <Authorizations redirectPath={'/organization'}/>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            component={Box}
            marginBottom='3rem !important'
            classes={{ root: classes.gridItemRoot + ' ' + classes.order2 }}
          >
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Tables
