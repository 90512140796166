import {
  SET_FIRST_ORGANIZATION,
  SET_FIRST_AUTHORIZATION,
  SET_FIRST_PROJECT,
  SET_FETCHING_SETUP_DATA,
  SET_SETUP_STEP,
  SET_FIRST_SPRINT_DATABASE,
  SET_FIRST_SPRINT_PROPERTIES,
  SET_FIRST_REQUIREMENT_DATABASE,
  SET_FIRST_REQUIREMENT_PROPERTIES,
  SET_FIRST_REQUIREMENT_STATUSES,
  SET_FIRST_REQUIREMENT_TYPES,
} from './actions'

const initState = {
  organization: null,
  authorization: null,
  project: null,
  activeStep: 0,
  fetching: false,
  sprintDatabase: null,
  sprintProperties: {
    dateRange: null,
    totalEffort: null,
    backlog: null,
    state: null,
  },
  requirementDatabase: null,
  requirementProperties: {
    number: null,
    status: null,
    type: null,
    storyPoints: null,
    sprint: null,
    parent: null,
    assignee: null,
  },
  requirementStatuses: {
    planned: [],
    inProgress: [],
    complete: [],
    removed: []
  },
  requirementTypes: {
    userStory: [],
    defect: [],
    task: [],
    other: []
  }
}

const setup = (state = initState, action) => {
  const { type, payload } = action
  switch(type) {
    case SET_SETUP_STEP:
      return {
        ...state,
        activeStep: payload
      }
    case SET_FETCHING_SETUP_DATA:
      return {
        ...state,
        fetching: true
      }
    case SET_FIRST_ORGANIZATION:
      return {
        ...state,
        organization: payload
      }
    case SET_FIRST_AUTHORIZATION:
      return {
        ...state,
        authorization: payload
      }
    case SET_FIRST_PROJECT:
      return {
        ...state,
        project: payload
      }
    case SET_FIRST_SPRINT_DATABASE:
      return {
        ...state,
        sprintDatabase: payload
      }
    case SET_FIRST_SPRINT_PROPERTIES:
      return {
        ...state,
        sprintProperties: payload
      }
    case SET_FIRST_REQUIREMENT_DATABASE:
      return {
        ...state,
        requirementDatabase: payload
      }
    case SET_FIRST_REQUIREMENT_PROPERTIES:
      return {
        ...state,
        requirementProperties: payload
      }
    case SET_FIRST_REQUIREMENT_STATUSES:
      return {
        ...state,
        requirementStatuses: payload
      }
    case SET_FIRST_REQUIREMENT_TYPES:
      return {
        ...state,
        requirementTypes: payload
      }
    default:
      return state
  }
}

export default setup
