import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { MARK_NOTIFICATION_SHOWN } from "../mutations";
import { useDispatch, useSelector } from 'react-redux'
import { selectNotifications } from "../views/auth/selectors";
import { setActiveModal } from '../components/Modals/actions'
import { setUserData } from '../views/auth/actions'
import store from "../store";

const CustomToast = ({title, subtitle}) => {
  return (
    <div>
      {title && title?.length > 0 && <div style={{ fontSize: 15, fontWeight: 'bold'}}>{title}</div>}
      {subtitle && subtitle?.length > 0 && <div>{subtitle}</div>}
    </div>
  )
}

const NotificationController = () => {
  const [focus, setFocus] = useState(!document.hidden)

  const notifications = useSelector(selectNotifications)
  const user = useSelector(state => state.auth?.user)
  const dispatch = useDispatch()

  const [markNotificationShown] = useMutation(MARK_NOTIFICATION_SHOWN, {
    onError: err => {
      console.log(err)
    }
  })

  const shownNotification = notification => {
    const _user = store.getState().auth.user
    dispatch(setUserData({
      ..._user,
      shownNotifications: [
        ..._user.shownNotifications,
        {
          notification
        }
      ]
    }))
    markNotificationShown({
      variables: {
        id: notification.id,
      },
    })
  }

  const notify = notification => {
    let toastFn
    const options = {
      onClick: () => {
        dispatch(setActiveModal({code: notification?.code, data: notification}))
      },
    }
    const title = notification?.toastSubtitle ? notification?.title : null
    const subtitle = notification?.toastSubtitle || notification?.title
    switch(notification.type) {
      case 'SUCCESS':
        toastFn = () => toast.success(<CustomToast title={title} subtitle={subtitle} />, options)
        break
      case 'INFO':
        toastFn = () => toast.info(<CustomToast title={title} subtitle={subtitle} />, options)
        break
      case 'ERROR':
        toastFn = () => toast.error(<CustomToast title={title} subtitle={subtitle} />, options)
        break
      case 'WARNING':
        toastFn = () => toast.warn(<CustomToast title={title} subtitle={subtitle} />, options)
        break
      default:
        toastFn = () => toast(<CustomToast title={title} subtitle={subtitle} />, options)
        break
    }
    toastFn()
    shownNotification(notification)
  }

  useEffect(() => {
    const onFocus = () => {
      setFocus(true)
    }
    const onBlur = () => {
      setFocus(false)
    }
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)

    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  useEffect(() => {
    if (focus) {
      notifications.forEach(n => {
        if (user?.shownNotifications.findIndex(sn => sn.notification.id === n.id) < 0 && !n.silent) {
          const userOlderThanNotification = user?.createdAt < n.createdAt //user is older

          let compDate = new Date()
          compDate.setHours(compDate.getHours() - 24)

          const notificationLessThanDayOld = new Date(n.createdAt) > compDate
          const expireToastAt = n.expireToastAt ? new Date(n.expireToastAt) : null

          if (expireToastAt && expireToastAt < new Date()) {
            notify(n)
          } else if (userOlderThanNotification && notificationLessThanDayOld) {
            notify(n)
          }
        }
      })
    }
  }, [notifications, focus])

  return (
    <ToastContainer position={'bottom-left'} />
  )
}

export default NotificationController
