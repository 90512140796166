import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import { FormControl, CircularProgress, CardContent, CardHeader, makeStyles, useTheme, Tooltip } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import componentStyles from '../../../../assets/theme/views/admin/profile'
import { REQUIREMENT_TABLE, NOTION_DATABASES, NOTION_DATABASE_PROPERTIES } from '../../../../queries'
import { UPDATE_REQUIREMENT_TABLE } from '../../../../mutations'
import { useSelector } from 'react-redux'
import { Select } from '../../../../components'
import { useQuery, useMutation } from '@apollo/client'
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {selectUser} from "../../../auth/selectors";

const useStyles = makeStyles(componentStyles)

const Requirements = () => {
  const classes = useStyles()
  const theme = useTheme()

  const projectId = useSelector(state => state.auth?.user?.lastOpenedProject?.id)
  const organization = useSelector(state => state.auth?.user?.lastOpenedProject?.organization)
  const user = useSelector(selectUser)

  const [database, setDatabase] = useState(null)
  const [number, setNumber] = useState(null)
  const [status, setStatus] = useState(null)
  const [type, setType] = useState(null)
  const [storyPoints, setStoryPoints] = useState(null)
  const [sprint, setSprint] = useState(null)
  const [parent, setParent] = useState(null)
  const [assignee, setAssignee] = useState(null)
  const [planned, setPlanned] = useState([])
  const [inProgress, setInProgress] = useState([])
  const [complete, setComplete] = useState([])
  const [removed, setRemoved] = useState([])
  const [userStory, setUserStory] = useState([])
  const [defect, setDefect] = useState([])
  const [task, setTask] = useState([])
  const [other, setOther] = useState([])
  const [requirementTable, setRequirementTable] = useState(null)

  const { loading, data, error, refetch } = useQuery(REQUIREMENT_TABLE, {
    variables: {
      projectId
    },
  })

  useEffect(() => {
    refetch()
  }, projectId)

  const stripTypeNameFromOptions = o => ({
    optionName: o.optionName,
    optionId: o.optionId,
    optionColor: o.optionColor,
  })

  const createStatusUpsert = (statuses, type) => {
    return statuses.map(s => ({
      create: {
        tenantId: organization?.id,
        name: s.title,
        notionId: s.id,
        color: s.color,
        type
      },
      update: {
        name: {
          set: s.title
        },
        notionId: {
          set: s.id,
        },
        color: {
          set: s.color
        },
        type: {
          set: type
        },
        active: {
          set: true
        }
      },
      where: {
        requirementTableId_notionId: {
          requirementTableId: requirementTable?.id,
          notionId: s.id,
        }
      }
    }))
  }

  const getStatusUpserts = () => {
    const plannedUpserts = createStatusUpsert(planned, 'PLANNED')
    const inProgressUpserts = createStatusUpsert(inProgress, 'IN_PROGRESS')
    const completeUpserts = createStatusUpsert(complete, 'COMPLETE')
    const removedUpserts = createStatusUpsert(removed, 'REMOVED')
    return [...plannedUpserts, ...inProgressUpserts, ...completeUpserts, ...removedUpserts]
  }

  const getStatusNotionIds = () => {
    const plannedIds = planned.map(s => s.id)
    const inProgressIds = inProgress.map(s => s.id)
    const completeIds = complete.map(s => s.id)
    const removedIds = removed.map(s => s.id)
    return [...plannedIds, ...inProgressIds, ...completeIds, ...removedIds]
  }

  const createTypeUpsert = (types, type) => {
    return types.map(t => ({
      create: {
        tenantId: organization?.id,
        name: t.title,
        notionId: t.id,
        color: t.color,
        type
      },
      update: {
        name: {
          set: t.title
        },
        notionId: {
          set: t.id,
        },
        color: {
          set: t.color
        },
        type: {
          set: type
        },
        active: {
          set: true
        }
      },
      where: {
        requirementTableId_notionId: {
          requirementTableId: requirementTable?.id,
          notionId: t.id,
        }
      }
    }))
  }

  const getTypeUpserts = () => {
    const userStoryUpserts = createTypeUpsert(userStory, 'USER_STORY')
    const defectUpserts = createTypeUpsert(defect, 'DEFECT')
    const taskUpserts = createTypeUpsert(task, 'TASK')
    const otherUpserts = createTypeUpsert(other, 'OTHER')
    return [...userStoryUpserts, ...defectUpserts, ...taskUpserts, ...otherUpserts]
  }

  const getTypeNotionIds = () => {
    const userStoryIds = userStory.map(t => t.id)
    const defectIds = defect.map(t => t.id)
    const taskIds = task.map(t => t.id)
    const otherIds = other.map(t => t.id)
    return [...userStoryIds, ...defectIds, ...taskIds, ...otherIds]
  }

  const getNotionOptionUpserts = configuration => {
    if (configuration?.options?.length) {
      return configuration?.options?.map(o => ({
        create: {
          ...stripTypeNameFromOptions(o),
          tenantId: organization?.id
        },
        update: {
          optionName: {
            set: o.optionName
          },
          optionId: {
            set: o.optionId
          },
          optionColor: {
            set: o.optionColor
          }
        },
        where: {
          optionId_configurationId: {
            optionId: o.optionId,
            configurationId: configuration?.id || -1
          }
        }
      }))
    }
    return []
  }

  const [updateRequirementTable, { loading: saving }] = useMutation(UPDATE_REQUIREMENT_TABLE, {
    variables: {
      projectId,
      notionDatabaseId: database?.id,
      notionDatabaseTitle: database?.title,
      notionNumberId: number?.id,
      notionNumberType: number?.type,
      notionNumberTitle: number?.title,
      notionStatusId: status?.id,
      notionStatusTitle: status?.title,
      notionStatusType: status?.type,
      notionStatusOptions: status?.selectConfiguration?.options?.map(stripTypeNameFromOptions),
      notionStatusOptionIds: status?.selectConfiguration?.options?.map(o => o.optionId),
      notionStatusUpserts: getNotionOptionUpserts(status?.selectConfiguration),
      notionTypeId: type?.id,
      notionTypeTitle: type?.title,
      notionTypeType: type?.type,
      notionStoryPointsId: storyPoints?.id,
      notionStoryPointsType: storyPoints?.type,
      notionStoryPointsTitle: storyPoints?.title,
      notionSprintId: sprint?.id,
      notionSprintType: sprint?.type,
      notionSprintTitle: sprint?.title,
      notionParentId: parent?.id,
      notionParentType: parent?.type,
      notionParentTitle: parent?.title,
      notionAssigneeId: assignee?.id,
      notionAssigneeType: assignee?.type,
      notionAssigneeTitle: assignee?.title,
      notionTypeOptions: type?.selectConfiguration?.options?.map(stripTypeNameFromOptions),
      notionTypeOptionIds: type?.selectConfiguration?.options?.map(o => o.optionId),
      notionTypeUpserts: getNotionOptionUpserts(type?.selectConfiguration),
      statusUpserts: getStatusUpserts(),
      statusNotionIds: getStatusNotionIds(),
      typeUpserts: getTypeUpserts(),
      typeNotionIds: getTypeNotionIds()
    }
  })

  const defaultDatabase = () => (requirementTable ? {
    title: requirementTable.notionDatabaseTitle,
    id: requirementTable.notionDatabaseId
  } : null)

  const defaultNumber = () => (requirementTable?.notionNumber ? {
    title: requirementTable.notionNumber.propertyTitle,
    id: requirementTable.notionNumber.propertyId,
    type: requirementTable.notionNumber.propertyType
  } : null)

  const defaultStatus = () => (requirementTable?.notionStatus ? {
    title: requirementTable.notionStatus.propertyTitle,
    id: requirementTable.notionStatus.propertyId,
    type: requirementTable.notionStatus.propertyType,
    selectConfiguration: requirementTable.notionStatus.selectConfiguration
  } : null)

  const defaultType = () => (requirementTable?.notionType ? {
    title: requirementTable.notionType.propertyTitle,
    id: requirementTable.notionType.propertyId,
    type: requirementTable.notionType.propertyType,
    selectConfiguration: requirementTable.notionType.selectConfiguration
  } : null)

  const defaultStoryPoints = () => (requirementTable?.notionStoryPoints ? {
    title: requirementTable.notionStoryPoints.propertyTitle,
    id: requirementTable.notionStoryPoints.propertyId,
    type: requirementTable.notionStoryPoints.propertyType
  } : null)

  const defaultSprint = () => (requirementTable?.notionSprint ? {
    title: requirementTable.notionSprint.propertyTitle,
    id: requirementTable.notionSprint.propertyId,
    type: requirementTable.notionSprint.propertyType
  } : null)

  const defaultParent = () => (requirementTable?.notionParent ? {
    title: requirementTable.notionParent.propertyTitle,
    id: requirementTable.notionParent.propertyId,
    type: requirementTable.notionParent.propertyType
  } : null)

  const defaultAssignee = () => (requirementTable?.notionAssignee ? {
    title: requirementTable.notionAssignee.propertyTitle,
    id: requirementTable.notionAssignee.propertyId,
    type: requirementTable.notionAssignee.propertyType
  } : null)

  const defaultPlanned = () => (requirementTable?.statuses?.filter(s => s.type === 'PLANNED').map(s => ({
    title: s.name,
    id: s.notionId,
    color: s.color
  })) || null)

  const defaultInProgress = () => (requirementTable?.statuses?.filter(s => s.type === 'IN_PROGRESS').map(s => ({
    title: s.name,
    id: s.notionId,
    color: s.color
  })) || null)

  const defaultComplete = () => (requirementTable?.statuses?.filter(s => s.type === 'COMPLETE').map(s => ({
    title: s.name,
    id: s.notionId,
    color: s.color
  })) || null)

  const defaultRemoved = () => (requirementTable?.statuses?.filter(s => s.type === 'REMOVED').map(s => ({
    title: s.name,
    id: s.notionId,
    color: s.color
  })) || null)

  const defaultUserStory = () => (requirementTable?.types?.filter(t => t.type === 'USER_STORY').map(t => ({
    title: t.name,
    id: t.notionId,
    color: t.color
  })) || null)

  const defaultDefect = () => (requirementTable?.types?.filter(t => t.type === 'DEFECT').map(t => ({
    title: t.name,
    id: t.notionId,
    color: t.color
  })) || null)

  const defaultTask = () => (requirementTable?.types?.filter(t => t.type === 'TASK').map(t => ({
    title: t.name,
    id: t.notionId,
    color: t.color
  })) || null)

  const defaultOther = () => (requirementTable?.types?.filter(t => t.type === 'OTHER').map(t => ({
    title: t.name,
    id: t.notionId,
    color: t.color
  })) || null)

  const canSave = () => {
    return (defaultDatabase()?.id !== database?.id
      || defaultNumber()?.id !== number?.id
      || defaultStatus()?.id !== status?.id
      || defaultType()?.id !== type?.id
      || defaultStoryPoints()?.id !== storyPoints?.id
      || defaultSprint()?.id !== sprint?.id
      || defaultParent()?.id !== parent?.id
      || defaultAssignee()?.id !== assignee?.id
      || !sameOptions(defaultInProgress(), inProgress)
      || !sameOptions(defaultPlanned(), planned)
      || !sameOptions(defaultComplete(), complete)
      || !sameOptions(defaultRemoved(), removed)
      || !sameOptions(defaultUserStory(), userStory)
      || !sameOptions(defaultDefect(), defect)
      || !sameOptions(defaultTask(), task)
      || !sameOptions(defaultOther(), other)
    ) && database?.id
      && database?.title
      && number?.id
      && number?.type
      && number?.title
      && status?.id
      && status?.title
      && status?.type
      && type?.id
      && type?.title
      && type?.type
      && storyPoints?.id
      && storyPoints?.title
      && storyPoints?.type
      && sprint?.id
      && sprint?.title
      && sprint?.type
      && parent?.id
      && parent?.title
      && parent?.type
      && assignee?.id
      && assignee?.title
      && assignee?.type
    && planned?.length
    && inProgress?.length
    && complete?.length
    && userStory?.length
    && defect?.length
    && task?.length
  }

  const sameOptions = (left, right) => left?.length === right?.length && left.every(lv => right.find(rv => rv.id === lv.id))

  const mapPropertyOptions = options => options?.map(o => ({
    title: o.name,
    id: o.id,
    type: o.type,
    selectConfiguration: {
      options: o.select?.options?.map(so => ({
        optionName: so.name,
        optionId: so.id,
        optionColor: so.color,
      })),
    }
  }))

  const mapStatusOptions = options => mapPropertyOptions(options)?.map(o => ({
    ...o,
    selectConfiguration: {
      ...o.selectConfiguration,
      id: defaultStatus()?.selectConfiguration?.id || -1,
    }
  }))

  const mapTypeOptions = options => mapPropertyOptions(options)?.map(o => ({
    ...o,
    selectConfiguration: {
      ...o.selectConfiguration,
      id: defaultType()?.selectConfiguration?.id || -1,
    }
  }))

  const mapSelectOptions = options => options?.map(o => ({
    title: o.name,
    id: o.id,
    color: o.color
  }))

  const filterAvailableStatuses = statuses => statuses?.filter(s => {
    return !planned?.find(p => p.id === s.id) && !inProgress?.find(p => p.id === s.id) && !complete?.find(c => c.id === s.id) && !removed?.find(r => r.id === s.id)
  })

  const filterAvailableTypes = types => types?.filter(t => {
    return !userStory?.find(u => u.id === t.id) && !defect?.find(d => d.id === t.id) && !task?.find(k => k.id === t.id) && !other?.find(o => o.id === t.id)
  })

  useEffect(() => {
    if (data?.requirementTable && data?.requirementStatuses && data?.requirementTypes) {
      setRequirementTable({ ...data.requirementTable, statuses: data?.requirementStatuses, types: data?.requirementTypes })
    }
  }, [data])

  useEffect(() => {
    setDatabase(defaultDatabase())
    setNumber(defaultNumber())
    setStatus(defaultStatus())
    setType(defaultType())
    setStoryPoints(defaultStoryPoints())
    setSprint(defaultSprint())
    setParent(defaultParent())
    setAssignee(defaultAssignee())
  }, [requirementTable])

  useEffect(() => {
    if (status?.id) {
      if (database?.id === defaultDatabase()?.id && status?.id === defaultStatus()?.id) {
        setPlanned(defaultPlanned())
        setInProgress(defaultInProgress())
        setComplete(defaultComplete())
        setRemoved(defaultRemoved())
      } else {
        setPlanned([])
        setInProgress([])
        setComplete([])
        setRemoved([])
      }
    }
  }, [status])

  useEffect(() => {
    if (type?.id) {
      if (database?.id === defaultDatabase()?.id && type?.id === defaultType()?.id) {
        setUserStory(defaultUserStory())
        setDefect(defaultDefect())
        setTask(defaultTask())
        setOther(defaultOther())
      } else {
        setUserStory([])
        setDefect([])
        setTask([])
        setOther([])
      }
    }
  }, [type])

  const onDatabaseChange = (event, value) => {
    setDatabase(value)
    if (value?.id === defaultDatabase()?.id) {
      setNumber(defaultNumber())
      setStatus(defaultStatus())
      setType(defaultType())
      setStoryPoints(defaultStoryPoints())
      setSprint(defaultSprint())
      setParent(defaultParent())
      setAssignee(defaultAssignee())
    } else {
      setNumber(null)
      setStatus(null)
      setType(null)
      setStoryPoints(null)
      setSprint(null)
      setParent(null)
      setAssignee(null)
    }
  }

  const onNumberChange = (event, value) => {
    setNumber(value)
  }

  const onStatusChange = (event, value) => {
    setStatus(value)
  }

  const onTypeChange = (event, value) => {
    setType(value)
  }

  const onStoryPointsChange = (event, value) => {
    setStoryPoints(value)
  }

  const onSprintChange = (event, value) => {
    setSprint(value)
  }

  const onParentChange = (event, value) => {
    setParent(value)
  }

  const onAssigneeChange = (event, value) => {
    setAssignee(value)
  }

  const onPlannedChange = (event, value) => {
    setPlanned(value)
  }

  const onInProgressChange = (event, value) => {
    setInProgress(value)
  }

  const onCompleteChange = (event, value) => {
    setComplete(value)
  }

  const onRemovedChange = (event, value) => {
    setRemoved(value)
  }

  const onUserStoryChange = (event, value) => {
    setUserStory(value)
  }

  const onDefectChange = (event, value) => {
    setDefect(value)
  }

  const onTaskChange = (event, value) => {
    setTask(value)
  }

  const onOtherChange = (event, value) => {
    setOther(value)
  }

  const onSave = () => {
    updateRequirementTable().catch(e => {
      //Todo: display message to use that there was a problem saving
      console.error(e)
      // Set fields (state) back to defaults
      setDatabase(defaultDatabase())
      setNumber(defaultNumber())
      setStatus(defaultStatus())
      setType(defaultType())
      setStoryPoints(defaultStoryPoints())
      setSprint(defaultSprint())
      setParent(defaultParent())
      setAssignee(defaultAssignee())
    }).then(res => {
      console.log(res)
    })
  }

  return (
    <Card
      classes={{
        root: classes.cardRoot + " " + classes.cardRootSecondary,
      }}
    >
      <CardHeader
        subheader={
          <Grid
            container
            component={Box}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs="auto">
              <Box
                component={Typography}
                variant="h3"
                marginBottom="0!important"
              >
                Requirements
              </Box>
            </Grid>
            <Grid item xs="auto">
              <Box
                justifyContent="flex-end"
                display="flex"
                flexWrap="wrap"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!canSave() || user?.readOnly}
                  onClick={onSave}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
        classes={{ root: classes.cardHeaderRoot }}
      />

        <CardContent classes={{ root: classes.requirementCardContent }}>
          { loading || !projectId ? <Box classes={{ root: classes.centerContent }}><CircularProgress classes={ classes.centerCardContent }/></Box> :
            <>
              <Box
                component={Typography}
                variant="h6"
                color={theme.palette.gray[600] + "!important"}
                paddingTop=".25rem"
                paddingBottom=".25rem"
                fontSize=".75rem!important"
                letterSpacing=".04em"
                marginBottom="1.5rem!important"
                classes={{root: classes.typographyRootH6}}
              >
                Table
              </Box>
              <div className={classes.plLg4}>
                <Grid container>
                  <Grid item xs={12} lg={8}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={user?.readOnly}
                          query={NOTION_DATABASES}
                          selectResponse={data => data.notionDatabases}
                          selectResults={response => response.results}
                          variables={{
                            projectId
                          }}
                          mapOptions={options => options?.map(o => ({
                            title: o.title[0]?.plain_text,
                            id: o.id
                          }))}
                          getOptionLabel={option => `${option.title} – ${option.id}`}
                          showId={true}
                          getOptionSelected={(option, value) => option.id === value.id}
                          label={'Database'}
                          onChange={onDatabaseChange}
                          value={database}
                          disableClearable={true}
                        />
                        <Box marginTop={'0.5rem'}>
                          <Tooltip title={'The database in Notion that holds all your requirements (User Stories, Bugs, and Tasks). If you have multiple databases with the same name, we recommend temporarily changing the one you want to a unique name so you can find it here.'}>
                            <HelpOutlineIcon/>
                          </Tooltip>
                        </Box>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
              <Box
                component={Divider}
                marginBottom="1.5rem!important"
                marginTop="1.5rem!important"
              />
              <Box
                component={Typography}
                variant="h6"
                color={theme.palette.gray[600] + "!important"}
                paddingTop=".25rem"
                paddingBottom=".25rem"
                fontSize=".75rem!important"
                letterSpacing=".04em"
                marginBottom="1.5rem!important"
                classes={{root: classes.typographyRootH6}}
              >
                Properties
              </Box>
              <div className={classes.plLg4}>
                <Grid container>
                  <Grid item xs={12} lg={4}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={user?.readOnly}
                          query={NOTION_DATABASE_PROPERTIES}
                          selectResponse={data => data.notionDatabaseProperties.filter(dp => dp.type === 'number')}
                          variables={{
                            projectId,
                            databaseId: database?.id
                          }}
                          mapOptions={mapPropertyOptions}
                          getOptionLabel={option => `${option.title} – ${option.id}`}
                          showId={true}
                          getOptionSelected={(option, value) => option.id === value.id}
                          label={'Number'}
                          onChange={onNumberChange}
                          value={number}
                          disableClearable={true}
                        />
                        <Tooltip title={'NotionOps will automatically assign unique identifiers to this number property for each requirement.'}>
                          <Box marginTop={'0.5rem'}>
                            <HelpOutlineIcon/>
                          </Box>
                        </Tooltip>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={user?.readOnly}
                          query={NOTION_DATABASE_PROPERTIES}
                          selectResponse={data => data.notionDatabaseProperties.filter(dp => dp.type === 'select' || dp.type === 'status')}
                          variables={{
                            projectId,
                            databaseId: database?.id
                          }}
                          mapOptions={mapStatusOptions}
                          getOptionLabel={option => `${option.title} – ${option.id}`}
                          showId={true}
                          getOptionSelected={(option, value) => option.id === value.id}
                          label={'Status'}
                          onChange={onStatusChange}
                          value={status}
                          disableClearable={true}
                        />
                        <Box marginTop={'0.5rem'}>
                          <Tooltip title={'A select property (single) that represents the status of a requirement.'}>
                            <HelpOutlineIcon/>
                          </Tooltip>
                        </Box>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={user?.readOnly}
                          query={NOTION_DATABASE_PROPERTIES}
                          selectResponse={data => data.notionDatabaseProperties.filter(dp => dp.type === 'select')}
                          variables={{
                            projectId,
                            databaseId: database?.id,
                          }}
                          mapOptions={mapTypeOptions}
                          getOptionLabel={option => `${option.title} – ${option.id}`}
                          showId={true}
                          getOptionSelected={(option, value) => option.id === value.id}
                          label={'Type'}
                          onChange={onTypeChange}
                          value={type}
                          disableClearable={true}
                        />
                        <Box marginTop={'0.5rem'}>
                          <Tooltip title={'A select property (single) that represents the type of requirement: User Story, Bug, or Task'}>
                            <HelpOutlineIcon/>
                          </Tooltip>
                        </Box>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={user?.readOnly}
                          query={NOTION_DATABASE_PROPERTIES}
                          selectResponse={data => data.notionDatabaseProperties.filter(dp => dp.type === 'number')}
                          variables={{
                            projectId,
                            databaseId: database?.id,
                          }}
                          mapOptions={mapPropertyOptions}
                          getOptionLabel={option => `${option.title} – ${option.id}`}
                          showId={true}
                          getOptionSelected={(option, value) => option.id === value.id}
                          label={'Story Points'}
                          onChange={onStoryPointsChange}
                          value={storyPoints}
                          disableClearable={true}
                        />
                        <Box marginTop={'0.5rem'}>
                          <Tooltip title={'A number property containing the story point score assigned to a requirement.'}>
                            <HelpOutlineIcon/>
                          </Tooltip>
                        </Box>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={user?.readOnly}
                          query={NOTION_DATABASE_PROPERTIES}
                          selectResponse={data => data.notionDatabaseProperties.filter(dp => dp.type === 'relation')}
                          variables={{
                            projectId,
                            databaseId: database?.id,
                          }}
                          mapOptions={mapPropertyOptions}
                          getOptionLabel={option => `${option.title} – ${option.id}`}
                          showId={true}
                          getOptionSelected={(option, value) => option.id === value.id}
                          label={'Sprint'}
                          onChange={onSprintChange}
                          value={sprint}
                          disableClearable={true}
                        />
                        <Box marginTop={'0.5rem'}>
                          <Tooltip title={'A relation property containing a reference to the sprint database. This is how requirements are assigned to sprints, producing the sprint backlog.'}>
                            <HelpOutlineIcon/>
                          </Tooltip>
                        </Box>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={user?.readOnly}
                          query={NOTION_DATABASE_PROPERTIES}
                          selectResponse={data => data.notionDatabaseProperties.filter(dp => dp.type === 'people')}
                          variables={{
                            projectId,
                            databaseId: database?.id,
                          }}
                          mapOptions={mapPropertyOptions}
                          getOptionLabel={option => `${option.title} – ${option.id}`}
                          showId={true}
                          getOptionSelected={(option, value) => option.id === value.id}
                          label={'Assignee'}
                          onChange={onAssigneeChange}
                          value={assignee}
                          disableClearable={true}
                        />
                        <Box marginTop={'0.5rem'}>
                          <Tooltip title={'A people property containing the user assigned to this requirement.'}>
                            <HelpOutlineIcon/>
                          </Tooltip>
                        </Box>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={user?.readOnly}
                          query={NOTION_DATABASE_PROPERTIES}
                          selectResponse={data => data.notionDatabaseProperties.filter(dp => dp.type === 'relation')}
                          variables={{
                            projectId,
                            databaseId: database?.id,
                          }}
                          mapOptions={mapPropertyOptions}
                          getOptionLabel={option => `${option.title} – ${option.id}`}
                          showId={true}
                          getOptionSelected={(option, value) => option.id === value.id}
                          label={'Parent'}
                          onChange={onParentChange}
                          value={parent}
                          disableClearable={true}
                        />
                        <Tooltip title={'A relation property containing a reference to a parent requirement. This is how parent-child requirements are formed. For now child requirements are excluded from analytics in favor of their parent, but this setting will be configurable soon!'}>
                          <Box marginTop={'0.5rem'}>
                            <HelpOutlineIcon/>
                          </Box>
                        </Tooltip>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
              {status?.selectConfiguration?.options ?
                <>
                  <Box
                    component={Typography}
                    variant="h6"
                    color={theme.palette.gray[600] + "!important"}
                    paddingTop=".25rem"
                    paddingBottom=".25rem"
                    fontSize=".75rem!important"
                    letterSpacing=".04em"
                    marginBottom="1.5rem!important"
                    classes={{root: classes.typographyRootH6}}
                  >
                    Status Groups
                  </Box>
                  <div className={classes.plLg4}>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                          >
                            <Select
                              query={NOTION_DATABASE_PROPERTIES}
                              selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === status?.id)?.select?.options}
                              variables={{
                                projectId,
                                databaseId: database?.id
                              }}
                              disabled={user?.readOnly}
                              mapOptions={options => filterAvailableStatuses(mapSelectOptions(options))}
                              getOptionLabel={option => option.title}
                              getOptionSelected={(option, value) => option.id === value.id}
                              label={'Planned'}
                              onChange={onPlannedChange}
                              value={planned}
                              multiple={true}
                              disableClearable={true}
                            />
                            <Box marginTop={'0.5rem'}>
                              <Tooltip title={'Any status that indicates an item has not be started yet. For example: Not Started, Planning, New etc.'}>
                                <HelpOutlineIcon/>
                              </Tooltip>
                            </Box>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                          >
                            <Select
                              query={NOTION_DATABASE_PROPERTIES}
                              selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === status?.id)?.select?.options}
                              variables={{
                                projectId,
                                databaseId: database?.id
                              }}
                              disabled={user?.readOnly}
                              mapOptions={options => filterAvailableStatuses(mapSelectOptions(options))}
                              getOptionLabel={option => option.title}
                              getOptionSelected={(option, value) => option.id === value.id}
                              label={'In Progress'}
                              onChange={onInProgressChange}
                              value={inProgress}
                              multiple={true}
                              disableClearable={true}
                            />
                            <Box marginTop={'0.5rem'}>
                              <Tooltip title={'Any status that indicates an item is currently being worked on. For example: In Progress, In Review, Testing etc.'}>
                                <HelpOutlineIcon/>
                              </Tooltip>
                            </Box>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                          >
                            <Select
                              query={NOTION_DATABASE_PROPERTIES}
                              selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === status?.id)?.select?.options}
                              variables={{
                                projectId,
                                databaseId: database?.id
                              }}
                              disabled={user?.readOnly}
                              mapOptions={options => filterAvailableStatuses(mapSelectOptions(options))}
                              getOptionLabel={option => option.title}
                              getOptionSelected={(option, value) => option.id === value.id}
                              label={'Complete'}
                              onChange={onCompleteChange}
                              value={complete}
                              multiple={true}
                              disableClearable={true}
                            />
                            <Box marginTop={'0.5rem'}>
                              <Tooltip title={'Any status that indicates an item is complete. For example: Complete, Done, Final etc.'}>
                                <HelpOutlineIcon/>
                              </Tooltip>
                            </Box>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                          >
                            <Select
                              query={NOTION_DATABASE_PROPERTIES}
                              selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === status?.id)?.select?.options}
                              variables={{
                                projectId,
                                databaseId: database?.id
                              }}
                              disabled={user?.readOnly}
                              mapOptions={options => filterAvailableStatuses(mapSelectOptions(options))}
                              getOptionLabel={option => option.title}
                              getOptionSelected={(option, value) => option.id === value.id}
                              label={'Removed (optional)'}
                              onChange={onRemovedChange}
                              value={removed}
                              multiple={true}
                              disableClearable={true}
                            />
                            <Box marginTop={'0.5rem'}>
                              <Tooltip title={'Any status that indicates an item should not be counted for analytics. For example: On Hold, Removed, Deleted etc.'}>
                                <HelpOutlineIcon/>
                              </Tooltip>
                            </Box>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </div>
                </>
                : null }
              {type?.selectConfiguration?.options ?
                <>
                  <Box
                    component={Typography}
                    variant="h6"
                    color={theme.palette.gray[600] + "!important"}
                    paddingTop=".25rem"
                    paddingBottom=".25rem"
                    fontSize=".75rem!important"
                    letterSpacing=".04em"
                    marginBottom="1.5rem!important"
                    classes={{root: classes.typographyRootH6}}
                  >
                    Types Groups
                  </Box>
                  <div className={classes.plLg4}>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                          >
                            <Select
                              query={NOTION_DATABASE_PROPERTIES}
                              selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === type?.id)?.select?.options}
                              variables={{
                                projectId,
                                databaseId: database?.id
                              }}
                              disabled={user?.readOnly}
                              mapOptions={options => filterAvailableTypes(mapSelectOptions(options))}
                              getOptionLabel={option => option.title}
                              getOptionSelected={(option, value) => option.id === value.id}
                              label={'User Story'}
                              onChange={onUserStoryChange}
                              value={userStory}
                              multiple={true}
                              disableClearable={true}
                            />
                            <Box marginTop={'0.5rem'}>
                              <Tooltip title={'Requirement that describes a feature from the perspective of a user (i.e. User Story).'}>
                                <HelpOutlineIcon/>
                              </Tooltip>
                            </Box>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                          >
                            <Select
                              query={NOTION_DATABASE_PROPERTIES}
                              selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === type?.id)?.select?.options}
                              variables={{
                                projectId,
                                databaseId: database?.id
                              }}
                              disabled={user?.readOnly}
                              mapOptions={options => filterAvailableTypes(mapSelectOptions(options))}
                              getOptionLabel={option => option.title}
                              getOptionSelected={(option, value) => option.id === value.id}
                              label={'Defect'}
                              onChange={onDefectChange}
                              value={defect}
                              multiple={true}
                              disableClearable={true}
                            />
                            <Box marginTop={'0.5rem'}>
                              <Tooltip title={'Requirement to investigate/resolve an unintended abnormality (i.e. Bug or Issue).'}>
                                <HelpOutlineIcon/>
                              </Tooltip>
                            </Box>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                          >
                            <Select
                              query={NOTION_DATABASE_PROPERTIES}
                              selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === type?.id)?.select?.options}
                              variables={{
                                projectId,
                                databaseId: database?.id
                              }}
                              disabled={user?.readOnly}
                              mapOptions={options => filterAvailableTypes(mapSelectOptions(options))}
                              getOptionLabel={option => option.title}
                              getOptionSelected={(option, value) => option.id === value.id}
                              label={'Task'}
                              onChange={onTaskChange}
                              value={task}
                              multiple={true}
                              disableClearable={true}
                            />
                            <Box marginTop={'0.5rem'}>
                              <Tooltip title={'Requirements that are generic task or a child task of another requirement (i.e. Task).'}>
                                <HelpOutlineIcon/>
                              </Tooltip>
                            </Box>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                          >
                            <Select
                              query={NOTION_DATABASE_PROPERTIES}
                              selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === type?.id)?.select?.options}
                              variables={{
                                projectId,
                                databaseId: database?.id
                              }}
                              disabled={user?.readOnly}
                              mapOptions={options => filterAvailableTypes(mapSelectOptions(options))}
                              getOptionLabel={option => option.title}
                              getOptionSelected={(option, value) => option.id === value.id}
                              label={'Other (optional)'}
                              onChange={onOtherChange}
                              value={other}
                              multiple={true}
                              disableClearable={true}
                            />
                          </FormControl>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </div>
                </>
                : null }
            </>
          }
        </CardContent>
    </Card>
  )
}

export default Requirements
