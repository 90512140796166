export const selectActiveStep = state => {
  return state.setup.activeStep
}

export const selectOrganization = state => {
  return state.setup.organization
}

export const selectAuthorization = state => {
  return state.setup.authorization
}

export const selectProject = state => {
  return state.setup.project
}

export const selectSprintDatabase = state => {
  return state.setup.sprintDatabase
}

export const selectSprintProperties = state => {
  return state.setup.sprintProperties
}

export const selectRequirementDatabase = state => {
  return state.setup.requirementDatabase
}

export const selectRequirementProperties = state => {
  return state.setup.requirementProperties
}

export const selectRequirementStatuses = state => {
  return state.setup.requirementStatuses
}

export const selectRequirementTypes = state => {
  return state.setup.requirementTypes
}
