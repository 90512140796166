import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import { FormControl, CircularProgress, CardContent, CardHeader, makeStyles, useTheme} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import componentStyles from '../../../../assets/theme/views/admin/profile'
import { SPRINT_TABLE, NOTION_DATABASES, NOTION_DATABASE_PROPERTIES } from '../../../../queries'
import { UPDATE_SPRINT_TABLE } from '../../../../mutations'
import { useSelector } from 'react-redux'
import { Select } from '../../../../components'
import { useQuery, useMutation } from '@apollo/client'
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {selectUser} from "../../../auth/selectors";

const useStyles = makeStyles(componentStyles)

const Sprints = () => {
  const classes = useStyles()
  const theme = useTheme()

  const projectId = useSelector(state => state.auth?.user?.lastOpenedProject?.id)
  const user = useSelector(selectUser)

  const [database, setDatabase] = useState(null)
  const [dateRange, setDateRange] = useState(null)
  const [plannedEffort, setPlannedEffort] = useState(null)
  const [backlog, setBacklog] = useState(null)
  const [state, setState] = useState(null)
  const [sprintTable, setSprintTable] = useState(null)

  const { loading, data, refetch } = useQuery(SPRINT_TABLE, {
    variables: {
      projectId
    },
  })

  useEffect(() => {
    refetch()
  }, projectId)

  const [updateSprintTable, { loading: saving }] = useMutation(UPDATE_SPRINT_TABLE, {
    variables: {
      projectId,
      notionDatabaseId: database?.id,
      notionDatabaseTitle: database?.title,
      notionDateRangeId: dateRange?.id,
      notionDateRangeTitle: dateRange?.title,
      notionDateRangeType: dateRange?.type,
      notionPlannedEffortId: plannedEffort?.id,
      notionPlannedEffortTitle: plannedEffort?.title,
      notionPlannedEffortType: plannedEffort?.type,
      notionBacklogId: backlog?.id,
      notionBacklogTitle: backlog?.title,
      notionBacklogType: backlog?.type,
      notionStateId: state?.id,
      notionStateTitle: state?.title,
      notionStateType: state?.type,
    }
  })

  const defaultDatabase = () => (sprintTable ? {
    title: sprintTable.notionDatabaseTitle,
    id: sprintTable.notionDatabaseId
  } : null)

  const defaultDateRange = () => (sprintTable?.notionDateRange ? {
    title: sprintTable.notionDateRange.propertyTitle,
    id: sprintTable.notionDateRange.propertyId,
    type: sprintTable.notionDateRange.propertyType
  } : null)

  const defaultPlannedEffort = () => (sprintTable?.notionPlannedEffort ? {
    title: sprintTable.notionPlannedEffort.propertyTitle,
    id: sprintTable.notionPlannedEffort.propertyId,
    type: sprintTable.notionPlannedEffort.propertyType
  } : null)

  const defaultBacklog = () => (sprintTable?.notionBacklog ? {
    title: sprintTable.notionBacklog.propertyTitle,
    id: sprintTable.notionBacklog.propertyId,
    type: sprintTable.notionBacklog.propertyType
  } : null)

  const defaultState = () => (sprintTable?.notionState ? {
    title: sprintTable.notionState.propertyTitle,
    id: sprintTable.notionState.propertyId,
    type: sprintTable.notionState.propertyType
  } : null)

  const canSave = () => {
    return (defaultDatabase()?.id !== database?.id
        || defaultDateRange()?.id !== dateRange?.id
        || defaultPlannedEffort()?.id !== plannedEffort?.id
        || defaultBacklog()?.id !== backlog?.id
        || defaultState()?.id !== state?.id
      ) && database?.id
      && database?.title
      && dateRange?.id
      && dateRange?.type
      && dateRange?.title
      && plannedEffort?.id
      && plannedEffort?.title
      && plannedEffort?.type
      && backlog?.id
      && backlog?.type
      && backlog?.title
      && state?.id
      && state?.type
      && state?.title
  }

  const mapPropertyOptions = options => options?.map(o => ({
    title: o.name,
    id: o.id,
    type: o.type
  }))

  useEffect(() => {
    if (data?.sprintTable) {
      setSprintTable(data.sprintTable)
    }
  }, [data])

  useEffect(() => {
    setDatabase(defaultDatabase())
    setDateRange(defaultDateRange())
    setPlannedEffort(defaultPlannedEffort())
    setBacklog(defaultBacklog())
    setState(defaultState())
  }, [sprintTable])

  const onDatabaseChange = (event, value) => {
    setDatabase(value)
    if (value?.id === defaultDatabase()?.id) {
      setDateRange(defaultDateRange())
      setPlannedEffort(defaultPlannedEffort())
      setBacklog(defaultBacklog())
      setState(defaultState())
    } else {
      setDateRange(null)
      setPlannedEffort(null)
      setBacklog(null)
      setState(null)
    }
  }

  const onDateRangeChange = (event, value) => {
    setDateRange(value)
  }

  const onPlannedEffortChange = (event, value) => {
    setPlannedEffort(value)
  }

  const onBacklogChange = (event, value) => {
    setBacklog(value)
  }

  const onStateChange = (event, value) => {
    setState(value)
  }

  const onSave = () => {
    updateSprintTable().catch(e => {
      //Todo: display message to use that there was a problem saving
      console.error(e)
      // Set fields (state) back to defaults
      setDatabase(defaultDatabase())
      setDateRange(defaultDateRange())
      setPlannedEffort(defaultPlannedEffort())
      setBacklog(defaultBacklog())
      setState(defaultState())
    }).then(res => {
      console.log(res)
    })
  }

  return (
    <Card
      classes={{
        root: classes.cardRoot + " " + classes.cardRootSecondary,
      }}
    >
      <CardHeader
        subheader={
          <Grid
            container
            component={Box}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs="auto">
              <Box
                component={Typography}
                variant="h3"
                marginBottom="0!important"
              >
                Sprints
              </Box>
            </Grid>
            <Grid item xs="auto">
              <Box
                justifyContent="flex-end"
                display="flex"
                flexWrap="wrap"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!canSave() || user?.readOnly}
                  onClick={onSave}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
        classes={{ root: classes.cardHeaderRoot }}
      />

      <CardContent classes={{ root: classes.requirementCardContent }}>
        { loading || !projectId ? <Box classes={{ root: classes.centerContent }}><CircularProgress classes={ classes.centerCardContent }/></Box> :
          <>
            <Box
              component={Typography}
              variant="h6"
              color={theme.palette.gray[600] + "!important"}
              paddingTop=".25rem"
              paddingBottom=".25rem"
              fontSize=".75rem!important"
              letterSpacing=".04em"
              marginBottom="1.5rem!important"
              classes={{root: classes.typographyRootH6}}
            >
              Table Setup
            </Box>
            <div className={classes.plLg4}>
              <Grid container>
                <Grid item xs={12} lg={8}>
                  <FormGroup>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <Select
                        disabled={user?.readOnly}
                        query={NOTION_DATABASES}
                        selectResponse={data => data.notionDatabases}
                        selectResults={response => response.results}
                        variables={{
                          projectId
                        }}
                        mapOptions={options => options?.map(o => ({
                          title: o.title[0]?.plain_text,
                          id: o.id
                        }))}
                        getOptionLabel={option => `${option.title} – ${option.id}`}
                        showId={true}
                        getOptionSelected={(option, value) => option.id === value.id}
                        label={'Database'}
                        onChange={onDatabaseChange}
                        value={database}
                        disableClearable={true}
                      />
                      <Box marginTop={'0.5rem'}>
                        <Tooltip title={'The database in Notion that holds your sprints. If you have multiple databases with the same name, we recommend temporarily changing the one you want to a unique name so you can find it here.'}>
                          <HelpOutlineIcon/>
                        </Tooltip>
                      </Box>
                    </FormControl>
                  </FormGroup>
                </Grid>
              </Grid>
            </div>
            <Box
              component={Divider}
              marginBottom="1.5rem!important"
              marginTop="1.5rem!important"
            />
            <Box
              component={Typography}
              variant="h6"
              color={theme.palette.gray[600] + "!important"}
              paddingTop=".25rem"
              paddingBottom=".25rem"
              fontSize=".75rem!important"
              letterSpacing=".04em"
              marginBottom="1.5rem!important"
              classes={{root: classes.typographyRootH6}}
            >
              Property Setup
            </Box>
            <div className={classes.plLg4}>
              <Grid container>
                <Grid item xs={12} lg={4}>
                  <FormGroup>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <Select
                        disabled={user?.readOnly}
                        query={NOTION_DATABASE_PROPERTIES}
                        selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'date')}
                        variables={{
                          projectId,
                          databaseId: database?.id
                        }}
                        mapOptions={mapPropertyOptions}
                        getOptionLabel={option => `${option.title} – ${option.id}`}
                        showId={true}
                        getOptionSelected={(option, value) => option.id === value.id}
                        label={'Date Range'}
                        onChange={onDateRangeChange}
                        value={dateRange}
                        disableClearable={true}
                      />
                      <Tooltip title={'The date property for the start and end dates of a sprint.'}>
                        <Box marginTop={'0.5rem'}>
                          <HelpOutlineIcon/>
                        </Box>
                      </Tooltip>
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormGroup>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <Select
                        disabled={user?.readOnly}
                        query={NOTION_DATABASE_PROPERTIES}
                        selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'rollup')}
                        variables={{
                          projectId,
                          databaseId: database?.id
                        }}
                        mapOptions={mapPropertyOptions}
                        getOptionLabel={option => `${option.title} – ${option.id}`}
                        showId={true}
                        getOptionSelected={(option, value) => option.id === value.id}
                        label={'Total Effort'}
                        onChange={onPlannedEffortChange}
                        value={plannedEffort}
                        disableClearable={true}
                      />
                      <Box marginTop={'0.5rem'}>
                        <Tooltip title={'A rollup property that contains the total story points in a sprint backlog.'}>
                          <HelpOutlineIcon/>
                        </Tooltip>
                      </Box>
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormGroup>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <Select
                        disabled={user?.readOnly}
                        query={NOTION_DATABASE_PROPERTIES}
                        selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'relation')}
                        variables={{
                          projectId,
                          databaseId: database?.id
                        }}
                        mapOptions={mapPropertyOptions}
                        getOptionLabel={option => `${option.title} – ${option.id}`}
                        showId={true}
                        getOptionSelected={(option, value) => option.id === value.id}
                        label={'Sprint Backlog'}
                        onChange={onBacklogChange}
                        value={backlog}
                        disableClearable={true}
                      />
                      <Box marginTop={'0.5rem'}>
                        <Tooltip title={'A relation property to the requirements database which contains the backlog of a sprint.'}>
                          <HelpOutlineIcon/>
                        </Tooltip>
                      </Box>
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormGroup>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <Select
                        disabled={user?.readOnly}
                        query={NOTION_DATABASE_PROPERTIES}
                        selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'formula')}
                        variables={{
                          projectId,
                          databaseId: database?.id
                        }}
                        mapOptions={mapPropertyOptions}
                        getOptionLabel={option => `${option.title} – ${option.id}`}
                        showId={true}
                        getOptionSelected={(option, value) => option.id === value.id}
                        label={'State'}
                        onChange={onStateChange}
                        value={state}
                        disableClearable={true}
                      />
                      <Box marginTop={'0.5rem'}>
                        <Tooltip title={'A formula property that calculates the state of a sprint: Planned, Current, or Past'}>
                          <HelpOutlineIcon/>
                        </Tooltip>
                      </Box>
                    </FormControl>
                  </FormGroup>
                </Grid>
              </Grid>
            </div>
          </>
        }
      </CardContent>
    </Card>
  )
}

export default Sprints
