import React from 'react'
import {makeStyles, useTheme, Typography} from '@material-ui/core'

const useStyles = makeStyles(({}) => ({
  versionText: {
    textAlign: 'center',
    color: '#727d83'
  },
}))

const ProjectSelector = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <Typography variant={'h6'} className={classes.versionText}>
      v1.0.0
    </Typography>
  )
}

export default ProjectSelector
