import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
  },
  cardRootSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardHeaderRoot: {
    backgroundColor: theme.palette.white.main + "!important",
  },
  requirementCardContent: {
    minHeight: 378,
    position: 'relative'
  },
  organizationDetailsCardContent: {
    minHeight: 153,
    position: 'relative'
  },
  editPeopleCardContent: {
    minHeight: 153,
    position: 'relative'
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  circularProgress: {
  },
  selectInput: {
    borderStyle: 'none !important',
    backgroundColor: 'transparent'
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "39px",
      paddingRight: "39px",
    },
  },
  gridItemRoot: {
    [theme.breakpoints.up("xl")]: {
      marginBottom: "0!important",
    },
  },
  typographyRootH6: {
    textTransform: "uppercase",
  },
  plLg4: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "1.5rem",
    },
  },
  ptMd4: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: "1.5rem!important",
    },
  },
  mtMd5: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: "3rem!important",
    },
  },
  cardHeaderRootPeople: {
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "1.5rem!important",
      paddingTop: "1.5rem!important",
    },
  },
  buttonRootInfo: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
  buttonRootDark: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.dark.main,
    "&:hover": {
      backgroundColor: theme.palette.dark.dark,
    },
  },
  order1: {
    [theme.breakpoints.down("lg")]: {
      order: "1!important",
    },
  },
  order2: {
    [theme.breakpoints.down("lg")]: {
      order: "2!important",
    },
  },
  marginBottomXl0: {
    [theme.breakpoints.up("lg")]: {
      marginBottom: "0!important",
    },
  },
  editPeopleModalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '623 !important',
  },
  changePasswordText: {
    color: '#727d83',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: 10,
  },
  modalHeaderLeftWrapper: {
    display: 'flex',
  },
  inputErrorText: {
    color: 'red',
    fontSize: 10,
  },
  modalMessage: {
    marginLeft: 'auto',
  },
  modalMessageError: {
    color: '#f5365c',
  },
  buttonError: {
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    }
  }
});

export default componentStyles;
