import React, {useState} from 'react'
import TableContainer from "@material-ui/core/TableContainer";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core";
import cardStyles from "../../assets/theme/components/card";
import componentStyles from "../../assets/theme/components/table";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import {useMutation, useQuery} from "@apollo/client";
import {NOTION_MEMBERS, PROJECTS} from "../../queries";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {UPDATE_MEMBER_BILLING_STATUS} from "../../mutations";
import { CircularProgress } from "@material-ui/core";
import {setActiveModal} from "../Modals/actions";
import organization from "../../assets/theme/views/admin/organization";

const useStyles = makeStyles(props => ({
  ...cardStyles(props),
  ...componentStyles(props),
}))

const NotionMembersTableItem = ({ item, index }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState(null)

  const dispatch = useDispatch()
  const user = useSelector(state => state.auth?.user)

  const [updateBillingStatus] = useMutation(UPDATE_MEMBER_BILLING_STATUS, {
    onError: err => {
      console.log(err)
    }
  })



  const { data: allUsers } = useQuery(NOTION_MEMBERS, {
    pollInterval: 5000,
  })

  console.log()

  let memberRole = item?.user?.roles[0]?.name

  const owners = []
  owners.push(allUsers?.notionMembers.filter(member => member.user?.roles[0]?.name === "OWNER"))

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }




  const getStatusClass = status => {
    switch(status) {
      case 'Active':
        return classes.bgSuccess
      case 'Inactive':
        return classes.bgSecondary
      default:
        return classes.bgError
    }
  }

  const deactivateMember = async () => {
    await updateBillingStatus({
      variables:{
        id: item?.id,
        status: 'Deactivated',
        lastActive: new Date(),
      },
      optimisticResponse: {
        updateOneNotionMember: {
          id: item?.id,
          __typename: 'NotionMember',
          status: 'Deactivated',
          lastActive: new Date()
        }
      }
    })
    handleClose()
  }

  const activateMember = async () => {
    await updateBillingStatus({
      variables:{
        id: item?.id,
        status: 'Active',
        lastActive: new Date()
      },
      optimisticResponse: {
        updateOneNotionMember: {
          id: item?.id,
          __typename: 'NotionMember',
          status: 'Active',
          lastActive: new Date()
        }
      }
    })
    handleClose()
  }

  const inviteMember = (email) => {
    dispatch(setActiveModal({code: 'admin_site_invite', data: {email}}))
    handleClose()
  }

  const getActions = () => {
    let actions = []

    const userRoles = user?.roles?.map(r => r.name)
    const isAdmin = userRoles?.includes('ADMIN')
    const isOwner = userRoles?.includes('OWNER')

    const billingStatusUpdateDisabled = (!isAdmin && !isOwner) || item?.user?.id
    const inviteDisabled = !isAdmin && !isOwner
    const deleteDisabled = item?.user?.id



    if(item?.billingStatus !== 'Deactivated') {
      actions.push(<MenuItem disabled={billingStatusUpdateDisabled} onClick={() => deactivateMember()}>Deactivate</MenuItem>)
    } else {
      actions.push(<MenuItem disabled={billingStatusUpdateDisabled} onClick={() => activateMember()}>Activate</MenuItem>)
    }

    if(!item?.user?.id) {
      actions.push(<MenuItem disabled={inviteDisabled} onClick={() => inviteMember(item.email)}>Invite</MenuItem>)
    }

    return actions
  }

  if(memberRole) {
    memberRole = memberRole.toLowerCase()
      .replace('_', ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    memberRole = memberRole.charAt(0).toUpperCase() + memberRole.slice(1)
  }

  return !item ? null : (
    <TableRow
      classes={{ root: classes.tableRow }}
      style={{ cursor: 'pointer' }}
      key={item.name}
    >
      <TableCell classes={{ root: classes.tableCellRoot }}>
        <Box alignItems='center' display='flex'>
          <Box component={Avatar} marginRight='1rem' alt='...' src={item.avatarUrl} />
          <Box display='flex' alignItems='flex-start' flexDirection='column'>
            <Box style={{ color: '#2e3d44', fontWeight: 'bold' }}>{item.name}</Box>
            <Box style={{ color: 'rgba(46,61,68,0.9)' }}>{item.email}</Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          {memberRole || <div style={{height: 1, backgroundColor: 'lightgray', width: 25}}/>}
        </Box>
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        <Box
          paddingTop='.35rem'
          paddingBottom='.35rem'>
          <Box
            component='i'
            width='.375rem'
            height='.375rem'
            borderRadius='50%'
            display='inline-block'
            marginRight='10px'
            marginBottom='2px'
            className={getStatusClass(item.billingStatus)}
          />
          {item.billingStatus}
        </Box>
      </TableCell>
      <TableCell
        classes={{ root: classes.tableCellRoot }}
        align="right"
      >
        <Box
          aria-controls={`simple-menu-${index}`}
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          component={Button}
          width="2rem!important"
          height="2rem!important"
          minWidth="2rem!important"
          minHeight="2rem!important"
        >
          <Box
            component={MoreVert}
            width="1.25rem!important"
            height="1.25rem!important"
            position="relative"
            top="2px"
            color={theme.palette.gray[500]}
          />
        </Box>
        <Menu
          id={`simple-menu-${index}`}
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {getActions()}
        </Menu>
      </TableCell>
    </TableRow>
  )
}

const NotionMembersTable = () => {
  const classes = useStyles()

  const { data, loading } = useQuery(NOTION_MEMBERS, {
    pollInterval: 5000,
  })

  console.log(data)


  const getItems = members => {
    members.map(member => console.log(member.id))
    return members.map(member => <NotionMembersTableItem item={member}/>)
  }

  return (
    <>
      <TableContainer>
        <Box component={Table} alignItems='center' marginBottom='0!important'>
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}>
                Name
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}>
                Admin Site Access
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}>
                Billing Status
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{position: 'relative', height: loading ? 200 : 'auto'}}>
              {data?.notionMembers?.length ? getItems(data.notionMembers) : loading ? (
                <Box style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}>
                  <CircularProgress />
                </Box>
              ) : null}
          </TableBody>
        </Box>
      </TableContainer>
    </>
  )
}

export default NotionMembersTable
