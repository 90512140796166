import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import CardActions from '@material-ui/core/CardActions'
import Pagination from '@material-ui/lab/Pagination'
import {makeStyles} from '@material-ui/core'

import componentStyles from '../../assets/theme/components/table'
import cardStyles from '../../assets/theme/components/card'

import { useQuery } from '@apollo/client'
import {NOTION_AUTHORIZATIONS} from '../../queries'
import {useSelector} from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import MoreVert from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {useTheme} from '@material-ui/core/styles'
import {selectUser} from "../../views/auth/selectors";


const useStyles = makeStyles(props => ({
  ...cardStyles(props),
  ...componentStyles(props)
}))

const AuthorizationItem = ({ item, index, onReconnectClicked }) => {
  const classes = useStyles()
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null)

  const user = useSelector(selectUser)

  const getStatusClass = status => {
    switch(status) {
      case 'PENDING':
        return classes.bgSecondary
      case 'ACTIVE':
        return classes.bgSuccess
      case 'ERROR':
        return classes.bgError
    }
  }



  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleReconnect = () => {
    handleClose()
    onReconnectClicked()
  }

  return (
    <TableRow key={`auth-item-${index}`}>
      <TableCell classes={{
        root:
          classes.tableCellRoot +
          " " +
          classes.tableCellRootBodyHead,
      }}
        component="th"
        variant="head"
        scope="row"
      >
        <Box alignItems="center" display="flex">
          <Box
            component={Avatar}
            marginRight="1rem"
            alt="..."
            src={item.workspaceIcon}
          />
          <Box display="flex" alignItems="flex-start">
            <Box fontSize=".875rem" component="span">
              {item.workspaceName}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        <Box paddingTop=".35rem" paddingBottom=".35rem">
          <Box
            marginRight="10px"
            component="i"
            width=".375rem"
            height=".375rem"
            borderRadius="50%"
            display="inline-block"
            className={
              classes.verticalAlignMiddle + " " + getStatusClass(item.status)
            }
          ></Box>
          {item.status.replace(/_/g, ' ').toLowerCase()}
        </Box>
      </TableCell>
      {/*<TableCell classes={{ root: classes.tableCellRoot }}>
        Hi
      </TableCell>*/}
      <TableCell
        classes={{ root: classes.tableCellRoot }}
        align="right"
      >
        <Box
          aria-controls={`simple-menu-${index}`}
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          component={Button}
          width="2rem!important"
          height="2rem!important"
          minWidth="2rem!important"
          minHeight="2rem!important"
        >
          <Box
            component={MoreVert}
            width="1.25rem!important"
            height="1.25rem!important"
            position="relative"
            top="2px"
            color={theme.palette.gray[500]}
          />
        </Box>
        <Menu
          id={`simple-menu-${index}`}
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled={user?.readOnly} onClick={handleReconnect}>Reconnect</MenuItem>
          {/*<MenuItem disabled={user?.readOnly} onClick={handleClose}>Remove</MenuItem>*/}
        </Menu>
      </TableCell>
    </TableRow>
  )
}

const AuthorizationsTable = ({ onReconnectClicked }) => {
  const classes = useStyles()

  const organizationId = useSelector(state => state.auth?.user?.lastOpenedProject?.organization?.id)

  const { data, loading, error } = useQuery(NOTION_AUTHORIZATIONS, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId,
    }
  })

  const getItems = authorizations => {
    return authorizations.map((authorization, index) => <AuthorizationItem index={index} onReconnectClicked={onReconnectClicked} item={authorization}/>)
  }

  return (
    <>
      <TableContainer>
        <Box
          component={Table}
          alignItems="center"
          marginBottom="0!important"
        >
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead,
                }}
              >
                Workspace
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead,
                }}
              >
                Status
              </TableCell>
              {/*<TableCell
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead,
                }}
              >
                Projects
              </TableCell>*/}
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead,
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.notionAuthorizations?.length ? getItems(data.notionAuthorizations) : null}
          </TableBody>
        </Box>
      </TableContainer>
      <Box
        classes={{ root: classes.cardActionsRoot }}
        component={CardActions}
        justifyContent="flex-end"
      >
        {/*<Pagination count={3} color="primary" variant="outlined" />*/}
      </Box>
    </>
  )
}

export default AuthorizationsTable
