import { gql } from '@apollo/client/core'
import { me } from './selections'

export const SIGN_IN = gql`
  mutation Login($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      token,
      user ${me}
    }
  }
`

export const REGISTER_ORGANIZATION = gql`
  mutation RegisterOrganization($token: String, $name: String!, $subscribe: Boolean, $domain: String!, $fullName: String, $email: String!, $newPassword: String!, $confirmNewPassword: String!) {
    registerOrganization(
      token: $token,
      name: $name,
      domain: $domain,
      subscribe: $subscribe,
      email: $email,
      fullName: $fullName,
      newPassword: $newPassword,
      confirmNewPassword: $confirmNewPassword
    ) {
      id
      number
      name
      domain
    }
  }
`

export const CREATE_NOTION_AUTHORIZATION = gql`
  mutation CreateNotionAuthorization($organizationId: Int!, $clientId: String!, $code: String!, $redirectUri: String!) {
    createNotionAuthorization(organizationId:$organizationId, clientId:$clientId, code:$code, redirectUri:$redirectUri) {
      id,
      accessToken,
      workspaceId,
      workspaceName,
      workspaceIcon,
      botId,
      owner {
        id,
        workspace
      },
      status
    }
  }
`

export const CREATE_PROJECT = gql`
mutation CreateProject($name:String!, $orgId:Int!, $timezone: String!, $userId:Int!){
  createOneProject(data:{
    name:$name
    timezone:$timezone
    users:{
      connect: {
        id: $userId
      }
    }
    organization:{
      connect:{
        id:$orgId
      }
    }
    tenantId: $orgId
  }){
    id
    name
    timezone
    organization{
      id
    }
  }
}
`

export const UPSERT_FIRST_PROJECT = gql`
  mutation UpsertFirstProject($organizationId: Int!, $projectId: Int, $userId: Int!, $authorizationId: Int!, $name: String!, $timezone: String!) {
    updateOneOrganization(data: {
      projects: {
        upsert: {
          where: {
            id: $projectId
          },
          update: {
            name: {
              set: $name
            },
            timezone: {
              set: $timezone
            },
          },
          create: {
            name: $name,
            timezone: $timezone,
            tenantId: $organizationId,
            users: {
              connect: {
                id: $userId
              }
            },
            notionAuthorization: {
              connect: {
                id: $authorizationId
              }
            }
          },
        },
      }
    }, where: {
      id: $organizationId
    })
    {
      id,
      projects {
        id
        number
        name
        timezone
        deleted
        deletedOn
      }
    }
  }
`

export const UPSERT_FIRST_SPRINT_TABLE = gql`
  mutation UpsertFirstSprintTable(
    $organizationId:Int!, 
    $projectId:Int!,
    $notionDatabaseId:String!,
    $notionDatabaseTitle:String!,
    $notionDateRangeId:String!,
    $notionDateRangeTitle:String!,
    $notionDateRangeType:String!,
    $notionPlannedEffortId:String!,
    $notionPlannedEffortTitle:String!,
    $notionPlannedEffortType:String!,
    $notionBacklogId:String!,
    $notionBacklogTitle:String!,
    $notionBacklogType:String!,
    $notionStateId:String!,
    $notionStateTitle:String!,
    $notionStateType:String!
  ) {
    updateOneProject(data: {
      sprintTable: {
        upsert: {
          update: {
            notionDatabaseId: {
              set:$notionDatabaseId
            },
            notionDatabaseTitle: {
              set:$notionDatabaseTitle
            },
            notionDateRange: {
              upsert: {
                update: {
                  propertyId: {
                    set:$notionDateRangeId
                  },
                  propertyType: {
                    set:$notionDateRangeType
                  },
                  propertyTitle: {
                    set:$notionDateRangeTitle
                  }
                },
                create: {
                  tenantId: $organizationId,
                  propertyId:$notionDateRangeId,
                  propertyType:$notionDateRangeType,
                  propertyTitle:$notionDateRangeTitle
                }
              }
            },
            notionPlannedEffort: {
              upsert: {
                update: {
                  propertyId: {
                    set:$notionPlannedEffortId
                  },
                  propertyType: {
                    set:$notionPlannedEffortType
                  },
                  propertyTitle: {
                    set:$notionPlannedEffortTitle
                  }
                },
                create: {
                  tenantId: $organizationId,
                  propertyId:$notionPlannedEffortId,
                  propertyType:$notionPlannedEffortType,
                  propertyTitle:$notionPlannedEffortTitle
                }
              }
            },
            notionBacklog: {
              upsert: {
                update: {
                  propertyId: {
                    set:$notionBacklogId
                  },
                  propertyType: {
                    set:$notionBacklogType
                  },
                  propertyTitle: {
                    set:$notionBacklogTitle
                  }
                },
                create: {
                  tenantId: $organizationId,
                  propertyId:$notionBacklogId,
                  propertyType:$notionBacklogType,
                  propertyTitle:$notionBacklogTitle
                }
              }
            },
            notionState: {
              upsert: {
                update: {
                  propertyId: {
                    set:$notionStateId
                  },
                  propertyType: {
                    set:$notionStateType
                  },
                  propertyTitle: {
                    set:$notionStateTitle
                  }
                },
                create: {
                  tenantId: $organizationId,
                  propertyId:$notionStateId,
                  propertyType:$notionStateType,
                  propertyTitle:$notionStateTitle
                }
              }
            }
          },
          create: {
            tenantId: $organizationId,
            notionDatabaseId:$notionDatabaseId,
            notionDatabaseTitle: $notionDatabaseTitle,
            notionDateRange: {
              create: {
                tenantId: $organizationId,
                propertyId:$notionDateRangeId,
                propertyType:$notionDateRangeType,
                propertyTitle:$notionDateRangeTitle
              }
            },
            notionPlannedEffort: {
              create: {
                tenantId: $organizationId,
                propertyId:$notionPlannedEffortId,
                propertyType:$notionPlannedEffortType,
                propertyTitle:$notionPlannedEffortTitle
              }
            },
            notionBacklog: {
              create: {
                tenantId: $organizationId,
                propertyId:$notionBacklogId,
                propertyType:$notionBacklogType,
                propertyTitle:$notionBacklogTitle
              }
            },
            notionState: {
              create: {
                tenantId: $organizationId,
                propertyId:$notionStateId,
                propertyType:$notionStateType,
                propertyTitle:$notionStateTitle
              }
            }
          }
        }
      }
    }, where: {
      id: $projectId
    }) {
      id
      sprintTable {
        id
        notionDateRange {
          propertyId
          propertyTitle
          propertyType
        }
        notionPlannedEffort {
          propertyId
          propertyTitle
          propertyType
        }
        notionBacklog {
          propertyId
          propertyTitle
          propertyType
        }
        notionState {
          propertyId
          propertyTitle
          propertyType
        }
        notionDatabaseId
        notionDatabaseTitle
        numberSequenceEnabled
      }
    }
  }
`

export const UPSERT_FIRST_REQUIREMENT_TABLE = gql`
  mutation UpsertFirstRequirementTable(
    $organizationId:Int!,
    $projectId:Int!,
    $notionDatabaseId:String!,
    $notionDatabaseTitle:String!,
    $notionNumberId:String!,
    $notionNumberTitle:String!,
    $notionNumberType:String!,
    $notionStatusId:String!,
    $notionStatusTitle:String!,
    $notionStatusType:String!,
    $notionTypeId:String!,
    $notionTypeTitle:String!,
    $notionTypeType:String!,
    $notionAssigneeId:String!,
    $notionAssigneeTitle:String!,
    $notionAssigneeType:String!,
    $notionStoryPointsId:String!,
    $notionStoryPointsTitle:String!,
    $notionStoryPointsType:String!,
    $notionSprintId:String!,
    $notionSprintTitle:String!,
    $notionSprintType:String!,
    $notionParentId:String!,
    $notionParentTitle:String!,
    $notionParentType:String!,
    $notionStatusOptions:[NotionDatabaseSelectOptionCreateWithoutConfigurationInput!],
    $notionStatusOptionIds: [String!],
    $notionStatusUpserts: [NotionDatabaseSelectOptionUpsertWithWhereUniqueWithoutConfigurationInput!],
    $notionTypeOptions:[NotionDatabaseSelectOptionCreateWithoutConfigurationInput!],
    $notionTypeOptionIds: [String!],
    $notionTypeUpserts: [NotionDatabaseSelectOptionUpsertWithWhereUniqueWithoutConfigurationInput!],
    $statusUpserts: [RequirementStatusUpsertWithWhereUniqueWithoutRequirementTableInput!],
    $statusCreates: [RequirementStatusCreateWithoutRequirementTableInput!],
    $statusNotionIds: [String!],
    $typeUpserts: [RequirementTypeUpsertWithWhereUniqueWithoutRequirementTableInput!],
    $typeCreates: [RequirementTypeCreateWithoutRequirementTableInput!],
    $typeNotionIds: [String!]
  ) {
    updateOneProject(
      data: {
        requirementTable: {
          upsert: {
            update: {
              notionDatabaseId: {
                set:$notionDatabaseId
              },
              notionDatabaseTitle: {
                set:$notionDatabaseTitle
              },
              notionNumber: {
                upsert: {
                  update: {
                    propertyId: {
                      set:$notionNumberId
                    },
                    propertyType: {
                      set:$notionNumberType
                    },
                    propertyTitle: {
                      set:$notionNumberTitle
                    }
                  },
                  create: {
                    tenantId:$organizationId,
                    propertyId:$notionNumberId,
                    propertyType:$notionNumberType,
                    propertyTitle:$notionNumberTitle
                  }
                }
              },
              notionStoryPoints: {
                upsert: {
                  update: {
                    propertyId: {
                      set:$notionStoryPointsId
                    },
                    propertyType: {
                      set:$notionStoryPointsType
                    },
                    propertyTitle: {
                      set:$notionStoryPointsTitle
                    }
                  },
                  create: {
                    tenantId:$organizationId,
                    propertyId:$notionStoryPointsId,
                    propertyType:$notionStoryPointsType,
                    propertyTitle:$notionStoryPointsTitle
                  }
                }
              },
              notionSprint: {
                upsert: {
                  update: {
                    propertyId: {
                      set:$notionSprintId
                    },
                    propertyType: {
                      set:$notionSprintType
                    },
                    propertyTitle: {
                      set:$notionSprintTitle
                    }
                  },
                  create: {
                    tenantId:$organizationId,
                    propertyId:$notionSprintId,
                    propertyType:$notionSprintType,
                    propertyTitle:$notionSprintTitle
                  }
                }
              },
              notionParent: {
                upsert: {
                  update: {
                    propertyId: {
                      set:$notionParentId
                    },
                    propertyType: {
                      set:$notionParentType
                    },
                    propertyTitle: {
                      set:$notionParentTitle
                    }
                  },
                  create: {
                    tenantId:$organizationId,
                    propertyId:$notionParentId,
                    propertyType:$notionParentType,
                    propertyTitle:$notionParentTitle
                  }
                }
              },
              notionAssignee: {
                upsert: {
                  update: {
                    propertyId: {
                      set:$notionAssigneeId
                    },
                    propertyType: {
                      set:$notionAssigneeType
                    },
                    propertyTitle: {
                      set:$notionAssigneeTitle
                    }
                  },
                  create: {
                    tenantId:$organizationId,
                    propertyId:$notionAssigneeId,
                    propertyType:$notionAssigneeType,
                    propertyTitle:$notionAssigneeTitle
                  }
                }
              },
              notionStatus: {
                upsert: {
                  update: {
                    propertyId: {
                      set:$notionStatusId
                    },
                    propertyType: {
                      set:$notionStatusType
                    },
                    propertyTitle: {
                      set:$notionStatusTitle
                    },
                    selectConfiguration: {
                      upsert: {
                        update: {
                          options: {
                            deleteMany: {
                              optionId: {
                                notIn: $notionStatusOptionIds
                              }
                            },
                            upsert: $notionStatusUpserts
                          }
                        },
                        create: {
                          tenantId:$organizationId,
                          options: {
                            create: $notionStatusOptions
                          }
                        }
                      }
                    }
                  },
                  create: {
                    tenantId:$organizationId,
                    propertyId:$notionStatusId,
                    propertyType:$notionStatusType,
                    propertyTitle:$notionStatusTitle,
                    selectConfiguration: {
                      create: {
                        tenantId:$organizationId,
                        options: {
                          create: $notionStatusOptions
                        },
                      },
                    }
                  }
                }
              },
              notionType: {
                upsert: {
                  update: {
                    propertyId: {
                      set:$notionTypeId
                    },
                    propertyType: {
                      set:$notionTypeType
                    },
                    propertyTitle: {
                      set:$notionTypeTitle
                    },
                    selectConfiguration: {
                      upsert: {
                        update: {
                          options: {
                            deleteMany: {
                              optionId: {
                                notIn: $notionTypeOptionIds
                              }
                            },
                            upsert: $notionTypeUpserts
                          }
                        },
                        create: {
                          tenantId:$organizationId,
                          options: {
                            create: $notionTypeOptions
                          }
                        }
                      }
                    }
                  },
                  create: {
                    tenantId:$organizationId,
                    propertyId:$notionTypeId,
                    propertyType:$notionTypeType,
                    propertyTitle:$notionTypeTitle,
                    selectConfiguration: {
                      create: {
                        tenantId:$organizationId,
                        options: {
                          create: $notionTypeOptions
                        },
                      }
                    }
                  }
                }
              },
              statuses: {
                upsert: $statusUpserts,
                updateMany: {
                  data: {
                    active: {
                      set: false
                    }
                  },
                  where: {
                    notionId: {
                      notIn: $statusNotionIds
                    }
                  }
                }
              },
              types: {
                upsert: $typeUpserts,
                updateMany: {
                  data: {
                    active: {
                      set: false
                    }
                  },
                  where: {
                    notionId: {
                      notIn: $typeNotionIds
                    }
                  }
                }
              }
            },
            create: {
              tenantId: $organizationId,
              notionDatabaseId: $notionDatabaseId,
              notionDatabaseTitle: $notionDatabaseTitle,
              numberSequenceEnabled: true,
              notionNumber: {
                create: {
                  tenantId:$organizationId,
                  propertyId:$notionNumberId,
                  propertyType:$notionNumberType,
                  propertyTitle:$notionNumberTitle,
                }
              },
              notionStoryPoints: {
                create: {
                  tenantId:$organizationId,
                  propertyId:$notionStoryPointsId,
                  propertyType:$notionStoryPointsType,
                  propertyTitle:$notionStoryPointsTitle
                }
              },
              notionSprint: {
                create: {
                  tenantId:$organizationId,
                  propertyId:$notionSprintId,
                  propertyType:$notionSprintType,
                  propertyTitle:$notionSprintTitle
                }
              },
              notionParent: {
                create: {
                  tenantId:$organizationId,
                  propertyId:$notionParentId,
                  propertyType:$notionParentType,
                  propertyTitle:$notionParentTitle
                }
              },
              notionAssignee: {
                create: {
                  tenantId:$organizationId,
                  propertyId:$notionAssigneeId,
                  propertyType:$notionAssigneeType,
                  propertyTitle:$notionAssigneeTitle
                }
              },
              notionStatus: {
                create: {
                  tenantId:$organizationId,
                  propertyId:$notionStatusId,
                  propertyType:$notionStatusType,
                  propertyTitle:$notionStatusTitle,
                  selectConfiguration: {
                    create: {
                      tenantId:$organizationId,
                      options: {
                        create: $notionStatusOptions
                      },
                    },
                  }
                }
              },
              notionType: {
                create: {
                  tenantId:$organizationId,
                  propertyId:$notionTypeId,
                  propertyType:$notionTypeType,
                  propertyTitle:$notionTypeTitle,
                  selectConfiguration: {
                    create: {
                      tenantId:$organizationId,
                      options: {
                        create: $notionTypeOptions
                      },
                    }
                  }
                }
              },
              statuses: {
                create: $statusCreates
              },
              types: {
                create: $typeCreates
              }
            }
          },
        }
      },
      where: {
        id: $projectId
      }
    ) {
      id
    }
  }
`

export const UPDATE_LAST_OPENED_PROJECT = gql`
  mutation UpdateLastOpenedProject($userId: Int!, $projectId: Int!) {
    updateOneUser(
      data: {
        lastOpenedProject: {
          connect: {
            id: $projectId
          }
        }
      },
      where: { 
        id: $userId 
      }
    ) {
      id
      email
      name
      roles {
        id
        name
      }
      lastOpenedProject {
        id
        name
        number
        timezone
         sprintTable{
        id
      }
      requirementTable{
        id
      }
      notionAuthorization {
        id
        workspaceIcon
        workspaceName
      }
        deleted
        deletedOn
        organization {
          id
          domain
        }
      }
      projects {
        id
        name
        number
        deleted
        deletedOn
        requirementTable{
          id
        }
        sprintTable{
          id
        }
        notionAuthorization{
          id
        }
      }
      organizations {
        id
        number
        name
        notionAuthorizations {
          id
        }
        domain
      }
    }
  }
`

export const UPDATE_REQUIREMENT_TABLE = gql`
  mutation UpdateRequirementTable(
    $projectId:Int!, 
    $notionDatabaseId:String!, 
    $notionDatabaseTitle:String!, 
    $notionNumberId:String!, 
    $notionNumberTitle:String!,
    $notionNumberType:String!,
    $notionStatusId:String!,
    $notionStatusTitle:String!,
    $notionStatusType:String!,
    $notionTypeId:String!,
    $notionTypeTitle:String!,
    $notionTypeType:String!,
    $notionStoryPointsId:String!, 
    $notionStoryPointsTitle:String!,
    $notionStoryPointsType:String!,
    $notionSprintId:String!, 
    $notionSprintTitle:String!,
    $notionSprintType:String!,
    $notionParentId:String!, 
    $notionParentTitle:String!,
    $notionParentType:String!,
    $notionAssigneeId:String!,
    $notionAssigneeTitle:String!,
    $notionAssigneeType:String!,
    $notionStatusOptions:[NotionDatabaseSelectOptionCreateWithoutConfigurationInput!],
    $notionStatusOptionIds: [String!],
    $notionStatusUpserts: [NotionDatabaseSelectOptionUpsertWithWhereUniqueWithoutConfigurationInput!],
    $notionTypeOptions:[NotionDatabaseSelectOptionCreateWithoutConfigurationInput!],
    $notionTypeOptionIds: [String!],
    $notionTypeUpserts: [NotionDatabaseSelectOptionUpsertWithWhereUniqueWithoutConfigurationInput!],
    $statusUpserts: [RequirementStatusUpsertWithWhereUniqueWithoutRequirementTableInput!],
    $statusNotionIds: [String!],
    $typeUpserts: [RequirementTypeUpsertWithWhereUniqueWithoutRequirementTableInput!],
    $typeNotionIds: [String!]) {
    updateOneRequirementTable(
      data: {
        notionDatabaseId: {
          set:$notionDatabaseId
        },
        notionDatabaseTitle: {
          set:$notionDatabaseTitle
        },
        notionNumber: {
          upsert: {
            update: {
              propertyId: {
                set:$notionNumberId
              },
              propertyType: {
                set:$notionNumberType
              },
              propertyTitle: {
                set:$notionNumberTitle
              }
            },
            create: {
              propertyId:$notionNumberId,
              propertyType:$notionNumberType,
              propertyTitle:$notionNumberTitle
            }
          }
        },
        notionStoryPoints: {
          upsert: {
            update: {
              propertyId: {
                set:$notionStoryPointsId
              },
              propertyType: {
                set:$notionStoryPointsType
              },
              propertyTitle: {
                set:$notionStoryPointsTitle
              }
            },
            create: {
              propertyId:$notionStoryPointsId,
              propertyType:$notionStoryPointsType,
              propertyTitle:$notionStoryPointsTitle
            }
          }
        },
        notionSprint: {
          upsert: {
            update: {
              propertyId: {
                set:$notionSprintId
              },
              propertyType: {
                set:$notionSprintType
              },
              propertyTitle: {
                set:$notionSprintTitle
              }
            },
            create: {
              propertyId:$notionSprintId,
              propertyType:$notionSprintType,
              propertyTitle:$notionSprintTitle
            }
          }
        },
        notionParent: {
          upsert: {
            update: {
              propertyId: {
                set:$notionParentId
              },
              propertyType: {
                set:$notionParentType
              },
              propertyTitle: {
                set:$notionParentTitle
              }
            },
            create: {
              propertyId:$notionParentId,
              propertyType:$notionParentType,
              propertyTitle:$notionParentTitle
            }
          }
        },
        notionAssignee: {
          upsert: {
            update: {
              propertyId: {
                set:$notionAssigneeId
              },
              propertyType: {
                set:$notionAssigneeType
              },
              propertyTitle: {
                set:$notionAssigneeTitle
              }
            },
            create: {
              propertyId:$notionAssigneeId,
              propertyType:$notionAssigneeType,
              propertyTitle:$notionAssigneeTitle
            }
          }
        },
        notionStatus: {
          upsert: {
            update: {
              propertyId: {
                set:$notionStatusId
              },
              propertyType: {
                set:$notionStatusType
              },
              propertyTitle: {
                set:$notionStatusTitle
              },
              selectConfiguration: {
                upsert: {
                  update: {
                    options: {
                      deleteMany: {
                        optionId: {
                          notIn: $notionStatusOptionIds
                        }
                      },
                      upsert: $notionStatusUpserts
                    }
                  },
                  create: {
                    options: {
                      create: $notionStatusOptions
                    }
                  }
                }
              }
            },
            create: {
              propertyId:$notionStatusId,
              propertyType:$notionStatusType,
              propertyTitle:$notionStatusTitle,
              selectConfiguration: {
                create: {
                  options: {
                    create: $notionStatusOptions
                  },
                },
              }
            }
          }
        },
        notionType: {
          upsert: {
            update: {
              propertyId: {
                set:$notionTypeId
              },
              propertyType: {
                set:$notionTypeType
              },
              propertyTitle: {
                set:$notionTypeTitle
              },
              selectConfiguration: {
                upsert: {
                  update: {
                    options: {
                      deleteMany: {
                        optionId: {
                          notIn: $notionTypeOptionIds
                        }
                      },
                      upsert: $notionTypeUpserts
                    }
                  },
                  create: {
                    options: {
                      create: $notionTypeOptions
                    }
                  }
                }
              }
            },
            create: {
              propertyId:$notionTypeId,
              propertyType:$notionTypeType,
              propertyTitle:$notionTypeTitle,
              selectConfiguration: {
                create: {
                  options: {
                    create: $notionTypeOptions
                  },
                }
              }
            }
          }
        },
        statuses: {
          upsert: $statusUpserts,
          updateMany: {
            data: {
              active: {
                set: false
              }
            },
            where: {
              notionId: {
                notIn: $statusNotionIds
              }
            }
          }
        },
        types: {
          upsert: $typeUpserts,
          updateMany: {
            data: {
              active: {
                set: false
              }
            },
            where: {
              notionId: {
                notIn: $typeNotionIds
              }
            }
          }
        }
      },
      where: {
        projectId:$projectId
      }
    ) {
      id
      notionNumber {
        propertyId
        propertyTitle
        propertyType
      }
      notionStatus {
        propertyId
        propertyTitle
        propertyType
        selectConfiguration {
          options {
            optionName
            optionId
            optionColor
          }
        }
      }
      notionType {
        propertyId
        propertyTitle
        propertyType
        selectConfiguration {
          options {
            optionName
            optionId
            optionColor
          }
        }
      }
      notionStoryPoints {
        propertyId
        propertyTitle
        propertyType
      }
      notionSprint {
        propertyId
        propertyTitle
        propertyType
      }
      notionParent {
        propertyId
        propertyTitle
        propertyType
      }
      notionAssignee {
        propertyId
        propertyTitle
        propertyType
      }
      notionDatabaseId
      notionDatabaseTitle
      numberSequenceEnabled
    }
  }
`

export const UPDATE_SPRINT_TABLE = gql`
  mutation UpdateSprintTable(
    $projectId:Int!, 
    $notionDatabaseId:String!, 
    $notionDatabaseTitle:String!, 
    $notionDateRangeId:String!, 
    $notionDateRangeTitle:String!,
    $notionDateRangeType:String!,
    $notionPlannedEffortId:String!,
    $notionPlannedEffortTitle:String!,
    $notionPlannedEffortType:String!,
    $notionBacklogId:String!,
    $notionBacklogTitle:String!,
    $notionBacklogType:String!,
    $notionStateId:String!,
    $notionStateTitle:String!,
    $notionStateType:String!) {
    updateOneSprintTable(
      data: {
        notionDatabaseId: {
          set:$notionDatabaseId
        },
        notionDatabaseTitle: {
          set:$notionDatabaseTitle
        },
        notionDateRange: {
          upsert: {
            update: {
              propertyId: {
                set:$notionDateRangeId
              },
              propertyType: {
                set:$notionDateRangeType
              },
              propertyTitle: {
                set:$notionDateRangeTitle
              }
            },
            create: {
              propertyId:$notionDateRangeId,
              propertyType:$notionDateRangeType,
              propertyTitle:$notionDateRangeTitle
            }
          }
        },
        notionPlannedEffort: {
          upsert: {
            update: {
              propertyId: {
                set:$notionPlannedEffortId
              },
              propertyType: {
                set:$notionPlannedEffortType
              },
              propertyTitle: {
                set:$notionPlannedEffortTitle
              }
            },
            create: {
              propertyId:$notionPlannedEffortId,
              propertyType:$notionPlannedEffortType,
              propertyTitle:$notionPlannedEffortTitle
            }
          }
        },
        notionBacklog: {
          upsert: {
            update: {
              propertyId: {
                set:$notionBacklogId
              },
              propertyType: {
                set:$notionBacklogType
              },
              propertyTitle: {
                set:$notionBacklogTitle
              }
            },
            create: {
              propertyId:$notionBacklogId,
              propertyType:$notionBacklogType,
              propertyTitle:$notionBacklogTitle
            }
          }
        },
        notionState: {
          upsert: {
            update: {
              propertyId: {
                set:$notionStateId
              },
              propertyType: {
                set:$notionStateType
              },
              propertyTitle: {
                set:$notionStateTitle
              }
            },
            create: {
              propertyId:$notionStateId,
              propertyType:$notionStateType,
              propertyTitle:$notionStateTitle
            }
          }
        }
      },
      where: {
        projectId:$projectId
      }
    ) {
      id
      notionDateRange {
        propertyId
        propertyTitle
        propertyType
      }
      notionPlannedEffort {
        propertyId
        propertyTitle
        propertyType
      }
      notionBacklog {
        propertyId
        propertyTitle
        propertyType
      }
      notionState {
        propertyId
        propertyTitle
        propertyType
      }
      notionDatabaseId
      notionDatabaseTitle
      numberSequenceEnabled
    }
  }
`

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($id:Int!, $name: String!, $email:String!) {
    updateUserProfile(data: { id: $id, name: $name, email: $email }) {
      id
      name
      email
    }
  }
`

export const UPDATE_USER_ROLE_AND_PROJECTS = gql`
  mutation UpdateUserRoleAndProjects($id: Int!, $roles: [Role!], $projects: [Int!]) {
    updateUserRoleAndProjects(data: { id: $id, roles: $roles, projects: $projects }) {
      id
      name
      email
      roles {
        id
        name
      }
      projects {
        id
        name
        number
        deleted
        deletedOn
      }
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($id:Int!, $oldPassword:String!, $newPassword:String!) {
    updateUserPassword(data: { id: $id, oldPassword: $oldPassword, newPassword: $newPassword }) {
      id 
    }
  }
`

export const REGISTER_INVITED_USER = gql`
mutation RegisterInvitedUser($id:Int!,$password:String!,$name:String!, $subscribe:Boolean!){
  registerInvitedUser(data:{
   id:$id
    password:$password
    name:$name
    subscribe:$subscribe
  }){
    id
    name
    password
    lastOpenedProject{
      id
      name
      organization {
        domain
      }
    }
  }
}
`

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($id:Int!, $name:String!, $domain:String!) {
    updateOneOrganization(
      data: {
        name: {
          set: $name
        },
        domain: {
          set: $domain
        }
      },
      where: {
        id: $id
      }
    ) {
      id,
      name,
      domain
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id:Int!, $name:String!, $timezone:String!, $authorization: NotionAuthorizationUpdateOneWithoutProjectsInput) {
    updateOneProject(
      data: {
        name: {
          set: $name
        },
        timezone: {
          set: $timezone
        },
        notionAuthorization: $authorization
      },
      where: {
        id: $id
      }
    ) {
    id
    name
    timezone
    notionAuthorization{
      id
    }
    organization{
      id
    }
  }
  }
`

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id:Int!, $deleted: Boolean!, $deletedOn: DateTime) {
    updateOneProject(
      data: {
        deleted: {
          set: $deleted
        },
        deletedOn: {
          set: $deletedOn
        },
      },
      where: {
        id: $id
      }
    ) {
      id
    }
  }
`

export const DELETE_ONE_USER = gql`
  mutation DeleteOneUser ($data: DeleteOneUser!) {
    deleteOneUser(data: $data) {
    id
    }
  }
`

export const RESET_LAST_OPENED_PROJECT = gql`
  mutation ResetLastOpenedProject($userId: Int!) {
    updateOneUser(
      data: {
        lastOpenedProject: {
          disconnect: true
        }
      },
      where: { 
        id: $userId 
      }
    ) {
      id
      email
      name
      roles {
        id
        name
      }
      lastOpenedProject {
        id
        name
        number
        deleted
        deletedOn
        organization {
          id
          domain
        }
      }
      projects {
        id
        name
        number
        deleted
        deletedOn
      }
      organizations {
        id
        number
        name
        notionAuthorizations {
          id
        }
        domain
      }
    }
  }
`
export const CREATE_NOTIFICATION = gql`
mutation CreateNotification($orgId:Int, $type:NotificationType!, $level:NotificationLevel!, $params:NotificationParams) {
  createOneNotification(data:{
    orgId: $orgId,
    type:$type,
    level:$level,
    params:$params,
  }){id}
}  
`

export const REMOVE_NOTIFICATION = gql`
mutation RemoveNotification($id:Int!) {
  removeOneNotification(data:{
    id: $id,
  })
}  
`

export const MARK_NOTIFICATION_READ = gql`
mutation ReadNotification($id:Int!){
  readOneNotification(data:{
    id:$id,
  }){id}
}
`

export const MARK_NOTIFICATION_SHOWN = gql`
mutation ShowNotification($id:Int!){
  showOneNotification(data:{
    id:$id,
  }){id}
}
`

export const UPDATE_MEMBER_BILLING_STATUS = gql`
mutation UpdateMemberBillingStatus($id:Int!, $status:BillingStatus!, $lastActive:DateTime){
  updateOneNotionMember(data:{
    billingStatus:{
      set:$status
    },
    lastActive: {
      set:$lastActive
    }
  }, where:{
    id:$id
  }){
    id
    billingStatus
  }
}  
`
export const INVITE_USERS = gql`
mutation InviteUsers($emails:[String!]!, $names:[String]!, $organizationId:Int!, $projectIds:[Int!]!, $role:String!, $notionMemberIds:[Int]!, $allProjects:Boolean!){
  inviteUsers(data:{
    emails:$emails,
    allProjects:$allProjects,
    names:$names,
    organizationId:$organizationId,
    projectIds:$projectIds,
    role:$role,
    notionMemberIds:$notionMemberIds,
  }) {
    results{
      email
      success
    }
  }
}
`

export const REQUEST_PASSWORD_RESET = gql`
mutation RequestResetPassword($email:String!){
  requestResetPassword(data:{
    email:$email
  })
}
`

export const RESET_USER_PASSWORD = gql`
mutation ResetUserPassword($id:Int!, $newPassword:String!){
  resetUserPassword(data: {
    id:$id
    newPassword:$newPassword
  }) { id } 
}
`
export const COMPLETE_ORG_ACHIEVEMENT = gql`
mutation CompleteOrgAchievement($organizationId: Int, $achievementId: Int!, $projectNumber: String) {
  completeOrgAchievement(organizationId: $organizationId, achievementId: $achievementId, projectNumber: $projectNumber) {
    id
  }
}
`

export const UPGRADE_PLAN = gql`
mutation UpgradePlan {
  upgradePlan
}
`
