export const StatusColorsLight = {
  ToDo: '#9d78a9',
  InProgress: '#69a6c3',
  InReview: '#feab84',
  Completed: '#88e7d1',
  OnHold: '#ff7d67',
}

/*
1ToDo: '#854D95',
InProgress: '#00B7B0',
InReview: '#C45B21',
Completed: '#A9BD59',
OnHold: '#FF664D',
*/

export const StatusColorsDark = {
  ToDo: '#72447e',
  InProgress: '#2b809b',
  InReview: '#cc7f54',
  Completed: '#5bbaa4',
  OnHold: '#ff664d',
}

/*
1ToDo: '#80518E',
InProgress: '#03A8A1',
InReview: '#B35521',
Completed: '#9BAD53',
OnHold: '#E85F48',
*/

export const Theme = {
  Primary: '#FF664D'
}

export const NotionColorsLight = {
  default: '#e6e6e6',
  defaultSecondary: '#CCCCCC',
  gray: '#8f8d8b',
  brown: '#886f62',
  orange: '#feab84',
  yellow: '#FEF17F',
  green: '#88e7d1',
  blue: '#69a6c3',
  purple: '#9d78a9',
  pink: '#e9a1c5',
  red: '#ff7d67',
}

/*
gray: '#73716E',
brown: '#754C36',
orange: '#C45B21',
yellow: '#FFCE54',
green: '#A9BD59',
blue: '#00B7B0',
purple: '#854D95',
pink: '#C66197',
red: '#FF664D',
*/

export const NotionColorsDark = {
  default: '#36352F',
  defaultSecondary: '#59574D',
  gray: '#62615e',
  brown: '#6c4834',
  orange: '#cc7f54',
  yellow: '#f7df6a',
  green: '#5bbaa4',
  blue: '#2b809b',
  purple: '#72447e',
  pink: '#b46290',
  red: '#ff664d',
}

/*
gray: '#6B6966',
brown: '#6C4834',
orange: '#B35521',
yellow: '#E8BC4F',
green: '#9BAD53',
blue: '#03A8A1',
purple: '#80518E',
pink: '#B55A8B',
red: '#E85F48',
*/

export const isDarkMode = () => {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
}
