import {SET_ACTIVE_MODAL} from "./actions";

const initState = {
  activeModal: {code: null, data: null},
}

const modals = (state=initState, action) => {
  switch(action.type) {
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.modal,
      }
    default:
      return state
  }
}

export default modals