import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Embed, {VelocityChart, Burndown, VelocityStat, NewItemsStat} from './views/embeds'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { persistCache, CachePersistor} from "apollo3-cache-persist";
import CssBaseline from "@material-ui/core/CssBaseline";
import { admin } from './assets/theme'
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'react-slideshow-image/dist/styles.css'
import AdminLayout from "layouts/Admin.js";
import OrgLayout from 'layouts/Org.js';
import AuthLayout from "layouts/Auth.js";
import ProductLayout from 'layouts/Product'
import SetupLayout from "layouts/Setup.js";
import NewProjectLayout from "layouts/NewProject"
import UpgradePlanLayout from 'layouts/UpgradePlan'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import {VELOCITY} from './queries'
import * as request from 'request-promise-native'
import { Provider } from 'react-redux'
import store from './store'
import config from './config'
import EditProfileModal from "./views/profile/EditProfileModal";
import CumulativeFlow from "./views/embeds/charts/CumulativeFlow/CumulativeFlow";
import DefaultNotification from "./components/Modals/DefaultNotification";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

let configFile = 'config.json'
console.log(process.env)
if (process.env.NODE_ENV === 'production') {
  configFile = 'config.production.json'
} else if (process.env.NODE_ENV === 'test') {
  configFile = 'config.test.json'
}

let clearCache = false

export const setClearCache = v => (clearCache = v)

const paginationMerge = (existing, incoming, { args: { cursor } }) => {
  // Slicing is necessary because the existing data is
  // immutable, and frozen in development.
  if (clearCache) {
    setClearCache(false)
    return incoming
  }
  const existingSelection = existing ? existing.results : undefined
  const merged = existingSelection ? existingSelection.slice(0) : []
  const cursorIndex = merged.length && cursor ? merged.findIndex(m => {
    return m?.__ref?.split(':')[1] === cursor
  }) : 0
  const skip = cursorIndex !== -1 ? cursorIndex : merged.length
  const incomingSelection = incoming ? incoming.results : undefined
  if (incomingSelection?.length) {
    for (let i = 0; i < incomingSelection.length; ++i) {
      merged[skip + i] = incomingSelection[i]
    }
  }
  return {
    ...incoming,
    results: merged
  }
}

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        notionDatabases: {
          keyArgs: [],
          merge: paginationMerge,
        }
      }
    }
  }
})

request(`${window.location.origin}/${configFile}`, { json: true }, async (err, res) => {
  await persistCache({
    cache,
    storage: localStorage,
  })

  /*const persistor = new CachePersistor({
    cache,
    storage: window.localStorage,
  })

  persistor.persist()

  setTimeout(() => {
    setInterval(() => {
      persistor.purge()
    }, 500000)
  }, 500000)*/

  config.siteBaseUrl = res.body.siteBaseUrl
  config.affiliateLink = res.body.affiliateLink
  //mutators.setSiteBaseUrl(res.body.siteBaseUrl)

  const httpLink = createHttpLink({
    uri: `${res.body.baseUrl}${res.body.graphQLPath}`,
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('loginToken')
    return {
      headers: {
        ...headers,
        authorization: token ? token : '',
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    onError: ({ networkError, graphQLErrors }) => {
      console.log('graphQLErrors', graphQLErrors)
      console.log('networkError', networkError)
    },
  })

  try {
    cache.readQuery({
      query: VELOCITY
    });
  } catch (error) {
    cache.writeData({
      data: {
        velocity: {
          data: [],
        }
      }
    });
  }

  let dark = false

  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    dark = true
  }

  const theme = createMuiTheme(admin)

  const loginToken = localStorage.getItem('loginToken')

  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <BrowserRouter>
              <EditProfileModal />
              <DefaultNotification />
              <Switch>
                <Route path={"/burndown"} render={(props) => <Embed {...props} component={Burndown} />} />
                <Route path={"/cumulative"} render={(props) => <Embed {...props} component={CumulativeFlow} />} />
                <Route path={"/velocity"} render={(props) => <Embed {...props} component={VelocityChart}/>} />
                <Route path={"/velocity-stat"} render={(props) => <Embed {...props} component={VelocityStat}/>} />
                <Route path={"/new-items-stat"} render={(props) => <Embed {...props} component={NewItemsStat}/>}/>
                <Route path={'/product'} name={'Home'} render={(props) => <ProductLayout {...props} />} />
                <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                <Route path="/setup" render={(props) => <SetupLayout {...props} />} />
                <Route path="/org" render={(props) => <OrgLayout {...props} />} />
                <Route path="/new-project" render={(props) => <NewProjectLayout {...props} />} />
                <Route path={'/upgrade-plan'} render={(props) => <UpgradePlanLayout {...props} />} />
                <Route path="/" render={(props) => <AdminLayout {...props} />} />
              </Switch>
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>,
    document.querySelector("#root")
  );
})
