const requirementTable = `{
  id
  notionNumber {
    id
    propertyId
    propertyTitle
    propertyType
  }
  notionStatus {
    id
    propertyId
    propertyTitle
    propertyType
    selectConfiguration {
      id
      options {
        id
        optionName
        optionId
        optionColor
      }
    }
  }
  notionType {
    id
    propertyId
    propertyTitle
    propertyType
    selectConfiguration {
      id
      options {
        id
        optionName
        optionId
        optionColor
      }
    }
  }
  notionAssignee {
    id
    propertyId
    propertyTitle
    propertyType
  }
  notionStoryPoints {
    id
    propertyId
    propertyTitle
    propertyType
  }
  notionSprint {
    id
    propertyId
    propertyTitle
    propertyType
  }
  notionParent {
    id
    propertyId
    propertyTitle
    propertyType
  }
  statuses {
    id
    name
    color
    notionId
    type
  }
  types {
    id
    name
    color
    notionId
    type
  }
  notionDatabaseId
  notionDatabaseTitle
  numberSequenceEnabled
}`

const sprintTable = `{
  id
  notionDatabaseTitle
  notionDatabaseId
  notionDateRange {
    id
    propertyTitle
    propertyId
    propertyType
  }
  notionPlannedEffort {
    id
    propertyTitle
    propertyId
    propertyType
  }
  notionBacklog {
    id
    propertyTitle
    propertyId
    propertyType
  }
  notionState {
    id
    propertyTitle
    propertyId
    propertyType
  }
}`

const achievements = `
{
  id
  achievement {
    id
    title
    infoUrl
    lineNumber
    level
  }
}
`

export const me = `
{
  id
  email
  name
  roles {
    id
    name
  }
  createdAt
  lastOpenedProject{
    id
    name
    number
    timezone
    deleted
    deletedOn
    notifications(
        where: {
          userId: null,
        }
      ) {
      id
        id
        title
        message
        type
        createdAt
        code
        toastSubtitle
        silent
        errorCode
        user {
          id
        }
        expireToastAt
    }
    notionAuthorization {
      id
      workspaceIcon
      workspaceName
    }
    sprintTable ${sprintTable}
    requirementTable ${requirementTable}
    organization{
      id
      domain
      name
      projects {
        id
        name
      }
      plan {
        id
        type
        status
        promotions {
          name
          amountOff
          percentOff
          start
          end
        }
      }
      notifications(
        where: {
          projectId: null,
          userId: null,
        }
      ) {
        id
        title
        message
        type
        createdAt
        code
        silent
        errorCode
        toastSubtitle
        user {
          id
        }
        expireToastAt
      }
      achievements ${achievements}
    }
  }
  projects{
    id
    name
    number
    timezone
    deleted
    deletedOn
    sprintTable{
      id
    }
    requirementTable{
      id
    }
    notionAuthorization{
      id
    }
  }
  organizations{
    id
    number
    name
    domain
    projects {
      id
      name
    }
    notionAuthorizations {
      id
      workspaceIcon
      workspaceName
      projects {
        id
        name
        number
        timezone
        deleted
        deletedOn
        sprintTable ${sprintTable}
        requirementTable ${requirementTable}
      }
    }
  }
  notifications {
    id
    title
    message
    type
    createdAt
    code
    silent
    errorCode
    expireToastAt
    toastSubtitle
  }
 shownNotifications {
    notification {
      id
    }
  }
  readNotifications {
    notification {
      id
    }
  }
}
`
