import {
  setAuth as newSetAuth,
  setOrganization as newSetOrganization,
  setProject as newSetProject,
  setRequirementDatabase as newSetRequirementDatabase,
  setRequirementProperties as newSetRequirementProperties,
  setRequirementStatuses as newSetRequirementStatuses,
  setRequirementTypes as newSetRequirementTypes,
  setSprintDatabase as newSetSprintDatabase,
  setSprintProperties as newSetSprintProperties
} from '../views/admin/NewProjectSetup/actions'

import {
  setOrganization as firstSetOrganization,
  setProject as firstSetProject,
  setRequirementDatabase as firstSetRequirementDatabase,
  setRequirementProperties as firstSetRequirementProperties,
  setRequirementStatuses as firstSetRequirementStatuses,
  setRequirementTypes as firstSetRequirementTypes,
  setSprintDatabase as firstSetSprintDatabase,
  setSprintProperties as firstSetSprintProperties
} from '../views/setup/actions'

export const initNewProjectSetupData = (project, dispatch) => {
  return initData(project, dispatch, {
    setAuth: newSetAuth,
    setOrganization: newSetOrganization,
    setProject: newSetProject,
    setRequirementDatabase: newSetRequirementDatabase,
    setRequirementProperties: newSetRequirementProperties,
    setRequirementStatuses: newSetRequirementStatuses,
    setRequirementTypes: newSetRequirementTypes,
    setSprintDatabase: newSetSprintDatabase,
    setSprintProperties: newSetSprintProperties
  })
}

export const initFirstProjectSetupData = (project, dispatch) => {
  return initData(project, dispatch, {
    setOrganization: firstSetOrganization,
    setProject: firstSetProject,
    setRequirementDatabase: firstSetRequirementDatabase,
    setRequirementProperties: firstSetRequirementProperties,
    setRequirementStatuses: firstSetRequirementStatuses,
    setRequirementTypes: firstSetRequirementTypes,
    setSprintDatabase: firstSetSprintDatabase,
    setSprintProperties: firstSetSprintProperties
  })
}

export const initData = (project, dispatch, {
  setAuth,
  setOrganization,
  setProject,
  setRequirementDatabase,
  setRequirementProperties,
  setRequirementStatuses,
  setRequirementTypes,
  setSprintDatabase,
  setSprintProperties
}) => {
  dispatch(setProject(project))
  dispatch(setOrganization(project?.organization))
  if (setAuth) {
    dispatch(setAuth(project?.notionAuthorization))
  }

  const sprintTable = project?.sprintTable
  const requirementTable = project?.requirementTable
  if (sprintTable) {
    dispatch(setSprintDatabase({
      id: sprintTable?.notionDatabaseId,
      title: sprintTable?.notionDatabaseTitle
    }))
    const sprintProps = {
      dateRange: {
        id: sprintTable?.notionDateRange?.propertyId,
        title: sprintTable?.notionDateRange?.propertyTitle,
        type: sprintTable?.notionDateRange?.propertyType
      },
      totalEffort: {
        id: sprintTable?.notionPlannedEffort?.propertyId,
        title: sprintTable?.notionPlannedEffort?.propertyTitle,
        type: sprintTable?.notionPlannedEffort?.propertyType
      },
      backlog: {
        id: sprintTable?.notionBacklog?.propertyId,
        title: sprintTable?.notionBacklog?.propertyTitle,
        type: sprintTable?.notionBacklog?.propertyType
      },
      state: {
        id: sprintTable?.notionState?.propertyId,
        title: sprintTable?.notionState?.propertyTitle,
        type: sprintTable?.notionState?.propertyType
      }
    }
    dispatch(setSprintProperties(sprintProps))
  }
  if (requirementTable) {
    dispatch(setRequirementDatabase({
      id: requirementTable?.notionDatabaseId,
      title: requirementTable?.notionDatabaseTitle
    }))
    const requirementProps = {
      number: {
        id: requirementTable?.notionNumber?.propertyId,
        title: requirementTable?.notionNumber?.propertyTitle,
        type: requirementTable?.notionNumber?.propertyType
      },
      status: {
        id: requirementTable?.notionStatus?.propertyId,
        title: requirementTable?.notionStatus?.propertyTitle,
        type: requirementTable?.notionStatus?.propertyType,
        selectConfiguration: requirementTable?.notionStatus?.selectConfiguration,
      },
      type: {
        id: requirementTable?.notionType?.propertyId,
        title: requirementTable?.notionType?.propertyTitle,
        type: requirementTable?.notionType?.propertyType,
        selectConfiguration: requirementTable?.notionType?.selectConfiguration,
      },
      assignee: {
        id: requirementTable?.notionAssignee?.propertyId,
        title: requirementTable?.notionAssignee?.propertyTitle,
        type: requirementTable?.notionAssignee?.propertyType,
      },
      storyPoints: {
        id: requirementTable?.notionStoryPoints?.propertyId,
        title: requirementTable?.notionStoryPoints?.propertyTitle,
        type: requirementTable?.notionStoryPoints?.propertyType
      },
      sprint: {
        id: requirementTable?.notionSprint?.propertyId,
        title: requirementTable?.notionSprint?.propertyTitle,
        type: requirementTable?.notionSprint?.propertyType
      },
      parent: {
        id: requirementTable?.notionParent?.propertyId,
        title: requirementTable?.notionParent?.propertyTitle,
        type: requirementTable?.notionParent?.propertyType
      }
    }
    dispatch(setRequirementProperties(requirementProps))
    const mapStatuses = statuses => {
      return statuses.map(o => ({
        title: o.name,
        id: o.notionId,
        color: o.color
      }))
    }
    const statuses = requirementTable.statuses
    if (statuses?.length) {
      const requirementStatuses = {
        planned: mapStatuses(statuses.filter(s => s.type === 'PLANNED')),
        inProgress: mapStatuses(statuses.filter(s => s.type === 'IN_PROGRESS')),
        complete: mapStatuses(statuses.filter(s => s.type === 'COMPLETE')),
        removed: mapStatuses(statuses.filter(s => s.type === 'REMOVED'))
      }
      dispatch(setRequirementStatuses(requirementStatuses))
    }
    const mapTypes = types => {
      return types.map(t => ({
        title: t.name,
        id: t.notionId,
        color: t.color
      }))
    }
    const types = requirementTable.types
    if (types?.length) {
      const requirementTypes = {
        userStory: mapTypes(types.filter(t => t.type === 'USER_STORY')),
        defect: mapTypes(types.filter(t => t.type === 'DEFECT')),
        task: mapTypes(types.filter(t => t.type === 'TASK')),
        other: mapTypes(types.filter(t => t.type === 'OTHER'))
      }
      dispatch(setRequirementTypes(requirementTypes))
    }
  }
}
