import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardContent,
  Modal,
  Box,
  CardHeader,
  useTheme,
  Divider,
  FormControl,
  CircularProgress,
  TextField
} from "@material-ui/core"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import componentStyles from "../../assets/theme/views/admin/profile";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FilledInput from "@material-ui/core/FilledInput";
import ArrowBackRounded from "@material-ui/icons/ArrowBackRounded";
import {useMutation} from "@apollo/client";
import {UPDATE_USER_PASSWORD} from "../../mutations";

const useStyles = makeStyles(componentStyles)


const EditPasswordModal = ({ setMessage, open, onClose, userId }) => {
  const [newPassword, setNewPassword] = useState(null)
  const [confirmNewPassword, setConfirmNewPassword] = useState(null)
  const [currentPassword, setCurrentPassword] = useState(null)

  const theme = useTheme()
  const classes = useStyles(theme)

  useEffect(() => {
    if(!open) {
      setNewPassword(null)
      setConfirmNewPassword(null)
      setCurrentPassword(null)
    } else {
      gtag('event', 'page_view', {
        'page_location': window.location.href,
        'page_title': 'Edit Password'
      })
    }
  }, [open])

  const [updateUserPassword, { loading: saving }] = useMutation(UPDATE_USER_PASSWORD, {
    variables: {
      id: userId,
      oldPassword: currentPassword,
      newPassword,
    }
  })

  const onSubmit = () => {
    console.log('test')
    updateUserPassword().catch(e => {
      console.error(e)
      setMessage({ text: e.graphQLErrors[0]?.message, isError: true })
      onClose()
    }).then(res => {
      if(res) {
        console.log(res)
        setMessage({text: 'Successfully updated password', isError: false})
        onClose()
      }
    })
  }

  const canSubmit = () => {
    return currentPassword && newPassword && confirmNewPassword && passwordsMatch()
  }

  const passwordsMatch = () => {
    return !confirmNewPassword || newPassword === confirmNewPassword
  }

  return (
    <Modal hideBackdrop open={open} onClose={onClose}>
      <Card
        classes={{
          root: classes.editProfileModalWrapper + " " + classes.cardRoot + " " + classes.cardRootSecondary,
        }}
      >
        <CardHeader
          subheader={
            <>
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs="auto"
                  className={classes.modalHeaderLeftWrapper}>
                  <Box
                    component={ArrowBackRounded}
                    width="1.5rem!important"
                    height="1.5rem!important"
                    onClick={onClose}
                    cursor="pointer"
                  />
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                    marginLeft="1rem!important"
                  >
                    Change Password
                  </Box>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    justifyContent="flex-end"
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!canSubmit()}
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </>
          }
          classes={{ root: classes.cardHeaderRoot }}
        />
        <CardContent classes={{ root: classes.editProfileCardContent }}>
              <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Current Password</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          margin="normal"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            value={currentPassword}
                            autoComplete="off"
                            type="password"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={0} lg={6}></Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>New Password</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          margin="normal"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                            autoComplete="off"
                            type="password"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Confirm New Password</FormLabel>
                        <FormControl
                          component={Box}
                          width="100%"
                          margin="normal"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            variant="filled"
                            component={TextField}
                            helperText={passwordsMatch() ? null : 'Passwords do not match'}
                            error={!passwordsMatch()}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            value={confirmNewPassword}
                            autoComplete="off"
                            type="password"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
              </Grid>
        </CardContent>
      </Card>
    </Modal>
  )
}

export default EditPasswordModal
