import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import componentStyles from '../../assets/theme/components/card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PeopleTable from '../Tables/PeopleTable'
import Button from '@material-ui/core/Button'
import {useDispatch, useSelector} from 'react-redux'
import {setActiveModal} from "../Modals/actions";

const useStyles = makeStyles(componentStyles)

const PeopleCard = () => {
  const classes = useStyles()
  const userRole = useSelector(state => state.auth?.user?.roles[0]?.name)
  const dispatch = useDispatch()

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        className={classes.cardHeader}
        titleTypographyProps={{
          component: Box,
          marginBottom: '0!important',
          variant: 'h3',
        }}
        subheader={
          <Grid
            container
            component={Box}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs='auto'>
              <Box component={Typography} variant='h3' marginBottom='0!important'>
                Admin Site Access
              </Box>
            </Grid>
            {userRole === 'ADMIN' || userRole === 'OWNER' ? (
              <Grid item xs='auto'>
                <Box justifyContent='flex-end' display='flex' flexWrap='wrap'>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => dispatch(setActiveModal({code: 'admin_site_invite', data: null}))}
                  >
                    Invite
                  </Button>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        }
      />
      <PeopleTable />
    </Card>
  )
}

export default PeopleCard
