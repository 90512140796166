export const SET_USER_DATA = 'SET_USER_DATA'

export const setUserData = (user) => {
  return {
    type: SET_USER_DATA,
    payload: user,
  }
}

export const removeNotification = (notification) => (dispatch, getState) => {
  const state = getState()
  let { user } = state.auth
  user = {
    ...user,
    notifications: user.notifications.filter(n => n.id !== notification.id),
    lastOpenedProject: {
      ...user.lastOpenedProject,
      notifications: user.lastOpenedProject.notifications.filter(n => n.id !== notification.id),
      organization: {
        ...user.lastOpenedProject.organization,
        notifications: user.lastOpenedProject.organization.notifications.filter(n => n.id !== notification.id)
      }
    },
    shownNotifications: user.shownNotifications.filter(({ notification: n }) => n.id !== notification.id),
    readNotifications: user.readNotifications.filter(({ notification: n }) => n.id !== notification.id)
  }
  dispatch(setUserData(user))
}

export const logout = (history, client) => {
  history.push('/auth/login')

  for(let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i)
    if (key.includes('-selectedSprint')) {
      window.localStorage.removeItem(key)
    }
  }

  window.localStorage.removeItem('loginToken')

  client.clearStore()

  return {
    type: SET_USER_DATA,
    payload: null,
  }
}
