import React, {useEffect, useState, useRef} from 'react'
import { makeStyles } from "@material-ui/core/styles"
import {
  useTheme,
  FormLabel,
  Box,
  Card,
  CardHeader,
  CardContent,
  Modal,
  TextField,
  Typography,
  FormHelperText, CircularProgress,
} from '@material-ui/core'
import componentStyles from "../../assets/theme/components/invite-modal"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import {useDispatch, useSelector} from "react-redux"
import {setActiveModal} from "./actions"
import {useMutation, useQuery} from "@apollo/client"
import {NOTION_MEMBERS} from "../../queries"
import {initialCaps} from "../../utils/initialCaps"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { Autocomplete } from "@mui/material"
import {INVITE_USERS} from "../../mutations"
import {validateEmail} from "../../utils/inputValidation"
import {toast} from "react-toastify"
import { Role } from '../../enums'

const useStyles = makeStyles(componentStyles)

const InviteModal = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const [emailOptions, setEmailOptions] = useState([])
  const [projectOptions, setProjectOptions] = useState([])
  const [selectedEmails, setSelectedEmails] = useState([])
  const [selectedUserRole, setSelectedUserRole] = useState(null)
  const [selectedProjects, setSelectedProjects] = useState([])
  const [customEmailInput, setCustomEmailInput] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const userRoleOptionsRef = useRef(Object.keys(Role).map(k => {
    return initialCaps(Role[k])
  }))

  const dispatch = useDispatch()
  const activeModal = useSelector(state => state.modals?.activeModal)
  const user = useSelector(state => state.auth?.user)
  const userIsOwner = user?.roles?.findIndex(r => r.name === 'OWNER') >= 0
  const userRoleOptions = userIsOwner ? userRoleOptionsRef.current : userRoleOptionsRef.current.filter(r => r !== 'Owner')

  const projects = user?.projects
  const organizationId = user?.lastOpenedProject?.organization?.id
  const open = activeModal?.code === 'admin_site_invite'

  useEffect(() => {
    if(selectedEmails.length <= 0 && activeModal?.data) {
      setSelectedEmails([activeModal.data.email])
    }
  }, [activeModal?.data])

  const [inviteUsers, { loading: mutationLoading }] = useMutation(INVITE_USERS, {
    onCompleted: data => {
      const errEmails = []
      data.inviteUsers?.results?.forEach(res => {
        if(!res.success) {
          errEmails.push(res.email)
        }
      })
      if(errEmails.length > 0) {
        setErrorMessage(`The following user(s) already belong to another organization and were therefore not invited. Multi-organizational user access will be a future feature: ${errEmails.join(', ')}`)
      } else {
        handleClose()
        const toastContent = data.inviteUsers?.results?.length === 1 ? `Invited ${data.inviteUsers?.results?.length} user` : `Invited ${data.inviteUsers?.results?.length} users`
        toast.success(toastContent)
      }
    },
    onError: err => {
      setErrorMessage(err.message)
    }
  })

  const { data: notionMembersData } = useQuery(NOTION_MEMBERS)

  useEffect(() => {
    const membersWithoutUser = notionMembersData?.notionMembers?.filter(m => !m.user)
    const emails = membersWithoutUser?.map(m => {
      return m.email
    }) || []
    setEmailOptions(emails)
  }, [notionMembersData])

  useEffect(() => {
    const mappedProjects = projects?.filter(p => !p.deleted && p.sprintTable && p.requirementTable)?.map(p => {
      return p.name
    })
    setProjectOptions(mappedProjects ? ['All', ...mappedProjects] : ['All'])
  }, [projects])

  const onClickInvite = async () => {
    setErrorMessage(null)
    const projectIds = selectedProjects.includes('All') ? projects?.filter(p => !p.deleted && p.sprintTable && p.requirementTable).map(p => p.id) : projects?.filter(p => selectedProjects.includes(p.name) && !p.deleted).map(p => p.id)
    const names = selectedEmails.map(e => {
      const membersWithoutUser = notionMembersData?.notionMembers?.filter(m => !m.user)
      const name = membersWithoutUser.find(m => m.email === e)?.name
      return validateEmail(name) ? null : name
    })
    const notionMemberIds = selectedEmails.map(e => {
      const membersWithoutUser = notionMembersData?.notionMembers?.filter(m => !m.user)
      return membersWithoutUser.find(m => m.email === e)?.id
    })
    await inviteUsers({
      variables: {
        emails: selectedEmails,
        names,
        organizationId,
        allProjects: selectedProjects.includes('All'),
        projectIds,
        role: selectedUserRole?.replace(' ', '_').toUpperCase(),
        notionMemberIds,
      }
    })
  }

  const handleClose = () => {
    setSelectedProjects([])
    setSelectedEmails([])
    setSelectedUserRole(null)
    setErrorMessage(null)
    setCustomEmailInput(null)
    dispatch(setActiveModal(null))
  }

  const onChangeProjects = (value, newInputValue) => {
    if(value === 'All') {
      setSelectedProjects(['All'])
    } else {
      setSelectedProjects(newInputValue)
    }
  }

  const onChangeRole = (value, newInputValue) => {
    setSelectedUserRole(newInputValue)
  }

  const onChangeEmails = (value, newInputValue, reason) => {
    switch(reason) {
      case 'removeOption':
        setSelectedEmails(newInputValue)
      case 'createOption':
        if(customEmailInput && validateEmail(customEmailInput)) {
          setSelectedEmails(newInputValue)
          setCustomEmailInput(null)
        }
        break
      case 'selectOption':
        setSelectedEmails(newInputValue)
        setCustomEmailInput(null)
        break
      case 'clear':
        setSelectedEmails([])
        setCustomEmailInput(null)
      default:
        break
    }
  }

  const handleEmailInputChange = (value) => {
    setCustomEmailInput(value)
    if(value && value[value.length - 1] === ' ') {
      onChangeEmails(null, [...selectedEmails, value], 'createOption')
    }
  }

  const isInviteDisabled = () => {
    return mutationLoading || !(selectedEmails.length && selectedProjects.length && selectedUserRole !== undefined && (!customEmailInput || (customEmailInput && !!validateEmail(customEmailInput))))
  }

  return (
      <Modal open={open} onClose={handleClose}>
        <Card
          classes={{
            root: classes.inviteModalWrapper + " " + classes.cardRoot + " " + classes.cardRootSecondary,
          }}
        >
          <CardHeader
            subheader={
              <>
                <Grid
                  container
                  component={Box}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    xs="auto"
                    className={classes.modalHeaderLeftWrapper}>
                    <Box
                      component={CloseRoundedIcon}
                      width="1.5rem!important"
                      height="1.5rem!important"
                      onClick={handleClose}
                      cursor="pointer"
                    />
                    <Box
                      component={Typography}
                      variant="h3"
                      marginBottom="0!important"
                      marginLeft="1rem!important"
                    >
                      Invite Users
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    <Box
                      justifyContent="flex-end"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={isInviteDisabled()}
                        onClick={() => onClickInvite()}
                      >
                        {mutationLoading ? <CircularProgress style={{height: 12, width: 12}} /> : 'Invite'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            }
            classes={{ root: classes.cardHeaderRoot }}
          />
          <CardContent classes={{ root: classes.inviteModalCardContent }}>
            <Grid container spacing={4}>
              <Grid item xs={12} >
                <FormLabel>Emails</FormLabel>
                <Autocomplete
                  key={0}
                  multiple
                  autoHighlight
                  freeSolo
                  limitTags={3}
                  disableListWrap
                  onInputChange={(e) => handleEmailInputChange(e.target.value)}
                  onChange={(e, newInputValue, reason) => onChangeEmails(e.target.textContent, newInputValue, reason)}
                  renderInput={(params) => {
                    return <TextField {...params} />
                  }}
                  value={selectedEmails?.filter(e => e) || []}
                  onBlur={() => {
                    onChangeEmails(null, [...selectedEmails, customEmailInput], 'createOption')
                  }}
                  options={emailOptions}
                  inputValue={customEmailInput || ''}
                />
                <FormHelperText marginLeft="14px!important" error={!validateEmail(customEmailInput)} classeName={classes.modalMessageError}>
                  {customEmailInput?.length > 0 ? validateEmail(customEmailInput) ? ' ' : 'Please enter a valid email' : ' '}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormLabel>Role</FormLabel>
                <Autocomplete
                  autoHighlight
                  onChange={(e, newInputValue) => onChangeRole(e.target.textContent, newInputValue)}
                  renderInput={(params) => (
                    <TextField {...params} />
                  )}
                  options={userRoleOptions}
                />
              </Grid>
              <Grid item xs={12} xl={8}>
                <FormLabel>Projects</FormLabel>
                  <Autocomplete
                    multiple
                    autoHighlight
                    limitTags={3}
                    onChange={(e, newInputValue) => onChangeProjects(e.target.textContent, newInputValue)}
                    options={projectOptions}
                    value={selectedProjects?.filter(p => p) || []}
                    getOptionDisabled={() => selectedProjects.includes('All')}
                    renderInput={(params) => (
                      <TextField {...params} />
                    )}
                    className={classes.projectSelect}
                  />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.modalMessageError}>
                  {errorMessage}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Modal>
  )
}

export default InviteModal
