import React, {useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Menu from '@material-ui/core/Menu'
import Typography from '@material-ui/core/Typography'
import Notifications from '@material-ui/icons/Notifications'
import componentStyles from 'assets/theme/components/dropdowns/notifications-dropdown.js'
import { useDispatch, useSelector } from 'react-redux'
import { selectNotifications } from '../../views/auth/selectors'
import {setActiveModal} from '../Modals/actions'
import NotificationIcon from "../../utils/NotificationIcon";
import useWindowSize from "../../hooks/useWindowSize";
import { removeNotification as removeNotificationFromState } from "../../views/auth/actions";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { useMutation } from '@apollo/client'
import { REMOVE_NOTIFICATION } from '../../mutations'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(componentStyles)

/*const items = [
  {
    image: require("assets/img/theme/team-1-800x800.jpg").default,
    name: "John Snow",
    description: "Let's meet at Starbucks at 11:30. Wdyt?",
    time: "2 hrs ago",
  },
  {
    image: require("assets/img/theme/team-2-800x800.jpg").default,
    name: "John Snow",
    description: "A new issue has been reported for Argon.",
    time: "3 hrs ago",
  },
  {
    image: require("assets/img/theme/team-3-800x800.jpg").default,
    name: "John Snow",
    description: "Your posts have been liked a lot.",
    time: "4 hrs ago",
  },
  {
    image: require("assets/img/theme/team-4-800x800.jpg").default,
    name: "John Snow",
    description: "Let's meet at Starbucks at 11:30. Wdyt?",
    time: "5 hrs ago",
  },
  {
    image: require("assets/img/theme/team-1-800x800.jpg").default,
    name: "John Snow",
    description: "A new issue has been reported for Argon.",
    time: "6 hrs ago",
  },
]*/

const NotificationsDropdown = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const dispatch = useDispatch()

  const notifications = useSelector(selectNotifications)
  const user = useSelector(state => state.auth?.user)
  const userReadNotifications = user?.readNotifications

  const { width, height } = useWindowSize();

  const isMenuOpen = Boolean(anchorEl)

  const [remove, { loading }] = useMutation(REMOVE_NOTIFICATION, {
    onError: (err) => {
      console.log(err)
    },
  })

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const openNotification = (notification) => {
    dispatch(setActiveModal({code: notification?.code, data: notification}))
    handleMenuClose()
  }

  const userHasReadNotification = (notification) => {
    return userReadNotifications?.findIndex(n => n.notification?.id === notification?.id || n.id === notification?.id) >= 0
  }

  const removeNotification = (event, notification) => {
    event.preventDefault()
    event.stopPropagation()
    if (!loading) {
      remove({
        variables: {
          id: notification?.id
        }
      }).then()
      dispatch(removeNotificationFromState(notification))
    }
  }

  const menuId = 'dropdowns-notifications-dropdown-id'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{
        list: classes.menuList,
        paper: classes.menuPaper,
      }}
    >
      <Box padding="16px 21px 16px 21px">
        <Typography
          variant="h6"
          component="h6"
          className={classes.typographyH6}
        >
          You have{" "}
          <Box component="strong" color={theme.palette.info.main}>
            {notifications.length}
          </Box>{" "}
          notification{notifications.length === 1 ? '' : 's'}.
        </Typography>
      </Box>
      <List disablePadding>
        {notifications.map((notification, key) => {
          return (
            <ListItem key={key} onClick={() => openNotification(notification)} className={classes.listItemRoot} component="a">
              <Grid container alignItems="center">
                <Box
                  flex="0 0 auto"
                  width="auto"
                  maxWidth="100%"
                  position="relative"
                  paddingRight="10px"
                  paddingLeft="10px"
                  minHeight="1px"
                >
                  <NotificationIcon scale={0.5} notification={notification} />
                </Box>
                <Box
                  flexBasis="0"
                  flexGrow="1"
                  width="100%;"
                  maxWidth="100%"
                  position="relative"
                  paddingRight="20px"
                  minHeight="1px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div>
                      <Box style={{color: '#2e3d44', fontWeight: 'bold', fontSize: 13, marginBottom: 5,}}>
                        {notification.title}
                      </Box>
                    </div>
                    <Box
                      textAlign="right"
                      color={theme.palette.gray[600]}
                      fontSize="80%"
                    >
                      {notification.time}
                    </Box>
                  </Box>
                  <Box
                    className={classes.multiLineEllipsis}
                    component="p"
                    fontSize=".875rem"
                    fontWeight="300"
                    lineHeight="1.4"
                    margin="0"
                    maxWidth={375}
                  >
                    {notification.message}
                  </Box>
                </Box>
                {!userHasReadNotification(notification) ?
                <Box
                  component="div"
                  alignSelf="center"
                  position="relative"
                  marginTop="3px"
                  marginRight="22px"
                  marginLeft="10px"
                  width="10px"
                  height="10px"
                  borderRadius="50%"
                  style={{backgroundColor: '#6AE1C6'}}
                /> :
                  <Box
                    component="div"
                    alignSelf="center"
                    position="relative"
                    marginTop="3px"
                    marginRight="22px"
                    marginLeft="10px"
                    width="10px"
                    height="10px"
                    borderRadius="50%"
                    style={{backgroundColor: 'transparent'}}
                  />
                }
              </Grid>
              <Box
                component="div"
                position="absolute"
                right={10}
                top={2}
              >
                {notification.type !== 'ERROR' ? (
                  <IconButton onClick={(e) => removeNotification(e, notification)} className={classes.exitIcon}>
                    <CloseRoundedIcon />
                  </IconButton>
                ) : (
                  <Button onClick={(e) => removeNotification(e, notification)} style={{ padding: 0, fontSize: 12, marginTop: 8 }}>Resolved</Button>
                )}
              </Box>
            </ListItem>
          );
        })}
      </List>
      {/*<Box
        component="a"
        href="#mui"
        padding=".5rem 1rem"
        fontSize=".875rem"
        color={theme.palette.info.main}
        fontWeight="600"
        textAlign="center"
        paddingBottom="1rem"
        paddingTop="1rem"
        display="block"
        className={classes.boxAnchor}
      >
        View all
      </Box>*/}
    </Menu>
  )

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        classes={{
          label: classes.buttonLabel,
        }}
      >
        <Box
          className={classes.icon}
          component={Notifications}
          width="1rem!important"
          height="1rem!important"
        />
      </IconButton>
      {renderMenu}
    </>
  )
}

export default NotificationsDropdown
