import { combineReducers } from 'redux'
import auth from './views/auth/reducer'
import profile from './views/profile/reducer'
import setup from './views/setup/reducer'
import newProjectSetup from './views/admin/NewProjectSetup/reducer'
import modals from './components/Modals/reducer'
import confetti from "./views/admin/Confetti/reducer";

const appReducer = combineReducers({auth, profile, setup, newProjectSetup, modals, confetti})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
