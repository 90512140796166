// core components
import Dashboard from "views/admin/Dashboard.js"
import Numbering from "views/admin/Numbering/Numbering.js"
import People from "views/admin/People/People.js"
import Teams from "views/admin/Teams.js"
import Icons from "views/admin/Icons.js"
import Index from './views/admin/Databases/Databases'
import Organization from './views/admin/Organization/Organization'
import Project from './views/admin/Project/Project'
import Home from './views/product/Home'
import Login from './views/auth/Login'
import Register from './views/auth/Register'
import UpgradePlan from './views/upgradePlan/UpgradePlan'
// @material-ui/icons components
import FlashOn from "@material-ui/icons/FlashOn";
import Grain from "@material-ui/icons/Grain";
import Tv from "@material-ui/icons/Tv";
import Storage from '@material-ui/icons/Storage'
import { FormatListNumbered, Person, SupervisorAccount, Business, AccountTree, Theaters, Description, VpnKey, Textsms } from '@material-ui/icons';
import Tables from './views/admin/Tables'
import ForgotPassword from "./views/auth/ForgotPassword";
import ResetPassword from "./views/auth/ResetPassword";
import OrgAuth from "./views/auth/OrgAuth";
import SetupBilling from "./views/auth/SetupBilling";


const admin = [
  /*{
    href: "#pablo",
    name: "Upgrade to pro",
    icon: FlashOn,
    upgradeToPro: true,
  },*/
  {
    path: "index",
    name: "Dashboard",
    icon: Tv,
    iconColor: "Primary",
    component: Dashboard,
    layout: "/",
    includeProjectPath: true,
  },
  {
    path: 'databases',
    name: 'Databases',
    icon: Storage,
    iconColor: 'Primary',
    component: Index,
    layout: '/',
    includeProjectPath: true,
  },
  {
    path: 'numbering',
    name: 'Numbering',
    icon: FormatListNumbered,
    iconColor: 'Primary',
    component: Numbering,
    layout: '/',
    includeProjectPath: true,
  },
  {
    path: 'people',
    name: 'People',
    icon: Person,
    iconColor: 'Primary',
    component: People,
    layout: '/',
    includeProjectPath: true,
  },
  {
    path: 'teams',
    name: 'Teams',
    icon: SupervisorAccount,
    iconColor: 'Primary',
    component: Teams,
    layout: '/',
    includeProjectPath: true,
  },
  {
    path: 'organization',
    optionalParams: '/:test',
    name: 'Organization Setup',
    icon: Business,
    iconColor: 'Primary',
    component: Organization,
    layout: '/',
    includeProjectPath: true,
  },
  {
    path: 'project',
    name: 'Project Setup',
    icon: AccountTree,
    iconColor: 'Primary',
    component: Project,
    layout: '/',
    includeProjectPath: true,
  },
  {
    path: "icons",
    name: "Icons",
    icon: Grain,
    iconColor: "Primary",
    component: Icons,
    layout: '/',
    includeProjectPath: true,
  },
  {
    divider: true,
  },
  {
    title: "Support",
  },
  {
    href:
      "https://vimeo.com/channels/1724629",
    name: "Tutorials",
    icon: Theaters,
  },
  {
    href:
      "https://oosstudio.notion.site/NotionOps-43d1e42fd9f84527a2a4b26b7899e09a",
    name: "Documentation",
    icon: Description,
  },
  {
    name: "Feedback",
    icon: Textsms,
    button: () => _urq.push(['Feedback_Open']),
  },
];

const auth = [
  {
    path: "/login",
    name: "Login",
    icon: VpnKey,
    iconColor: "Info",
    component: Login,
    layout: "/auth",
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    layout: '/auth',
  },
  {
    path: '/forgot-password',
    name: "Forgot Password",
    component: ForgotPassword,
    layout: '/auth',
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    layout: '/auth',
  },
  {
    path: '/setup-billing',
    name: 'Setup Billing',
    component: SetupBilling,
    layout: '/auth',
    hideLoginButtons: true,
  },
];

const product = [
  {
    path: '/',
    name: 'Home',
    icon: VpnKey,
    iconColor: 'Info',
    component: Home,
    layout: '/product',
  }
]

const upgradePlan = [
  {
    path: '/',
    name: 'Upgrade Plan',
    component: UpgradePlan,
    layout: '/upgrade-plan',
  }
]

const org = [
  {
    path: '/login',
    name: "Organization Login",
    component: OrgAuth,
    layout: '/org',
    hideLoginButtons: true,
  },
]



export {admin, auth, product, org, upgradePlan}

