import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import config from '../../config'
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Box, Container, Badge } from '@material-ui/core'
import Button from "@material-ui/core/Button";
import {Typography} from '@material-ui/core'
import Grid from "@material-ui/core/Grid";
import { Fade } from 'react-slideshow-image'

// @material-ui/icons components
import { Equalizer, TrendingDown, ShowChart, LowPriority, List, ViewWeek, Map, MoreHoriz, Check } from '@material-ui/icons'

// import slideshow images
import slide1 from '../../assets/img/product/dashboard-light.png'
import slide2 from '../../assets/img/product/dashboard-dark.png'

// core components
import componentStyles from "assets/theme/views/product/home.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";

import { SimpleCallToAction, UsageValidation, ComparisonTable, PricingInfo } from './components'
import Values from './components/Values'
import AngledBackground from './components/AngledBackground'

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);

function Home() {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const history = useHistory()
  const theme = useTheme();
  const [ titleSize, setTitleSize ] = useState(window.innerWidth <= 405 ? classes.title : classes.title)

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': 'Home'
    })
    const handleTitleResize = () => {
      if (window.innerWidth <= 405) {
        setTitleSize(classes.smallTitle)
      } else {
        setTitleSize(classes.title)
      }
    }
    window.addEventListener('resize', handleTitleResize)
    return () => window.removeEventListener('resize', handleTitleResize)
  }, [])

  return (
    <>
      <Container className={classes.headerWrapper} maxWidth={'xl'}>
        <Grid className={classes.leftGridItem} item sm={12} md={6}>
          <Box
            className={classes.leftBox}
            color={theme.palette.white.main}
          >
            <Typography variant="h1" className={titleSize}>Agile scrum with Notion</Typography>
            <Box
              component="p"
              //color={theme.palette.gray[100]}
              lineHeight="1.7"
              fontSize="1rem"
              className={classes.titleBlurb}
            >
              Finally a way to conduct your agile scrum process in Notion—from burndowns to velocity. Track your team's performance like never before!
            </Box>
            <Button
              style={{ marginRight: '1rem', marginBottom: '1rem' }}
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/auth/register')
              }}
              //href={'https://signup.notionops.com/early-access'}
            >
              Get started for free
            </Button>
            <Button
              target={'_blank'}
              href={config.affiliateLink}
              style={{ marginBottom: '1rem', backgroundColor: '#FFF', color: '#FF664D' }} className={classes.pricingCardBtn}
            >
              What's Notion?
            </Button>
          </Box>
        </Grid>
        <Grid  className={classes.rightGridItem} item sm={12} md={6}>
        <div className={classes.slider}>
          <Fade
            autoplay={true}
            canSwipe={false}
            arrows={false}

          >
            <div>
              <img className={classes.slideImage} src={slide1}/>
            </div>
            <div>
              <img className={classes.slideImage} src={slide2}/>
            </div>
          </Fade>
        </div>
        </Grid>
      </Container>
      <Box paddingTop="1.0rem" paddingBottom="4.5rem" component="section">
        <Container maxWidth="xl">
          <Box marginBottom={'8rem'}>
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src={'https://player.vimeo.com/video/694051497?h=71b7943d1f&amp;badge=4&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
                frameBorder={0}
                allow={'autoplay; fullscreen; picture-in-picture'}
                allowFullScreen
                style={{ borderRadius: 6, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                title={'NotionOps: Agile Scrum With Notion'}
              />
            </div>
          </Box>
        </Container>
        <Values />
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container className={classes.headerWrapper} maxWidth={'xl'}>
            <Box>
              <Grid container alignItems="center">
                <Grid item xs={12} md={6} className={classes.orderMd2}>
                  <Box
                    component="img"
                    alt="..."
                    maxWidth="100%"
                    height="auto"
                    className={classes.imgBox}
                    src={require("assets/img/product/landing-1.png").default}
                  ></Box>
                </Grid>
                <Grid item xs={12} md={6} className={classes.orderMd1}>
                  <div className={classes.prMd5}>
                    <Typography variant="h2" component="h2">
                      Analytics
                    </Typography>
                    <Box
                      component="p"
                      fontWeight="300"
                      lineHeight="1.7"
                      fontSize="1rem"
                      marginBottom="1rem"
                      marginTop="0"
                    >
                      NotionOps processes your agile scrum data from Notion and produces insights. Simply embed their widgets into your pages for the whole team to see.
                    </Box>
                    <Box
                      component="ul"
                      paddingLeft="0"
                      className={classes.listStyleNone}
                    >
                      <Box
                        component="li"
                        paddingTop=".5rem"
                        paddingBottom=".5rem"
                      >
                        <Box display="flex" alignItems="center">
                          <div>
                            <Badge
                              badgeContent={<Equalizer />}
                              classes={{
                                badge: clsx(
                                  classes.badgePositionRelative,
                                  classes.badgeCircle,
                                  classes.badgeSuccess
                                ),
                              }}
                            ></Badge>
                          </div>
                          <div>
                            <Typography
                              className={classes.typographyH4}
                            >
                              Velocity charts
                            </Typography>
                          </div>
                        </Box>
                      </Box>
                      <Box
                        component="li"
                        paddingTop=".5rem"
                        paddingBottom=".5rem"
                      >
                        <Box display="flex" alignItems="center">
                          <div>
                            <Badge
                              badgeContent={<TrendingDown />}
                              classes={{
                                badge: clsx(
                                  classes.badgePositionRelative,
                                  classes.badgeCircle,
                                  classes.badgeSuccess
                                ),
                              }}
                            ></Badge>
                          </div>
                          <div>
                            <Typography
                              className={classes.typographyH4}
                            >
                              Sprint burndowns
                            </Typography>
                          </div>
                        </Box>
                      </Box>
                      <Box
                        component="li"
                        paddingTop=".5rem"
                        paddingBottom=".5rem"
                      >
                        <Box display="flex" alignItems="center">
                          <div>
                            <Badge
                              badgeContent={<ShowChart />}
                              classes={{
                                badge: clsx(
                                  classes.badgePositionRelative,
                                  classes.badgeCircle,
                                  classes.badgeSuccess
                                ),
                              }}
                            ></Badge>
                          </div>
                          <div>
                            <Typography
                              className={classes.typographyH4}
                            >
                              Cumulative flow diagrams
                            </Typography>
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  alt="..."
                  maxWidth="100%"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/product/landing-2.png").default}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.prMd5}>
                  <Typography variant="h2" component="h2">
                    Number sequencing
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                  >
                    Notion is a great place for managing your product backlog and viewing kanban boards. However, as your requirements grow they can become harder to track. This is why NotionOps automatically assigns unique numbers to your backlog so you don't have to worry.
                  </Box>
                  {/*<Box
                    component={Link}
                    to="/admin/profile"
                    color={theme.palette.warning.main}
                    marginTop="3rem"
                    fontWeight="600"
                    className={classes.warningAnchor}
                  >
                    Explore pages
                  </Box>*/}
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box paddingTop="4.5rem" paddingBottom="1rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} className={classes.orderMd2}>
                <Box
                  component="img"
                  alt="..."
                  maxWidth="100%"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/product/landing-3.png").default}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6} className={classes.orderMd1}>
                <div className={classes.prMd5}>
                  <Typography variant="h2" component="h2">
                    A powerful template
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                  >
                    Getting started with your project in Notion can feel overwhelming. How do you choose from all the available tools and templates? NotionOps makes it easy with a compatible agile scrum template that’s ready to go.
                  </Box>
                  <Box
                    component="ul"
                    paddingLeft="0"
                    className={classes.listStyleNone}
                  >
                    <Box
                      component="li"
                      paddingTop=".5rem"
                      paddingBottom=".5rem"
                    >
                      <Box display="flex" alignItems="center">
                        <div>
                          <Badge
                            badgeContent={<LowPriority />}
                            classes={{
                              badge: clsx(
                                classes.badgePositionRelative,
                                classes.badgeCircle,
                                classes.badgeSuccess
                              ),
                            }}
                          ></Badge>
                        </div>
                        <div>
                          <Typography
                            className={classes.typographyH4}
                          >
                            Sprint planning
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box
                      component="li"
                      paddingTop=".5rem"
                      paddingBottom=".5rem"
                    >
                      <Box display="flex" alignItems="center">
                        <div>
                          <Badge
                            badgeContent={<List />}
                            classes={{
                              badge: clsx(
                                classes.badgePositionRelative,
                                classes.badgeCircle,
                                classes.badgeSuccess
                              ),
                            }}
                          ></Badge>
                        </div>
                        <div>
                          <Typography
                            className={classes.typographyH4}
                          >
                            Product backlog
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box
                      component="li"
                      paddingTop=".5rem"
                      paddingBottom=".5rem"
                    >
                      <Box display="flex" alignItems="center">
                        <div>
                          <Badge
                            badgeContent={<ViewWeek />}
                            classes={{
                              badge: clsx(
                                classes.badgePositionRelative,
                                classes.badgeCircle,
                                classes.badgeSuccess
                              ),
                            }}
                          ></Badge>
                        </div>
                        <div>
                          <Typography
                            className={classes.typographyH4}
                          >
                            Current board
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box
                      component="li"
                      paddingTop=".5rem"
                      paddingBottom=".5rem"
                    >
                      <Box display="flex" alignItems="center">
                        <div>
                          <Badge
                            badgeContent={<Map />}
                            classes={{
                              badge: clsx(
                                classes.badgePositionRelative,
                                classes.badgeCircle,
                                classes.badgeSuccess
                              ),
                            }}
                          ></Badge>
                        </div>
                        <div>
                          <Typography
                            className={classes.typographyH4}
                          >
                            Roadmaps
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box
                      component="li"
                      paddingTop=".5rem"
                      paddingBottom=".5rem"
                    >
                      <Box display="flex" alignItems="center">
                        <div>
                          <Badge
                            badgeContent={<MoreHoriz />}
                            classes={{
                              badge: clsx(
                                classes.badgePositionRelative,
                                classes.badgeCircle,
                                classes.badgeSuccess
                              ),
                            }}
                          ></Badge>
                        </div>
                        <div>
                          <Typography
                            className={classes.typographyH4}
                          >
                            And much more
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <ComparisonTable />
      <UsageValidation />
      {/*<SimpleCallToAction/>*/}
      <PricingInfo />
    </>
  );
}

export default Home;
