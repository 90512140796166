import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useLocation } from 'react-router-dom'

// core components
import componentStyles from "assets/theme/components/auth-header.js";
import Button from '@material-ui/core/Button'
import {useSelector} from "react-redux";

const useStyles = makeStyles(componentStyles);

const AuthHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation()

  const user = useSelector(state => state.auth?.user)
  const isInvitedUser = user?.organizations?.length > 0

  let headingMessage = 'Welcome!'
  let message = 'Don\'t have an account? Use the link above to get started for free today.'
  if (location.pathname === '/auth/register') {
    if(isInvitedUser) {
      message = `We're excited to have you get started with NotionOps! Please fill out the form below to create your account.`
    } else {
      message = `We're excited to have you get started with NotionOps! Please fill out the form below to create your account and start setting up your first project.`
    }
  }

  if(location.pathname === '/auth/forgot-password') {
    headingMessage = 'Forgot Password?'
    message = `Enter your email below, and if we recognize it we'll send you a link to reset your password.`
  }

  if(location.pathname === '/auth/reset-password') {
    headingMessage = 'Reset Password'
    message = `Enter a new password below.`
  }

  if(location.pathname === '/auth/setup-billing') {
    headingMessage = 'Welcome!'
    message = `Thank you ${location.state?.registeredFirstName || ''} for creating an account.`
  }

  return (
    <>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="8rem"
        paddingBottom={isInvitedUser ? '4rem' : '8rem'}
      >
        <Container maxWidth="xl">
          <Box marginBottom="6rem" textAlign="center">
            <Box
              component={Grid}
              container
              justifyContent="center"
              color={theme.palette.white.main}
            >
              <Grid item lg={5} md={6} xs={12}>
                <h1>{headingMessage}</h1>
                <Box
                  component="p"
                  lineHeight="1.7"
                  fontSize="1rem"
                >
                  {message}
                </Box>
              </Grid>
            </Box>
          </Box>
        </Container>
        <Box
          position="absolute"
          zIndex="100"
          height="70px"
          top="auto"
          bottom="0"
          pointerEvents="none"
          left="0"
          right="0"
          width="100%"
          overflow="hidden"
          transform="translateZ(0)"
        >
          <Box
            bottom="0"
            position="absolute"
            pointerEvents="none"
            component="svg"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
          </Box>
        </Box>
        <Box
          position="absolute"
          zIndex="100"
          height="70px"
          top="auto"
          bottom="0"
          pointerEvents="none"
          left="0"
          right="0"
          width="100%"
          overflow="hidden"
          transform="translateZ(0)"
        >
          <Box
            bottom="0"
            position="absolute"
            pointerEvents="none"
            component="svg"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <Box
              component="polygon"
              fill={'white'}
              points="2560 0 2560 100 0 100"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AuthHeader;
