import themeColors from "../colors"
import boxShadows from "../box-shadow";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
  },
  cardRootSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardHeaderRoot: {
    backgroundColor: theme.palette.white.main + "!important",
  },
  inviteModalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '75%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 750,
  },
  modalHeaderLeftWrapper: {
    display: 'flex',
  },
  inviteModalCardContent: {
    minHeight: 153,
    position: 'relative'
  },
  contentWrapper : {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  headerButton: {
    position: 'absolute',
    right: '1.5rem',
  },
  exitIcon: {
    marginLeft: '-3.75%',
    marginTop: '-3.75%',
  },
  projectSelectWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  projectSelect: {
    flex: 1,
  },
  modalMessageError: {
    color: '#f5365c',
    fontSize: 12,
  },
})

export default componentStyles
