import { SET_USER_DATA } from './actions'

const initState = {
  user: null,
}

const auth = (state=initState, action) => {
  switch(action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      }
    default:
      return state
  }
}

export default auth
