const componentStyles = (theme) => ({
  buttonLabel: {
    fontSize: ".875rem",
    fontWeight: "600",
    color: theme.palette.buttonLightLabel.main,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "0!important",
    },
  },
  listItemRoot: {
    padding: "1rem",
    borderBottom: "1px solid " + theme.palette.gray[200],
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.gray[100],
      cursor: 'pointer'
    },
  },
  boxAnchor: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.gray[100],
      color: theme.palette.info.dark,
    },
  },
  menuList: {
    padding: "0!important",
  },
  menuPaper: {
    padding: "0!important",
    //minWidth: "420px",
  },
  typographyH6: {
    fontSize: ".875rem!important",
    margin: "0!important",
    color: theme.palette.gray[600],
  },
  typographyH4: {
    fontSize: ".875rem!important",
    margin: "0!important",
  },
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  icon: {
    color: 'white',
    [theme.breakpoints.down("sm")]: {
      color: '#2E3D44',
    },
  },
});

export default componentStyles;
