import themeColors from "../colors"

const componentStyles = (theme) => ({
  box : {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gif: {
    paddingTop: '-10%',
    height: '50%',
    width: '50%',
  },
  headerButton: {
    position: 'absolute',
    right: '1.5rem',
  },
  exitIcon: {
    marginLeft: '-3.75%',
    marginTop: '-3.75%',
  },
  notificationTitle : {
    padding: "0.75rem",
    borderTopLeftRadius: ".4375rem",
    borderTopRightRadius: ".4375rem",
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: -10,
    textAlign: 'justify',
  },
  notificationContent : {
    padding: "0.75rem",
    paddingInline: "2.75rem",
    borderTopLeftRadius: ".4375rem",
    borderTopRightRadius: ".4375rem",
    fontSize: '14px',
    marginBottom: 25,
    textAlign: 'center',
  },
  notificationButton : {
    marginBottom: 25,
    backgroundColor: '#ff664d' ,
    borderColor: '#ff664d',
    color: '#FFFFFF',
    "&:hover": {
      backgroundColor: '#ff3210' ,
      borderColor: '#ff3210',
    }
  },
  errorCode: {
    fontSize: 12,
    color: 'rgba(41,61,68,0.8)',
    marginTop: 10,
  },
  successIconWrapper: {
    backgroundColor: '#07BC0C',
  },
  errorIconWrapper: {
    backgroundColor: '#E74C3C',
  },
  warningIconWrapper: {
    backgroundColor: '#F1C40F',
  },
  infoIconWrapper: {
    backgroundColor: '#3498DB',
  },
  successIcon: {
    fill: 'white',
    transform: 'scale(2)',
  },
  warningIcon: {
    fill: 'white',
    transform: 'scale(2)',
  },
  errorIcon: {
    fill: 'white',
    transform: 'scale(2)',
  },
})

export default componentStyles
