import React, { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { useTheme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CardContent from "@material-ui/core/CardContent"
import Checkbox from "@material-ui/core/Checkbox"
import FilledInput from "@material-ui/core/FilledInput"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import Lock from "@material-ui/icons/Lock"
import Person from '@material-ui/icons/Person'
import componentStyles from "assets/theme/views/auth/register.js"
import {useMutation} from "@apollo/client";
import {REGISTER_INVITED_USER } from "../../mutations";
import {CircularProgress} from '@material-ui/core'

const useStyles = makeStyles(componentStyles);

const passwordStatus = {
  empty: 0,
  match: 1,
  mismatch: 2
}

const InvitedUserRegisterForm = ({ signIn, user, setSigningIn }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [name, setName] = useState(null)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [subscribe, setSubscribe] = useState(true)
  const [agreeToTerms, setAgreeToTerms] = useState(false)

  useEffect(() => {
    setName(user?.name || null)
  }, [user?.name])

  const [registerUser] = useMutation(REGISTER_INVITED_USER, {
    variables: {
      id: user?.id,
      password,
      name,
      subscribe,
    },
  })

  const getPasswordStatus = () => {
    if ((password === '' || !password) && (confirmPassword === '' || !confirmPassword)) {
      return passwordStatus.empty
    }
    if (password !== '' && password && (confirmPassword === '' || !confirmPassword)) {
      return passwordStatus.empty
    }
    if (password !== confirmPassword) {
      return passwordStatus.mismatch
    }
    return passwordStatus.match
  }

  const pStatus = getPasswordStatus()

  const formValid = () => {
    return pStatus === passwordStatus.match && agreeToTerms && (name && name.length > 0)
  }

  const onSubmit = async () => {
    setSigningIn(true)
    registerUser().then(({ registerInvitedUser }) => {
      signIn({
        variables: {
          email: user?.email,
          password,
        },
      })
      //todo: when we add multi-org support for users we will need to make sure we add them to those groups when existing users are invited to projects of different orgs
      gtag('event', 'join_group', {
        group_id: registerInvitedUser?.lastOpenedProject?.organization?.domain
      })
    })
  }

  return (
    <CardContent classes={{root: classes.cardContent}}>
      <Box
        color={theme.palette.gray[600]}
        textAlign="center"
        marginBottom="1.5rem"
        marginTop=".5rem"
        fontSize="1rem"
      >
        <Box fontSize="80%" fontWeight="400" component="small">
           Enter your information below to create your account.
        </Box>
      </Box>
      {!user?.name && <FormControl
        variant="filled"
        component={Box}
        width="100%"
        marginBottom="1.5rem!important"
      >
        <FilledInput
          value={name}
          onChange={e => setName(e.target.value)}
          autoComplete="off"
          type="text"
          placeholder="Name*"
          startAdornment={
            <InputAdornment position="start">
              <Person />
            </InputAdornment>
          }
        />
      </FormControl>}
      <FormControl
        variant="filled"
        component={Box}
        width="100%"
        marginBottom="1.5rem!important"
      >
        <FilledInput
          value={password}
          onChange={e => setPassword(e.target.value)}
          autoComplete="off"
          type="password"
          placeholder="Password*"
          startAdornment={
            <InputAdornment position="start">
              <Lock/>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl
        variant="filled"
        component={Box}
        width="100%"
        marginBottom="1.5rem!important"
      >
        <FilledInput
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          autoComplete="off"
          type="password"
          placeholder="Confirm Password*"
          startAdornment={
            <InputAdornment position="start">
              <Lock/>
            </InputAdornment>
          }
        />
      </FormControl>
      <Box
        fontStyle="italic"
        fontSize="1rem"
        color={theme.palette.gray[600]}
        marginBottom=".5rem"
        classes={{root: pStatus === passwordStatus.empty ? classes.hiddenFormMessage : classes.visibleFormMessage}}
      >
        <Box component="small" fontSize="80%">
          passwords{" "}
          <Box
            component="span"
            fontWeight="700"
            color={pStatus === passwordStatus.match ? theme.palette.success.main : theme.palette.error.main}
          >
            {pStatus === passwordStatus.match ? 'match' : `don't match`}
          </Box>
        </Box>
      </Box>
      <FormControlLabel
        control={<Checkbox checked={subscribe} onChange={o => {
          setSubscribe(o.target.checked)
        }}
                           color="primary"/>}
        label={'Keep me informed with free resources and news updates.'}
        labelPlacement="end"
        classes={{
          root: classes.formControlLabelRoot,
          label: classes.formControlLabelLabel,
        }}
      />
      <FormControlLabel
        control={<Checkbox checked={agreeToTerms} onChange={o => setAgreeToTerms(o.target.checked)}
                           color="primary"/>}
        label={
          <>
            I agree with the{" "}
            <Box
              color={theme.palette.primary.main}
              component="a"
              href={'https://www.notion.so/oosstudio/Terms-and-Privacy-b497fbb6a418424aa5e39e60070b3fcd'}
              target={'_blank'}
              textDecoration="none"
            >
              Terms and Privacy
            </Box>
          </>
        }
        labelPlacement="end"
        classes={{
          root: classes.formControlLabelRoot,
          label: classes.formControlLabelLabel,
        }}
      />
      <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
        <Button style={{width: 150, height: 43}} disabled={!formValid()} onClick={onSubmit} color="primary" variant="contained">
          Create account
        </Button>
      </Box>
    </CardContent>
  );
}

export default InvitedUserRegisterForm;
