import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import componentStyles from '../../assets/theme/components/card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OrganizationProjectsTable from '../Tables/OrganizationProjectsTable'
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../../views/auth/selectors";

const useStyles = makeStyles(componentStyles)

const OrganizationProjects = () => {
  const classes = useStyles()
  const history = useHistory()

  const user = useSelector(selectUser)

  return (
    <Card classes={{ root: classes.cardRoot }} style={{ marginTop: '30px' }}>
      <CardHeader
        className={classes.cardHeader}
        titleTypographyProps={{
          component: Box,
          marginBottom: '0!important',
          variant: 'h3',
        }}
        subheader={
          <Grid
            container
            component={Box}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs='auto'>
              <Box
                component={Typography}
                variant='h3'
                marginBottom='0!important'
              >
                Projects
              </Box>
            </Grid>
            <Grid item xs='auto'>
              <Box
                justifyContent='flex-end'
                display='flex'
                flexWrap='wrap'
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={user?.readOnly}
                  onClick={() => history.replace({pathname: '/new-project'})}
                >
                  New Project
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
      />
      <OrganizationProjectsTable/>
    </Card>
  )
}

export default OrganizationProjects
