import {isDarkMode} from "../../../styles";

const styles = ({renderOnSite}) => {
  return {
    container: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      backgroundColor: isDarkMode() && !renderOnSite ? 'rgb(25, 25, 25)' : 'white',
      padding: renderOnSite ? 7 : 0,
      borderRadius: renderOnSite ? '0.375rem' : 0,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    chartWrapper: {
      // position: 'absolute',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      margin: 1,
      marginBottom: 5,
      height: 'calc(100% - 5px)',
      padding: 7,
      backgroundColor: isDarkMode() && !renderOnSite ? 'rgba(255, 255, 255, 0.03)' : undefined
    },
    button: {
      display: 'block',
    },
    formWrapper: {
      display: 'flex',
      width: 'fit-content',
      marginTop: 10,
    },
    formControl: {
      flexDirection: 'row',
      marginLeft: 5,
      zIndex: 5,
      flexWrap: 'wrap',
      marginBottom: 10,
    },
    select: {
      marginRight: 10,
      '& .MuiSelect-root': {
        borderStyle: 'none',
        marginBottom: -14,
      },
      '& .MuiSelect-icon': {
        top: 'calc(50% - 5px)',
      },
    },
    textUppercase: {
      padding: 5,
      textTransform: "uppercase",
    },
    textUppercaseRight: {
      padding: 5,
      paddingBottom: 0,
      width: '100%',
      textAlign: 'right',
      textTransform: "uppercase",
    },
    refetchProgressOverlay: {
      position: 'absolute',
      top: 'calc(50% - 20px)',
      left: 'calc(50% - 20px)',
      zIndex: 1000,
    },
    error: {
      color: '#727d83',
      fontSize: 12,
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 50,
      flex: 'auto',
      flexDirection: 'column',
    },
    viewBtn: {
      margin: '0 auto',
      marginTop: '2em'
    },
    iconWrapper: {
      // position: 'absolute',
      // bottom: 10,
      // right: 14,
      zIndex: 1000,
      padding: 5,
    },
    copyButton: {
      position: 'absolute',
      bottom: 10,
      zIndex: 1000,
      right: 14,
    },
    dateInput: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 0,
      marginBottom: 0,
      '& .MuiFormLabel-root': {
        color: '#727d83',
        fontSize: 14,
        fontWeight: 400,
        paddingTop: 5,
      },
    },
    statusHelpText: {
      fontSize: 12,
      textDecoration: 'underline',
      marginLeft: 10,
      marginBottom: 10,
      color: '#727d83',
      cursor: 'pointer',
    },
    checkboxWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginLeft: 10,
      width: '100%',
    },
    upgradePlanContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  }
}

export default styles
