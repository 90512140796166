import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import { makeStyles } from '@material-ui/core'
import componentStyles from '../../assets/theme/components/table'
import cardStyles from '../../assets/theme/components/card'
import { useMutation, useQuery } from '@apollo/client'
import { PROJECTS } from '../../queries'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import MoreVert from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTheme } from '@material-ui/core/styles'
import { DELETE_PROJECT, UPDATE_LAST_OPENED_PROJECT } from '../../mutations'
import { setUserData } from '../../views/auth/actions'
import { useHistory } from 'react-router-dom'
import { setProject } from '../../views/setup/actions'
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const useStyles = makeStyles(props => ({
  ...cardStyles(props),
  ...componentStyles(props),
}))

const OrganizationProjectItem = ({ item, index, isUserProject }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState(null)
  const [timeLeft, setTimeLeft] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const calculateTimeLeft = (item) => {
    if (!item || !item?.deleted || !item?.deletedOn || !endTime) return
    const difference = +endTime - +new Date()
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    }
    return timeLeft;
  }

  useEffect(() => {
    if (item && item.deleted && item.deletedOn && !endTime) {
      let deletedOn = new Date(item.deletedOn)
      let end = new Date()
      end.setDate(deletedOn.getDate() + 30)
      setEndTime(end)
    }
  }, [item])

  useEffect(() => {
    if (item && item.deleted && item.deletedOn) {
      const timeout = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(item));
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  });

  const user = useSelector(state => state.auth?.user)
  const userRole = user?.roles[0]?.name

  const [restoreProject] = useMutation(DELETE_PROJECT, {
    onError: (e) => {
      console.log(e)
    }
  })

  const [updateLastOpenedProject] = useMutation(UPDATE_LAST_OPENED_PROJECT, {
    onCompleted: async ({ updateOneUser }) => {
      dispatch(setUserData(updateOneUser))
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const restore = async (item) => {
    await restoreProject({
      variables: {
        id: item?.id,
        deleted : false,
        deletedOn: null,
      }
    })
    handleClose()
  }


  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleSetLastProjectOpened = async () => {
    if (!isUserProject) {
      handleOpenDialog()
    }
    if (item && !item?.deleted && isUserProject && user?.lastOpenedProject?.id !== item.id) {
      if (!item?.requirementTable) {
        dispatch(setProject(item))
        let step = 0
        if (item?.id && item?.timezone) {
          step = 2
          if (item?.notionAuthorization) {
            step = 3
            if (item?.sprintTable) {
              step = 4
            }
          }
          history.replace({pathname: `/new-project/pid${item?.id}/${step}`})
        } else {
          history.replace({pathname: `/new-project/${step}`})
        }
      } else {
        await updateLastOpenedProject({
          variables: {
            userId: user?.id,
            projectId: item.id,
          },
        })
      }
    }
  }

  const deleted = item?.deleted

  return (
    <>
      <TableRow
        classes={{ root: classes.tableRow }}
        style={{ cursor: deleted || !isUserProject ? 'default' : 'pointer' }}
        onClick={handleSetLastProjectOpened}
      >
        <TableCell classes={{ root: classes.tableCellRoot }}>
          <Box
            paddingTop='.35rem'
            paddingBottom='.35rem'
            style={{
              color: deleted ? '#b8b8b8' : '#2e3d44',
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'flex-start',
              lineHeight: 1,
              flexDirection: 'column',
            }}
          >
            <Box>
              {deleted ? (
                <Box
                  component='i'
                  width='.375rem'
                  height='.375rem'
                  borderRadius='50%'
                  display='inline-block'
                  marginRight='10px'
                  className={classes.bgError}
                />
              ) : null}
              <span>{item.name}</span>
            </Box>
            {deleted ? (
              <>
                <span style={{ marginTop: '10px', fontSize: '11px' }}>Permanently removes in</span>
                <span style={{ fontSize: '11px' }}>{timeLeft?.days}dy {timeLeft?.hours}hr {timeLeft?.minutes}m</span>
              </>
            ) : null}
          </Box>
        </TableCell>
        <TableCell classes={{ root: classes.tableCellRoot }}>
          <Box
            paddingTop='.35rem'
            paddingBottom='.35rem'
            style={{ color: deleted ? '#b8b8b8' : '#2e3d44' }}
          >
            {item?.timezone ? item?.timezone.replace('_', ' ') : ''}
          </Box>
        </TableCell>
        <TableCell
          classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootBodyHead }}
          component='th'
          variant='head'
          scope='row'
        >
          <Box alignItems='center' display='flex'>
            <Box component={Avatar} marginRight='1rem' alt='...' src={item?.notionAuthorization?.workspaceIcon} />
            <Box display='flex' alignItems='flex-start'>
              <Box style={{ color: deleted ? '#b8b8b8' : '#2e3d44' }}>{item?.notionAuthorization?.workspaceName}</Box>
            </Box>
          </Box>
        </TableCell>
        {deleted && isUserProject ? (
          <TableCell classes={{ root: classes.tableCellRoot }} align='right' onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}>
            <Box
              aria-controls={`simple-menu-${index}`}
              aria-haspopup='true'
              onClick={handleClick}
              size='small'
              component={Button}
              width='2rem!important'
              height='2rem!important'
              minWidth='2rem!important'
              minHeight='2rem!important'
            >
              <Box
                component={MoreVert}
                width='1.25rem!important'
                height='1.25rem!important'
                position='relative'
                top='2px'
                color={theme.palette.gray[500]}
              />
            </Box>
            <Menu
              id={`simple-menu-${index}`}
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => restore(item)}>Restore</MenuItem>
            </Menu>
          </TableCell>
        ) : <TableCell classes={{ root: classes.tableCellRoot }} align='right'></TableCell>}
      </TableRow>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cannot Select Project"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {userRole === 'ADMIN' || userRole === 'OWNER' ? 'You must add yourself to this project if you wish to select it' : 'Please contact an administrator if you wish to be added to this project'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const OrganizationProjectsTable = () => {
  const classes = useStyles()

  const organizationId = useSelector(state => state.auth?.user?.lastOpenedProject?.organization?.id)
  const userProjects = useSelector(state => state.auth?.user?.projects)

  const { data } = useQuery(PROJECTS, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId,
    }
  })

  const getItems = projects => {
    return projects.map(project => {
      const isUserProject = !!userProjects.find(p => p.id === project.id)
      return <OrganizationProjectItem item={project} isUserProject={isUserProject}/>
    })
  }

  return (
    <>
      <TableContainer>
        <Box component={Table} alignItems='center' marginBottom='0!important'>
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}>
                Name
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}>
                Timezone
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}>
                Authorization
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.projects?.length ? getItems(data.projects) : null}
          </TableBody>
        </Box>
      </TableContainer>
    </>
  )
}

export default OrganizationProjectsTable
