import React, {useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import { makeStyles } from '@material-ui/core'
import componentStyles from '../../assets/theme/components/table'
import cardStyles from '../../assets/theme/components/card'
import {useMutation, useQuery} from '@apollo/client'
import { PEOPLE } from '../../queries'
import {DELETE_ONE_USER, DELETE_PROJECT} from "../../mutations";
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import MoreVert from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTheme } from '@material-ui/core/styles'
import EditPeopleModal from '../../views/admin/People/components/EditPeopleModal'
import Tooltip from '@mui/material/Tooltip'
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles(props => ({
  ...cardStyles(props),
  ...componentStyles(props),
}))

const PeopleItem = ({ item, organizationProjects, organizationId, index, organizationRoles }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const [showEditPeopleModal, setShowEditPeopleModal] = useState(false)

  const user = useSelector(state => state.auth?.user)
  const userRole = user?.roles[0]?.name
  const role = item?.roles[0]?.name
  const owners = organizationRoles.filter(role => {
    return role === 'OWNER'
  })

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const formatRole = role => {
    if (!role || role === '') return null
    return role.toLowerCase()
      .replace('_', ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  }

  const [deleteOneUser, { loading }] = useMutation(DELETE_ONE_USER, {
    onCompleted: () => {
      toast.success(`${item.name} has been removed from the work space. `)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const handleDelete = async (item, organizationId) => {
    await deleteOneUser({
      variables: {
        data: {
          id: item?.id,
          organizationId,
        }
      }
    })
  }

  const deleteDisabled = loading || item.roles[0].name === 'OWNER' && owners.length === 1

  const getActions = () => {
    let actions = []

    if (userRole !== 'READ_ONLY') {
      actions.push(<MenuItem disabled={deleteDisabled} onClick={() => handleDelete(item, organizationId)}>Remove</MenuItem>)
    }

    return actions
  }

  return (
    <>
      <TableRow classes={{ root: classes.tableRow }} style={{ cursor: 'pointer' }} onClick={() => setShowEditPeopleModal(true)}>
        <TableCell classes={{ root: classes.tableCellRoot }}>
          <Box alignItems='center' display='flex'>
            <Box display='flex' alignItems='flex-start' flexDirection='column'>
              <Box style={{ color: '#2e3d44', fontWeight: 'bold' }}>{item.name}</Box>
              <Box style={{ color: 'rgba(46,61,68,0.9)' }}>{item.email}</Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell classes={{ root: classes.tableCellRoot }}>
          <Box paddingTop='.35rem' paddingBottom='.35rem'>
            {formatRole(role)}
          </Box>
        </TableCell>
        <TableCell classes={{ root: classes.tableCellRoot }}>
          {organizationProjects?.length === item?.projects?.length ? (
            <Box paddingTop='.35rem' paddingBottom='.35rem'>All</Box>
          ) : item?.projects?.length === 1 ? (
            <Box paddingTop='.35rem' paddingBottom='.35rem'>{item?.projects[0]?.name}</Box>
          ) : (
            <Tooltip arrow title={item?.projects?.map(project => { return <p>{project.name}</p> })}>
              <Box paddingTop='.35rem' paddingBottom='.35rem'>Multiple</Box>
            </Tooltip>
          )}
        </TableCell>
          <TableCell classes={{ root: classes.tableCellRoot }} align='right' onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}>
            <Box
              aria-controls={`simple-menu-${index}`}
              aria-haspopup="true"
              onClick={handleClick}
              size="small"
              component={Button}
              width="2rem!important"
              height="2rem!important"
              minWidth="2rem!important"
              minHeight="2rem!important"
            >
              <Box
                component={MoreVert}
                width="1.25rem!important"
                height="1.25rem!important"
                position="relative"
                top="2px"
                color={theme.palette.gray[500]}
              />
            </Box>
            {
              userRole === 'READ_ONLY' ? (
                null
              ) : (
                <Menu
                  id={`simple-menu-${index}`}
                  keepMounted
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {getActions()}
                </Menu>
              )
            }
            </TableCell>
      </TableRow>
      <EditPeopleModal
        user={item}
        organizationProjects={organizationProjects}
        open={showEditPeopleModal}
        onClose={() => setShowEditPeopleModal(false)}
        userRole={userRole}
      />
    </>
  )
}

const PeopleTable = () => {
  const classes = useStyles()

  const organizationId = useSelector(state => state.auth?.user?.organizations[0]?.id)
  const { data, loading, refetch } = useQuery(PEOPLE, {
    pollInterval: 5000,
    //Todo: figure out why we have to use network-only in order to prevent toggling billing statuses on a notion member from causing this data to become blank
    fetchPolicy: 'network-only',
    variables: {
      organizationId,
    },
    onCompleted: data => {
      console.log(data)
    }
  })

  useEffect(() => {
    refetch()
  }, [data])

  const organizationRoles = []

  data?.organization?.users.map(user => user?.roles.map(role => organizationRoles.push(role.name)))

  const getItems = organization => {
    return organization?.users.map(user => <PeopleItem item={user} organizationId={organizationId} organizationProjects={organization?.projects} organizationRoles={organizationRoles}/>)
  }

  return (
    <>
      <TableContainer>
        <Box component={Table} alignItems='center' marginBottom='0!important'>
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}>
                Name
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}>
                Role
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}>
                Projects
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot + ' ' + classes.tableCellRootHead }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{position: 'relative', height: loading || !data?.organization ? 200 : 'auto'}}>
            {data?.organization ? getItems(data?.organization)  : loading ? (
              <Box style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}>
                <CircularProgress />
              </Box>
            ) : null}
          </TableBody>
        </Box>
      </TableContainer>
    </>
  )
}

export default PeopleTable
