import React, {useEffect} from "react";
import {Link, useHistory} from 'react-router-dom'
import config from '../../config'
import { logout } from '../../views/auth/actions'
import { useApolloClient } from '@apollo/client'
import { useDispatch } from 'react-redux'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
// @material-ui/icons components
import AccountCircle from "@material-ui/icons/AccountCircle";
import Clear from "@material-ui/icons/Clear";
import Dashboard from "@material-ui/icons/Dashboard";
import MenuIcon from "@material-ui/icons/Menu";
import Person from "@material-ui/icons/Person";
import VpnKey from "@material-ui/icons/VpnKey";

// core components
import componentStyles from "assets/theme/components/auth-navbar.js";
import Button from '@material-ui/core/Button'
import {useSelector} from "react-redux";

const useStyles = makeStyles(componentStyles);

export default function SimpleNavbar({ routes, hideLoginButtons: pHideLoginButtons = false }) {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const client = useApolloClient()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [hideLoginButtons, setHideLoginButtons] = React.useState(pHideLoginButtons)
  const isMenuOpen = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(routes) {
      let pathname = history.location.pathname
      const currentRoute = routes.find((route) => {
        let routePath = route.layout + route.path
        if(routePath[routePath.length - 1] === '/') {
          routePath = routePath.slice(0, -1)
        }
        if(pathname[pathname.length - 1] === '/') {
          pathname = pathname.slice(0, -1)
        }
        return pathname.includes(routePath)
      })
      if(currentRoute && currentRoute.hideLoginButtons) {
        setHideLoginButtons(true)
      }
    }
  }, [history?.location?.pathname])

  const menuId = "responsive-menu-id";
  const ListObject = !hideLoginButtons ? (
    <Box
      display="flex"
      alignItems="center"
      width="auto"
      component={List}
      className={classes.flexDirectionColumn}
    >
      <ListItem
        component={Link}
        onClick={() => {
          window.open(config.affiliateLink, '_blank')
        }}
        width="auto"
        classes={{
          root: classes.listItemRoot,
        }}
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        What's Notion?
      </ListItem>
      <ListItem
        component={Link}
        to="/auth/login"
        onClick={handleMenuClose}
        classes={{
          root: classes.listItemRoot,
        }}
      >
        <Box
          component={VpnKey}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight=".5rem!important"
        />
        Login
      </ListItem>
      <ListItem
        style={{
          paddingTop: '1rem',
          paddingBottom: '1rem',
          whiteSpace: 'nowrap'
        }}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            history.push('/auth/register')
            handleMenuClose()
          }}
          style={{
            whitespace: 'no-wrap'
          }}
        >
          Get started for free
        </Button>
      </ListItem>
    </Box>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      width="auto"
      component={List}
      className={classes.flexDirectionColumn}
    >
      <ListItem
        component={Link}
        to="/auth/login"
        onClick={() => dispatch(logout(history, client))}
        classes={{
          root: classes.listItemRoot,
        }}
      >
        Logout
      </ListItem>
    </Box>
  );
  return (
    <>
      <AppBar position="absolute" color="transparent" elevation={0}>
        <Toolbar>
          <Container
            display="flex!important"
            justifyContent="space-between"
            alignItems="center"
            marginTop=".75rem"
            component={Box}
            maxWidth="xl"
          >
            <ListItem
              component={Link}
              to="/product"
              classes={{
                root: classes.brandItem,
              }}
            >
              <Box
                alt="..."
                height="30px"
                component="img"
                className={classes.headerImg}
                src={require("assets/img/brand/notionops-white.png").default}
              />
            </ListItem>
            <Hidden mdUp implementation="css">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleMenuOpen}
                aria-controls={menuId}
                aria-haspopup="true"
              >
                <Box
                  component={MenuIcon}
                  color={theme.palette.white.main}
                  width="2rem!important"
                  height="2rem!important"
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={handleMenuClose}
                classes={{ paper: classes.menuPaper }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingLeft="1.25rem"
                  paddingRight="1.25rem"
                  paddingBottom="1rem"
                  className={classes.outlineNone}
                >
                  <Box
                    alt="..."
                    height="36px"
                    component="img"
                    className={classes.headerImg}
                    src={require("assets/img/brand/notionops.png").default}
                  />
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleMenuClose}
                    aria-controls={menuId}
                    aria-haspopup="true"
                  >
                    <Box
                      component={Clear}
                      width="2rem!important"
                      height="2rem!important"
                    />
                  </IconButton>
                </Box>
                <Box
                  component={Divider}
                  marginBottom="1rem!important"
                  marginLeft="1.25rem!important"
                  marginRight="1.25rem!important"
                />
                {ListObject}
              </Menu>
            </Hidden>
            <Hidden smDown implementation="css">
              {ListObject}
            </Hidden>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
