export const getAvatarUrl = (name) => {
  const nameArray = name.split(' ')
  let firstName = ''
  let lastName = ''
  if(nameArray?.length > 0) {
   firstName = nameArray[0]
   if(nameArray?.length > 1) {
     lastName = nameArray[nameArray.length - 1]
   }
  }

  let url = 'https://ui-avatars.com/api/?name='
  if(firstName.length > 0) {
    url += firstName
  }
  if(lastName.length > 0) {
    url += `+${lastName}`
  }

  return url
}
