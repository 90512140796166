import {ThemePreferenceProvider} from '../../components'
import { embed } from '../../assets/theme'

export * from './charts'
export * from './stats'

const Embed = ({component, render, theme = embed, ...rest}) => {
  const Component = component
  return <ThemePreferenceProvider theme={theme}>
    {render ? render(rest) : <Component {...rest}/>}
  </ThemePreferenceProvider>
}

export default Embed
