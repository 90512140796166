import { SET_EDIT_PROFILE_MODAL_VISIBLE } from './actions'

const initState = {
    isEditModalVisible: false,
}

const profile = (state = initState, action) => {
    switch(action.type) {
        case SET_EDIT_PROFILE_MODAL_VISIBLE:
            return {
                ...state,
                isEditModalVisible: action.isVisible,
            }
        default:
            return state
    }
}

export default profile