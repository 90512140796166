import React from 'react'
import {makeStyles} from '@material-ui/core'
import CopyIcon from '../../assets/img/icons/common/Copy_Button.svg'
import config from '../../config'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'inline',
    marginLeft: 10,
  },
  copyIcon: {
    width: 14,
    height: 14,
    cursor: 'pointer',
  },
  noticeHide: {
    fontSize: 10,
    position: 'absolute',
    color: '#727d83',
    top: -18,
    left: -13,
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: 2,
    opacity: 0,
    transition: 'opacity 2s'
  },
  noticeShow: {
    opacity: 1,
    fontSize: 10,
    position: 'absolute',
    color: '#727d83',
    top: -18,
    left: -13,
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: 2,
  },
})

const CopyEmbedUrlButton = ({type, projectNumber, className}) => {
  const classes = useStyles()
  const [showNotice, setShowNotice] = React.useState(false)


  React.useEffect(() => {
    if(showNotice) {
      setTimeout(() => {
        setShowNotice(false)
      }, 500)
    }
  }, [showNotice])

  const copyEmbedUrlToClipboard = () => {
    navigator.clipboard.writeText(`${config.siteBaseUrl}/${type}/${projectNumber}`).then(() => {
      setShowNotice(true)
    })
  }

  return (
    <div className={classes.container + ' ' + className}>
      <img onClick={copyEmbedUrlToClipboard} className={classes.copyIcon} src={CopyIcon} />
      <div className={showNotice ? classes.noticeShow : classes.noticeHide}>Copied</div>
    </div>
  )
}

export default CopyEmbedUrlButton
