import React from 'react'
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NotionMembersTable from "../Tables/NotionMembersTable";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import componentStyles from "../../assets/theme/components/card";

const useStyles = makeStyles(componentStyles)


const NotionMembers = () => {
  const classes = useStyles()

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        className={classes.cardHeader}
        titleTypographyProps={{
          component: Box,
          marginBottom: '0!important',
          variant: 'h3',
        }}
        subheader={
          <Grid
            container
            component={Box}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs='auto'>
              <Box
                component={Typography}
                variant='h3'
                marginBottom='0!important'
              >
                Notion Members
              </Box>
            </Grid>
          </Grid>
        }
      />
      <NotionMembersTable />
    </Card>
  )
}

export default NotionMembers