import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import {FormControl} from "@material-ui/core";
import {Select} from "../../../../components";
import moment from "moment-timezone";
import FilledInput from "@material-ui/core/FilledInput";
import {useDispatch, useSelector} from "react-redux";
import {setProject} from "../actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountTree from "@material-ui/icons/AccountTree";
import {useTheme} from "@material-ui/core/styles";
import {selectNewProject, selectNewProjectOrganization} from "../selectors";
import {useMutation} from "@apollo/client";
import { CREATE_PROJECT } from "../../../../mutations";

const EnterProjectDetailsStep = ({ onNextClick }) => {
  const theme = useTheme()

  const dispatch = useDispatch()
  const project = useSelector(selectNewProject)

  const [localTimezoneState, setLocalTimezoneState] = useState(project?.timezone || null)
  const [duplicateName, setDuplicateName] = useState(false)

  const organization = useSelector(selectNewProjectOrganization)
  const user = useSelector(state => state.auth?.user)

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': 'New Project Step'
    })
  },[])

  const [createProject, { loading: createProjectLoading }] = useMutation(CREATE_PROJECT, {
    fetchPolicy: 'no-cache',
    variables: {
      orgId: organization?.id,
      userId: user?.id,
      name: project?.name,
      timezone: project?.timezone
    },
    onError: err => {
      console.log(err)
    },
    onCompleted: async (data) => {
      console.log(data)
      dispatch(setProject(data?.createOneProject))
    }
  })

  useEffect(() => {
    setLocalTimezoneState(project?.timezone)
  }, [project?.timezone])

  const onNext = async () => {
    const existingName = organization?.projects?.findIndex(p => p.name.trim() === project?.name.trim() && p.id !== project?.id)
    if(existingName <= -1) {
      if(!project?.id) {
        await createProject()
        if(onNextClick) {
          onNextClick()
        }
      } else {
        if(onNextClick) {
          onNextClick()
        }
      }
    } else {
      setDuplicateName(true)
    }
  }

  useEffect(() => {
    if (project?.name && project?.name !== '') {
      const debounce = setTimeout(() => {
        const found = organization?.projects?.findIndex(p => p.name.trim() === project?.name.trim() && p.id !== project?.id)
        if (found !== -1) {
          setDuplicateName(true)
        } else {
          setDuplicateName(false)
        }
      }, 500)
      return () => clearTimeout(debounce)
    }
  }, [project?.name, organization?.projects])

  return (
    <>
      <FormControl
        variant="filled"
        component={Box}
        width="100%"
        marginBottom="1.5rem!important"
      >
        <FilledInput
          value={project?.name || ''}
          onChange={(e) => dispatch(setProject({
            ...project,
            name: e.target.value,
          }))}
          autoComplete="off"
          type="text"
          placeholder="Project Name*"
          startAdornment={
            <InputAdornment position="start">
              <AccountTree />
            </InputAdornment>
          }
        />
        { duplicateName ?
          <Box
            marginTop={'0.25rem'}
            component="span"
            fontWeight="700"
            color={theme.palette.error.main}
          >
            This name is already taken by another project in your organization.
          </Box>
          : null
        }
        <Box marginTop={'0.5rem'} color={theme.palette.gray[600]} fontSize="80%" fontWeight="400" component="small">
          We recommend giving your project the same name you give your template page you duplicated.
        </Box>
      </FormControl>
      <FormControl
        variant="filled"
        component={Box}
        width="100%"
        marginBottom="1rem!important"
      >
        <Select
          options={moment.tz.names()}
          getOptionLabel={option => option}
          renderOption={option => option}
          disableClearable={true}
          label={'Timezone'}
          value={localTimezoneState}
          onChange={(e, v) => {
            dispatch(setProject({
              ...project,
              timezone: v,
            }))
          }}
        />
        <Box marginTop={'0.5rem'} color={theme.palette.gray[600]} fontSize="80%" fontWeight="400" component="small">
          Your project's timezone will be used to determine when it is a new day within your analytics, such as the next day of your burndown.
        </Box>
        <Box marginTop={'0.5rem'} color={theme.palette.gray[600]} fontSize="80%" fontWeight="400" component="small">
          If your team is in multiple timezones, we recommend selecting one closest to most of your project managers.
        </Box>
      </FormControl>
      <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
        <Button disabled={duplicateName || !project?.name || !project?.timezone || createProjectLoading } variant="contained" color="primary" onClick={onNext}>
          Next
        </Button>
      </Box>
    </>
  )
}

export default EnterProjectDetailsStep
