import React, { useState, useEffect } from 'react'
import {CircularProgress, makeStyles, TextField, Box, useTheme} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import { useLazyQuery } from '@apollo/client'
import componentStyles from '../../assets/theme/views/admin/profile'

const useStyles = makeStyles(componentStyles)

export const Select = ({
  query,
  key,
  variables,
  onOpen = () => true,
  onClose = () => true,
  open,
  options,
  getOptionLabel,
  loading,
  label,
  showId,
  renderOption,
  renderInput,
  mapOptions,
  selectResponse = data => data,
  selectResults = response => response,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const [_open, setOpen] = useState(false)

  const [fetch, { loading: _loading, data, fetchMore }] = useLazyQuery(query, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.log(error)
    }
  })

  const res = data ? selectResponse(data) : null
  const results = res ? selectResults(res) : []
  const cursor = res ? res.next_cursor : null
  const hasMore = res ? res.has_more : false

  useEffect(() => {
    if (hasMore) {
      fetchMore({
        variables: {
          ...variables,
          cursor
        }
      })
    }
  }, [results, hasMore])

  const actuallyLoading = loading !== undefined ? loading : _loading

  const getOptions = () => {
    if (options) {
      return options
    }
    if (mapOptions) {
      return mapOptions(results)
    }
    return results
  }

  return (
    <Autocomplete
      open={open !== undefined ? open : _open}
      onOpen={e => {
        const res = onOpen(e)
        if (res !== false) {
          setOpen(true)
          if (query) {
            fetch({
              variables
            })
          }
        }
      }}
      onClose={e => {
        const res = onClose(e)
        if (res !== false) {
          setOpen(false)
        }
      }}
      options={getOptions()}
      getOptionLabel={getOptionLabel}
      loading={actuallyLoading}
      renderOption={(option, state) => {
        if (renderOption) {
          return renderOption(option, state)
        }
        return (
          <li>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              {option.title}
              { showId ?
                <>
                <br/>
                <span style={{ color: theme.palette.gray[600], fontSize: 14 }}>{option.id}</span>
                </>
              : null }
            </Box>
          </li>
        )
      }}
      renderInput={(props) => {
        if (renderInput) {
          return renderInput(props)
        }
        return (
          <TextField
            {...props}
            label={label}
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <>
                  {actuallyLoading ? <CircularProgress color="inherit" size={20}/> : null}
                  {props.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
      {...rest}
    />
  )
}
