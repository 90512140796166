import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import componentStyles from "assets/theme/components/auth-footer.js";

const useStyles = makeStyles(componentStyles);

const Footer = () => {
  const classes = useStyles();
  return (
    <Box component="footer" width="100%" paddingTop="1rem">
      <Container
        component={Box}
        maxWidth="xl"
        display="flex!important"
        alignItems="center"
        classes={{
          root:
            classes.justifyContentCenter + " " + classes.flexDirectionColumn,
        }}
      >
        <Grid item xs={12} xl={6}>
          <div className={classes.copyrightWrapper}>
            © {new Date().getFullYear()}{" "}
            <span
              href="https://www.creative-tim.com?ref=adr-admin-footer"
              rel="noopener noreferrer"
            >
              NotionOps
            </span>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          xl={6}
          component={Box}
          display="flex"
          justifyContent="flex-end"
          classes={{
            root:
              classes.justifyContentCenter + " " + classes.flexDirectionColumn,
          }}
        >
          <Box
            component={List}
            display="flex"
            justifyContent="center"
            alignItems="center"
            classes={{
              root:
                classes.justifyContentCenter +
                " " +
                classes.flexDirectionColumn,
            }}
          >
            <ListItem
              component="a"
              href="https://www.linkedin.com/showcase/notionops/"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              <Box className={'fab fa-linkedin'}/>
            </ListItem>

            <ListItem
              component="a"
              href="https://facebook.com/NotionOps"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              <Box className={'fab fa-facebook'}/>
            </ListItem>

            <ListItem
              component="a"
              href="https://twitter.com/NotionOps"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              <Box className={'fab fa-twitter'}/>
            </ListItem>

            <ListItem
              component="a"
              href="https://oosstudio.notion.site/NotionOps-43d1e42fd9f84527a2a4b26b7899e09a"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              Documentation
            </ListItem>

            <ListItem
              component="a"
              href="https://oosstudio.notion.site/How-will-pricing-work-3a17f3e39962485b922a6ba0a6faa3b5"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              Pricing
            </ListItem>

            <ListItem
              component="a"
              href="https://oosstudio.notion.site/Terms-and-Privacy-b497fbb6a418424aa5e39e60070b3fcd"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              Terms and Privacy
            </ListItem>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
