export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'
export const SET_PROJECT = 'SET_PROJECT'
export const SET_AUTH = 'SET_AUTH'
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const SET_SPRINT_DATABASE = 'SET_SPRINT_DATABASE'
export const SET_SPRINT_PROPERTIES = 'SET_SPRINT_PROPERTIES'
export const SET_REQUIREMENT_DATABASE = 'SET_REQUIREMENT_DATABASE'
export const SET_REQUIREMENT_PROPERTIES = 'SET_REQUIREMENT_PROPERTIES'
export const SET_REQUIREMENT_STATUSES = 'SET_REQUIREMENT_STATUSES'
export const SET_REQUIREMENT_TYPES = 'SET_REQUIREMENT_TYPES'
export const RESET_STATE = 'RESET_STATE'

export const setStep = (activeStep) => {
  return {
    type: SET_ACTIVE_STEP,
    activeStep,
  }
}

export const setProject = (project) => {
  return {
    type: SET_PROJECT,
    project,
  }
}

export const setAuth = (auth) => {
  return {
    type: SET_AUTH,
    auth,
  }
}

export const setOrganization = (organization) => {
  return {
    type: SET_ORGANIZATION,
    organization,
  }
}

export const setSprintDatabase = (sprintDatabase) => {
  return {
    type: SET_SPRINT_DATABASE,
    sprintDatabase,
  }
}

export const setSprintProperties = (sprintProperties) => {
  return {
    type: SET_SPRINT_PROPERTIES,
    sprintProperties
  }
}

export const setRequirementDatabase = reqDatabase => {
  return {
    type: SET_REQUIREMENT_DATABASE,
    reqDatabase
  }
}

export const setRequirementProperties = reqProperties => {
  return {
    type: SET_REQUIREMENT_PROPERTIES,
    reqProperties
  }
}

export const setRequirementStatuses = reqStatuses => {
  return {
    type: SET_REQUIREMENT_STATUSES,
    reqStatuses
  }
}

export const setRequirementTypes = reqTypes => {
  return {
    type: SET_REQUIREMENT_TYPES,
    reqTypes
  }
}

export const resetState = () => {
  return {
    type: RESET_STATE,
  }
}
