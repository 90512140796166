import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Dialog from "@material-ui/core/Dialog"
import componentStyles from "../../assets/theme/components/notifications"
import Typography from "@material-ui/core/Typography"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import DialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import Box from "@material-ui/core/Box"
import {useDispatch, useSelector} from "react-redux";
import {useMutation} from "@apollo/client";
import {MARK_NOTIFICATION_READ} from "../../mutations";
import {setActiveModal} from './actions'
import NotificationIcon from "../../utils/NotificationIcon";
import {setUserData} from "../../views/auth/actions";

const useStyles = makeStyles(componentStyles)

const DefaultNotification = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const activeModal = useSelector(state => state.modals?.activeModal)
  const user = useSelector(state => state.auth?.user)

  const [markNotificationRead] = useMutation(MARK_NOTIFICATION_READ, {
    onError: err => {
      console.log(err)
    },
  })

  const open = activeModal?.data ? !['first_project_setup'].includes(activeModal?.code) : false
  const notification = activeModal?.data

  const handleClose = () => {
    dispatch(setActiveModal(null))
    if (user) {
      dispatch(setUserData({
        ...user,
        readNotifications: [
          ...user.readNotifications,
          notification
        ]
      }))
      markNotificationRead({
        variables: {
          id: notification?.id
        }
      })
    }
  }

  return (
    <Container>
      <Dialog open={open} onClose={handleClose} className={classes.container}>
        <DialogContent>
          <IconButton onClick={handleClose} className={classes.exitIcon}>
            <CloseRoundedIcon />
          </IconButton>
          <Box className={classes.box}>
            <Box>
              {open && <NotificationIcon notification={notification} />}
            </Box>
            {
              notification?.type === 'ERROR' && notification?.errorCode && (
                <Typography className={classes.errorCode}>
                  {notification.errorCode}
                </Typography>
              )
            }
            <Box>
              <Typography className={classes.notificationTitle}>
                {notification?.title}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.notificationContent}>
                {notification?.message}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  )
}

export default DefaultNotification
