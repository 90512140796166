import React, {useEffect, useRef, useState} from 'react'
import {useQuery} from '@apollo/client'
import { VELOCITY } from '../../../queries'
import { ResponsiveBar } from '@nivo/bar'
import { StatusColorsLight as ColorsLight, StatusColorsDark as ColorsDark } from '../../styles'
import {
  Typography,
  Card,
  CardContent,
  Box,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  Grid, CircularProgress
} from '@material-ui/core'
import { isDarkMode } from '../../styles'
import CopyEmbedUrlButton from '../../../components/Buttons/CopyEmbedUrlButton'
import RefreshButton from "../../../components/Buttons/RefreshButton";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { setActiveModal } from "../../../components/Modals/actions";
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(({ renderOnSite }) => ({
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: isDarkMode() && !renderOnSite ? 'rgb(25, 25, 25)' : 'white',
    padding: renderOnSite ? 7 : 0,
    borderRadius: renderOnSite ? '0.375rem' : 0,
  },
  card: {
    margin: 1,
    marginBottom: 5,
    height: 'calc(100% - 5px)',
    padding: 7,
    backgroundColor: isDarkMode() && !renderOnSite ? 'rgba(255, 255, 255, 0.03)' : 'white'

  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'block',
  },
  formControl: {
    marginLeft: 5,
    zIndex: 5,
  },
  select: {
    '& .MuiSelect-root': {
      borderStyle: 'none'
    }
  },
  textUppercase: {
    padding: 5,
    textTransform: "uppercase",
  },
  textUppercaseRight: {
    padding: 5,
    paddingBottom: 0,
    width: '100%',
    textAlign: 'right',
    textTransform: "uppercase",
  },
  refetchProgressOverlay: {
    zIndex: 1000,
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
  copyButton: {
    zIndex: 1000,
    position: 'absolute',
    bottom: 10,
    right: 14,
  },
  error: {
    color: '#727d83',
    fontSize: 12,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    flex: 'auto',
    flexDirection: 'column',
  },
  iconWrapper: {
    padding: 5,
    zIndex: 1000,
  },
  upgradePlanContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  viewBtn: {
    margin: '0 auto',
    marginTop: '2em'
  }
}))

function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export const VelocityChart = ({ location, projectNumber: _projectNumber }) => {
  const projectNumber = useRef()
  let pathname
  if (location) pathname = location.pathname
  const renderOnSite = !pathname

  const history = useHistory()

  const [showCopyButton, setShowCopyButton] = useState(true)
  const [refetching, setRefetching] = useState(false)
  const [slice, setSlice] = useState(0)

  const dispatch = useDispatch()

  const updateSlice = (width) => {
    if (width < 430) {
      setSlice(4)
    } else if (width < 520) {
      setSlice(2)
    } else if (width >= 520) {
      setSlice(0)
    }
  }

  useEffect(() => {
    if (!renderOnSite) {
      gtag('event', 'page_view', {
        'page_location': window.location.href,
        'page_title': 'Velocity Chart Embed'
      })
    }
  },[])

  useEffect(() => {
    if(_projectNumber?.length > 0) {
      projectNumber.current = _projectNumber
      refetch({
        projectNumber: projectNumber.current
      })
    }
    else if(pathname && pathname.length > 0) {
      setShowCopyButton(false)
      const parts = pathname.split('/')
      projectNumber.current = parts[parts.length-1]
      refetch({
        projectNumber: projectNumber.current
      })
    }
    window.addEventListener('load', () => {
      const container = document.getElementById('velocity-chart')
      if (container) {
        updateSlice(container.offsetWidth)
        window.addEventListener('resize', () => {
          debounce(() => {
            updateSlice(container.offsetWidth)
          }, 1000)()
        })
      }
    })
  },[setSlice])

  const { loading, error, data, refetch } = useQuery(VELOCITY, {
    variables: {
      projectNumber: _projectNumber || projectNumber.current,
      isEmbed: pathname && pathname.length > 0
    },
    skip: !_projectNumber && !projectNumber.current,
  })

  const onRefresh = async () => {
    setRefetching(true)
    try {
      await refetch()
      setRefetching(false)
    } catch(e) {
      setRefetching(false)
    }
  }

  const theme = useTheme()
  theme.renderOnSite = renderOnSite
  const classes = useStyles(theme)

  const [byPoints, setByPoints] = useState(true)

  const chartData = () => {
    const { velocity: { data: chartData } } = data
    const rv = chartData.map(d => ({
      Sprint: d.sprintName,
      Committed: byPoints ? d.committedPoints : d.committedCount,
      Completed: byPoints ? d.completedPoints : d.completedCount,
    }))
    return rv.length <= slice ? rv : rv.slice(slice, 7)
  }

  const WrapperComponent = renderOnSite ? React.Fragment : ({children}) => <Card className={classes.card}>{children}</Card>

  let showViewErrorBtn = false
  let originCode, originMessage, title
  let notice = false

  if (error) {
    const gqlError = error.graphQLErrors[0]
    originCode = gqlError?.extensions?.code

    showViewErrorBtn = originCode !== 'UPGRADE_PLAN'

    try {
      const errorNotification = JSON.parse(gqlError?.message)

      notice = errorNotification?.error
      originMessage = errorNotification?.message
      title = errorNotification?.title
    } catch (e) {
      originMessage = gqlError?.message

      if (!originCode) {
        originCode = gqlError?.name ? gqlError?.name : gqlError?.prototype?.name
      }

      if (originCode === 'BAD_USER_INPUT') {
        notice = gqlError.message
      } else if (originCode === 'UPGRADE_PLAN') {
        const path = gqlError?.extensions?.exception?.upgradePlanUrl
        notice = (
          <Box className={classes.upgradePlanContainer}>
            {gqlError.message}
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.viewBtn}
              onClick={() => {
                if (renderOnSite) {
                  history.push(path)
                } else {
                  window.location.replace(path)
                }
              }}
            >
              Upgrade Plan
            </Button>
          </Box>
        )
      } else {
        notice = 'An error occurred while loading the velocity data for this sprint.'
      }
    }
  } else if (data?.velocity?.data?.length <= 0) {
    notice = 'No velocity data exists yet.'
  }

  const InProgressColor = isDarkMode() && !renderOnSite ? ColorsDark.InProgress : ColorsLight.InProgress
  const CompletedColor = isDarkMode() && !renderOnSite ? ColorsDark.Completed : ColorsLight.Completed

  const showOverlay = loading || (!data && !notice)
  const showTransparentOverlay = refetching

  return (
      <div id={'velocity-chart'} className={classes.container}>
        { showOverlay ? <CircularProgress className={classes.refetchProgressOverlay} /> : (
          <WrapperComponent>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
              <Grid container>
                <Grid item xs={6}>
                  <div className={classes.header}>
                    <Box
                      component={Typography}
                      variant="h5"
                      marginBottom="0!important"
                      marginTop="0!important"
                      className={classes.textUppercase}
                    >
                      Velocity
                    </Box>
                    <Box className={classes.iconWrapper}>
                      <RefreshButton onClick={onRefresh} />
                      {showCopyButton && <CopyEmbedUrlButton className={classes.copyButton} type={'velocity'} projectNumber={_projectNumber} />}
                    </Box>
                  </div>
                  <Box>
                    <FormControl border={0} className={classes.formControl}>
                      <Select
                        border={0}
                        className={classes.select}
                        autoWidth={true}
                        value={byPoints}
                        onChange={event => setByPoints(event.target.value)}
                      >
                        <MenuItem value={true}>Story points</MenuItem>
                        <MenuItem value={false}>Item count</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    component={Typography}
                    variant="h5"
                    marginBottom="0!important"
                    marginTop="0!important"
                    className={classes.textUppercaseRight}
                  >
                    Average
                  </Box>
                  <Box
                    component={Typography}
                    variant="h2"
                    fontWeight="600!important"
                    marginBottom="0!important"
                    marginTop="0!important"
                    className={classes.textUppercaseRight}
                  >
                    {byPoints ? data?.velocity?.completedPointsAverage : data.velocity.completedCountAverage}
                  </Box>
                </Grid>
              </Grid>
              {notice ? (
                <div className={classes.error}>
                  {notice}
                  {showViewErrorBtn ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.viewBtn}
                      onClick={() => dispatch(setActiveModal({
                        code: originCode,
                        data: {
                          errorCode: originCode,
                          title: title ? title : 'Error Calculating Velocity',
                          message: originMessage,
                          type: 'ERROR',
                        },
                      }))}
                    >
                      View
                    </Button>
                  ) : null}
                </div>
              ) : <CardContent>
                <div style={{position: 'absolute', overflow: 'hidden', left: 0, right: 0, top: 0, bottom: 0}}>
                  {showTransparentOverlay && <CircularProgress className={classes.refetchProgressOverlay}/>}
                  <ResponsiveBar
                    theme={{
                      textColor: theme.palette.text.secondary,
                      fontSize: theme.typography.fontSize,
                      axis: {
                        ticks: {
                          line: {
                            stroke: theme.palette.divider,
                            strokeWidth: 1
                          }
                        },
                        domain: {
                          line: {
                            stroke: theme.palette.divider,
                            strokeWidth: 0,
                          }
                        },
                        text: {
                          fontSize: 50,
                        }
                      },
                      grid: {
                        line: {
                          stroke: theme.palette.divider
                        }
                      },
                      labels: {
                        text: {
                          fill: theme.palette.divider,
                          fontSize: 50,
                        }
                      },
                      legends: {
                        text: {
                          fontSize: theme.typography.h6.fontSize,
                        }
                      },
                      tooltip: {
                        container: {
                          background: theme.palette.background.default
                        },
                      }
                    }}
                    data={chartData()}
                    keys={['Committed', 'Completed']}
                    indexBy={'Sprint'}
                    margin={{top: 145, right: 15, bottom: 105, left: 40}}
                    padding={0.3}
                    groupMode={'grouped'}
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    valueFormat={{format: '', enabled: false}}
                    enableLabel={false}
                    colors={[InProgressColor, CompletedColor]}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: 'middle',
                      legendOffset: -32
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: 'middle',
                      legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateY: 70,
                        itemsSpacing: 25,
                        itemWidth: 150,
                        itemHeight: 20,
                        symbolSize: 20,
                        itemTextColor: theme.palette.text.primary,
                        symbolShape: 'circle',
                      }
                    ]}
                  />
                </div>
              </CardContent>}
            </div>
          </WrapperComponent>
        )}
      </div>
  )
}
