import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/client'
import {RESET_USER_PASSWORD, SIGN_IN} from '../../mutations'
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from '@material-ui/lab/Alert'
import Lock from "@material-ui/icons/Lock";
import componentStyles from "assets/theme/views/auth/login.js";
import {REGISTRATION_TOKEN} from "../../queries";
import {useTheme} from "@material-ui/core";
import {setUserData} from "./actions";
import {useDispatch} from "react-redux";


const useStyles = makeStyles(componentStyles);

function ResetPassword() {
  const history = useHistory()

  const dispatch = useDispatch()

  const classes = useStyles()
  const theme = useTheme()

  const token = new URLSearchParams(location.search).get('token')

  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': 'Reset Password'
    })
  }, [])

  const { data, loading: loadingToken } = useQuery(REGISTRATION_TOKEN, {
    variables: {
      token
    },
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (err) => {
      console.log(err)
    }
  })

  const user = data?.registrationToken ? data.registrationToken.user : null
  const invalidToken = token && !data?.registrationToken && !loadingToken

  const [signIn, { loading: signInLoading }] = useMutation(SIGN_IN, {
    variables: {
      email: user?.email,
      password,
    },
    onCompleted: async ({ login }) => {
      await localStorage.setItem('loginToken', login.token)
      history.push('/index')
      if (login?.user) {
        dispatch(setUserData(login.user))
        if (!(!!login.user.projects.length)) {
          history.push('/setup/1')
        }
      }
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const [resetUserPassword, { loading: resetLoading, error }] = useMutation(RESET_USER_PASSWORD, {
    variables: {
      id: user?.id,
      newPassword: password,
    },
    onCompleted: (data) => {
      setMessage({type: 'success', message: 'Password successfully reset!'})
      signIn()
    },
    onError: (err) => {
      setMessage({type: 'error', message: err?.message})
    },
  })

  const submitResetPassword = async (e) => {
    if (e.keyCode === 13) await resetUserPassword()
  }

  useEffect(() => {
    if(password && confirmPassword) {
      if(password !== confirmPassword) {
        setMessage({type: 'error', message: 'Passwords do not match.'})
      } else {
        setMessage(null)
      }
    }
  }, [password, confirmPassword])

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          {invalidToken ? <CardContent classes={{root: classes.cardContent}}>
              <Box
                color={theme.palette.gray[600]}
                textAlign="center"
                marginBottom="1.5rem"
                marginTop=".5rem"
                fontSize="1rem"
              >
                <Box fontSize="80%" fontWeight="400" component="small">
                  We're sorry, this link is no longer valid.
                </Box>
              </Box>
            </CardContent> :
            <CardContent classes={{root: classes.cardContent}}>
              <FormControl
                variant="filled"
                component={Box}
                width="100%"
                marginBottom="1rem!important"
              >
                <FilledInput
                  autoComplete="off"
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={submitResetPassword}
                  startAdornment={
                    <InputAdornment position="start">
                      <Lock/>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl
                variant="filled"
                component={Box}
                width="100%"
                marginBottom="1rem!important"
              >
                <FilledInput
                  autoComplete="off"
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onKeyDown={submitResetPassword}
                  startAdornment={
                    <InputAdornment position="start">
                      <Lock/>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {message && <Alert severity={message.type} style={{alignItems: 'center'}}>
                {message.message}
              </Alert>}
              <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                <Button color="primary" variant="contained" disabled={!user || !password || !confirmPassword || resetLoading || signInLoading || (confirmPassword !== password)}
                        onClick={resetUserPassword}>
                  Reset Password
                </Button>
              </Box>
            </CardContent>
          }
        </Card>
        <Grid container component={Box} marginTop="1rem">
          <Grid item xs={6} component={Box} textAlign="left">
            <a
              onClick={() => history.push('/auth/login')}
              className={classes.footerLinks}
            >
              Back to Login
            </a>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ResetPassword;
