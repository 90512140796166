import {
  RESET_STATE,
  SET_ACTIVE_STEP,
  SET_AUTH,
  SET_ORGANIZATION,
  SET_PROJECT,
  SET_REQUIREMENT_DATABASE,
  SET_REQUIREMENT_PROPERTIES,
  SET_REQUIREMENT_STATUSES,
  SET_REQUIREMENT_TYPES,
  SET_SPRINT_DATABASE,
  SET_SPRINT_PROPERTIES
} from "./actions";

const initState = {
  activeStep: 0,
  project: null,
  auth: null,
  organization: null,
  sprintDatabase: null,
  sprintProperties: {
    dateRange: null,
    totalEffort: null,
    backlog: null,
    state: null,
  },
  reqDatabase: null,
  reqProperties: {
    number: null,
    status: null,
    type: null,
    storyPoints: null,
    sprint: null,
    parent: null,
    assignee: null,
  },
  reqStatuses: {
    planned: [],
    inProgress: [],
    complete: [],
    removed: []
  },
  reqTypes: {
    userStory: [],
    defect: [],
    task: [],
    other: []
  }
}

const newProjectSetup = (state=initState, action) => {
  const { activeStep, project, auth, organization, sprintDatabase, sprintProperties, reqDatabase, reqProperties, reqStatuses, reqTypes } = action
  switch(action.type) {
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep,
      }
    case SET_PROJECT:
      return {
        ...state,
        project,
      }
    case SET_AUTH:
      return {
        ...state,
        auth,
      }
    case SET_ORGANIZATION:
      return {
        ...state,
        organization,
      }
    case SET_SPRINT_DATABASE:
      return {
        ...state,
        sprintDatabase,
      }
    case SET_SPRINT_PROPERTIES:
      return {
        ...state,
        sprintProperties,
      }
    case SET_REQUIREMENT_DATABASE:
      return {
        ...state,
        reqDatabase,
      }
    case SET_REQUIREMENT_PROPERTIES:
      return {
        ...state,
        reqProperties,
      }
    case SET_REQUIREMENT_STATUSES:
      return {
        ...state,
        reqStatuses,
      }
    case SET_REQUIREMENT_TYPES:
      return {
        ...state,
        reqTypes,
      }
    case RESET_STATE:
      return initState
    default:
      return state
  }
}

export default newProjectSetup
