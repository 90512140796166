import React from 'react'
import { Box, Container, Grid, makeStyles, Typography } from "@material-ui/core"
import usageValidationStyles from "../../../assets/theme/views/product/usage-validation"
import componentStyles from '../../../assets/theme/views/product/home'

const useStyles = makeStyles(componentStyles)
const useUsageValidationStyles = makeStyles(usageValidationStyles)

const UsageValidation = () => {
  const classes = {...useStyles(), ...useUsageValidationStyles()}

  return (
    <Grid container className={classes.container}>
      <Container maxWidth={'xl'} className={classes.contentWrapper}>
        <Grid item xs={12} className={classes.copyWrapper}>
          <Typography className={classes.sectionHeader}>
            Made by an agile team for agile teams.
          </Typography>
          <Typography className={classes.copy}>
            We created NotionOps for our own team. Then we posted about the concept online and discovered that lots of other agile teams would love to use Notion for their scrum processes! So we shared NotionOps with the world, continuously adding new features based on user feedback and interaction. We think this is just the beginning of what NotionOps will be able to do.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.statWrapper}>
            <Grid item xs={12} lg={4} className={classes.stat}>
              <Typography className={classes.statNumber}>100+</Typography>
              <Typography className={classes.statText}>Organizations</Typography>
            </Grid>
            <Box className={classes.divider} />
            <Grid item xs={12} lg={4} className={classes.statMiddle}>
              <Typography className={classes.statNumber}>500+</Typography>
              <Typography className={classes.statText}>Users</Typography>
            </Grid>
            <Box className={classes.divider} />
            <Grid item xs={12} lg={4} className={classes.stat}>
              <Typography className={classes.statNumber}>10k+</Typography>
              <Typography className={classes.statText}>Requirements</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}

export default UsageValidation
