export const Role = {
  Owner: 'OWNER',
  Admin: 'ADMIN',
  ReadOnly: 'READ_ONLY'
}

export const AchievementLevel = {
  Heats: 'HEATS',
  QuarterFinals: 'QUARTER_FINALS',
  SemiFinals: 'SEMI_FINALS',
  Finals: 'FINALS'
}
