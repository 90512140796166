import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// core components
import componentStyles from "assets/theme/components/card-stats.js";
import boxShadows from "assets/theme/box-shadow.js";
import {CircularProgress} from "@material-ui/core";
import {isDarkMode} from "../../views/styles";
import RefreshButton from "../Buttons/RefreshButton";
import CopyEmbedUrlButton from "../Buttons/CopyEmbedUrlButton";

const useStyles = makeStyles(componentStyles);

function CardStats({ subtitle, title, footer, icon, color, cardRoot, refetching = false, loading=false, showIcon=true, type, onWrapChange=null, onRefresh=() => null, projectNumber, showCopyButton=true, showButtons=false}) {
  const classes = useStyles();
  const theme = useTheme();
  const [wrap, setWrap] = useState(false)



  const onResize = () => {
    const titleElement = document.getElementById(`card-stat-title-${type}`)
    const titleWidth = titleElement?.offsetWidth

    if(titleWidth) {
      if (window.innerWidth - titleWidth <= 190) {
        setWrap(true)
        if(onWrapChange) {
          onWrapChange(true)
        }
      } else {
        setWrap(false)
        if(onWrapChange) {
          onWrapChange(false)
        }
      }
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <>
      <Card
        style={loading && !showIcon ?
          { width: 'fit-content', backgroundColor: 'transparent'}
          :
          { width: showIcon ? 'auto' : 'fit-content'}}
        classes={{ root: cardRoot || classes.cardRoot }} elevation={0}>
        <CardContent classes={{ root: classes.cardContentRoot }}>
          { (loading || refetching) && <Box className={classes.loadingOverlay}>
            <CircularProgress/>
          </Box>}
            <Grid style={{opacity: loading ? 0 : 1}} container component={Box}  justifyContent={showIcon ? 'space-between' : 'flex-start' } flexDirection={wrap ? 'column' : 'row'} flexWrap={wrap ? 'none' : 'wrap'}>
                <Grid item sm={6}>
                <div className={classes.header}>
                  <Box
                    component={Typography}
                    variant="h5"
                    color={theme?.palette?.gray && theme.palette.gray[600] + "!important"}
                    marginBottom="0!important"
                    marginTop="0!important"
                    className={classes.textUppercase}
                  >
                    {subtitle}
                  </Box>
                  {showButtons && <Box className={classes.buttons}>
                    <RefreshButton onClick={onRefresh}/>
                    {showCopyButton && <CopyEmbedUrlButton className={classes.copyButton} type={type} projectNumber={projectNumber} />}
                  </Box>}
                </div>
                <Box
                  component={Typography}
                  id={`card-stat-title-${type}`}
                  variant="h2"
                  fontWeight="600!important"
                  marginBottom="0!important"
                  marginTop="0!important"
                  marginRight="10px!important"
                >
                  {loading ? '00.0' : title}
                </Box>
              </Grid>
              {showIcon &&
                <Grid item>
                <Box
                width="3rem"
                height="3rem"
                padding="12px"
                textAlign="center"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                color={theme?.palette?.white?.main && theme.palette.white.main}
                style={{
                  backgroundColor: color,
                }}
                >
              {icon && typeof icon === "object" ? (
                <Box
                component={icon}
                width="1.5rem!important"
                height="1.5rem!important"
                />
                ) : null}
              {icon && typeof icon === "string" ? (
                <Box component="i" fontSize="1.25rem" className={icon}/>
                ) : null}
                </Box>
                </Grid>}
              {footer && !showIcon && (
                <Grid item className={classes.footerWrapper}>
                <Box
                fontSize=".875rem"
                color={theme?.palette?.gray && theme.palette.gray[600]}
                marginTop="1rem"
                marginBottom="0"
                display="flex"
                alignItems={wrap ? 'center' : 'flex-end'}
                flexWrap="wrap"
                flexDirection={wrap ? 'row' : 'column'}
                >
              {footer}
                </Box>
                </Grid>
                )}
            </Grid>
            {footer && showIcon && (
              <Box
                fontSize=".875rem"
                color={theme?.palette?.gray && theme.palette.gray[600]}
                marginTop="1rem"
                marginBottom="0"
                display="flex"
                alignItems="center"
                flexWrap="wrap"
              >
                {footer}
              </Box>
            )}
        </CardContent>
      </Card>
    </>
  );
}

CardStats.defaultProps = {
  color: "bgPrimaryLight",
};

CardStats.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOfType([
    // i.e. an icon name from Nucleo Icons - e.g. ni ni-atom
    // // or an icon name from Font Awesome - e.g. fa fa-heart
    PropTypes.string,
    // i.e. a component from @material-ui/icons
    PropTypes.object,
  ]),
  color: PropTypes.string,
};

export default CardStats;
