import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import { FormControl, CircularProgress, CardContent, CardHeader, makeStyles, useTheme} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import componentStyles from '../../../../assets/theme/views/admin/profile'
import { UPDATE_PROJECT } from '../../../../mutations'
import { NOTION_AUTHORIZATIONS } from '../../../../queries'
import { useSelector } from 'react-redux'
import { Select } from '../../../../components'
import { useQuery, useMutation } from '@apollo/client'
import FormLabel from '@material-ui/core/FormLabel'
import FilledInput from '@material-ui/core/FilledInput'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment-timezone'
import DeleteProjectModal from './DeleteProjectModal'

const useStyles = makeStyles(componentStyles)

const ProjectSetup = () => {
  const classes = useStyles()
  const theme = useTheme()

  const project = useSelector(state => state.auth?.user?.lastOpenedProject)
  const user = useSelector(state => state.auth?.user)
  const userAdminOrOwner = user?.roles?.length ? user?.roles?.find(r => r.name === 'ADMIN' || r.name === 'OWNER') : null

  const [name, setName] = useState(null)
  const [timezone, setTimezone] = useState(null)
  const [authorization, setAuthorization] = useState(null)
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false)

  useEffect(() => {
    setName(defaultName())
    setTimezone(defaultTimezone())
    setAuthorization(defaultAuthorization())
  }, [project?.id])

  const [updateProject, { loading: saving }] = useMutation(UPDATE_PROJECT, {
    variables: {
      id: project?.id,
      name,
      timezone,
      authorizationId: authorization?.id,
    }
  })

  const defaultName = () => project?.name || null

  const defaultTimezone = () => project?.timezone || null

  const defaultAuthorization = () => (project?.notionAuthorization ? {
    title: project.notionAuthorization.workspaceName,
    icon: project.notionAuthorization.workspaceIcon,
    id: project.notionAuthorization.id
  } : null)

  const canSave = () => {
    return (defaultName() !== name
        || defaultTimezone() !== timezone
        || defaultAuthorization()?.id !== authorization?.id
      ) && name
      && timezone
      && authorization?.id
  }

  const onNameChange = ({ target: { value }}) => {
    setName(value)
  }

  const onTimezoneChange = ({ target: { value }}) => {
    setTimezone(value)
  }

  const onAuthorizationChange = (event, value) => {
    setAuthorization(value)
  }

  const onSave = () => {
    updateProject().catch(e => {
      //Todo: display message to use that there was a problem saving
      console.error(e)
      // Set fields (state) back to defaults
      setName(defaultName())
      setTimezone(defaultTimezone())
    }).then(res => {
      console.log(res)
    })
  }

  const timezones = moment.tz.names()

  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot + " " + classes.cardRootSecondary,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  Project Details
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box
                  justifyContent="flex-end"
                  display="flex"
                  flexWrap="wrap"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!canSave() || !userAdminOrOwner}
                    onClick={onSave}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        />

        <CardContent classes={{ root: classes.organizationDetailsCardContent }}>
          { !project?.id ? <Box classes={{ root: classes.centerContent }}><CircularProgress classes={ classes.centerCardContent }/></Box> :
            <>
              <div className={classes.plLg4}>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormLabel>Name</FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          disabled={!userAdminOrOwner}
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          onChange={onNameChange}
                          value={name}
                          autoComplete="off"
                          type="text"
                          placeholder="My Project"
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={!userAdminOrOwner}
                          options={moment.tz.names()}
                          getOptionLabel={option => option}
                          renderOption={option => option}
                          disableClearable={true}
                          label={'Timezone'}
                          value={timezone}
                          onChange={(event, value) => setTimezone(value)}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Select
                          disabled={!userAdminOrOwner}
                          query={NOTION_AUTHORIZATIONS}
                          selectResponse={data => data.notionAuthorizations}
                          variables={{
                            organizationId: project?.organization?.id
                          }}
                          mapOptions={options => options.map(o => ({
                            title: o.workspaceName,
                            icon: o.workspaceIcon,
                            id: o.id,
                          }))}
                          getOptionLabel={o => o.title}
                          getOptionSelected={(o, v) => o.id === v.id}
                          label={'Authorization'}
                          onChange={onAuthorizationChange}
                          value={authorization}
                          disableClearable={true}
                          renderOption={(option, state) => {
                            return (
                              <li>
                                <Box alignItems="center" display="flex">
                                  <Box
                                    component={Avatar}
                                    marginRight="1rem"
                                    alt="..."
                                    src={option.icon}
                                  />
                                  <Box
                                    sx={{
                                      flexGrow: 1,
                                    }}
                                  >
                                    {option.title}
                                  </Box>
                                </Box>
                              </li>
                            )
                          }}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
            </>
          }
        </CardContent>
      </Card>
      {userAdminOrOwner ? (
        <Button
          classes={{ root: classes.buttonError }}
          disabled={false}
          onClick={() => setShowDeleteProjectModal(true)}
          style={{
            marginTop: '1.5em',
          }}
        >
          Delete Project
        </Button>
      ) : null}
      <DeleteProjectModal
        user={user}
        project={project}
        open={showDeleteProjectModal}
        onClose={() => setShowDeleteProjectModal(false)}
      />
    </>
  )
}

export default ProjectSetup
