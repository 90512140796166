import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//@material-ui/icons components
//import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined";


// core components
import CardStats from "components/Cards/CardStats.js";
import componentStyles from "assets/theme/components/header.js";

// stat imports
import VelocityStat from "../../views/embeds/stats/VelocityStat";
import NewItemsStat from "../../views/embeds/stats/NewItemsStat";
import {isDarkMode, NotionColorsDark, NotionColorsLight} from '../../views/styles'


const useStyles = makeStyles(componentStyles);

const Header = () => {
  const [projectNumber, setProjectNumber] = useState(null)
  const classes = useStyles();
  const theme = useTheme();

  const lastOpenedProjectNumber = useSelector(state => state.auth?.user?.lastOpenedProject?.number)

  useEffect(() => {
    setProjectNumber(lastOpenedProjectNumber)
  }, [lastOpenedProjectNumber])

  return (
      <>
        <div className={classes.header}>
          <Container
              maxWidth={false}
              component={Box}
              classes={{ root: classes.containerRoot }}
          >
            <div>
              <Grid container>
                <VelocityStat key={projectNumber} projectNumber={projectNumber}/>
                <>
                  <NewItemsStat key={projectNumber} projectNumber={projectNumber}/>
                </>
                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                      subtitle="COMPLETED ITEMS"
                      icon="ni ni-check-bold"
                      color={NotionColorsLight.yellow}
                      footer={
                        <>
                          <Box
                              component="span"
                              fontSize=".875rem"
                              color={theme.palette.warning.main}
                              marginRight=".1rem"
                              display="flex"
                              alignItems="center"
                          >
                            <Box
                                //component={ArrowDownward}
                                width="0rem"//width="1.5rem!important"
                                height="0rem" //height="1.5rem!important"

                            />{" "}
                              {/*1.10%*/}
                          </Box>
                          <Box component="span" whiteSpace="nowrap">
                              Coming soon
                          </Box>
                        </>
                      }
                  />
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                      subtitle="TIME TO MARKET"
                      icon="ni ni-delivery-fast"
                      color={NotionColorsLight.green}
                      footer={
                        <>
                          <Box
                              component="span"
                              fontSize=".875rem"
                              color={theme.palette.success.main}
                              marginRight=".1rem"
                              display="flex"
                              alignItems="center"
                          >
                            <Box
                                //component={ArrowUpward}
                                width="0rem"//width="1.5rem!important"
                                height="0rem"//height="1.5rem!important"
                            />{" "}
                              {/*10%*/}
                          </Box>
                          <Box component="span" whiteSpace="nowrap">
                              Coming soon
                          </Box>
                        </>
                      }
                  />
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </>
  );
};

export default Header;

