import React from "react";
import {useLocation, Route, Switch, Redirect, useHistory} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// core components
import SimpleNavbar from "../components/Navbars/SimpleNavbar";
import ProductHeader from "components/Headers/ProductHeader.js";
import ProductFooter from "components/Footers/ProductFooter.js";

import { product } from "routes.js";

import componentStyles from "assets/theme/layouts/product.js";

const useStyles = makeStyles(componentStyles);

const Product = () => {
  const classes = useStyles();
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src="//js.hs-scripts.com/21375735.js";
    script.async = true;

    document.body.appendChild(script);
  }, [])

  React.useEffect(() => {
    document.body.classList.add(classes.bgDefault);
    return () => {
      document.body.classList.remove(classes.bgDefault);
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (product) => {
    return product.map((prop, key) => {
      if (prop.layout === "/product") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className={`main-content ${classes.container}`} ref={mainContent}>
        <SimpleNavbar routes={product} />
        <ProductHeader />
        {/* Page content */}
        <Container
          component={Box}
          maxWidth={false}
          marginTop="-33rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Box justifyContent={'center'} component={Grid} container>
            <Switch>
              {getRoutes(product)}
              <Redirect from="*" to={'/product'} />
            </Switch>
          </Box>
        </Container>
      </div>
      <ProductFooter />
    </>
  );
};

export default Product
