import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  container: {
    backgroundColor: '#FEF4F1',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentWrapper: {
    marginLeft: '0 !important',
    marginRight: '0 !important',
    width: '100%',
    padding: '72px 24px 225px 24px',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '72px 24px 150px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '45px 15px 200px',
    },
  },
  copyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  copy: {
    fontFamily: "azo-sans-web, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    lineHeight: 1.7,
    color: '#2E3D44',
  },
  statWrapper: {
    width: '100%',
    marginLeft: '0 !important',
    marginRight: '0 !important',
    justifyContent: 'center',
  },
  stat: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
    },
  },
  statMiddle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
    borderLeftWidth: 1,
    borderLeftColor: '#DBD6D1',
    borderLeftStyle: 'solid',
    borderRightWidth: 1,
    borderRightColor: '#DBD6D1',
    borderRightStyle: 'solid',
    [theme.breakpoints.down('md')]: {
      borderLeftWidth: 0,
      borderRightWidth: 0,
      paddingTop: 20,
    },
  },
  statNumber: {
    fontFamily: "freightdispcmp-pro !important",
    fontWeight: 300,
    fontSize: 145,
    lineHeight: 1.25,
    paddingLeft: 5,
    paddingRight: 5,
    background: '-webkit-linear-gradient(270deg, rgba(0,183,176,1) 30%, rgba(106,225,198,1) 70%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  statText: {
    fontFamily: "azo-sans-web, sans-serif",
    letterSpacing: 1.3,
    fontWeight: 600,
    fontSize: 12,
    textTransform: 'uppercase',
    color: '#2E3D44',
    marginTop: -10,
  },
  divider: {
    width: 500,
    height: 1,
    backgroundColor: '#DBD6D1',
    marginTop: 40,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
});

export default componentStyles;
