import { gql } from '@apollo/client'
import { me } from './selections'

export const ME = gql`query Me {
  me ${me}
}`

export const REGISTRATION_TOKEN = gql`
  query RegistrationToken($token:String) {
    registrationToken(where: {
      token:  $token
    }) {
      user {
        id
        name
        email
        password
        organizations{
          id
        }
      } 
    }
  }
`

export const ORGANIZATION_DOMAIN = gql`
  query Organization($domain:String) {
    organization(where: {
      domain: $domain
    }) {
      id
    }
  }
`

export const GET_ORGANIZATION_ID_BY_DOMAIN = gql`
query GetOrgIdByDomain($orgDomain:String!){
  organization(where: {domain: $orgDomain}) {
    id
  }
}
`

export const GET_ORG_BY_ID = gql`
  query GetOrgById($id:Int!) {
    organization(where: {id: $id}) {
      id
      name
     projects {
       id
       name
       notionAuthorization {
         id
         workspaceIcon
       }
     } 
    }
}
`

export const NOTION_SPRINT_TABLE_DATA = gql`
  query NotionAccessToken($projectId:Int) {
    project(where: {
      id: $projectId
    }) {
      id
      notionAccessToken,
      sprintTable {
        id
        notionDatabaseId
      }
    }
  }
`

export const VELOCITY = gql`
  query Velocity($projectNumber:String!, $isEmbed:Boolean) {
    velocity(
      where: {
        projectNumber: $projectNumber,
        isEmbed: $isEmbed
    }) {
      committedCountAverage
      committedPointsAverage
      completedCountAverage
      completedPointsAverage
      data {
        sprintName
        committedPoints
        completedPoints
        committedCount
        completedCount
      }
    }
  }
`

export const CHANGE_IN_VELOCITY = gql`
  query ChangeInVelocity($projectNumber:String!) {
    changeInVelocity(
      where: {
        projectNumber: $projectNumber
    }) {
      currentVelocity
      percentChange
    }
  }
`


export const NEW_ITEMS = gql`
   query NewItems($projectNumber:String!){
     newItems(
       where: {
         projectNumber: $projectNumber
       }) {
         current,
         change
       }
     }
   `

export const BURNDOWN = gql`
  query Burndown($projectNumber:String, $currentSprint:Boolean, $sprintName:String, $isEmbed:Boolean) {
    burndown(where: {
      projectNumber: $projectNumber,
      currentSprint: $currentSprint,
      sprintName: $sprintName,
      isEmbed: $isEmbed
    }) {
      number
      idealCount
      idealPoints
      remainingCount
      remainingPoints
      date
    }
  }
`

export const SPRINT_STATUS_SNAPSHOTS = gql`
  query SprintStatusSnapshots($projectId:Int) {
    requirementTables(where: {
      projectId: {
        equals: $projectId
      }
    }) {
      statuses {
        id
        name
        snapshots(where: {
          sprintNotionId: {
            not: null
          }
        }) {
          id
          dateTime,
          sumPoints
          count,
          sprintNotionId
        }
      }
    }
  }
`

export const NOTION_AUTHORIZATIONS = gql`
  query NotionAuthorizations($organizationId:Int!) {
    notionAuthorizations(where: {
      orgId: {
        equals: $organizationId
      } 
    }) {
      id
      workspaceName,
      workspaceIcon,
      status,
      projects {
        id
        name,
        icon
      }
    }
  }
`
export const PROJECT_SPRINTS = gql`
  query ProjectSprints($projectNumber:String) {
    projectSprints(
      where: {
        projectNumber: $projectNumber
    }) {
      sprintName,
      sprintId,
      isCurrentSprint,
    }
  }
`

export const NOTION_DATABASES = gql`
  query NotionDatabases($projectId:Int!, $query:String, $take:Int, $cursor:String) {
    notionDatabases(projectId:$projectId, query:$query, take:$take, cursor:$cursor) {
      results {
        object
        id
        title {
          plain_text
        }
        properties {
          name
          id
          type
        }
      }
      has_more
      next_cursor
    }
  }
`

export const NOTION_DATABASE_PROPERTIES = gql`
  query NotionDatabaseProperties($projectId:Int!, $databaseId:String!) {
    notionDatabaseProperties(projectId:$projectId, databaseId:$databaseId) {
      name
      id
      type
      select {
        options {
          name
          id
          color
        }
      }
    }
  }
`

export const REQUIREMENT_TABLE = gql`
  query RequirementTable($projectId:Int!) {
    requirementTable(where: {
      projectId:$projectId
    }) {
      id
      notionNumber {
        id
        propertyId
        propertyTitle
        propertyType
      }
      notionStatus {
        id
        propertyId
        propertyTitle
        propertyType
        selectConfiguration {
          id
          options {
            optionName
            optionId
            optionColor
          }
        }
      }
      notionType {
        id
        propertyId
        propertyTitle
        propertyType
        selectConfiguration {
          id
          options {
            optionName
            optionId
            optionColor
          }
        }
      }
      notionStoryPoints {
        id
        propertyId
        propertyTitle
        propertyType
      }
      notionSprint {
        id
        propertyId
        propertyTitle
        propertyType
      }
      notionParent {
        id
        propertyId
        propertyTitle
        propertyType
      }
      notionAssignee {
        id
        propertyId
        propertyTitle
        propertyType
      }
      notionDatabaseId
      notionDatabaseTitle
      numberSequenceEnabled
    }
    requirementStatuses(where: {
      requirementTable: {
        projectId: {
          equals: $projectId
        }
      }
      active: {
        equals: true
      }
    }) {
      id
      name
      color
      notionId
      type
    }
    requirementTypes(where: {
      requirementTable: {
        projectId: {
          equals: $projectId
        }
      }
      active: {
        equals: true
      }
    }) {
      id
      name
      color
      notionId
      type
    }
  }  
`

export const SPRINT_TABLE = gql`
  query SprintTable($projectId:Int!) {
    sprintTable(where: {
      projectId:$projectId
    }) {
      id
      notionDateRange {
        id
        propertyId
        propertyTitle
        propertyType
      }
      notionPlannedEffort {
        id
        propertyId
        propertyTitle
        propertyType
      }
      notionBacklog {
        id
        propertyId
        propertyTitle
        propertyType
      }
      notionState {
        id
        propertyId
        propertyTitle
        propertyType
      }
      notionDatabaseId
      notionDatabaseTitle
      numberSequenceEnabled
    }
  }  
`
export const GET_AUTH = gql`
  query GetAuth($orgId:Int!){
  notionAuthorizations(where:{
    orgId:{
      equals:$orgId
    }
  }, orderBy:{id:asc}, take: 1) {
    id,
      accessToken,
      workspaceId,
      workspaceName,
      workspaceIcon,
      botId,
      owner {
        id,
        workspace
      },
      status
  }
}  
`

export const PROJECTS = gql`
  query Projects($organizationId:Int!) {
    projects(where: {
      organization: {
        id: {
          equals: $organizationId
        }
      } 
    }) {
      id
      name
      timezone
      number
      icon
      deleted
      deletedOn
      notionAuthorization {
        id
        workspaceName,
        workspaceIcon,
        status
      }
      sprintTable{
        id
      }
      requirementTable{
        id
      }
    }
  }
`

export const NOTION_MEMBERS = gql`
  query NotionMembers{
    notionMembers(where: {
      email: {
        not: {
          equals: "unknown"
        }
      }
    },
    orderBy: {
      id:desc
    }){ 
      id
      tenantId
      object
      notionId
      type
      name
      avatarUrl
      email
      billingStatus
      notionAuthorization{
        id
      }
      organization{
        id
      }
      user{
        id
        roles{
          name
        }
      }
    }
  }
`

export const PEOPLE = gql`
  query People($organizationId:Int!) {
    organization(where: {
      id: $organizationId
    }) {
      id
      projects {
        id
        name
      }
      users {
        id
        name
        email
        roles {
          id
          name
        }
        projects {
          id
          name
        }
      }
    }
  }
`
export const CUMULATIVE_FLOW = gql`
query CumulativeFlow($projectNumber:String!, $startDate:String, $endDate:String) {
  cumulativeFlow(where: {
    projectNumber: $projectNumber,
    startDate: $startDate,
    endDate: $endDate
  }) {
    statuses {
      id
      color
      data {
        count
        date
        points
      }
    }
  }
}
`

export const PRODUCT_OPTIONS = gql`
  query ProductOptions {
    productOptions
  }
`

export const CREATE_CUSTOMER_PORTAL_SESSION = gql`
  query CreateCustomerPortalSession($returnUrl: String!) {
    createCustomerPortalSession(returnUrl: $returnUrl)
  }
`

export const GET_ACHIEVEMENTS = gql`
  query Achievements {
    achievements(orderBy: [{ level: asc }, { lineNumber: asc }]) {
     id
     title
     infoUrl
     lineNumber
     level
    }  
  }
`

export const HAS_PAYMENT_METHOD = gql`
  query HasPaymentMethod {
    hasPaymentMethod
  }
`

export const HAS_SUBSCRIPTION = gql`
  query HasSubscription {
    hasSubscription
  }
`
