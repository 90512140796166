import React, {useEffect, useMemo, useState} from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Menu from '@material-ui/core/Menu'
import Typography from '@material-ui/core/Typography'
import componentStyles from 'assets/theme/components/dropdowns/achievements-dropdown.js'
import { useSelector } from 'react-redux'
import { AchievementLevel } from '../../enums'
import { Close } from '@material-ui/icons'

import HeatsBadge from 'assets/img/achievements/HeatsBadge.png'
import HeatsBadgeLight from 'assets/img/achievements/HeatsBadgeLight.png'
import QuarterFinalsBadge from 'assets/img/achievements/QuarterFinalsBadge.png'
import QuarterFinalsBadgeLight from 'assets/img/achievements/QuarterFinalsBadgeLight.png'
import SemiFinalsBadge from 'assets/img/achievements/SemiFinalsBadge.png'
import SemiFinalsBadgeLight from 'assets/img/achievements/SemiFinalsBadgeLight.png'
import FinalsBadge from 'assets/img/achievements/FinalsBadge.png'
import LockedBadge from 'assets/img/achievements/LockedBadge.png'
import Crowd from 'assets/img/achievements/Crowd.png'
import Info from 'assets/img/achievements/Info.png'
import Sprint from 'assets/img/achievements/Sprint.svg'
import Star from 'assets/img/achievements/Star.svg'
import StarFilled from 'assets/img/achievements/StarFilled.svg'
import Check from 'assets/img/achievements/Check.svg'
import AchievementsIcon from 'assets/img/achievements/AchievementsIcon.svg'
import {useQuery} from "@apollo/client";
import {GET_ACHIEVEMENTS} from "../../queries";

const useStyles = makeStyles(componentStyles)

const LEVEL_MAP = [
  AchievementLevel.Heats,
  AchievementLevel.QuarterFinals,
  AchievementLevel.SemiFinals,
  AchievementLevel.Finals,
]

const AchievementList = ({ achievements, selectedLevel }) => {
  const classes = useStyles()
  const [hoveredIndex, setHoveredIndex] = useState(null)

  return achievements.filter(a => a.level === selectedLevel).map((achievement, index) => (
    <ListItem
      key={`achievement-${index}`}
      style={{ marginBottom: index === achievements.length - 1 ? 0 : 15, backgroundColor: achievement.locked ? '#172B35' : '#2E3D44' }}
      className={classes.listItem}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
      onClick={() => window.open(achievement.infoUrl, '__blank')}>
      <Grid container alignItems="center">
        <Box component={'img'} src={achievement.completed ? Check : Sprint} style={{ height: 30, paddingTop: achievement.completed && 2, paddingBottom: achievement.completed && 2, opacity: achievement.locked ? 0.25 : 1, }}/>
        <Box
          flexBasis="0"
          flexGrow="1"
          width="100%;"
          maxWidth="100%"
          position="relative"
          paddingRight="20px"
          minHeight="1px"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <Box className={classes.achievementTitle} style={{ color: `rgba(250, 250, 250, ${achievement.locked ? 0.5 : 1})`}}>
                {achievement.title}
              </Box>
            </div>
          </Box>
        </Box>
        <Box component={'img'} src={Info} style={{ paddingRight: 3, opacity: hoveredIndex === index ? 1 : 0.35, transition: 'opacity .3s' }}/>
      </Grid>
    </ListItem>
  ))
}

const AchievementsDropdown = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [selectedLevel, setSelectedLevel] = React.useState(AchievementLevel.Heats)

  const user = useSelector(state => state.auth?.user)

  const { data } = useQuery(GET_ACHIEVEMENTS, {
    onError: (error) => {
      console.log(error)
    },
  })

  const { achievements, currentLevel } = useMemo(() => {
    let orgAchievements = user?.lastOpenedProject?.organization?.achievements?.map(a => a.achievement)
    let allAchievements = data?.achievements
    let retAchievements = []
    let level = AchievementLevel.Heats

    if(allAchievements?.length > 0) {
      if(orgAchievements?.length > 0) {
        let groupedAchievements = {}
        allAchievements.forEach(a => {
          if (!groupedAchievements[a.level]) {
            groupedAchievements[a.level] = []
          }
          groupedAchievements[a.level].push(a)
        })

        for (let _level of Object.keys(groupedAchievements)) {
          let levelCompleted = true
          groupedAchievements[_level].forEach(a => {
            if (!orgAchievements.find(oa => oa.id === a.id)) {
              levelCompleted = false
            }
          })
          if (!levelCompleted || _level === AchievementLevel.Finals) {
            level = _level
            break
          }
        }
      }
      retAchievements = allAchievements.map(a => {
        const achievementLevelIndex = LEVEL_MAP.indexOf(a.level)
        const currentLevelIndex = LEVEL_MAP.indexOf(level)
        return {
          ...a,
          completed: !!orgAchievements?.find(oa => oa.id === a.id),
          infoUrl: a.infoUrl,
          locked: achievementLevelIndex > currentLevelIndex || a.level === AchievementLevel.Finals || [12,13].includes(a.id)
        }
      })
    }

    return {
      achievements: retAchievements || [],
      currentLevel: level,
    }
  }, [data?.achievements, user?.lastOpenedProject?.organization?.achievements])

  useEffect(() => {
    setSelectedLevel(currentLevel)
  }, [currentLevel])

  const isMenuOpen = Boolean(anchorEl)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const renderAchievementLevelText = () => {
    switch (currentLevel) {
      case AchievementLevel.Heats:
        return <Typography className={classes.achievementLevelText + ' ' + classes.juniorVarsity}>Junior Varsity</Typography>
      case AchievementLevel.QuarterFinals:
        return <Typography className={classes.achievementLevelText + ' ' + classes.varsity}>Varsity</Typography>
      case AchievementLevel.SemiFinals:
        return <Typography className={classes.achievementLevelText + ' ' + classes.collegiate}>Collegiate</Typography>
      case AchievementLevel.Finals:
        return <Typography className={classes.achievementLevelText + ' ' + classes.pro}>Pro</Typography>
      case AchievementLevel.Heats:
      default:
        return <Typography className={classes.achievementLevelText + ' ' + classes.juniorVarsity}>Junior Varsity</Typography>
    }
  }

  const renderAchievementBadges = () => {
    const level = Object.values(AchievementLevel).findIndex(k => k === currentLevel) + 1

    const selectedStyle = {
      transform: 'none',
      opacity: '1 !important'
    }
    const unselectedStyle = {
      transform: 'scale(0.75)',
      opacity: 0.5,
      '&:hover': {
        transform: 'scale(0.8) !important'
      },
    }
    return (
      <Box className={classes.achievementScrollView}>
        <Box className={classes.achievementBadgeContainer}>
          <Box
            className={selectedLevel === AchievementLevel.Heats ? classes.badgeWrapperSelected : classes.badgeWrapper}
            onClick={() => setSelectedLevel(AchievementLevel.Heats)}
          >
            <Box component="img" src={getBadge(1, level >= 1, level === 1)} />
            <Box className={classes.starContainer}>
              {currentLevel === AchievementLevel.Heats && getProgressStars(achievements)}
            </Box>
          </Box>
          <Box
            className={selectedLevel === AchievementLevel.QuarterFinals ? classes.badgeWrapperSelected : classes.badgeWrapper}
            onClick={() => setSelectedLevel(AchievementLevel.QuarterFinals)}
          >
            <Box component="img" src={getBadge(2, level >= 2, level === 2)} />
            <Box className={classes.starContainer}>
              {currentLevel === AchievementLevel.QuarterFinals && getProgressStars(achievements)}
            </Box>
          </Box>
          <Box
            className={selectedLevel === AchievementLevel.SemiFinals ? classes.badgeWrapperSelected : classes.badgeWrapper}
            onClick={() => setSelectedLevel(AchievementLevel.SemiFinals)}
          >
            <Box component="img" src={getBadge(3, level >= 3, level === 3)} />
            <Box className={classes.starContainer}>
              {currentLevel === AchievementLevel.SemiFinals && getProgressStars(achievements)}
            </Box>
          </Box>
          <Box
            className={selectedLevel === AchievementLevel.Finals ? classes.badgeWrapperSelected : classes.badgeWrapper}
            onClick={() => setSelectedLevel(AchievementLevel.Finals)}
          >
            <Box component="img" src={getBadge(4, level >= 4, level === 4)} />
            <Box className={classes.starContainer}>
              {currentLevel === AchievementLevel.Finals && getProgressStars(achievements)}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const getProgressStars = (achievements) => {
    let locked = achievements.length && !achievements.find(a => !a.locked)
    const currentLevelAchievements = achievements.filter(a => a.level === currentLevel)
    const completedCount = currentLevelAchievements.filter(a => a.completed)?.length
    const starStyles = []
    for(let i = 0; i < currentLevelAchievements.length; i++) {
      if(i < completedCount) {
        starStyles.push({ filled: true })
      } else  {
        starStyles.push({ filled: false })
      }
    }
    return locked ? <Box className={classes.comingSoonText}>Coming soon!</Box> : (
      starStyles.map((star, index) => (
        <Box key={`achievement-start-${index}`} className={classes.star} component={'img'} src={star.filled ? StarFilled : Star} />
      ))
    )
  }

  const getBadge = (level, completed, current) => {
    switch (level) {
      case 1:
        return completed ? HeatsBadge : LockedBadge
      case 2:
        return completed ? QuarterFinalsBadge : LockedBadge
      case 3:
        return completed ? SemiFinalsBadge : LockedBadge
      case 4:
        return completed ? FinalsBadge : LockedBadge
      default:
        return
    }
  }

  const renderProgressBar = (progress = '0%') => {
    return (
      <Box className={classes.progressBarContainer}>
        <Box className={classes.progressBar}>
          <Box className={classes.progress} style={{ width: progress }} />
        </Box>
        <Box className={classes.activityContainer}>
          <Box>Activity Score</Box>
          <Box>{progress}</Box>
        </Box>
      </Box>
    )
  }

  const menuId = 'dropdowns-notifications-dropdown-id'

  const progress = achievements.length > 0 ? Math.round(achievements.filter(a => a.completed && !a.locked).length / achievements.length * 100) : 0

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{
        list: classes.menuList,
        paper: classes.menuPaper,
      }}
    >
      <Box width={'100%'} position={'relative'}>
        <Box className={classes.header}>
          <Box onClick={handleMenuClose} className={classes.closeButton}>
            <Close className={classes.closeIcon}/>
          </Box>
          <Box className={classes.blueBar} />
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box className={classes.achievementsLevelBars} style={{ marginRight: 8 }} />
            {renderAchievementLevelText()}
            <Box className={classes.achievementsLevelBars} style={{ marginLeft: 8 }} />
          </Box>
          <Box className={classes.nameContainer}>
            <Typography variant="h4" component="h4" className={classes.name}>
              {user?.name?.split(' ')?.slice(-1)[0]?.toUpperCase()}
            </Typography>
          </Box>
          {renderProgressBar(progress.toString() + '%')}
          {renderAchievementBadges()}
        </Box>
        <Box component="img" className={classes.crowdBackground} src={Crowd} />
      </Box>
      <List disablePadding className={classes.achievementBadgeList}>
        {achievements && <AchievementList achievements={achievements} selectedLevel={selectedLevel}/>}
      </List>
    </Menu>
  )

  return (
    <>
      <IconButton
        aria-label="achievements"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        classes={{ label: classes.buttonLabel }}
      >
        <div className={classes.circleContainer}>
          <svg viewBox="0 0 36 36" className={classes.circle}>
            <path className={classes.circleBackground} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
            <path className={classes.circleProgress} strokeDasharray={`${progress}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
          </svg>
          <Box className={classes.circleIcon} component='img' src={AchievementsIcon} />
        </div>
      </IconButton>
      {renderMenu}
    </>
  )
}

export default AchievementsDropdown
