import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { UPDATE_LAST_OPENED_PROJECT } from '../../mutations'
import { setUserData } from '../auth/actions'
import { makeStyles, useTheme, Select, FormControl, MenuItem, InputLabel } from '@material-ui/core'
import {useHistory} from "react-router-dom";
import {setProject} from "./NewProjectSetup/actions";
import {selectUser} from "../auth/selectors";

const useStyles = makeStyles(({}) => ({
  select: {
    '& .MuiSelect-root': {
      borderStyle: 'none',
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      paddingBottom: 0
    },
    '& .MuiMenu-list': {
      paddingBottom: 0
    }
  }
}))

const ProjectSelector = ({ mobile, onSelectProject }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles(theme)
  const user = useSelector(selectUser)
  const userId = user?.id


  const lastOpenedProject = useSelector(state => state.auth?.user?.lastOpenedProject)
  const projects = useSelector(state => state.auth?.user?.projects)
  const history = useHistory()

  const [selectedProject, setSelectedProject] = useState('')

  useEffect(() => {
    if (lastOpenedProject?.id) {
      setSelectedProject(lastOpenedProject.id)
    }
  }, [lastOpenedProject])

  const [updateLastOpenedProject] = useMutation(UPDATE_LAST_OPENED_PROJECT, {
    variables: {
      userId,
      projectId: selectedProject,
    },
    onCompleted: async ({ updateOneUser }) => {
      dispatch(setUserData(updateOneUser))
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const handleChange = async (event) => {
    setSelectedProject(event.target.value)
    if(event.target.value === -1) {
      history.replace({pathname: '/new-project'})
    } else if (event.target.value) {
      const project = projects?.find(p => p.id === event.target.value)
      if(!project?.requirementTable || !project?.sprintTable) {
        dispatch(setProject(project))
        let step = 0
        if(project?.id && project?.timezone) {
          step = 2
          if(project?.notionAuthorization) {
            step = 3
            if(project?.sprintTable) {
              step = 4
            }
          }
        }
        history.replace({pathname: `/new-project/${step}`})
      } else {
        if (onSelectProject) onSelectProject()
        await updateLastOpenedProject({
          variables: {
            userId,
            projectId: event.target.value
          }
        })
      }
    }
  }

  const renderMenuItems = () => {
    const retItems = projects && projects.length ? projects.map((project, index) => {
      if(!project.deleted) {
        return <MenuItem key={`p-item-${index}`} value={project.id}>{project.name}</MenuItem>
      }
    }) : []

    retItems.push(
        <MenuItem key={`p-item-footer`} disabled={user?.readOnly} value={-1} style={{
          color: '#00b7b0',
          borderTopColor: 'rgba(0,0,0,0.08)',
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          paddingTop: '12px',
          paddingBottom: '12px',
        }}>
          + New Project
        </MenuItem>
    )

    return retItems
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2rem' }}>
      <FormControl variant='standard' sx={{ m: 1 }} style={{ width: '100%', marginLeft: mobile ? 0 : 24, paddingRight: mobile ? 0 : 24 }}>
        <InputLabel id='demo-simple-select-standard-label'>Project</InputLabel>
        <Select
          labelId='simple-select-standard-label'
          id='simple-select-standard'
          value={selectedProject}
          onChange={handleChange}
          label='Selected Project'
          className={classes.select}
          MenuProps={{
            className: classes.menu
          }}
        >
          {renderMenuItems()}
        </Select>
      </FormControl>
    </div>
  )
}

export default ProjectSelector
