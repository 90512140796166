import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from 'components/Headers/Header.js'
import { Box, Container } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import PeopleCard from '../../../components/Cards/PeopleCard'
import NotionMembers from "../../../components/Cards/NotionMembers";
import componentStyles from 'assets/theme/views/admin/organization.js'

const useStyles = makeStyles(componentStyles)

const People = () => {
  const classes = useStyles();

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': 'People'
    })
  },[])

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop='-6rem'
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom='3rem !important'
            classes={{ root: classes.gridItemRoot + ' ' + classes.order2 }}
          >
            <NotionMembers />
          </Grid>
          <Grid
            item
            xs={12}
            xl={4}
            component={Box}
            marginBottom='3rem !important'
            classes={{ root: classes.gridItemRoot + ' ' + classes.order2 }}
          >
            <PeopleCard />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default People;
