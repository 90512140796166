const componentStyles = (theme) => ({
  buttonLabel: {
    fontSize: ".875rem",
    fontWeight: "600",
    color: theme.palette.buttonLightLabel.main,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "0!important",
    },
  },
  closeButton: {
    width: 30,
    height: 30,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 100,
    right: -13,
    top: -20,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    [theme.breakpoints.up("sm")]: {
      display: 'none',
    },
  },
  closeIcon: {
    height: 20,
    width: 20,
    fill: 'white',
  },
  crowdBackground: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: '-3rem',
  },
  header: {
    backgroundColor: 'white',
    //width: '27rem',
    height: '20rem',
    boxShadow: '0px 3px 24px #00000041',
    borderRadius: 8,
    marginBottom: 15,
    borderTop: '6px solid #FF664D',
    position: 'relative',
    zIndex: 1,
    margin: '3rem',
    [theme.breakpoints.down("xs")]: {
      margin: '1.5rem'
    },
  },
  blueBar: {
    backgroundColor: '#6FBFE4',
    width: '100%',
    height: 6,
    marginTop: '0.25rem',
    marginBottom: '1.25rem',
  },
  listItem: {
    padding: "1rem 2rem",
    textDecoration: "none",
    boxShadow: '0px 3px 24px #00000041',
    borderRadius: 8,
    //margin: '0 3rem'
    cursor: 'pointer'
  },
  boxAnchor: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.gray[100],
      color: theme.palette.info.dark,
    },
  },
  menuList: {
    padding: "0!important",
  },
  menuPaper: {
    backgroundColor: '#001B27',
    padding: '0 !important',
    maxWidth: 508,
    width: 'calc(100% - 32px)',
    maxHeight: 'calc(100vh - 32px)',
  },
  achievementLevelText: {
    fontSize: "25px !important",
    lineHeight: '25px',
    margin: "0!important",
    color: '#6AE1C6',
    fontFamily: 'funkydori !important',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  name: {
    fontSize: "40px !important",
    margin: "-8px 0 0 0 !important",
    fontFamily: 'abolition !important',
    fontWeight: 300,
    letterSpacing: 3,
    lineHeight: '40px',
    color: '#2E3D44'
  },
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  circleBorder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    textAlign: 'center',
    width: 52,
    height: 52,
    borderRadius: '100%',
    backgroundColor: 'rgba(185, 195, 200, 0.25)',
  },
  starContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: -1,
    left: 0,
    right: -19.5,
  },
  star: {
    marginRight: 2,
  },
  juniorVarsity: {
    color: '#6FBFE4'
  },
  varsity: {
    color: '#6AE1C6'
  },
  collegiate: {
    color: '#FFC600'
  },
  pro: {
    color: '#FF664D'
  },
  achievementBadgeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    transform: 'translateX(-9.5px)',
    [theme.breakpoints.down("xs")]: {
      width: 468
    },
  },
  achievementScrollView: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    position: 'absolute',
    bottom: '2rem',
    left: 0,
    right: 0,
    justifyContent: 'left',
  },
  achievementBadgeList: {
    marginBottom: '3rem',
    padding: '0 3rem',
    [theme.breakpoints.down("xs")]: {
      padding: '0 1.5rem',
      marginBottom: '1.5rem'
    },
  },
  badgeWrapper: {
    maxWidth: 90,
    position: 'relative',
    transition: 'opacity 0.3s, transform 0.3s',
    transform: 'scale(0.75)',
    opacity: 0.5,
    '&:hover': {
      cursor: 'pointer',
      opacity: '1 !important',
      transform: 'scale(0.8) !important'
    },
  },
  badgeWrapperSelected: {
    maxWidth: 90,
    position: 'relative',
    transition: 'opacity 0.3s, transform 0.3s',
    transform: 'translateX(-2.5px)',
    opacity: '1 !important'
  },
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '2rem'
  },
  progressBar: {
    backgroundColor: 'rgb(220, 225,227)',
    width: '80%',
    borderRadius: '2rem',
    height: 6
  },
  progress: {
    backgroundColor: '#6AE1C6',
    borderRadius: '2rem',
    height: 6,
    transition: 'width 0.3s'
  },
  activityContainer: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 12,
    color: '#2E3D44',
    fontFamily: 'azo-sans-web',
    fontWeight: 500,
    marginTop: '0.5rem'
  },
  achievementTitle: {
    fontFamily: 'azo-sans-web',
    fontSize: 19,
    marginLeft: '1rem',
  },
  achievementsLevelBars: {
    width: 15,
    backgroundColor: 'rgb(220, 225,227)',
    height: 3,
  },
  circleContainer: {
    width: 55,
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  circleIcon: {
    position: 'absolute',
    width: 45,
    height: 45,
    borderRadius: '100%',
    backgroundColor: '#2E3D44',
    border: '5px solid #00b7b0'
  },
  circle: {
    stroke: '#2E3D44',
    height: 55,
    width: 55,
  },
  circleBackground: {
    fill: 'none',
    stroke: 'rgba(185, 195, 200, 0.25)',
    strokeWidth: 3.8,
  },
  circleProgress: {
    fill: 'none',
    strokeWidth: 2.8,
    strokeLinecap: 'round',
    stroke: '#6AE1C6',
  },
  comingSoonText: {
    color: '#2E3D44',
    fontSize: 12,
    fontFamily: 'azo-sans-web',
    fontWeight: 500,
  },
})

export default componentStyles;
