import React, { useEffect, useState } from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from "@material-ui/core/Box"
import Grid from '@material-ui/core/Grid'
import FormControl from "@material-ui/core/FormControl"
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import componentStyles from 'assets/theme/views/auth/register.js'
import {Select} from '../../../components'

import { UPSERT_FIRST_REQUIREMENT_TABLE } from '../../../mutations'

import {useLazyQuery, useMutation} from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import {CircularProgress} from '@material-ui/core'
import {
  setRequirementDatabase as _setRequirementDatabase,
  setRequirementProperties as _setRequirementProperties,
  setRequirementStatuses as _setRequirementStatuses,
  setRequirementTypes as _setRequirementTypes
} from '../actions'
import {
  setRequirementDatabase as setNewRequirementDatabase,
  setRequirementProperties as setNewRequirementProperties,
  setRequirementStatuses as setNewRequirementStatuses,
  setRequirementTypes as setNewRequirementTypes
} from "../../admin/NewProjectSetup/actions";
import {
  selectOrganization,
  selectProject,
  selectRequirementDatabase,
  selectRequirementProperties,
  selectRequirementStatuses,
  selectRequirementTypes,
} from '../selectors'

import {NOTION_DATABASE_PROPERTIES, NOTION_DATABASES} from '../../../queries'
import FormGroup from '@material-ui/core/FormGroup'
import {
  selectNewProject,
  selectNewProjectOrganization,
  selectNewProjectReqDatabase,
  selectNewProjectReqProperties,
  selectNewProjectReqStatuses,
  selectNewProjectReqTypes
} from '../../admin/NewProjectSetup/selectors'

const useStyles = makeStyles(componentStyles)

const RequirementStep = ({ onBackClick = () => {}, onNextClick = () => {}, submitting = false, isOrgSetup, newProject }) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [quickSetupErrorMessage, setQuickSetupErrorMessage] = useState(null)

  const database = isOrgSetup ? useSelector(selectRequirementDatabase) : useSelector(selectNewProjectReqDatabase)
  const properties = isOrgSetup ? useSelector(selectRequirementProperties) : useSelector(selectNewProjectReqProperties)
  const statuses =  isOrgSetup ? useSelector(selectRequirementStatuses) : useSelector(selectNewProjectReqStatuses)
  const types =  isOrgSetup ? useSelector(selectRequirementTypes) : useSelector(selectNewProjectReqTypes)
  const organization = isOrgSetup ? useSelector(selectOrganization) : useSelector(selectNewProjectOrganization)
  const project = isOrgSetup ? useSelector(selectProject) : useSelector(selectNewProject)

  const setRequirementDatabase = isOrgSetup ? _setRequirementDatabase : setNewRequirementDatabase
  const setRequirementProperties = isOrgSetup ? _setRequirementProperties : setNewRequirementProperties
  const setRequirementStatuses = isOrgSetup ? _setRequirementStatuses : setNewRequirementStatuses
  const setRequirementTypes = isOrgSetup ? _setRequirementTypes : setNewRequirementTypes

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': newProject ? 'New Project: Requirement Step' : 'Requirement Step'
    })
  },[])

  const [getNotionDatabases, { data: quickSetupData, loading: quickSetupLoading }] = useLazyQuery(NOTION_DATABASES, {
    variables: {
      projectId: project?.id,
    },
    fetchPolicy: 'network-only'
  })
  const [getNotionDatabaseProperties, { data: quickSetupProperties, loading: quickSetupLoadingProperties }] = useLazyQuery(NOTION_DATABASE_PROPERTIES, {
    fetchPolicy: 'network-only'
  })

  const stripTypeNameFromOptions = o => ({
    optionName: o.optionName,
    optionId: o.optionId,
    optionColor: o.optionColor,
  })

  const createStatusUpsert = (statuses, type) => {
    return statuses.map(s => ({
      create: {
        tenantId: organization?.id,
        name: s.title,
        notionId: s.id,
        color: s.color,
        type
      },
      update: {
        name: {
          set: s.title
        },
        notionId: {
          set: s.id,
        },
        color: {
          set: s.color
        },
        type: {
          set: type
        },
        active: {
          set: true
        }
      },
      where: {
        requirementTableId_notionId: {
          requirementTableId: project?.requirementTable?.id || -1,
          notionId: s.id,
        }
      }
    }))
  }

  const createStatusCreate = (statuses, type) => {
    return statuses.map(s => ({
      tenantId: organization?.id,
      name: s.title,
      notionId: s.id,
      color: s.color,
      type
    }))
  }

  const getStatusCreates = () => {
    const plannedCreates = createStatusCreate(statuses.planned, 'PLANNED')
    const inProgressCreates = createStatusCreate(statuses.inProgress, 'IN_PROGRESS')
    const completeCreates = createStatusCreate(statuses.complete, 'COMPLETE')
    const removedCreates = createStatusCreate(statuses.removed, 'REMOVED')
    return [...plannedCreates, ...inProgressCreates, ...completeCreates, ...removedCreates]
  }

  const getStatusUpserts = () => {
    const plannedUpserts = createStatusUpsert(statuses.planned, 'PLANNED')
    const inProgressUpserts = createStatusUpsert(statuses.inProgress, 'IN_PROGRESS')
    const completeUpserts = createStatusUpsert(statuses.complete, 'COMPLETE')
    const removedUpserts = createStatusUpsert(statuses.removed, 'REMOVED')
    return [...plannedUpserts, ...inProgressUpserts, ...completeUpserts, ...removedUpserts]
  }

  const getStatusNotionIds = () => {
    const plannedIds = statuses.planned.map(s => s.id)
    const inProgressIds = statuses.inProgress.map(s => s.id)
    const completeIds = statuses.complete.map(s => s.id)
    const removedIds = statuses.removed.map(s => s.id)
    return [...plannedIds, ...inProgressIds, ...completeIds, ...removedIds]
  }

  const createTypeUpsert = (types, type) => {
    return types?.length ? types.map(t => ({
      create: {
        tenantId: organization?.id,
        name: t.title,
        notionId: t.id,
        color: t.color,
        type
      },
      update: {
        name: {
          set: t.title
        },
        notionId: {
          set: t.id,
        },
        color: {
          set: t.color
        },
        type: {
          set: type
        },
        active: {
          set: true
        }
      },
      where: {
        requirementTableId_notionId: {
          requirementTableId: project?.requirementTable?.id || -1,
          notionId: t.id,
        }
      }
    })) : []
  }

  const createTypeCreate = (types, type) => {
    return types?.length ? types.map(t => ({
      tenantId: organization?.id,
      name: t.title,
      notionId: t.id,
      color: t.color,
      type
    })) : []
  }

  const getTypeCreates = () => {
    const userStoryCreates = createTypeCreate(types.userStory, 'USER_STORY')
    const defectCreates = createTypeCreate(types.defect, 'DEFECT')
    const taskCreates = createTypeCreate(types.task, 'TASK')
    const otherCreates = createTypeCreate(types.other, 'OTHER')
    return [...userStoryCreates, ...defectCreates, ...taskCreates, ...otherCreates]
  }

  const getTypeUpserts = () => {
    const userStoryUpserts = createTypeUpsert(types.userStory, 'USER_STORY')
    const defectUpserts = createTypeUpsert(types.defect, 'DEFECT')
    const taskUpserts = createTypeUpsert(types.task, 'TASK')
    const otherUpserts = createTypeUpsert(types.other, 'OTHER')
    return [...userStoryUpserts, ...defectUpserts, ...taskUpserts, ...otherUpserts]
  }

  const getTypeNotionIds = () => {
    const userStoryIds = types.userStory.map(t => t.id)
    const defectIds = types.defect.map(t => t.id)
    const taskIds = types.task.map(t => t.id)
    const otherIds = types.other.map(t => t.id)
    return [...userStoryIds, ...defectIds, ...taskIds, ...otherIds]
  }

  const getNotionOptionUpserts = configuration => {
    if (configuration?.options?.length) {
      return configuration?.options?.map(o => ({
        create: {
          ...stripTypeNameFromOptions(o),
          tenantId: organization?.id,
        },
        update: {
          optionName: {
            set: o.optionName
          },
          optionId: {
            set: o.optionId
          },
          optionColor: {
            set: o.optionColor
          }
        },
        where: {
          optionId_configurationId: {
            optionId: o.optionId,
            configurationId: configuration?.id || -1
          }
        }
      }))
    }
    return []
  }

  const [createOrUpdateRequirementTable, { loading }] = useMutation(UPSERT_FIRST_REQUIREMENT_TABLE, {
    fetchPolicy: 'no-cache',
    variables: {
      projectId: project?.id,
      organizationId: organization?.id,
      notionDatabaseId: database?.id,
      notionDatabaseTitle: database?.title,
      notionNumberId: properties?.number?.id,
      notionNumberType: properties?.number?.type,
      notionNumberTitle: properties?.number?.title,
      notionStatusId: properties?.status?.id,
      notionStatusTitle: properties?.status?.title,
      notionStatusType: properties?.status?.type,
      notionStatusOptions: properties?.status?.selectConfiguration?.options?.map(stripTypeNameFromOptions),
      notionStatusOptionIds: properties?.status?.selectConfiguration?.options?.map(o => o.optionId),
      notionStatusUpserts: getNotionOptionUpserts(properties?.status?.selectConfiguration),
      notionTypeId: properties?.type?.id,
      notionTypeTitle: properties?.type?.title,
      notionTypeType: properties?.type?.type,
      notionStoryPointsId: properties?.storyPoints?.id,
      notionStoryPointsType: properties?.storyPoints?.type,
      notionStoryPointsTitle: properties?.storyPoints?.title,
      notionSprintId: properties?.sprint?.id,
      notionSprintType: properties?.sprint?.type,
      notionSprintTitle: properties?.sprint?.title,
      notionParentId: properties?.parent?.id,
      notionParentType: properties?.parent?.type,
      notionParentTitle: properties?.parent?.title,
      notionAssigneeId: properties?.assignee?.id,
      notionAssigneeType: properties?.assignee?.type,
      notionAssigneeTitle: properties?.assignee?.title,
      notionTypeOptions: properties?.type?.selectConfiguration?.options?.map(stripTypeNameFromOptions),
      notionTypeOptionIds: properties?.type?.selectConfiguration?.options?.map(o => o.optionId),
      notionTypeUpserts: getNotionOptionUpserts(properties?.type?.selectConfiguration),
      statusUpserts: getStatusUpserts(),
      statusCreates: getStatusCreates(),
      statusNotionIds: getStatusNotionIds(),
      typeUpserts: getTypeUpserts(),
      typeCreates: getTypeCreates(),
      typeNotionIds: getTypeNotionIds()
    },
    onError: e => {
      console.log(e)
    }
  })

  const isValid = () => {
    return !loading && !quickSetupLoading && !quickSetupLoadingProperties && database?.id
      && database?.title
      && properties.number?.id
      && properties.number?.type
      && properties.number?.title
      && properties.status?.id
      && properties.status?.title
      && properties.status?.type
      && properties.type?.id
      && properties.type?.title
      && properties.type?.type
      && properties.storyPoints?.id
      && properties.storyPoints?.title
      && properties.storyPoints?.type
      && properties.sprint?.id
      && properties.sprint?.title
      && properties.sprint?.type
      && properties.parent?.id
      && properties.parent?.title
      && properties.parent?.type
      && properties.assignee?.id
      && properties.assignee?.title
      && properties.assignee?.type
      && statuses.planned?.length
      && statuses.inProgress?.length
      && statuses.complete?.length
    && statuses.planned?.length + statuses.inProgress?.length + statuses.complete?.length + statuses.removed?.length >= properties.status?.selectConfiguration?.options?.length
      && types.userStory?.length
      && types.defect?.length
      && types.task?.length
      && types.userStory?.length + types.defect?.length + types.task?.length + types.other?.length >= properties.type?.selectConfiguration?.options?.length
  }

  const handleNext = () => {
    createOrUpdateRequirementTable().then(() => {
      onNextClick()
    }).catch(e => {
      console.log(e)
    })
  }

  const handleQuickSetup = async () => {
    setQuickSetupErrorMessage(null)
    getNotionDatabases()
  }

  useEffect(() => {
    if (quickSetupProperties?.notionDatabaseProperties?.length) {
      const statusProperty = quickSetupProperties?.notionDatabaseProperties.find(p => p.name === properties?.status?.title)
      const newRequirementProperties = { ...properties }
      if (statusProperty?.select?.options?.length) {
        const newStatus = {
          ...properties.status,
          selectConfiguration: {
            options: statusProperty?.select?.options?.map(so => ({
              optionName: so.name,
              optionId: so.id,
              optionColor: so.color,
            })),
          }
        }
        newRequirementProperties.status = newStatus
      }
      const typeProperty = quickSetupProperties?.notionDatabaseProperties.find(p => p.name === properties.type.title)
      if (typeProperty?.select?.options?.length) {
        const newType = {
          ...properties.type,
          selectConfiguration: {
            options: typeProperty?.select?.options?.map(so => ({
              optionName: so.name,
              optionId: so.id,
              optionColor: so.color,
            }))
          }
        }
        newRequirementProperties.type = newType
      }
      dispatch(setRequirementProperties(newRequirementProperties))
    }
  }, [quickSetupProperties])

  useEffect(() => {

    if (quickSetupData?.notionDatabases?.results?.length) {
      const notionDatabases = quickSetupData.notionDatabases.results
      const requirementDatabases = notionDatabases.filter(nd => {
        const plainTextTitle = nd?.title?.length ? nd.title[0]?.plain_text : null
        const lower = plainTextTitle?.toLowerCase().trim()
        return lower === 'backlog' || lower === 'requirements'
      })
      if (requirementDatabases?.length === 1) {
        const requirementDatabase = requirementDatabases[0]
        dispatch(setRequirementDatabase({
          title: requirementDatabase.title[0]?.plain_text,
          id: requirementDatabase.id
        }))
        const requirementProps = {
          number: null,
          status: null,
          type: null,
          storyPoints: null,
          sprint: null,
          parent: null,
          assignee: null,
        }
        let count = 0
        requirementDatabase.properties.forEach(property => {
          if ((property.name.toLowerCase().trim() === 'number' || property.name.toLowerCase().trim() === 'id') && property.type === 'number') {
            requirementProps.number = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++
          } else if ((property.name.toLowerCase().trim() === 'status') && (property.type === 'select' || property.type === 'status')) {
            requirementProps.status = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++

            getNotionDatabaseProperties({
              variables: {
                projectId: project?.id,
                databaseId: requirementDatabase.id
              }
            })
          } else if ((property.name.toLowerCase().trim() === 'type') && property.type === 'select') {
            requirementProps.type = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++

            getNotionDatabaseProperties({
              variables: {
                projectId: project?.id,
                databaseId: requirementDatabase.id
              }
            })
          } else if ((property.name.toLowerCase().trim() === 'story points' || property.name.toLowerCase().trim() === 'points') && property.type === 'number') {
            requirementProps.storyPoints = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++
          } else if ((property.name.toLowerCase().trim() === 'sprint') && property.type === 'relation') {
            requirementProps.sprint = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++
          } else if ((property.name.toLowerCase().trim() === 'parent') && property.type === 'relation') {
            requirementProps.parent = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++
          }  else if ((property.name.toLowerCase().trim() === 'assignee') && property.type === 'people') {
            requirementProps.assignee = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++
          }      })
        if (count < 5) {
          setQuickSetupErrorMessage('Could not find every property. Please select the missing properties from your requirement database manually.')
        }
        dispatch(setRequirementProperties(requirementProps))
      } else if (requirementDatabases?.length > 1) {
        setQuickSetupErrorMessage(`More than one database titled 'Backlog' was found in your workspace. Try temporarily changing the names of the databases you don't want, or select the one you want manually below.` )
      } else if (!requirementDatabases?.length) {
        setQuickSetupErrorMessage(`No databases titled 'Backlog' where found in your workspace. Make sure you've given NotionOps access to the right page. Go back to the authorizations step and click 'Change'.` )
      }
    } else if (quickSetupData?.notionDatabases) {
      setQuickSetupErrorMessage(`No databases where found in your workspace. Make sure you've given NotionOps access to the right page. Go back to the authorizations step and click 'Change'.` )
    }
  }, [quickSetupData])

  const mapPropertyOptions = options => options?.map(o => ({
    title: o.name,
    id: o.id,
    type: o.type,
    selectConfiguration: {
      options: o.select?.options?.map(so => ({
        optionName: so.name,
        optionId: so.id,
        optionColor: so.color,
      })),
    }
  }))

  const mapSelectOptions = options => options?.map(o => ({
    title: o.name,
    id: o.id,
    color: o.color
  }))

  const filterAvailableStatuses = _statuses => _statuses?.filter(s => {
    return !statuses.planned?.find(p => p.id === s.id) && !statuses.inProgress?.find(p => p.id === s.id) && !statuses.complete?.find(c => c.id === s.id) && !statuses.removed?.find(r => r.id === s.id)
  })

  const filterAvailableTypes = _types => _types?.filter(t => {
    return !types.userStory?.find(u => u.id === t.id) && !types.defect?.find(d => d.id === t.id) && !types.task?.find(k => k.id === t.id) && !types.other?.find(o => o.id === t.id)
  })

  useEffect(() => {
    if (properties?.status?.id) {
      if (!properties?.status?.selectConfiguration?.options?.length) {
        dispatch(setRequirementStatuses({
          planned: [],
          inProgress: [],
          complete: [],
          removed: []
        }))
      } else {
        const totalOptions = properties.status.selectConfiguration.options.length
        let optionsCount = 0
        const requirementStatuses = {
          planned: [],
          inProgress: [],
          complete: [],
          removed: []
        }
        properties.status.selectConfiguration.options?.forEach(option => {
          const lower = option.optionName.toLowerCase().trim()
          if (lower.includes('not started') || lower.includes('not active') || lower.includes('planned') || lower.includes('new') || lower.includes('planning') || lower.includes('created')) {
            requirementStatuses.planned.push({
              title: option.optionName,
              id: option.optionId,
              color: option.optionColor
            })
            optionsCount++
          } else if (lower.includes('in progress') || lower.includes('started') || lower.includes('not finished') || lower.includes('not final') || lower.includes('not done') || lower.includes('not complete') || lower.includes('not completed') || lower.includes('working') || lower.includes('in review') || lower.includes('review') || lower.includes('active') || lower.includes('testing') || lower.includes('reviewing') || lower.includes('test') || lower.includes('in test') || lower.includes('in testing')) {
            requirementStatuses.inProgress.push({
              title: option.optionName,
              id: option.optionId,
              color: option.optionColor
            })
            optionsCount++
          } else if (lower.includes('complete') || lower.includes('done') || lower.includes('final') || lower.includes('finished')) {
            requirementStatuses.complete.push({
              title: option.optionName,
              id: option.optionId,
              color: option.optionColor
            })
            optionsCount++
          } else if (lower.includes('deleted') || lower.includes('removed') || lower.includes('hold') || lower.includes('on hold')) {
            requirementStatuses.removed.push({
              title: option.optionName,
              id: option.optionId,
              color: option.optionColor
            })
            optionsCount++
          }
        })
        if (optionsCount < totalOptions) {
          setQuickSetupErrorMessage('Could not assign all statuses to either Planned, In Progress, Complete, or Removed. Please complete assigning the remaining statuses.')
        }
        dispatch(setRequirementStatuses(requirementStatuses))
      }
    }
  }, [properties?.status])

  useEffect(() => {
    if (properties?.type?.id) {
      if (!properties?.type?.selectConfiguration?.options?.length) {
        dispatch(setRequirementTypes({
          userStory: [],
          defect: [],
          task: [],
          other: []
        }))
      } else {
        const totalOptions = properties.type.selectConfiguration.options.length
        let optionsCount = 0
        const requirementTypes = {
          userStory: [],
          defect: [],
          task: [],
          other: []
        }
        properties.type.selectConfiguration.options?.forEach(option => {
          const lower = option.optionName.toLowerCase().trim()
          if (lower.includes('defect')
            || lower.includes('bug')
            || lower.includes('issue')
            || lower.includes('problem')
            || lower.includes('error')
            || lower.includes('side effect')
            || lower.includes('oops')
            || lower.includes('broke')
            || lower.includes('fault')
            || lower.includes('incident')
            || lower.includes('crash')
            || lower.includes('break')
            || lower.includes('accident')
          ) {
            requirementTypes.defect.push({
              title: option.optionName,
              id: option.optionId,
              color: option.optionColor
            })
            optionsCount++
          } else if (lower.includes('task')
            || lower.includes('item')
            || lower.includes('to do')
            || lower.includes('action')
            || lower.includes('child')
          ) {
            requirementTypes.task.push({
              title: option.optionName,
              id: option.optionId,
              color: option.optionColor
            })
            optionsCount++
          }
          else if (lower.includes('story')
            || lower.includes('feature')
            || lower.includes('user')
          ) {
            requirementTypes.userStory.push({
              title: option.optionName,
              id: option.optionId,
              color: option.optionColor
            })
            optionsCount++
          } else {
            requirementTypes.other.push({
              title: option.optionName,
              id: option.optionId,
              color: option.optionColor
            })
            optionsCount++
          }
        })
        if (optionsCount < totalOptions) {
          setQuickSetupErrorMessage('Could not assign all types to either User Story, Defect, Task, or Other. Please complete assigning the remaining types.')
        }
        dispatch(setRequirementTypes(requirementTypes))
      }
    }
  }, [properties?.type])

  const onDatabaseChange = (event, value) => {
    dispatch(setRequirementDatabase(value))
    dispatch(setRequirementProperties({
      number: null,
      status: null,
      type: null,
      storyPoints: null,
      sprint: null,
      parent: null,
    }))
  }

  const onNumberChange = (event, value) => {
    dispatch(setRequirementProperties({
      ...properties,
      number: value
    }))
  }

  const onStatusChange = (event, value) => {
    dispatch(setRequirementProperties({
      ...properties,
      status: value
    }))
  }

  const onTypeChange = (event, value) => {
    dispatch(setRequirementProperties({
      ...properties,
      type: value
    }))
  }

  const onStoryPointsChange = (event, value) => {
    dispatch(setRequirementProperties({
      ...properties,
      storyPoints: value
    }))
  }

  const onSprintChange = (event, value) => {
    dispatch(setRequirementProperties({
      ...properties,
      sprint: value
    }))
  }

  const onParentChange = (event, value) => {
    dispatch(setRequirementProperties({
      ...properties,
      parent: value
    }))
  }

  const onAssigneeChange = (event, value) => {
    dispatch(setRequirementProperties({
      ...properties,
      assignee: value
    }))
  }

  const onPlannedChange = (event, value) => {
    dispatch(setRequirementStatuses({
      ...statuses,
      planned: value
    }))
  }

  const onInProgressChange = (event, value) => {
    dispatch(setRequirementStatuses({
      ...statuses,
      inProgress: value
    }))
  }

  const onCompleteChange = (event, value) => {
    dispatch(setRequirementStatuses({
      ...statuses,
      complete: value
    }))
  }

  const onRemovedChange = (event, value) => {
    dispatch(setRequirementStatuses({
      ...statuses,
      removed: value
    }))
  }

  const onUserStoryChange = (event, value) => {
    dispatch(setRequirementTypes({
      ...types,
      userStory: value
    }))
  }

  const onDefectChange = (event, value) => {
    dispatch(setRequirementTypes({
      ...types,
      defect: value
    }))
  }

  const onTaskChange = (event, value) => {
    dispatch(setRequirementTypes({
      ...types,
      task: value
    }))
  }

  const onOtherChange = (event, value) => {
    dispatch(setRequirementTypes({
      ...types,
      other: value
    }))
  }

  return (
    <>
      <Box
        color={theme.palette.gray[600]}
        textAlign="center"
        marginBottom="1.5rem"
        marginTop=".5rem"
        fontSize="1rem"
      >
        <Box fontWeight="400" component="small">
          Now lets map the properties of your requirement database (in most templates this database is titled 'Backlog'). Or if you are using a quick setup template, NotionOps can find the mappings for you.
        </Box>
        <Box textAlign="center" marginTop="1.5rem">
          { quickSetupLoading || quickSetupLoadingProperties ?
            <CircularProgress size={38}/>
            :
            <Button onClick={handleQuickSetup} color={'primary'}>
              Quick Setup
            </Button>
          }

        </Box>
        {quickSetupErrorMessage ? <Box
          marginTop={'1rem'}
          fontWeight="700"
          color={theme.palette.error.main}
        >
          {quickSetupErrorMessage}
        </Box> : null }
      </Box>
      <FormControl
        variant="filled"
        component={Box}
        width="100%"
        marginBottom="1rem!important"
      >
        <Select
          query={NOTION_DATABASES}
          selectResponse={data => data.notionDatabases}
          selectResults={response => response.results}
          variables={{
            projectId: project?.id
          }}
          mapOptions={options => options?.map(o => ({
            title: o.title[0]?.plain_text,
            id: o.id
          }))}
          getOptionLabel={option => `${option.title} – ${option.id}`}
          showId={true}
          getOptionSelected={(option, value) => option.id === value.id}
          label={'Requirement Database (Backlog)'}
          onChange={onDatabaseChange}
          value={database}
          disableClearable={true}
        />
        <Box marginTop={'0.5rem'}>
          <Tooltip title={'The database in Notion that holds all your requirements (User Stories, Bugs, and Tasks). If you have multiple databases with the same name, we recommend temporarily changing the one you want to a unique name so you can find it here.'}>
            <HelpOutlineIcon/>
          </Tooltip>
        </Box>
      </FormControl>
      <Box
        component={Typography}
        variant="h6"
        color={theme.palette.gray[600] + "!important"}
        paddingTop="2.25rem"
        paddingBottom="0.25rem"
        fontSize=".75rem!important"
        letterSpacing=".04em"
        classes={{root: classes.typographyRootH6}}
      >
        Property Setup
      </Box>
      <Grid container>
        <Grid item xs={12} lg={4}>
          <FormGroup>

            <FormControl
              variant="filled"
              component={Box}
              width="100%"
            >

              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'number')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'Number'}
                onChange={onNumberChange}
                value={properties.number}
                disableClearable={true}
              />
              <Tooltip title={'NotionOps will automatically assign unique identifiers to this number property for each requirement.'}>
                <Box marginTop={'0.5rem'}>
                  <HelpOutlineIcon/>
                </Box>
              </Tooltip>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormGroup>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
            >
              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'select' || p.type === 'status')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'Status'}
                onChange={onStatusChange}
                value={properties.status}
                disableClearable={true}
              />
              <Box marginTop={'0.5rem'}>
                <Tooltip title={'A select property (single) that represents the status of a requirement.'}>
                  <HelpOutlineIcon/>
                </Tooltip>
              </Box>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormGroup>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
            >
              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'select')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'Type'}
                onChange={onTypeChange}
                value={properties.type}
                disableClearable={true}
              />
              <Box marginTop={'0.5rem'}>
                <Tooltip title={'A select property (single) that represents the type of requirement: User Story, Bug, or Task'}>
                  <HelpOutlineIcon/>
                </Tooltip>
              </Box>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormGroup>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'number')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'Story Points'}
                onChange={onStoryPointsChange}
                value={properties.storyPoints}
                disableClearable={true}
              />
              <Box marginTop={'0.5rem'}>
                <Tooltip title={'A number property containing the story point score assigned to a requirement.'}>
                  <HelpOutlineIcon/>
                </Tooltip>
              </Box>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormGroup>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'relation')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'Sprint'}
                onChange={onSprintChange}
                value={properties.sprint}
                disableClearable={true}
              />
              <Box marginTop={'0.5rem'}>
                <Tooltip title={'A relation property containing a reference to the sprint database. This is how requirements are assigned to sprints, producing the sprint backlog.'}>
                  <HelpOutlineIcon/>
                </Tooltip>
              </Box>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormGroup>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'relation')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'Parent'}
                onChange={onParentChange}
                value={properties.parent}
                disableClearable={true}
              />
              <Tooltip title={'A relation property containing a reference to a parent requirement. This is how parent-child requirements are formed. For now child requirements are excluded from analytics in favor of their parent, but this setting will be configurable soon!'}>
                <Box marginTop={'0.5rem'}>
                  <HelpOutlineIcon/>
                </Box>
              </Tooltip>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormGroup>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'people')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value?.id}
                label={'Assignee'}
                onChange={onAssigneeChange}
                value={properties.assignee}
                disableClearable={true}
              />
              <Tooltip title={'A people property containing the user assigned to this requirement.'}>
                <Box marginTop={'0.5rem'}>
                  <HelpOutlineIcon/>
                </Box>
              </Tooltip>
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>
      {properties?.status?.selectConfiguration?.options ?
        <>
          <Box
            component={Typography}
            variant="h6"
            color={theme.palette.gray[600] + "!important"}
            paddingTop=".25rem"
            paddingBottom=".25rem"
            fontSize=".75rem!important"
            letterSpacing=".04em"

            classes={{root: classes.typographyRootH6}}
          >
            Status Groups

          </Box>
          <Box marginBottom="1.5rem!important" marginTop={'0.5rem'} color={theme.palette.gray[600]} fontSize="80%" fontWeight="400" component="small">
            NotionOps cares about four primary statuses. You can define as many statuses as you want on your status property in Notion. However, you must then assign them to the appropriate bucket here so NotionOps knows how to count them. For example, 'In Progress' and 'In Review' are two common statuses that should both be assigned to 'In Progress' below.
          </Box>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Select
                    query={NOTION_DATABASE_PROPERTIES}
                    selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === properties?.status?.id)?.select?.options}
                    variables={{
                      projectId: project?.id,
                      databaseId: database?.id
                    }}
                    mapOptions={options => filterAvailableStatuses(mapSelectOptions(options))}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    label={'Planned'}
                    onChange={onPlannedChange}
                    value={statuses.planned}
                    multiple={true}
                    disableClearable={true}
                  />
                  <Box marginTop={'0.5rem'}>
                    <Tooltip title={'Any status that indicates an item has not be started yet. For example: Not Started, Planning, New etc.'}>
                      <HelpOutlineIcon/>
                    </Tooltip>
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Select
                    query={NOTION_DATABASE_PROPERTIES}
                    selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === properties?.status?.id)?.select?.options}
                    variables={{
                      projectId: project?.id,
                      databaseId: database?.id
                    }}
                    mapOptions={options => filterAvailableStatuses(mapSelectOptions(options))}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    label={'In Progress'}
                    onChange={onInProgressChange}
                    value={statuses.inProgress}
                    multiple={true}
                    disableClearable={true}
                  />
                  <Box marginTop={'0.5rem'}>
                    <Tooltip title={'Any status that indicates an item is currently being worked on. For example: In Progress, In Review, Testing etc.'}>
                      <HelpOutlineIcon/>
                    </Tooltip>
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Select
                    query={NOTION_DATABASE_PROPERTIES}
                    selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === properties?.status?.id)?.select?.options}
                    variables={{
                      projectId: project?.id,
                      databaseId: database?.id
                    }}
                    mapOptions={options => filterAvailableStatuses(mapSelectOptions(options))}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    label={'Complete'}
                    onChange={onCompleteChange}
                    value={statuses.complete}
                    multiple={true}
                    disableClearable={true}
                  />
                  <Box marginTop={'0.5rem'}>
                    <Tooltip title={'Any status that indicates an item is complete. For example: Complete, Done, Final etc.'}>
                      <HelpOutlineIcon/>
                    </Tooltip>
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Select
                    query={NOTION_DATABASE_PROPERTIES}
                    selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === properties?.status?.id)?.select?.options}
                    variables={{
                      projectId: project?.id,
                      databaseId: database?.id
                    }}
                    mapOptions={options => filterAvailableStatuses(mapSelectOptions(options))}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    label={'Removed (optional)'}
                    onChange={onRemovedChange}
                    value={statuses.removed}
                    multiple={true}
                    disableClearable={true}
                  />
                  <Box marginTop={'0.5rem'}>
                    <Tooltip title={'Any status that indicates an item should not be counted for analytics. For example: On Hold, Removed, Deleted etc.'}>
                      <HelpOutlineIcon/>
                    </Tooltip>
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
        </>
        : null }
      {properties?.type?.selectConfiguration?.options ?
        <>
          <Box
            component={Typography}
            variant="h6"
            color={theme.palette.gray[600] + "!important"}
            paddingTop=".25rem"
            paddingBottom=".25rem"
            fontSize=".75rem!important"
            letterSpacing=".04em"

            classes={{root: classes.typographyRootH6}}
          >
            Type Groups

          </Box>
          <Box marginBottom="1.5rem!important" marginTop={'0.5rem'} color={theme.palette.gray[600]} fontSize="80%" fontWeight="400" component="small">
            NotionOps cares about four primary types. You can define as many types as you want on your type property in Notion. However, you must then assign them to the appropriate bucket here so NotionOps knows how to count them. For example, 'Bug' or 'Issue' are two common types that can be assigned to 'Defect' below.
          </Box>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Select
                    query={NOTION_DATABASE_PROPERTIES}
                    selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === properties?.type?.id)?.select?.options}
                    variables={{
                      projectId: project?.id,
                      databaseId: database?.id
                    }}
                    mapOptions={options => filterAvailableTypes(mapSelectOptions(options))}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    label={'User Story'}
                    onChange={onUserStoryChange}
                    value={types.userStory}
                    multiple={true}
                    disableClearable={true}
                  />
                  <Box marginTop={'0.5rem'}>
                    <Tooltip title={'Requirement that describes a feature from the perspective of a user (i.e. User Story).'}>
                      <HelpOutlineIcon/>
                    </Tooltip>
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Select
                    query={NOTION_DATABASE_PROPERTIES}
                    selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === properties?.type?.id)?.select?.options}
                    variables={{
                      projectId: project?.id,
                      databaseId: database?.id
                    }}
                    mapOptions={options => filterAvailableTypes(mapSelectOptions(options))}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    label={'Defect'}
                    onChange={onDefectChange}
                    value={types.defect}
                    multiple={true}
                    disableClearable={true}
                  />
                  <Box marginTop={'0.5rem'}>
                    <Tooltip title={'Requirement to investigate/resolve an unintended abnormality (i.e. Bug or Issue).'}>
                      <HelpOutlineIcon/>
                    </Tooltip>
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Select
                    query={NOTION_DATABASE_PROPERTIES}
                    selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === properties?.type?.id)?.select?.options}
                    variables={{
                      projectId: project?.id,
                      databaseId: database?.id
                    }}
                    mapOptions={options => filterAvailableTypes(mapSelectOptions(options))}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    label={'Task'}
                    onChange={onTaskChange}
                    value={types.task}
                    multiple={true}
                    disableClearable={true}
                  />
                  <Box marginTop={'0.5rem'}>
                    <Tooltip title={'Requirements that are generic task or a child task of another requirement (i.e. Task).'}>
                      <HelpOutlineIcon/>
                    </Tooltip>
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Select
                    query={NOTION_DATABASE_PROPERTIES}
                    selectResponse={data => data.notionDatabaseProperties.find(dp => dp.id === properties?.type?.id)?.select?.options}
                    variables={{
                      projectId: project?.id,
                      databaseId: database?.id
                    }}
                    mapOptions={options => filterAvailableTypes(mapSelectOptions(options))}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    label={'Other (optional)'}
                    onChange={onOtherChange}
                    value={types.other}
                    multiple={true}
                    disableClearable={true}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
        </>
        : null }
      <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
        <Button onClick={onBackClick} className={classes.backButton}>Back</Button>
        <Button disabled={!isValid()} variant="contained" color="primary" onClick={handleNext}>
          Complete
          { (loading || submitting) && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Box>
    </>
  )
}

export default RequirementStep
