import React, { useEffect}  from 'react'
import { makeStyles, useTheme } from "@material-ui/core/styles"
import componentStyles from "../../assets/theme/views/auth/setupBilling"
import { useHistory, userLocation } from "react-router-dom"
import { Grid, Card, CardContent } from '@material-ui/core'
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import {useLazyQuery, useMutation, useQuery} from '@apollo/client'
import {CREATE_CUSTOMER_PORTAL_SESSION, HAS_PAYMENT_METHOD} from '../../queries'
import { UPGRADE_PLAN } from '../../mutations'
import config from "../../config"
import { useDispatch } from "react-redux"
import Alert from '@material-ui/lab/Alert'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(componentStyles)


const UpgradePlan = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const returnUrl = config.siteBaseUrl + history?.location?.pathname + history?.location?.search
  const originPath = new URLSearchParams(history?.location?.search).get('origin')

  const [createCustomerPortalSession, { loading: loadingPortalSession }] = useLazyQuery(CREATE_CUSTOMER_PORTAL_SESSION, {
    fetchPolicy: 'no-cache',
    variables: {
      returnUrl,
    },
    onCompleted: ({ createCustomerPortalSession }) => {
      if (createCustomerPortalSession) {
        window.location.replace(createCustomerPortalSession)
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const { data, loading: loadingPaymentMethod } = useQuery(HAS_PAYMENT_METHOD, {
    fetchPolicy: 'no-cache',
    pollInterval: 2000,
    onError: e => {
      if(e.toString().includes('Invalid login token')) {
        localStorage.removeItem('loginToken')
        history.push('/auth/login', { prevPath: location.pathname + location.search })
      }
    }
  })

  const [upgradePlan, { error, loading: loadingUpgradePlan }] = useMutation(UPGRADE_PLAN)

  const hasPaymentMethod = data?.hasPaymentMethod

  const onClickPayment = async () => {
    await createCustomerPortalSession()
  }

  const onClickUpgrade = async () => {
    if (hasPaymentMethod) {
      await upgradePlan()
      backToAdminSite()
    }
  }

  const backToAdminSite = () => {
    history.replace({ pathname: originPath ? originPath : '/admin/index' })
  }

  const loading = loadingPaymentMethod || loadingPortalSession || loadingUpgradePlan

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent classes={{root: classes.cardContent}}>
            <Box
              color={theme.palette.gray[600]}
              textAlign="center"
              fontSize="1.5rem"
            >
              <Box fontSize="80%" fontWeight="400" component="small">
                Standard: <b>$4.50</b>/user/month
              </Box>
            </Box>
            { loading ? <Box marginTop="1.5rem" marginBottom="1.5rem" textAlign="center"><CircularProgress /></Box> : (
              <>
                <Box
                  color={theme.palette.gray[600]}
                  textAlign="center"
                  marginBottom="1.5rem"
                  marginTop="1.5rem"
                  fontSize="1rem"
                >
                  <Box fontSize="80%" fontWeight="400" component="small">
                    { hasPaymentMethod ? 'Need to make a change to your payment method?' : 'You must add a payment method to your account in order to upgrade.' }
                  </Box>
                </Box>
                <Box textAlign="center" marginTop="1.5rem" marginBottom="2.5rem">
                  <Button disabled={loading} onClick={onClickPayment} color="primary">
                    { hasPaymentMethod ? 'Manage Payment Methods' : 'Add Payment Method' }
                  </Button>
                </Box>
                <Box
                  color={theme.palette.gray[600]}
                  textAlign="center"
                  marginBottom="1.5rem"
                  marginTop=".5rem"
                  fontSize="1rem"
                >
                  <Box fontSize="80%" fontWeight="400" component="small">
                    <span>By upgrading, you agree to our </span>
                    <Box
                      color={theme.palette.primary.main}
                      component="a"
                      href={'https://oosstudio.notion.site/Terms-and-Privacy-b497fbb6a418424aa5e39e60070b3fcd'}
                      target={'_blank'}
                      textDecoration="none"
                    >
                      Terms and Privacy
                    </Box>
                    <span> as well as stripe's </span>
                    <Box
                      color={theme.palette.primary.main}
                      component="a"
                      href={'https://stripe.com/legal/end-users'}
                      target={'_blank'}
                      textDecoration="none"
                    >
                      End User Terms of Service
                    </Box>
                    .
                  </Box>
                </Box>
                <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                  <Button disabled={loading || !hasPaymentMethod} onClick={onClickUpgrade} color="primary" variant="contained">
                    Upgrade
                  </Button>
                </Box>
              </>
            )}
          {error ? (
            <Alert severity="error" style={{ alignItems: 'center' }}>
              {error.message ? error.message : 'An error occurred upgrading your plan'}
            </Alert>
          ) : (
            ''
          )}
          </CardContent>
        </Card>
        <div style={{ width: '100%', display: 'flex', marginTop: 15, justifyContent: 'flex-end'}}>
          <a
            onClick={backToAdminSite}
            style={{
              cursor: 'pointer',
            }}
            className={classes.miniButton}
          >
            Go back to admin site
          </a>
        </div>
      </Grid>
    </>
  )
}

export default UpgradePlan
