import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  Modal,
  Box,
  CardHeader,
  useTheme,
  FormControl,
  TextField
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import componentStyles from '../../../../assets/theme/views/admin/profile'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import { useMutation } from '@apollo/client'
import { DELETE_PROJECT, RESET_LAST_OPENED_PROJECT, UPDATE_LAST_OPENED_PROJECT } from '../../../../mutations'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { setUserData } from '../../../auth/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(componentStyles)

const DeleteProjectModal = ({ open, onClose, user, project }) => {
  const [confirmDeleteProject, setConfirmDeleteProject] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)

  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()

  useEffect(() => {
    setConfirmDeleteProject(null)
    setSelectedProject(null)
  }, [open])

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    variables: {
      id: project?.id,
      deleted : true,
      deletedOn: new Date(),
    }
  })

  const [updateLastOpenedProject] = useMutation(UPDATE_LAST_OPENED_PROJECT, {
    variables: {
      userid: user?.id,
      projectId: selectedProject,
    },
    onCompleted: async ({ updateOneUser }) => {
      dispatch(setUserData(updateOneUser))
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const [resetLastOpenedProject] = useMutation(RESET_LAST_OPENED_PROJECT, {
    variables: {
      userId: user?.id,
    },
    onCompleted: async ({ updateOneUser }) => {
      dispatch(setUserData(updateOneUser))
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const onSubmit = () => {
    deleteProject().catch(e => {
      console.error(e)
      onClose()
    }).then(res => {
      if (res) {
        const findExistingProject = user?.projects?.length ? user?.projects?.find(p => !p.deleted && p.id !== project?.id) : null
        console.log(findExistingProject)
        if (findExistingProject) {
          setSelectedProject(findExistingProject?.id)
          updateLastOpenedProject({
            variables: {
              userId: user?.id,
              projectId: findExistingProject?.id
            }
          }).catch(e => {
            console.error(e)
            onClose()
          }).then(() => {
            onClose()
          })
        } else {
          resetLastOpenedProject().catch(e => {
            console.error(e)
            onClose()
          }).then(() => {
            onClose()
          })
        }
      }
    })
  }

  const canSubmit = () => {
    return user && project && confirmDeleteProject && textMatch()
  }

  const textMatch = () => {
    return confirmDeleteProject === `Delete ${project?.name}`
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        classes={{
          root: classes.editProfileModalWrapper + ' ' + classes.cardRoot + ' ' + classes.cardRootSecondary,
        }}
      >
        <CardHeader
          subheader={
            <>
              <Grid
                container
                component={Box}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid
                  item
                  xs='auto'
                  className={classes.modalHeaderLeftWrapper}
                >
                  <Box
                    component={CloseRoundedIcon}
                    width='1.5rem!important'
                    height='1.5rem!important'
                    onClick={onClose}
                    cursor='pointer'
                  />
                  <Box
                    component={Typography}
                    variant='h3'
                    marginBottom='0!important'
                    marginLeft='1rem!important'
                  >
                    Delete Project
                  </Box>
                </Grid>
                <Grid item xs='auto'>
                  <Box
                    justifyContent='flex-end'
                    display='flex'
                    flexWrap='wrap'
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      disabled={!canSubmit()}
                      onClick={onSubmit}
                    >
                      Delete
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </>
          }
          classes={{ root: classes.cardHeaderRoot }}
        />
        <CardContent classes={{ root: classes.editProfileCardContent }}>
          <Grid container>
            <Grid item lg={12}>
              <p>Your project will be permanently removed after 30 days from deleting it. Until then you will be able to restore your project.</p>
              <FormGroup>
                <FormLabel>Enter "Delete {project?.name}"</FormLabel>
                <FormControl
                  component={Box}
                  width='100%'
                  margin='normal'
                >
                  <Box
                    paddingLeft='0.75rem'
                    paddingRight='0.75rem'
                    variant='filled'
                    component={TextField}
                    helperText={textMatch() ? null : 'Text does not match'}
                    error={!textMatch()}
                    onChange={(e) => setConfirmDeleteProject(e.target.value)}
                    value={confirmDeleteProject}
                    autoComplete='off'
                    type='text'
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  )
}

export default DeleteProjectModal
