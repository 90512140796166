import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  sectionHeader: {
    fontSize: 42,
    fontWeight: 400,
    fontFamily: "freight-text-pro !important",
    color: theme.palette.text.primary,
    lineHeight: 1.125,
    marginBottom: 25,
  },
  buttonRoot: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
    },
  },
  slider: {
    //position: 'absolute',
    //left: '50%',
    pointerEvents: 'none',
    width: 1400,
    marginLeft: -100,
    marginTop: -80
  },
  slideImage: {
    width: 1400
  },
  leftGridItem: {
  },
  rightGridItem: {
    pointerEvents: 'none'
  },
  leftBox: {
  },
  title: {
    fontSize: 86,
    lineHeight: 1,
    marginBottom: 10,
    color: 'white',
    marginTop: 45,
    [theme.breakpoints.down("xs")]: {
      fontSize: 72,
    },
  },
  smallTitle: {
    fontSize: 72,
    lineHeight: 1,
    marginBottom: 10,
    color: 'white',
    marginTop: 45,
  },
  titleBlurb: {
    fontSize: 18,
    lineHeight: 1.5,
    fontWeight: 500,
    marginBottom: 27,
  },
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    width: 500,
    backgroundColor: theme.palette.secondary.main,
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  cardContent: {
    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },
  },
  buttonImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  /*buttonRoot: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    borderColor: theme.palette.white.main + "!important",
    "&:hover": {
      color: theme.palette.gray[900],
      borderColor: theme.palette.white.main + "!important",
      backgroundColor: theme.palette.white.main,
    },
  },*/
  formControlLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
  },
  formControlLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: theme.palette.gray[600],
  },
  footerLinks: {
    color: theme.palette.gray[400],
    textDecoration: "none",
  },
  orderMd1: {
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  orderMd2: {
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
  },
  prMd5: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "3rem",
    },
  },
  typographyH4: {
    marginBottom: "0",
    marginLeft: "1rem",
    fontWeight: '300'
  },
  imgBox: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  warningAnchor: {
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.warning.dark,
    },
  },
  listStyleNone: {
    listStyle: "none",
  },
  checkIcon: {
    color: "rgba(0, 174, 167, 1)",
    width: "1.7rem",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "0 auto",
    height: "100%",
  },
  table: {},
  tableContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tableSectionHeader: {
    margin: 'auto',
    textAlign: "center",
    maxWidth: 460,
  },
  tableHeader: {
    fontFamily: "azo-sans-web, sans-serif !important",
    fontSize: "0.8rem !important",
    letterSpacing: "1.3px !important",
    fontWeight: "700 !important",
    color: "#2E3D44",
    borderBottom: "3px solid #2E3D44 !important",
    paddingTop: "4rem !important",
    paddingBottom: "0.6rem !important",
    height: 'auto !important',
  },
  tableCell: {
    fontFamily: "azo-sans-web, sans-serif !important",
    paddingTop: "0.75rem !important",
    paddingBottom: "0.75rem !important",
    fontSize: "1rem !important",
    color: "#2E3D44",
    width: "14%",
    height: 'auto !important',
    lineHeight: "1.35rem",
    position: "relative",
  },
  tableCellSubject: {
    width: "30%"
  },
  container: {
    backgroundColor: theme.palette.gray.gradient,
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
  },
  content: {
    backgroundColor: theme.palette.gray.gradient,
    display: 'flex',
    justifyContent: 'center',
    height: 200,
    marginBottom: 55,
    width: 1100,
    [theme.breakpoints.down("sm")]: {
      height: 700,
    },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '1.25%',
  },
  firstWord: {
    color: theme.palette.info.mainGradient,
    fontWeight: 700,
    marginRight: '1.25%',
    fontSize: 28,
  },
  secondWord: {
    color: theme.palette.dark.heading,
    fontSize: 28,
    fontWeight: 300,
  },
  angleContainer: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    top: -450,
    zIndex: -1,
  },
  box: {
    width: '100%',
    height: 200,
    position: 'absolute',
    backgroundColor: theme.palette.gray.gradient,
    top: 300,
    left: 0,
  },
  angle: {
    width: '125%',
    height: 500,
    background: 'linear-gradient(10deg, white 40%, #F8F9FA 40%)',
    transform: `rotate(${-10}deg)`,
    position: 'absolute',
    top: 0,
    left: 0,
    [theme.breakpoints.down("sm")]: {
      top: 200,
    },
  },
  pricingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: -150,
    [theme.breakpoints.down("lg")]: {
      marginTop: '-14%',
    },
    [theme.breakpoints.down("md")]: {
      marginTop: '-12%',
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    }
  },
  pricingTitle: {
    fontSize: 42,
    fontWeight: 500,
    fontFamily: "freight-text-pro !important",
    color: theme.palette.text.primary,
    lineHeight: 1.125,
    paddingBottom: 25,
    textAlign: 'center'
  },
  pricingSubtitle: {
    fontSize: 28,
    fontWeight: 500,
    color: '#00b7b0',
    fontFamily: "azo-sans-web !important",
  },
  pricingDivider: {
    marginLeft: 18,
    marginRight: 18,
  },
  pricingLink: {
    color: '#00b7b0 !important',
  },
  pricingCardWrapper: {
    display: 'flex',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
    }
  },
  pricingCardPrimary: {
    width: 422,
    background: 'transparent linear-gradient(40deg, #00B7B0 0%, #6AE1C6 100%) 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 50px #8898AA59',
    zIndex: 10,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      margin: '0 0 1.25rem 0 !important',
    }
  },
  pricingCardSecondary: {
    width: 384,
    boxShadow: '0px 0px 32px #8898AA26',
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 6,
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      margin: '0 0 1.25rem 0 !important',
    }
  },
  pricingCardHeader: {
    width: '100%',
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pricingCardHeaderText: {
    textAlign: 'center',
    font: 'Azo Sans',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 1.5,
  },
  pricingCardContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  pricingCardBody: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pricingCardFooter: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pricingCardTitleContainer: {
    padding: '2rem 2rem 0.25rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pricingCardTitle: {
    textAlign: 'center',
    font: 'Azo Sans',
    fontWeight: 600,
    letterSpacing: 0,
    fontSize: '3rem',
    lineHeight: '2.5rem',
  },
  pricingCardSubtitle: {
    font: 'Azo Sans',
    fontWeight: 600,
    letterSpacing: 0,
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
  },
  pricingCardTitleIcon: {
    height: '6rem',
    width: '6rem',
    color: '#2E3D44',
  },
  pricingCardRecurringText: {
    textAlign: 'center',
    font: 'Azo Sans',
    fontWeight: 500,
  },
  pricingCardAmountContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  pricingCardChecklistWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '2.5rem 1.5rem',
  },
  pricingCardDescription: {
    font: 'Azo Sans',
    fontWeight: 500,
  },
  pricingCardChecklistContainer: {},
  pricingCardChecklist: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '0.5rem',
  },
  pricingCardChecklistIcon: {
    width: "1.25rem",
    height: "100%",
    marginRight: 15,
  },
  pricingCardChecklistText: {
    textAlign: 'left',
    font: 'Azo Sans',
    letterSpacing: 0,
    color: '#2E3D44',
  },
  pricingCardBtnContainer: {
    paddingBottom: '4rem',
  },
  pricingCardBtn: {
    border: '2px solid #FF664D',
    borderRadius: 7,
    "&:hover": {
      backgroundColor: '#fff'
    },
  },
  pricingCardBtnText: {
    font: 'Azo Sans',
    fontWeight: 600,
    color: '#FF664D',
  },
});



export default componentStyles;




