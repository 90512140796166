const componentStyles = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 1200,
  },
  boxImg: {
    [theme.breakpoints.up('sm')]: {
      transform: 'translate(9%, -29%) scale(1.10)',
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'none',
      marginTop: '-30%',
    }
  },
  copy: {
    marginLeft: '-10%',
    marginBottom: 0,
    lineHeight: 1.125,
    [theme.breakpoints.up('sm')]: {
      marginTop: 55,
      marginLeft: '-10%',
      maxWidth: 667,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10%',
      marginTop: '-15%',
      textAlign: 'center',
      marginLeft: 0,
      maxWidth: false
    }
  },
  btnWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  btn: {
    marginTop: 32,
    marginLeft: '-10%',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    }
  },
})

export default componentStyles
