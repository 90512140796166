import React, { useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Headers/Header.js";
import {Box, Container} from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 475,
  },
  notice: {
    color: 'rgb(114, 125, 131)',
  },
});

const Numbering = () => {
  const classes = useStyles();

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': 'Numbering'
    })
  },[])

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        classes={{ root: classes.container }}
      >
        <div className={classes.notice}>Customizable number sequences coming soon!</div>
      </Container>

    </>
  );
};

export default Numbering;
