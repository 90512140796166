import React, {useEffect, useRef, useState} from 'react'
import CardStats from "../../../components/Cards/CardStats"
import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined"
import Box from "@material-ui/core/Box"
import ArrowUpward from "@material-ui/icons/ArrowUpward"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Grid from "@material-ui/core/Grid"
import { useTheme } from "@material-ui/core/styles"
import {makeStyles} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {NEW_ITEMS} from "../../../queries";
import {isDarkMode, NotionColorsDark, NotionColorsLight} from '../../styles'
import { useHistory } from 'react-router-dom'
import themeColors from "../../../assets/theme/colors";

const useStyles = makeStyles(({ renderOnSite }) => ({
  cardStats: {
    backgroundColor: isDarkMode() && !renderOnSite ? 'rgba(32, 32, 32)' : undefined,
  },
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: isDarkMode() && !renderOnSite ? 'rgb(25, 25, 25)' : 'white',
    padding: renderOnSite ? 7 : 0,
    borderRadius: renderOnSite ? '0.375rem' : 0,
  },
  copyIcon: {
    marginLeft: 10,
    display: 'inline',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: 1,
    marginBottom: 5,
    height: 'calc(100% - 5px)',
    padding: 7,
    backgroundColor: isDarkMode() && !renderOnSite ? 'rgba(255, 255, 255, 0.03)' : undefined
  },
  iconWrapper: {
    position: 'absolute',
    bottom: 10,
    right: 14,
  },
  iconWrapperEmbed: {
    position: 'absolute',
    top: 10,
    right: 14,
  },
  upgradePlan: {
    cursor: 'pointer',
    "&:hover": {
      textDecoration: 'underline',
      color: themeColors.secondary.btnActive,
    },
  }
}))

const NewItemsStat = ({ location, projectNumber: _projectNumber }) => {
  let pathname
  if (location) pathname = location.pathname
  let renderOnSite = !pathname
  const projectNumber = useRef()
  const history = useHistory()
  const theme = useTheme()
  theme.renderOnSite = renderOnSite
  const classes = useStyles(theme)

  const [showCopyButton, setShowCopyButton] = useState(true)
  const [wrap, setWrap] = useState(false)
  const [refetching, setRefetching] = useState(false)

  useEffect(() => {
    if (_projectNumber?.length > 0) {
      projectNumber.current = _projectNumber
      refetch({
        projectNumber: projectNumber.current
      })
    }
    else if (pathname && pathname.length > 0) {
      setShowCopyButton(false)
      const parts = pathname.split('/')
      projectNumber.current = parts[parts.length-1]
      refetch({
        projectNumber: projectNumber.current
      })
    }

    if (!renderOnSite) {
      gtag('event', 'page_view', {
        'page_location': window.location.href,
        'page_title': 'New Items Stat Embed'
      })
    }
  }, [])

  const { error, data, refetch, loading: fetching } = useQuery(NEW_ITEMS, {
    variables: {
      projectNumber: _projectNumber || projectNumber.current,
    },
    skip: !_projectNumber && !projectNumber.current,
    notifyOnNetworkStatusChange: true,
  })

  const onRefresh = async () => {
    setRefetching(true)
    try {
      await refetch()
      setRefetching(false)
    } catch(e) {
      setRefetching(false)
    }
  }


  let current = data?.newItems?.current || 0
  const change = data?.newItems?.change
  const showIcon = !(pathname && pathname.length > 0)
  const loading = !data && fetching && !error

  let originCode, originMessage
  let notice = false

  if (error) {
    const gqlError = error.graphQLErrors[0]
    originCode = gqlError?.extensions?.code
    originMessage = gqlError?.message

    if (!originCode) {
      originCode = gqlError?.name ? gqlError?.name : gqlError?.prototype?.name
    }

    if (originCode === 'UPGRADE_PLAN') {
      current = null
      const path = gqlError?.extensions?.exception?.upgradePlanUrl
      notice = <a className={classes.upgradePlan} onClick={() => {
        if (renderOnSite) {
          history.push(path)
        } else {
          window.location.replace(path)
        }
      }}>{originMessage}</a>
    }
  }

  return (
    <Grid item xl={3} lg={6} xs={12}>
      <CardStats
        subtitle="NEW ITEMS"
        title={current?.toString() || ''}
        cardRoot={pathname && classes.cardStats}
        icon='ni ni-align-left-2'
        color={isDarkMode() && !renderOnSite ? NotionColorsDark.orange : NotionColorsLight.orange }
        showButtons={true}
        showCopyButton={showCopyButton}
        onRefresh={onRefresh}
        projectNumber={projectNumber.current}
        showIcon={showIcon}
        loading={loading}
        refetching={refetching}
        onWrapChange={setWrap}
        type={'new-items-stat'}
        footer={
          <>
            {notice ? <Box>{notice}</Box> : current === null && !refetching && !loading ?
              <Box >{'Data unavailable'}</Box>
              : ''}
            <Box
              style={{ opacity: loading ? 0 : 1 }}
              component="span"
              fontSize=".875rem"
              color={change && change > 0 ? theme.palette.success.main : theme.palette.primary.dark}
              marginRight={wrap || showIcon ? '0.5rem' : 0}
              display="flex"
              alignItems="center"
            >
              <Box
                component={!change ? null : change > 0 ? ArrowUpward : ArrowDownward}
                width="1.5rem!important"
                height="1.5rem!important"
              />
              { !change ? '' : `${change}%`}
            </Box>
            <Box component="span" whiteSpace="nowrap" style={{ opacity: loading ? 0 : 1, marginRight: pathname ? 0 : 38}}>
              {!change ? null : 'Since last week'}
            </Box>
          </>
        }
      />
    </Grid>
  )
}

export default NewItemsStat
