export const selectNewProjectActiveStep = state => {
  return state.newProjectSetup.activeStep
}

export const selectNewProject = state => {
  return state.newProjectSetup.project
}

export const selectNewProjectOrganization = state => {
  return state.newProjectSetup.organization
}

export const selectNewProjectSprintDatabase = state => {
  return state.newProjectSetup.sprintDatabase
}

export const selectNewProjectSprintProperties = state => {
  return state.newProjectSetup.sprintProperties
}

export const selectNewProjectReqDatabase = state => {
  return state.newProjectSetup.reqDatabase
}

export const selectNewProjectReqProperties = state => {
  return state.newProjectSetup.reqProperties
}

export const selectNewProjectReqStatuses = state => {
  return state.newProjectSetup.reqStatuses
}

export const selectNewProjectReqTypes = state => {
  return state.newProjectSetup.reqTypes
}
