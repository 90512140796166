import React, { useState } from "react";
import {useLocation, useHistory} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SimpleNavbar from "components/Navbars/SimpleNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import componentStyles from "assets/theme/layouts/auth.js";
import NewOrgStepper from "../views/setup/NewOrgStepper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SetupHeader from "../components/Headers/SetupHeader";
import {useQuery} from "@apollo/client";
import {ME} from "../queries";
import {useDispatch, useSelector} from 'react-redux'
import {setUserData} from '../views/auth/actions'
import {
  setAuthorization,
} from '../views/setup/actions'

import equal from 'deep-equal'
import { initFirstProjectSetupData} from '../utils/projectSetup'

const useStyles = makeStyles(componentStyles);

const Setup = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)

  const user = useSelector(state => state.auth?.user)

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src="//js.hs-scripts.com/21375735.js";
    script.async = true;

    document.body.appendChild(script);
  }, [])

  useQuery(ME, {
    notifyOnNetworkStatusChange: true,
    pollInterval: 5000,
    onCompleted: (data) => {
      if (!data) {
        return
      }
      const me = data.me
      if (me) {
        let firstName = ''
        if (me.name && me.name.length) {
          firstName = me.name.split(' ')[0]
        }
        dispatch(setUserData(me))
        const firstOrganization = me.organizations?.length && me.organizations[0]
        const firstAuthorization = firstOrganization?.notionAuthorizations?.length && firstOrganization.notionAuthorizations[0]
        const firstProject = firstAuthorization.projects?.length && firstAuthorization.projects[0]
        const sprintTable = firstProject?.sprintTable
        const requirementTable = firstProject?.requirementTable
        dispatch(setAuthorization(firstAuthorization))
        if (firstLoad) {
          initFirstProjectSetupData({
            ...firstProject,
            sprintTable,
            requirementTable,
            organization: firstOrganization
          }, dispatch)
        }
        setName(firstName)
      } else {
        history.push('/auth/login')
      }
      setFirstLoad(false)
    },
    onError: (e) => {
      if(e.toString().includes('Invalid login token')) {
        localStorage.removeItem('loginToken')
        history.push('/auth/login', { prevPath: location.pathname })
      }
    }
  })

  const classes = useStyles();
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add(classes.bgDefault)
    const loginToken = localStorage.getItem('loginToken')
    if (!loginToken) history.push('/auth/login')
    return () => {
      document.body.classList.remove(classes.bgDefault)
    };
  },[]);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <SimpleNavbar hideLoginButtons={true} />
        <SetupHeader name={name} />
        {/* Page content */}
        <Container
          component={Box}
          maxWidth="xl"
          marginTop="-8rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Box component={Grid} container justifyContent="center">
            <NewOrgStepper {...props} />
          </Box>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Setup;
