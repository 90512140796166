import React from 'react'
import Box from '@material-ui/core/Box'
import componentStyles from '../../../assets/theme/views/product/home'
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(componentStyles)

const AngledBackground = () => {
  const classes = { ...useStyles() }
  return (
    <Box className={classes.angleContainer}>
      <Box className={classes.angle} />
      <Box className={classes.box}/>
    </Box>
  );
};

export default AngledBackground