import React, {useEffect, useRef, useState} from 'react'
import {useApolloClient, useQuery} from '@apollo/client'
import { BURNDOWN, PROJECT_SPRINTS } from '../../../queries'
import { ResponsiveLine } from '@nivo/line'
import { Theme, isDarkMode } from '../../styles'
import './styles.css'
import {
  Typography,
  Card,
  CardContent,
  Box,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  Grid, CircularProgress
} from '@material-ui/core'
import CopyEmbedUrlButton from '../../../components/Buttons/CopyEmbedUrlButton'
import RefreshButton from "../../../components/Buttons/RefreshButton";
import Button from "@material-ui/core/Button";
import {setActiveModal} from "../../../components/Modals/actions";
import { useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";

const Areas = ({
                 series, areaGenerator, xScale, yScale,
               }) => series.map(({ id, data, color }) => id === 'Remaining work' ? (
  <path
    key={id}
    d={areaGenerator(
      data.map(d => d.data.y !== null ? ({
        x: xScale(d.data.x),
        y: yScale(d.data.y),
      }) : null).filter(d => d),
    )}
    fill={color}
    fillOpacity={0.2}
  />
): null);

const useStyles = makeStyles(({ renderOnSite }) => ({
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: isDarkMode() && !renderOnSite ? 'rgb(25, 25, 25)' : 'white',
    padding: renderOnSite ? 7 : 0,
    borderRadius: renderOnSite ? '0.375rem' : 0,
  },
  chartWrapper: {
    position: 'absolute',
    overflow: 'hidden',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  card: {
    margin: 1,
    marginBottom: 5,
    height: 'calc(100% - 5px)',
    padding: 7,
    backgroundColor: isDarkMode() && !renderOnSite ? 'rgba(255, 255, 255, 0.03)' : undefined
  },
  button: {
    display: 'block',
  },
  formWrapper: {
    display: 'flex',
    width: 'fit-content',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    flexDirection: 'row',
    marginLeft: 5,
    zIndex: 5,
  },
  select: {
    marginRight: 10,
    '& .MuiSelect-root': {
      borderStyle: 'none'
    }
  },
  textUppercase: {
    padding: 5,
    textTransform: "uppercase",
  },
  textUppercaseRight: {
    padding: 5,
    paddingBottom: 0,
    width: '100%',
    textAlign: 'right',
    textTransform: "uppercase",
  },
  refetchProgressOverlay: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
  error: {
    color: '#727d83',
    fontSize: 12,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    flex: 'auto',
    flexDirection: 'column',
  },
  iconWrapper: {
    padding: 5,
    zIndex: 1000,
  },
  copyButton: {
    zIndex: 1000,
    position: 'absolute',
    bottom: 10,
    right: 14,
  },
  upgradePlanContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  viewBtn: {
    margin: '0 auto',
    marginTop: '2em'
  }
}))

export const Burndown = ({ location, projectNumber: _projectNumber }) => {
  let pathname
  if (location) pathname = location.pathname
  let renderOnSite = !pathname

  const history = useHistory()

  const projectNumber = useRef()
  const currentSprint = useRef(true)
  const sprintName = useRef()

  const theme = useTheme()
  theme.renderOnSite = renderOnSite
  const classes = useStyles(theme)

  const client = useApolloClient()

  const [burndownData, setBurndownData] = useState(null)
  const [byPoints, setByPoints] = useState(true)
  const [selectedSprint, setSelectedSprint] = useState(null)
  const [sprints, setSprints] = useState([])
  const [newSprintSelected, setNewSprintSelected] = useState(false)
  const [currentSprintExists, setCurrentSprintExists] = useState(false)
  const [showCopyButton, setShowCopyButton] = useState(true)
  const [refetching, setRefetching] = useState(false)

  const didInit = useRef(false)

  const dispatch = useDispatch()

  useEffect(() => {
    let localSelected = window.localStorage.getItem(`${_projectNumber}-selectedSprint`)
    if(localSelected) {
      localSelected = JSON.parse(localSelected)
      setSelectedSprint(localSelected)
      didInit.current = true
      currentSprint.current = false
    } else {
      currentSprint.current = true
    }

    if (pathname) {
      setShowCopyButton(false)
      const parts = pathname.split('/')
      currentSprint.current = parts[parts.length - 2] === 'burndown'
      if (currentSprint.current) {
        projectNumber.current = parts[parts.length - 1]
        sprintName.current = null
      } else {
        projectNumber.current = parts[parts.length - 2]
        sprintName.current = 'Sprint ' + parts[parts.length - 1]
      }
    }

    if (!renderOnSite) {
      gtag('event', 'page_view', {
        'page_location': window.location.href,
        'page_title': 'Burndown Embed'
      })
    }
  }, [])

  const { loading, error, data, refetch: refetchBurndown } = useQuery(BURNDOWN, {
    variables: {
      projectNumber: _projectNumber || projectNumber.current,
      currentSprint: currentSprint.current,
      sprintName: selectedSprint?.name,
      isEmbed: pathname && pathname.length > 0
    },
    onCompleted: data => {
      setBurndownData(data)
    },
    onError: () => {
      if(data) {
        client.resetStore().catch(e => {
          console.log(e)
        })
      }
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: projectSprintsData, refetch: refetchProjectSprints } = useQuery(PROJECT_SPRINTS, {
    variables: {
      projectNumber: _projectNumber || projectNumber.current,
    },
  })

  const onRefresh = async () => {
    setRefetching(true)
    try {
      await refetchBurndown()
      setRefetching(false)
    } catch(e) {
      setRefetching(false)
    }
  }

  const checkCurrent = () => {
    if(sprints.length > 0 && !currentSprintExists && currentSprint.current) {
      setSelectedSprint({ id: -1 })
    }
  }

  useEffect(() => {
    checkCurrent()
  }, [currentSprintExists, sprints])

  useEffect(() => {
    if(projectSprintsData?.projectSprints?.length > 0) {
      let newSprints = []
      let foundCurrent = false
      projectSprintsData.projectSprints.forEach(sprint => {
        newSprints.push({
          id: sprint.sprintId,
          name: sprint.sprintName,
          isCurrentSprint: sprint.isCurrentSprint,
        })
        if(sprint.isCurrentSprint) {
          foundCurrent = true
        }
      })
      setCurrentSprintExists(foundCurrent)
      setSprints(newSprints)
      if(newSprints.length > 0 && !foundCurrent && currentSprint.current) {
        setSelectedSprint({ id: -1 })
      } else {
        if (!selectedSprint && !didInit.current) {
          if (foundCurrent) {
            setSelectedSprint(newSprints.find(s => s.isCurrentSprint))
          } else {
            setSelectedSprint(newSprints[0])
          }
        } else {
          if (selectedSprint?.id === -1 && !didInit.current) {
            if (foundCurrent) {
              setSelectedSprint(newSprints.find(s => s.isCurrentSprint))
            } else {
              setSelectedSprint(newSprints[0])
            }
          } else if (selectedSprint && !didInit.current) {
            setSelectedSprint(newSprints.find(s => s.id === selectedSprint.id))
          }
        }
      }
    }
  }, [projectSprintsData])

  useEffect(() => {
    if(selectedSprint && selectedSprint.id !== -1) {
      currentSprint.current = selectedSprint.isCurrentSprint
      refetchBurndown({sprintName: selectedSprint.name, currentSprint: selectedSprint.isCurrentSprint })
      if(!selectedSprint.isCurrentSprint) {
        window.localStorage.setItem(`${_projectNumber}-selectedSprint`, JSON.stringify(selectedSprint))
      } else {
        window.localStorage.removeItem(`${_projectNumber}-selectedSprint`)
      }
    }
    if(selectedSprint?.id === -1) {
      window.localStorage.removeItem(`${_projectNumber}-selectedSprint`)
    }
  }, [selectedSprint])

  const chartData = () => {
    if(!burndownData && !data) {
      return []
    }
    const { burndown } = burndownData || data
    const rv = [
      {
        id: 'Ideal burn rate',
        color: '#123456',
        //color: 'grey',
        data: burndown.map(d => ({
          x: d.date,
          y: byPoints ? d.idealPoints : d.idealCount
        }))
      },
      {
        id: 'Remaining work',
        color: '#123456',
        //color: 'orange',
        data: burndown.map((d, index) => ({
          x: d.date,
          y: byPoints ? d.remainingPoints : d.remainingCount
        }))
      },

    ]
    return rv
  }

  const getRemaining = () => {
    if(!burndownData && !data) {
      return 0
    }
    const { burndown } = burndownData || data
    const reversibleBurndown = [
      ...burndown
    ]
    const index = reversibleBurndown.reverse().findIndex(b => b.remainingCount !== null)
    const mostRecent = reversibleBurndown[index]
    if (mostRecent && mostRecent.remainingCount) {
      return byPoints ? mostRecent.remainingPoints : mostRecent.remainingCount
    }
    return 0
  }

  const WrapperComponent = renderOnSite ? React.Fragment : ({ children }) => <Card className={classes.card}>{children}</Card>

  const onOpenSprintSelect = () => {
    refetchProjectSprints()
    setNewSprintSelected(true)
  }

  const retryCurrent = () => {
    currentSprint.current = true
    refetchProjectSprints().then(() => {
      checkCurrent()
    })
  }

  const renderSprintSelector = () => {
    return (
      <FormControl border={0} className={classes.formControl}>
        <Select
          border={0}
          className={classes.select}
          autoWidth={true}
          value={currentSprint.current && !currentSprintExists ? -1 : selectedSprint?.id}
          onOpen={onOpenSprintSelect}
          onChange={event => {
            if(event.target.value === -1) {
              setSelectedSprint({ id: -1 })
              retryCurrent()
            }
            setSelectedSprint(sprints.find(s => s.id === event.target.value))
          }}
        >
          {!currentSprintExists && <MenuItem value={-1}>Current</MenuItem>}
          {sprints.map((s, index) => {
            return <MenuItem key={`sprints-dd-${index}`} value={s.id}>{s.isCurrentSprint ? `Current (${s.name})` : s.name}</MenuItem>
          })}
        </Select>
      </FormControl>
    )
  }

  const noData = burndownData?.burndown?.length <= 0 || data?.burndown?.length <= 0
  let showViewErrorBtn = false
  let originCode, originMessage, title
  let notice = false

  if (error) {
    const gqlError = error.graphQLErrors[0]
    originCode = gqlError?.extensions?.code

    showViewErrorBtn = originCode !== 'UPGRADE_PLAN'

    try {
      const errorNotification = JSON.parse(gqlError?.message)

      notice = errorNotification?.error
      originMessage = errorNotification?.message
      title = errorNotification?.title
    } catch (e) {
      originMessage = gqlError?.message

      if (!originCode) {
        originCode = gqlError?.name ? gqlError?.name : gqlError?.prototype?.name
      }

      if (originCode === 'BAD_USER_INPUT') {
        notice = gqlError.message
      } else if (originCode === 'UPGRADE_PLAN') {
        const path = gqlError?.extensions?.exception?.upgradePlanUrl
        notice = (
          <Box className={classes.upgradePlanContainer}>
            {gqlError.message}
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.viewBtn}
              onClick={() => {
                if (renderOnSite) {
                  history.push(path)
                } else {
                  window.location.replace(path)
                }
              }}
            >
              Upgrade Plan
            </Button>
          </Box>
        )
      } else {
        notice = 'An error occurred while loading the burndown data for this sprint.'
      }
    }
  } else if (noData && selectedSprint?.id !== -1) {
    notice = 'No burndown data exists yet.'
  } else if (selectedSprint?.id === -1) {
    notice = 'No current sprint exists.'
  }

  const showInitialOverlay = (!projectSprintsData && !notice && loading)
  const showTransparentOverlay = (loading && newSprintSelected || refetching)
  return (
    <div className={classes.container}>
      { showInitialOverlay ? <CircularProgress className={classes.refetchProgressOverlay} /> : (
        <WrapperComponent>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Grid container>
              <Grid item xs={6}>
                <div className={classes.header}>
                  <Box
                    component={Typography}
                    variant="h5"
                    marginBottom="0!important"
                    marginTop="0!important"
                    className={classes.textUppercase}
                  >
                    Burndown
                  </Box>
                  <Box className={classes.iconWrapper}>
                    <RefreshButton onClick={onRefresh} />
                    {showCopyButton && <CopyEmbedUrlButton className={classes.copyButton} type={'burndown'} projectNumber={_projectNumber} />}
                  </Box>
                </div>
                <Box className={classes.formWrapper}>
                  {renderSprintSelector()}
                  <FormControl border={0} className={classes.formControl}>
                    <Select
                      border={0}
                      className={classes.select}
                      autoWidth={true}
                      value={byPoints}
                      onChange={event => setByPoints(event.target.value)}
                    >
                      <MenuItem value={true}>Story points</MenuItem>
                      <MenuItem value={false}>Item count</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  component={Typography}
                  variant="h5"
                  marginBottom="0!important"
                  marginTop="0!important"
                  className={classes.textUppercaseRight}
                >
                  Remaining
                </Box>
                <Box
                  component={Typography}
                  variant="h2"
                  fontWeight="600!important"
                  marginBottom="0!important"
                  marginTop="0!important"
                  className={classes.textUppercaseRight}
                >
                  {getRemaining()}
                </Box>
              </Grid>
            </Grid>
            {notice ? (
              <div className={classes.error}>
                {notice}
                {showViewErrorBtn ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.viewBtn}
                    onClick={() => dispatch(setActiveModal({
                      code: originCode,
                      data: {
                        errorCode: originCode,
                        title: title ? title : 'Error Calculating Burndown',
                        message: originMessage,
                        type: 'ERROR',
                      },
                    }))}
                  >
                    View
                  </Button>
                ) : null}
              </div>
            ) : <CardContent>
              <div className={classes.chartWrapper}>
                {showTransparentOverlay && <CircularProgress className={classes.refetchProgressOverlay}/>}
                <ResponsiveLine
                  enableArea={true}
                  colors={[theme.palette.text.secondary, Theme.Primary]}
                  layers={['grid', 'markers', 'axes', Areas, 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
                  theme={{
                    textColor: theme.palette.text.secondary,
                    fontSize: theme.typography.fontSize,
                    axis: {
                      ticks: {
                        line: {
                          stroke: theme.palette.divider,
                          strokeWidth: 1
                        }
                      },
                      domain: {
                        line: {
                          stroke: theme.palette.divider,
                          strokeWidth: 0,
                        }
                      },
                      text: {
                        fontSize: 50,
                      }
                    },
                    grid: {
                      line: {
                        stroke: theme.palette.divider
                      }
                    },
                    labels: {
                      text: {
                        fill: theme.palette.divider,
                        fontSize: 50,
                      }
                    },
                    legends: {
                      text: {
                        fontSize: theme.typography.h6.fontSize,
                      }
                    },
                    tooltip: {
                      container: {
                        background: theme.palette.background.default
                      },
                    }
                  }}
                  data={chartData()}
                  margin={{top: 145, right: 15, bottom: 155, left: 55}}
                  xScale={{ type: 'point' }}
                  yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                  axisTop={null}
                  axisRight={null}
                  markers={selectedSprint?.isCurrentSprint ? [
                    {
                      axis: 'x',
                      value: (new Date()).toLocaleDateString('en-US'),
                      legend: 'Today',
                      legendPosition: 'top-left',
                      legendOrientation: 'vertical',
                      lineStyle: {
                        stroke: theme.palette.text.secondary,
                        strokeDasharray: 10,
                        border: '1px dotted'
                      },
                      textStyle: {
                        fill: theme.palette.text.secondary
                      }
                    }
                  ] : null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 10,
                    tickRotation: -60,
                    legendOffset: 36,
                    legendPosition: 'middle'
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 20,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: 'middle'
                  }}
                  enablePoints={false}
                  enableGridX={false}
                  enableSlices={'x'}
                  pointSize={10}
                  pointColor={{ theme: 'background' }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: 'serieColor' }}
                  pointLabelYOffset={-12}
                  pointLabel={'y'}
                  useMesh={true}
                  enablePointLabel={true}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateY: 120,
                      itemsSpacing: 25,
                      itemWidth: 200,
                      itemHeight: 20,
                      symbolSize: 20,
                      itemTextColor: theme.palette.text.primary,
                      symbolShape: 'circle',
                    }
                  ]}
                />
              </div>
            </CardContent>}
          </div>
        </WrapperComponent>
      )}
    </div>
  )
}
