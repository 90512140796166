import React, { useEffect } from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import componentStyles from "../../../assets/theme/views/auth/register";
import ListItem from '@material-ui/core/ListItem'
import config from '../../../config'

const useStyles = makeStyles(componentStyles)

const NotionTemplateStep = ({ onNextClick, onBackClick, newProject }) => {
  const theme = useTheme()
  const classes = useStyles()

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': newProject ? 'New Project: Notion Template Step' : 'Notion Template Step'
    })
  }, [])

  return (
    <>
      <Box
        color={theme.palette.gray[600]}
        textAlign="center"
        marginBottom="1.5rem"
        marginTop=".5rem"
        fontSize="1rem"
      >
        <Box fontWeight="400" component="small">
          First things first, browse the NotionOps template gallery and add the one you like to your Notion workspace.
        </Box>
        { !newProject ? (
          <Box marginTop={'0.5rem'}>
            <Box fontWeight="400" component="small">
              <ListItem
                component="a"
                href={config.affiliateLink}
                target="_blank"
                classes={{
                  root: classes.textLink,
                }}
              >
                Don't have Notion?
              </ListItem>
            </Box>
          </Box>
        ) : null }
        <Box textAlign="center" marginTop="1.5rem" marginBottom="4rem">
          <Button target={'_blank'} href={'https://oosstudio.notion.site/NotionOps-Template-Gallery-ea3dbc85b8af4802b36c84bb4b7ead71'} color={'primary'} endIcon={<ArrowForwardIcon/>}>
            Template Gallery
          </Button>
        </Box>
      </Box>
      <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
        {onBackClick && <Button onClick={onBackClick} className={classes.backButton}>Back</Button>}
        <Button variant="contained" color="primary" onClick={onNextClick}>
          Next
        </Button>
      </Box>
    </>
  )
}

export default NotionTemplateStep
