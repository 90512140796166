import React, { useState, useEffect } from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import {useHistory, useLocation} from 'react-router-dom'
import {useQuery, useLazyQuery, useMutation} from '@apollo/client'
import { REGISTRATION_TOKEN, ORGANIZATION_DOMAIN } from '../../queries'
import {REGISTER_ORGANIZATION, SIGN_IN, UPDATE_USER_PROFILE} from '../../mutations'
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import Person from '@material-ui/icons/Person'
import Email from '@material-ui/icons/Email'
import Lock from "@material-ui/icons/Lock"
import Domain from '@material-ui/icons/Domain'

// core components
import componentStyles from "assets/theme/views/auth/register.js";
import {Link} from '@mui/material'
import {setUserData} from './actions'
import {useDispatch, useSelector} from 'react-redux'
import {setAuthorization, setOrganization, setProject} from '../setup/actions'
import {CircularProgress} from '@material-ui/core'
import InvitedUserRegisterForm from "./InvitedUserRegisterForm";
import {validateDomain, validateEmail} from "../../utils/inputValidation";

const useStyles = makeStyles(componentStyles);

const passwordStatus = {
  empty: 0,
  match: 1,
  mismatch: 2
}

function Register() {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const token = new URLSearchParams(location.search).get('token')

  const [organizationName, setOrganizationName] = useState('')
  const [domain, setDomain] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [subscribe, setSubscribe] = useState(true)
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [signingIn, setSigningIn] = useState(false)

  //Todo: This will always return userId 1 if token is null. Logically we still detect it as an invalid token below, but we don't want other user information coming back in this network request
  const { data, loading: loadingToken } = useQuery(REGISTRATION_TOKEN, {
    variables: {
      token
    }
  })
  const [getOrganizationByDomain, { data: organizationData }] = useLazyQuery(ORGANIZATION_DOMAIN, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 2000
  })

  const user = data?.registrationToken ? data.registrationToken.user : null
  const isInvitedUser = user?.organizations?.length > 0

  const firstName = user?.name ? user.name.split(' ')[0] : null
  const invalidToken = token && (!data?.registrationToken || (!isInvitedUser && user?.password))
  const domainNotAvailable = !!organizationData?.organization
  const loading = token && loadingToken

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': isInvitedUser ? 'Activate' : 'Register'
    })
  }, [])

  const [registerOrganization] = useMutation(REGISTER_ORGANIZATION, {
    variables: {
      token,
      name: organizationName,
      domain,
      subscribe,
      fullName: name,
      email: token ? user?.email : email,
      newPassword: password,
      confirmNewPassword: confirmPassword
    }
  })
  const [signIn] = useMutation(SIGN_IN, {
    variables: {
      email: token ? user?.email : email,
      password
    },
    onCompleted: async ({ login }) => {
      await localStorage.setItem('loginToken', login.token)
      if (login?.user) {
        if(isInvitedUser) {
          dispatch(setUserData(login.user))
          history.push('/index')
        } else {
          dispatch(setUserData(login.user))
          const firstOrganization = login.user.organizations?.length && login.user.organizations[0]
          const firstAuthorization = firstOrganization?.notionAuthorizations?.length && firstOrganization.notionAuthorizations[0]
          const firstProject = firstAuthorization?.project
          dispatch(setOrganization(firstOrganization))
          dispatch(setAuthorization(firstAuthorization))
          dispatch(setProject(firstProject))
          const nameSplit = name.split(' ')
          let firstName = null
          if(nameSplit.length) {
            firstName = nameSplit[0]
          }
          history.push('/auth/setup-billing', { registeredFirstName: firstName })
        }
      }
      gtag('event', 'login', {
        method: isInvitedUser ? 'NotionOps Activate' : 'NotionOps Register'
      })
    },
    onError: err => {
      console.log(err)
    }
  })

  useEffect(() => {
    if (user?.id) {
      dispatch(setUserData(user))
    }
  }, [user])

  useEffect(() => {
    if (domain !== '' && domain) {
      const debounce = setTimeout(() => {
        getOrganizationByDomain({
          variables: {
            domain: domain.toLowerCase(),
          }
        })
      }, 500)
      return () => clearTimeout(debounce)
    }
  }, [domain])

  const getPasswordStatus = () => {
    if ((password === '' || !password) && (confirmPassword === '' || !confirmPassword)) {
      return passwordStatus.empty
    }
    if (password !== '' && password && (confirmPassword === '' || !confirmPassword)) {
      return passwordStatus.empty
    }
    if (password !== confirmPassword) {
      return passwordStatus.mismatch
    }
    return passwordStatus.match
  }

  const pStatus = getPasswordStatus()

  const domainValid = validateDomain(domain)

  const emailValid = validateEmail(email)

  const formValid = () => {
    return (
      pStatus === passwordStatus.match &&
      organizationName !== '' && domain !== '' && organizationName && domain && agreeToTerms && !domainNotAvailable && domainValid && (token || (
        emailValid
        && name !== ''
        && name
      ))
    )
  }

  const submit = async () => {
    registerOrganization().then(({ registerOrganization }) => {
      signIn()
      rdt('track', 'SignUp') //reddit tracking func
      window.lintrk('track', { conversion_id: 8119041 }) //LinkedIn tracking
      fbq('track', 'CompleteRegistration') //Facebook tracking
      qp('track', 'CompleteRegistration', { email })  //Quora tracking
      gtag_report_conversion() //Google Pixel tracking
      gtag('event', 'sign_up', {
        method: 'NotionOps',
      })
      gtag('event', 'join_group', {
        group_id: registerOrganization?.domain
      })
      gtag('event', 'generate_lead', {
        currency: 'USD',
        value: subscribe ? '65.00' : '50.00'
        // value = $4.5 x 5 (avg usrs) x 12 (anum revenue) x 20% (expected conv. rate) OR x 25% (expected conv. rate of subscribed accounts)
      })
    }).catch(e => {
      if (e?.graphQLErrors?.length && e.graphQLErrors[0].extensions.exception.code === 'P2002') {
        setErrorMessage('Sorry, this domain is no longer available. Please enter something else.')
      } else if (e.message) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('Something went wrong')
      }
    })
  }

  return (
    <>
      <Grid item xs={12} lg={6} md={8}>
        <Card classes={{ root: classes.cardRoot }}>
          { loading ?
            <CardContent classes={{root: classes.cardContent}}>
              <Box
                textAlign={'center'}
                marginBottom={'1.5rem'}
              >
                <CircularProgress />
              </Box>
            </CardContent>
            :
            invalidToken ?
            <CardContent classes={{root: classes.cardContent}}>
              <Box
                color={theme.palette.gray[600]}
                textAlign="center"
                marginBottom="1.5rem"
                marginTop=".5rem"
                fontSize="1rem"
              >
                <Box fontSize="80%" fontWeight="400" component="small">
                  We're sorry, this link is no longer valid.
                </Box>
              </Box>
            </CardContent>
            :
              isInvitedUser ? signingIn ?
                  <CardContent classes={{root: classes.cardContent}}>
                    <Box
                      color={theme.palette.gray[600]}
                      textAlign="center"
                      marginBottom="1.5rem"
                      marginTop=".5rem"
                      fontSize="1rem"
                    >
                      <CircularProgress />
                    </Box>
                  </CardContent> :
                  <InvitedUserRegisterForm user={user} setSigningIn={setSigningIn} signIn={signIn} /> :
            <CardContent classes={{root: classes.cardContent}}>
              <Box
                color={theme.palette.gray[600]}
                textAlign="center"
                marginBottom="1.5rem"
                marginTop=".5rem"
                fontSize="1rem"
              >
                <Box fontSize="80%" fontWeight="400" component="small">
                  {token ? `${firstName ? `Hello ${firstName}! ` : ''}Enter your information below to get started with your account.`
                    : 'Enter your information below to create your account.'
                  }
                </Box>
              </Box>
              <FormControl
                variant="filled"
                component={Box}
                width="100%"
                marginBottom="1.5rem!important"
              >
                <FilledInput
                  value={organizationName}
                  onChange={o => setOrganizationName(o.target.value)}
                  autoComplete="off"
                  type="text"
                  placeholder="Organization Name*"
                  startAdornment={
                    <InputAdornment position="start">
                      <Domain/>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl
                value={domain}
                onChange={o => setDomain(o.target.value)}
                variant="filled"
                component={Box}
                error={domainNotAvailable}
                width="100%"
                marginBottom="1.5rem!important"
              >
                <FilledInput
                  autoComplete="off"
                  type="text"
                  placeholder="Domain*"
                  startAdornment={
                    <InputAdornment position="start">
                      <Domain/>
                    </InputAdornment>
                  }
                />
                <Box marginTop={'0.5rem'} color={theme.palette.gray[600]} fontSize="80%" fontWeight="400"
                     component="small">
                  *Your domain will be how you access your organization's admin site and can only contain lowercase
                  letters and hyphens. For example: my-domain
                </Box>
                <Box
                  fontStyle="italic"
                  fontSize="1rem"
                  color={theme.palette.gray[600]}
                  marginBottom=".5rem"
                  classes={{root: domain === '' || !domain ? classes.hiddenFormMessage : classes.visibleFormMessage}}
                >
                  <Box component="small" fontSize="80%">
                    domain is{" "}
                    <Box
                      component="span"
                      fontWeight="700"
                      color={domainNotAvailable || !domainValid ? theme.palette.error.main : theme.palette.success.main}
                    >
                      {domainNotAvailable ? 'not available' : domainValid ? 'available' : 'not valid'}
                    </Box>
                  </Box>
                </Box>
              </FormControl>
              { !token ?
                <>
                  <FormControl
                    value={name}
                    onChange={o => setName(o.target.value)}
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1.5rem!important"
                  >
                    <FilledInput
                      autoComplete="off"
                      type="text"
                      placeholder="Your Full Name*"
                      startAdornment={
                        <InputAdornment position="start">
                          <Person/>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl
                    value={email}
                    onChange={o => setEmail(o.target.value)}
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1.5rem!important"
                  >
                    <FilledInput
                      autoComplete="off"
                      type="email"
                      placeholder="Email*"
                      startAdornment={
                        <InputAdornment position="start">
                          <Email/>
                        </InputAdornment>
                      }
                    />
                    <Box
                      fontStyle="italic"
                      fontSize="1rem"
                      color={theme.palette.gray[600]}
                      classes={{root: email === '' || !email ? classes.hiddenFormMessage : classes.visibleFormMessage}}
                    >
                      <Box component="small" fontSize="80%">
                        email is{" "}
                        <Box
                          component="span"
                          fontWeight="700"
                          color={!emailValid ? theme.palette.error.main : theme.palette.success.main}
                        >
                          {!emailValid ? 'not valid' : 'valid'}
                        </Box>
                      </Box>
                    </Box>
                  </FormControl>
                </>
                : null
              }
              <FormControl
                value={password}
                onChange={o => setPassword(o.target.value)}
                variant="filled"
                component={Box}
                width="100%"
                marginBottom="1.5rem!important"
              >
                <FilledInput
                  autoComplete="off"
                  type="password"
                  placeholder="Password*"
                  startAdornment={
                    <InputAdornment position="start">
                      <Lock/>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl
                value={confirmPassword}
                onChange={o => setConfirmPassword(o.target.value)}
                variant="filled"
                component={Box}
                width="100%"
                marginBottom="1.5rem!important"
              >
                <FilledInput
                  autoComplete="off"
                  type="password"
                  placeholder="Confirm Password*"
                  startAdornment={
                    <InputAdornment position="start">
                      <Lock/>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box
                fontStyle="italic"
                fontSize="1rem"
                color={theme.palette.gray[600]}
                marginBottom=".5rem"
                classes={{root: pStatus === passwordStatus.empty ? classes.hiddenFormMessage : classes.visibleFormMessage}}
              >
                <Box component="small" fontSize="80%">
                  passwords{" "}
                  <Box
                    component="span"
                    fontWeight="700"
                    color={pStatus === passwordStatus.match ? theme.palette.success.main : theme.palette.error.main}
                  >
                    {pStatus === passwordStatus.match ? 'match' : `don't match`}
                  </Box>
                </Box>
              </Box>
              { !token ?
                <FormControlLabel
                  control={<Checkbox checked={subscribe} onChange={o => {
                    setSubscribe(o.target.checked)
                  }}
                                     color="primary"/>}
                  label={'Keep me informed with free resources and news updates.'}
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.formControlLabelLabel,
                  }}
                />
                : null
              }
              <FormControlLabel
                control={<Checkbox checked={agreeToTerms} onChange={o => setAgreeToTerms(o.target.checked)}
                                   color="primary"/>}
                label={
                  <>
                    I agree with the{" "}
                    <Box
                      color={theme.palette.primary.main}
                      component="a"
                      href={'https://oosstudio.notion.site/Terms-and-Privacy-b497fbb6a418424aa5e39e60070b3fcd'}
                      target={'_blank'}
                      textDecoration="none"
                    >
                      Terms and Privacy
                    </Box>
                  </>
                }
                labelPlacement="end"
                classes={{
                  root: classes.formControlLabelRoot,
                  label: classes.formControlLabelLabel,
                }}
              />
              {errorMessage ? <Box
                component="span"
                fontWeight="700"
                color={theme.palette.error.main}
              >
                {errorMessage}
              </Box> : null}
              <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                <Button disabled={!formValid() || invalidToken} onClick={submit} color="primary" variant="contained">
                  Create account
                </Button>
              </Box>
            </CardContent>
          }
        </Card>
      </Grid>
    </>
  );
}

export default Register;
