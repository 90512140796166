import React from "react";
import {useLocation, Route, Switch, Redirect, useHistory} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// core components
import SimpleNavbar from "../components/Navbars/SimpleNavbar";
import UpgradePlanHeader from "components/Headers/UpgradePlanHeader"
import UpgradePlanFooter from "components/Footers/UpgradePlanFooter"

import { upgradePlan } from "routes.js";

import componentStyles from "assets/theme/layouts/product.js";
import {useQuery} from '@apollo/client'
import {ME} from '../queries'
import equal from 'deep-equal'
import { setUserData } from '../views/auth/actions'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles(componentStyles);

const UpgradePlan = () => {
  const classes = useStyles();
  const mainContent = React.useRef(null);
  const location = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth?.user)

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src="//js.hs-scripts.com/21375735.js";
    script.async = true;

    document.body.appendChild(script);
  }, [])

  React.useEffect(() => {
    document.body.classList.add(classes.bgDefault);
    return () => {
      document.body.classList.remove(classes.bgDefault);
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (upgradePlan) => {
    return upgradePlan.map((prop, key) => {
      if (prop.layout === "/upgrade-plan") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useQuery(ME, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data) {
        return
      }
      const me = data.me
      if (!equal(me, user)) {
        dispatch(setUserData(me))
      }
      if (!(!!me.projects.length)) {
        history.push('/setup/1')
      }
    },
    onError: (e) => {
      if(e.toString().includes('Invalid login token')) {
        localStorage.removeItem('loginToken')
        history.push('/auth/login', { prevPath: location.pathname + location.search })
      }
    },
  })

  return (
    <>
      <div className={`main-content ${classes.container}`} ref={mainContent}>
        <UpgradePlanHeader />
        {/* Page content */}
        <Container
          component={Box}
          maxWidth={false}
          marginTop="-8rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Box justifyContent={'center'} component={Grid} container>
            <Switch>
              {getRoutes(upgradePlan)}
              <Redirect from="*" to={'/upgrade-plan'} />
            </Switch>
          </Box>
        </Container>
      </div>
      <UpgradePlanFooter />
    </>
  );
};

export default UpgradePlan
