import React, { useState, useEffect } from 'react';

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleChange = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("change", handleChange);

        handleChange();

        return () => window.removeEventListener("change", handleChange);

    }, [])
    return windowSize;
}

export default useWindowSize;
