const componentStyles = (theme) => ({
  cardRoot: {
    marginBottom: "1.5rem",
    [theme.breakpoints.up("xl")]: {
    //  marginBottom: 0,
    },
  },
  cardContentRoot: {
    padding: "1rem 1.5rem !important",
    position: 'relative',
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: 10,
  },
  copyButton: {
    zIndex: 1000,
    position: 'absolute',
    bottom: 10,
    right: 14,
  },
  buttons: {
    display: 'flex',
    paddingLeft: 5,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: '0.375rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  /*bgPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  bgPrimaryLight: {
    backgroundColor: theme.palette.primary.light,
  },
  bgError: {
    backgroundColor: theme.palette.error.main,
  },
  bgErrorLight: {
    backgroundColor: theme.palette.error.light,
  },
  bgWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  bgWarningLight: {
    backgroundColor: theme.palette.warning.light,
  },
  bgInfo: {
    backgroundColor: theme.palette.info.main,
  },
  bgInfoLight: {
    backgroundColor: theme.palette.info.light,
  },*/
});

export default componentStyles;
