import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Headers/Header.js";
import {Box, Container} from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 475,
  },
  notice: {
    color: 'rgb(114, 125, 131)',
  },
});

const Icons = () => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        classes={{ root: classes.container }}
      >
        <div className={classes.notice}>Setup custom icons for your requirements. Coming soon!</div>
      </Container>

    </>
  );
};

export default Icons;
