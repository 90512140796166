export const SET_FIRST_ORGANIZATION = 'SET_FIRST_ORGANIZATION'
export const SET_FIRST_AUTHORIZATION = 'SET_FIRST_AUTHORIZATION'
export const SET_FIRST_PROJECT = 'SET_FIRST_PROJECT'
export const SET_FETCHING_SETUP_DATA = 'SET_FETCHING_SETUP_DATA'
export const SET_SETUP_STEP = 'SET_SETUP_STEP'
export const SET_FIRST_SPRINT_DATABASE = 'SET_FIRST_SPRINT_DATABASE'
export const SET_FIRST_SPRINT_PROPERTIES = 'SET_FIRST_SPRINT_PROPERTIES'
export const SET_FIRST_REQUIREMENT_DATABASE = 'SET_FIRST_REQUIREMENT_DATABASE'
export const SET_FIRST_REQUIREMENT_PROPERTIES = 'SET_FIRST_REQUIREMENT_PROPERTIES'
export const SET_FIRST_REQUIREMENT_STATUSES = 'SET_FIRST_REQUIREMENT_STATUSES'
export const SET_FIRST_REQUIREMENT_TYPES = 'SET_FIRST_REQUIREMENT_TYPES'

export const fetching = value => {
  return {
    type: SET_FETCHING_SETUP_DATA,
    payload: value
  }
}

export const setStep = step => {
  return {
    type: SET_SETUP_STEP,
    payload: step
  }
}

export const setOrganization = organization => {
  return {
    type: SET_FIRST_ORGANIZATION,
    payload: organization
  }
}

export const setAuthorization = authorization => {
  return {
    type: SET_FIRST_AUTHORIZATION,
    payload: authorization
  }
}

export const setProject = project => {
  return {
    type: SET_FIRST_PROJECT,
    payload: project
  }
}

export const setSprintDatabase = database => {
  return {
    type: SET_FIRST_SPRINT_DATABASE,
    payload: database
  }
}

export const setSprintProperties = properties => {
  return {
    type: SET_FIRST_SPRINT_PROPERTIES,
    payload: properties
  }
}

export const setRequirementDatabase = database => {
  return {
    type: SET_FIRST_REQUIREMENT_DATABASE,
    payload: database
  }
}

export const setRequirementProperties = properties => {
  return {
    type: SET_FIRST_REQUIREMENT_PROPERTIES,
    payload: properties
  }
}

export const setRequirementStatuses = statuses => {
  return {
    type: SET_FIRST_REQUIREMENT_STATUSES,
    payload: statuses
  }
}

export const setRequirementTypes = types => {
  return {
    type: SET_FIRST_REQUIREMENT_TYPES,
    payload: types
  }
}
