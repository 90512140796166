import React from 'react'
import { makeStyles } from '@material-ui/core'
import RefreshIcon from '../../assets/img/icons/common/Refresh_Button.svg'
const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    display: 'inline',
    zIndex: 1000,
  },
  refreshIcon: {
    width: 14,
    height: 14,
  },
  refreshIconEmbed: {
    width: 14,
    height: 14,
  }
})

const RefreshButton = ({ showIcon, onClick = () => {} }) => {
  const classes = useStyles()
  return (
    <div className={classes.container} onClick={() => onClick()}>
      <img className={showIcon ? classes.refreshIconEmbed : classes.refreshIcon} src={RefreshIcon} />
    </div>

  )
}

export default RefreshButton
