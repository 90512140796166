const ACTIVE_PLANS = ['INCOMPLETE', 'TRIALING', 'ACTIVE', 'PAST_DUE']

export const getPlan = (user) => {
  const plan = user?.lastOpenedProject?.organization?.plan

  if(plan?.status && ACTIVE_PLANS.includes(plan.status)) {
    return plan.type
  }

  return null
}
