import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import { Box, Typography } from '@material-ui/core'
import NotionOpsBox from '../../../assets/img/product/NotionOps_Package_Photo_NoBackground@2x.png'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import componentStyles from '../../../assets/theme/views/product/home'
import simpleCallToActionStyles from '../../../assets/theme/views/product/simple-call-to-action'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(componentStyles)
const useSimpleCallToActionStyles = makeStyles(simpleCallToActionStyles)

const SimpleCallToAction = () => {
  const classes = { ...useStyles(), ...useSimpleCallToActionStyles() }
  const history = useHistory()

  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Box
            component="img"
            alt="..."
            maxWidth={'100%'}
            height="auto"
            className={classes.boxImg}
            src={NotionOpsBox}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Box>
            <Typography className={[classes.sectionHeader, classes.copy].join(' ')}>
              Start tracking your team's performance like never before.
            </Typography>
          </Box>
          <Box className={classes.btnWrapper}>
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/auth/register')
              }}
            >
              Get started for free
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SimpleCallToAction
