import React, { useState } from 'react'
import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import usageValidationStyles from '../../../assets/theme/views/product/usage-validation'
import componentStyles from '../../../assets/theme/views/product/home'
import { Check } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { PRODUCT_OPTIONS } from '../../../queries'

const useStyles = makeStyles(componentStyles)
const useUsageValidationStyles = makeStyles(usageValidationStyles)

const PricingInfo = () => {
  const classes = {...useStyles(), ...useUsageValidationStyles()}
  const history = useHistory()

  const [productOptions, setProductOptions] = useState([])

  useQuery(PRODUCT_OPTIONS, {
    pollInterval: 30000,
    onCompleted: ({ productOptions }) => {
      if (productOptions && productOptions?.length) {
        const options = []
        productOptions.map(option => {
          try {
            options.push(JSON.parse(option))
          } catch (e) {
            console.log(e)
          }
        })
        setProductOptions(options)
      }
    },
    onError: e => console.log(e)
  })

  const standard = productOptions?.find(option => option.name === 'Standard')
  const notionTemplate = productOptions?.find(option => option.name === 'Notion Template')
  const enterprise = productOptions?.find(option => option.name === 'Enterprise')

  const renderCtaBtn = (option, primary) => {
    if (option?.metadata?.call_to_action) {
      return (
        <Grid className={classes.pricingCardBtnContainer}>
          <Button
            onClick={() => {
              if (option?.metadata?.path_when_clicked) {
                history.push(option?.metadata?.path_when_clicked)
              } else if (option?.metadata?.email_when_clicked) {
                window.location = `mailto:${option?.metadata?.email_when_clicked}`
              } else if (option?.metadata?.link_when_clicked) {
                window.open(option?.metadata?.link_when_clicked, '_blank')
              }
            }}
            style={{ backgroundColor: primary ? '#FF664D' : '#FFF' }} className={classes.pricingCardBtn}
          >
            <Typography style={{ color: primary ? '#FFF' : '#FF664D' }} className={classes.pricingCardBtnText}>{option?.metadata?.call_to_action}</Typography>
          </Button>
        </Grid>
      )
    }
  }

  const renderPrice = (option, primary = false) => {
    let priceText
    let recurringText
    if (option?.price?.unit_amount) {
      const amount = (option?.price?.unit_amount / 100)?.toFixed(2).split('.')
      const dollars = amount[0]
      const cents = amount[1]
      priceText = (
        <Grid className={classes.pricingCardAmountContainer}>
          <Typography className={classes.pricingCardSubtitle}>$</Typography>
          <Typography style={{ fontSize: '4rem', lineHeight: '3rem' }} className={classes.pricingCardTitle}>{`${dollars}`}</Typography>
          <Typography className={classes.pricingCardSubtitle}>{`.${cents}`}</Typography>
        </Grid>
      )
    } else if (option?.price?.unit_amount === 0) {
      priceText = <Typography className={classes.pricingCardTitle}>Free</Typography>
    } else if (option?.images?.length) {
      const src = option?.images[0]
      priceText = <img src={src} alt={src} className={classes.pricingCardTitleIcon} />
    }
    if (option?.price?.recurring?.interval && option?.unit_label) {
      recurringText = <Typography style={{ color: primary ? '#FFF' : '#2E3D44', paddingTop: '0.5rem' }} className={classes.pricingCardRecurringText}>{`per ${option?.unit_label}, per ${option?.price?.recurring?.interval?.replace('ly', '')}`}</Typography>
    }
    if (priceText) {
      return (
        <Grid className={classes.pricingCardTitleContainer}>
          {priceText}
          {recurringText}
        </Grid>
      )
    }
  }

  const renderDescription = (option, primary = false) => {
    if (option?.description) {
      return <Typography style={{ color: primary ? '#FFF' : '#2E3D44' }} className={classes.pricingCardDescription}>{option?.description}</Typography>
    }
  }

  const renderFeatureList = (option, primary = false) => {
    if (option?.metadata?.features) {
      const features = option?.metadata?.features?.split(',')
      return (
        <Grid className={classes.pricingCardChecklistWrapper}>
          <Grid className={classes.pricingCardChecklistContainer}>
            {features.map(feature => (
              <Grid className={classes.pricingCardChecklist}>
                <Box style={{ color: primary ? '#FFF' : '#2E3D44' }} component={Check} className={classes.pricingCardChecklistIcon} />
                <Typography style={{ color: primary ? '#FFF' : '#2E3D44', fontWeight: primary ? 500 : 400 }} className={classes.pricingCardChecklistText}>{feature}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )
    }
  }

  const renderPricingCard = (option, primary = false) => {
    if (option) {
      const header = option?.name
      return (
        <Grid className={primary ? classes.pricingCardPrimary : classes.pricingCardSecondary}>
          <Grid style={{ borderBottom: primary ? '2px solid rgb(125, 225, 205)' : '2px solid #EEE' }} className={classes.pricingCardHeader}>
            <Typography style={{ color: primary ? '#FFF' : '#FF664D' }} className={classes.pricingCardHeaderText}>{header}</Typography>
          </Grid>
          <Grid className={classes.pricingCardContent}>
            <Grid className={classes.pricingCardBody}>
              {renderPrice(option, primary)}
              {renderDescription(option, primary)}
              {renderFeatureList(option, primary)}
            </Grid>
            <Grid className={classes.pricingCardFooter}>
              {renderCtaBtn(option, primary)}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  return productOptions?.length ? (
    <Grid container className={classes.pricingContainer}>
      <Container maxWidth={'xl'} className={classes.pricingCardWrapper}>
        {renderPricingCard(notionTemplate)}
        {renderPricingCard(standard, true)}
        {renderPricingCard(enterprise)}
      </Container>
    </Grid>
  ) : null
}

export default PricingInfo
