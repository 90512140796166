const sortNotificationsByDate = (a, b) => {
  const leftDate = new Date(a.createdAt).getTime()
  const rightDate = new Date(b.createdAt).getTime()
  if (leftDate < rightDate) {
    return 1
  }
  if (leftDate > rightDate) {
    return -1
  }
  return 0
}

export const selectNotifications = state => {
  const { auth: { user } } = state
  const organizationNotifications = user?.lastOpenedProject?.organization?.notifications || []
  const projectNotifications = user?.lastOpenedProject?.notifications || []
  const userNotifications = user?.notifications || []

  const allNotifications = [...organizationNotifications, ...projectNotifications, ...userNotifications]

  const readNotifications = allNotifications.filter(notification => user?.readNotifications?.find(n => n?.notification?.id === notification.id))
  const unreadNotifications = allNotifications.filter(notification => !user?.readNotifications?.find(n => n?.notification?.id === notification.id))

  return unreadNotifications.sort(sortNotificationsByDate).concat(readNotifications.sort(sortNotificationsByDate))
}

export const selectUserNotification = code => state => {
  const { auth: { user }} = state
  const _notification = user?.notifications?.find(notification => notification.code === code)
  const notification = _notification ? { ..._notification } : null
  if (notification) {
    notification.shown = !!user?.shownNotifications?.find(n => {
      return n.notification.id === notification.id
    })
    notification.read = !!user?.readNotifications?.find(n => n.notification.id === notification.id)
  }
  return notification
}

export const selectUser = state => {
  const { auth: { user }} = state
  let _user = user

  if(!_user) {
    return null
  }

  const roles =  _user.roles?.filter(r => r.name === 'READ_ONLY')

  if(roles && roles.length > 0) {
    _user = {
      ..._user,
      readOnly: true,
    }
  } else {
    _user = {
      ..._user,
      readOnly: false,
    }
  }

  return _user
}
