import React, {useEffect} from 'react'
import {makeStyles, useTheme} from "@material-ui/core/styles"
import componentStyles from "../../assets/theme/views/auth/setupBilling"
import {useHistory, useLocation} from "react-router-dom"
import {Grid, Card, CardContent, CircularProgress} from '@material-ui/core'
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { CREATE_CUSTOMER_PORTAL_SESSION, HAS_PAYMENT_METHOD } from '../../queries'
import config from "../../config";
import { useDispatch } from "react-redux";
import { UPGRADE_PLAN } from '../../mutations'
import { setActiveModal } from '../../components/Modals/actions'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles(componentStyles)


const SetupBilling = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const returnUrl = config.siteBaseUrl + history?.location?.pathname

  const [createCustomerPortalSession, { loading: loadingPortalSession }] = useLazyQuery(CREATE_CUSTOMER_PORTAL_SESSION, {
    fetchPolicy: 'no-cache',
    variables: {
      returnUrl,
    },
    onCompleted: ({ createCustomerPortalSession }) => {
      if (createCustomerPortalSession) {
        window.location.replace(createCustomerPortalSession)
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const { data, loading: loadingPaymentMethod } = useQuery(HAS_PAYMENT_METHOD, {
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    onCompleted: ({ hasPaymentMethod }) => {
      if (!hasPaymentMethod) {
        dispatch(setActiveModal('/auth/setup-billing'))
      }
    },
    onError: e => {
      if(e.toString().includes('Invalid login token')) {
        localStorage.removeItem('loginToken')
        history.push('/auth/login', { prevPath: location.pathname + location.search })
      }
    }
  })

  const [upgradePlan, { error, loading: loadingUpgradePlan }] = useMutation(UPGRADE_PLAN)

  const hasPaymentMethod = data?.hasPaymentMethod

  const onClickContinue = async () => {
    if (hasPaymentMethod) {
      await upgradePlan()
      history.push('/setup/0')
    }
  }

  const onClickPayment = async () => {
    await createCustomerPortalSession()
  }

  const loading = loadingPaymentMethod || loadingPortalSession || loadingUpgradePlan

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent classes={{root: classes.cardContent}}>
            <Box
              color={theme.palette.gray[600]}
              textAlign="center"
              fontSize="1rem"
              letterSpacing={theme.typography.pxToRem(1)}
            >
              <Box fontWeight="400" component="small">
                20 DAY FREE TRIAL
              </Box>
            </Box>
            <Box
              color={theme.palette.gray[600]}
              textAlign="center"
              fontSize="2rem"
            >
              <Box fontWeight="400" component="small">
                Standard Plan
              </Box>
            </Box>
            <Box
              color={theme.palette.gray[600]}
              textAlign="center"
              fontSize="4.5rem"
            >
              <Box fontWeight="400" component="small">
                <span style={{ fontSize: '1rem', verticalAlign: 'text-top' }}>$</span>0
              </Box>
            </Box>
            { loading ? <Box marginTop="1.5rem" marginBottom="1.5rem" textAlign="center"><CircularProgress /></Box> : (
              <>
                <Box
                  color={theme.palette.gray[600]}
                  textAlign="center"
                  marginBottom="2rem"
                  marginTop={'0.25rem'}
                  fontSize="1rem"
                  fontWeight={400}

                >
                  <Box fontSize="80%" fontWeight="400" component="small">
                    $4.50 per user per month after 20 days
                  </Box>
                </Box>
                <Box
                  textAlign="center"
                  marginBottom="1.5rem"
                  paddingTop="2rem"
                  fontSize="1rem"
                  borderTop={'1px solid ' + theme.palette.gray[300]}
                >
                  <Box fontSize="80%" fontWeight="400" component="small">
                    { hasPaymentMethod ? 'Need to make a change to your payment method?' : 'Please enter a payment method to use after your trial ends. You may cancel your plan at any time.' }
                  </Box>
                </Box>
                <Box color={theme.palette.gray[600]} textAlign="center" marginTop="1.5rem" fontSize={'1rem'} marginBottom="2.5rem">
                  <Button disabled={loading} onClick={onClickPayment} color="primary">
                    { hasPaymentMethod ? 'Manage Payment Methods' : 'Add Payment Method' }
                  </Button>
                </Box>
                <Box
                  color={theme.palette.gray[600]}
                  textAlign="center"
                  marginBottom="1.5rem"
                  marginTop=".5rem"
                  fontSize="1rem"
                >
                  <Box fontSize="80%" fontWeight="400" component="small">
                    Get started setting up your first project!
                  </Box>
                </Box>
                <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                  <Button disabled={loading || !hasPaymentMethod} onClick={onClickContinue} color="primary" variant="contained">
                    Start free trial
                  </Button>
                </Box>
              </>
            )}
            {error ? (
              <Alert severity="error" style={{ alignItems: 'center' }}>
                {error.message ? error.message : 'An error occurred upgrading your plan'}
              </Alert>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export default SetupBilling
