import React from 'react'
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core'

export const ThemePreferenceProvider = ({ theme, children, overrideParent, ...rest }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme2 = React.useMemo(() =>
    createMuiTheme({
      ...theme,
      palette: {
        ...theme.palette,
        type: prefersDarkMode ? 'dark' : 'light'
      }
    }),
    [prefersDarkMode]
  )

  const Wrapper = overrideParent ? ThemeProvider : React.Fragment

  return <ThemeProvider {...rest} theme={theme2}>
    {children}
  </ThemeProvider>
}
