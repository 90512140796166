import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  Modal,
  TextField,
  Box,
  CardHeader,
  useTheme,
  FormControl,
  CircularProgress,
  FormHelperText,
  Select,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import componentStyles from '../../../../assets/theme/views/admin/people'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FilledInput from '@material-ui/core/FilledInput'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { useMutation } from '@apollo/client'
import { Autocomplete, MenuItem } from '@mui/material'
import { UPDATE_USER_ROLE_AND_PROJECTS } from '../../../../mutations'
import { validateEmail} from "../../../../utils/inputValidation"
import { Role } from '../../../../enums'
import {initialCaps} from '../../../../utils/initialCaps'

const useStyles = makeStyles(componentStyles)

const EditPeopleModal = ({ open, onClose, user, organizationProjects, userRole }) => {
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [role, setRole] = useState(null)
  const [projects, setProjects] = useState([])
  const [orgProjects, setOrgProjects] = useState([])

  const userRoleOptionsRef = useRef(Object.keys(Role).map(k => Role[k]))

  const canEdit = userRole === 'ADMIN' || userRole === 'OWNER'
  const roles = userRole === 'OWNER' ? userRoleOptionsRef.current : userRoleOptionsRef.current.filter(r => r !== 'OWNER')

  const theme = useTheme()
  const classes = useStyles(theme)

  useEffect(() => {
    setName(defaultName())
    setEmail(defaultEmail())
    setRole(defaultRole())
    setProjects(defaultProjects())
    setOrgProjects(organizationProjects?.length ? organizationProjects : [])
  }, [open])

  const defaultName = () => user?.name || null
  const defaultEmail = () => user?.email || null
  const defaultRole = () => user?.roles?.length ? user?.roles[0]?.name : ''
  const defaultProjects = () => {
    return user?.projects?.length ? user?.projects?.map(project => {
      return { id: project.id, label: project.name }
    }) : []
  }

  const canSave = () => {
    return (defaultRole() !== role
      || defaultProjects().map(p => p.id).sort().toString() !== projects.map(p => p.id).sort().toString()
    ) && role && projects?.length
  }

  const [updateUserRoleAndProjects, { loading: saving }] = useMutation(UPDATE_USER_ROLE_AND_PROJECTS, {
    variables: {
      id: user?.id,
      roles: [role],
      projects: projects?.map(project => project.id),
    }
  })

  const onSave = () => {
    updateUserRoleAndProjects().catch(e => {
      console.error(e)
    }).then(res => {
      console.log(res)
      onClose()
    })
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Card classes={{ root: classes.editPeopleModalWrapper + ' ' + classes.cardRoot + ' ' + classes.cardRootSecondary }}>
          <CardHeader
            subheader={
              <>
                <Grid container component={Box} alignItems='center' justifyContent='space-between'>
                  <Grid item xs='auto' className={classes.modalHeaderLeftWrapper}>
                    <Box component={CloseRoundedIcon} onClick={onClose} width='1.5rem!important' height='1.5rem!important' cursor='pointer' />
                    <Box component={Typography} variant='h3' marginBottom='0!important' marginLeft='1rem!important'>User</Box>
                  </Grid>
                  <Grid item xs='auto'>
                    <Box justifyContent='flex-end' display='flex' flexWrap='wrap'>
                      {canEdit ? (
                        <Button
                          variant='contained'
                          color='primary'
                          size='small'
                          disabled={!canSave() || saving}
                          onClick={onSave}
                        >
                          Save
                        </Button>
                      ) : null}
                    </Box>
                  </Grid>
                </Grid>
              </>
            }
            classes={{ root: classes.cardHeaderRoot }}
          />
          <CardContent classes={{ root: classes.editPeopleCardContent }}>
            { !user?.name ? <Box classes={{ root: classes.centerContent }}><CircularProgress classes={ classes.centerCardContent }/></Box> :
              <>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormLabel>Name</FormLabel>
                      <FormControl variant='filled' component={Box} width='100%' margin='normal'>
                        <Box
                          paddingLeft='0.75rem'
                          paddingRight='0.75rem'
                          component={FilledInput}
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          onFocus={() => setMessage(null)}
                          autoComplete='off'
                          type='text'
                          disabled={true}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormLabel>Email</FormLabel>
                      <FormControl component={Box} width='100%' margin='normal'>
                        <Box
                          paddingLeft='0.75rem'
                          paddingRight='0.75rem'
                          variant='filled'
                          id='filled-error'
                          component={TextField}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          onBlur={() => validateEmail(email)}
                          onFocus={() => setMessage(null)}
                          autoComplete='off'
                          type='text'
                          disabled={true}
                        />
                        <FormHelperText marginLeft='14px !important' error={!validateEmail(email)} classes={classes.modalMessageError}>
                          {validateEmail(email) ? ' ' : 'Please enter a valid email'}
                        </FormHelperText>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                   <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormLabel>Role</FormLabel>
                      <FormControl variant='filled' component={Box} width='100%' marginBottom='1rem !important'>
                        <Select
                          id='user-role-select'
                          value={role}
                          label='Role'
                          onChange={(e) => setRole(e.target.value)}
                          disabled={!canEdit}
                        >
                          {roles?.map(role => <MenuItem value={role}>{initialCaps(role)}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormGroup>
                      <FormLabel>Projects</FormLabel>
                      <FormControl component={Box} width='100%' marginBottom='1rem!important'>
                        <Autocomplete
                          multiple
                          id='tags-outlined'
                          options={orgProjects?.filter(op => !projects?.some(p => p.id === op.id))?.map(p => { return { id: p.id, label: p.name }})}
                          value={projects}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField {...params} placeholder={'Project'} />
                          )}
                          onChange={(event, value) => setProjects(value)}
                          disabled={!canEdit}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </Grid>
              </>
            }
          </CardContent>
        </Card>
      </Modal>
    </>
  )
}

export default EditPeopleModal
