import React from "react"
import config from '../../config'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { useTheme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Link from '@material-ui/core/Link'
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

// core components
import componentStyles from "assets/theme/components/auth-header.js"
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(componentStyles);

const SetupHeader = ({ name }) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="8rem"
        paddingBottom="8rem"
      >
        <Container maxWidth="xl">
          <Box marginBottom="6rem" textAlign="center">
            <Box
              component={Grid}
              container
              justifyContent="center"
              color={theme.palette.white.main}
            >
              <Grid item lg={5} md={6} xs={12}>
                <h1>Welcome, {name}!</h1>
                <Box
                  component="p"
                  lineHeight="1.7"
                  fontSize="1rem"
                >
                  Follow the prompts below to get started with your first NotionOps project.
                </Box>
              </Grid>
            </Box>
            <Box>
            <Button
              variant={'contained'}
              color={'primary'}
              size="small"
              target={'_blank'}
              href={'https://oosstudio.notion.site/Getting-started-4fb7bcdbd4b54fe98c5e4759ed7a4461'}
            >
              Guide to getting started
            </Button>
            </Box>
            <Box paddingTop={'0.75rem'}>
            <Button
              variant={'contained'}
              color={'secondary'}
              size="small"
              target={'_blank'}
              style={{
                color: theme.palette.primary.main
              }}
              href={config.affiliateLink}
            >
              Get a Notion account
            </Button>
            </Box>
          </Box>
        </Container>
        <Box
          position="absolute"
          zIndex="100"
          height="70px"
          top="auto"
          bottom="0"
          pointerEvents="none"
          left="0"
          right="0"
          width="100%"
          overflow="hidden"
          transform="translateZ(0)"
        >
          <Box
            bottom="0"
            position="absolute"
            pointerEvents="none"
            component="svg"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <Box
              component="polygon"
              fill={'white'}
              points="2560 0 2560 100 0 100"
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SetupHeader
