import {CheckOutlined, PriorityHighOutlined, WarningSharp} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import boxShadows from "../assets/theme/box-shadow";
import InfoIcon from "../assets/img/Info.png";
import React from "react";
import componentStyles from "../assets/theme/components/notifications"
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(componentStyles)

const NotificationIcon = ({notification, scale = 1}) => {
  const classes = useStyles()

  let wrapperClass, iconComponent, iconClass
  switch(notification?.type) {
    case 'SUCCESS':
      wrapperClass = classes.successIconWrapper
      iconComponent = CheckOutlined
      iconClass = classes.successIcon
      break
    case 'ERROR':
      wrapperClass = classes.errorIconWrapper
      iconComponent = PriorityHighOutlined
      iconClass = classes.errorIcon
      break
    case 'WARNING':
      wrapperClass = classes.warningIconWrapper
      iconComponent = WarningSharp
      iconClass = classes.warningIcon
      break
    default:
      wrapperClass = classes.infoIconWrapper
      iconComponent = null
      iconClass = null
      break
  }
  return (
    <Box
      width="3rem"
      height="3rem"
      style={{ transform: `scale(${scale})`}}
      padding="12px"
      textAlign="center"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      boxShadow={boxShadows.boxShadow}
      className={wrapperClass}
    >
      {notification?.type === 'INFO' ? (
        <img src={InfoIcon} style={{height: 25}}/>
      ) : (
        <Box
          component={iconComponent}
          className={iconClass}
        />
      )}
    </Box>
  )
}

export default NotificationIcon
