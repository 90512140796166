import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import { FormControl, CircularProgress, CardContent, CardHeader, makeStyles, useTheme} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import componentStyles from '../../../../assets/theme/views/admin/profile'
import {UPDATE_ORGANIZATION, UPDATE_STRIPE_CUSTOMER_NAME} from '../../../../mutations'
import { useSelector } from 'react-redux'
import { Select } from '../../../../components'
import {useQuery, useMutation, useLazyQuery} from '@apollo/client'
import FormLabel from '@material-ui/core/FormLabel'
import FilledInput from '@material-ui/core/FilledInput'
import {selectUser} from "../../../auth/selectors";
import {CREATE_CUSTOMER_PORTAL_SESSION} from "../../../../queries";
import {useHistory} from "react-router-dom";
import config from "../../../../config";
import { getPlan } from '../../../../utils/subscriptions'
import ListItem from '@material-ui/core/ListItem'

const useStyles = makeStyles(componentStyles)

const OrganizationSetup = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const organization = useSelector(state => state.auth?.user?.lastOpenedProject?.organization)
  const user = useSelector(selectUser)

  const userRole = user?.roles[0]?.name
  const returnUrl = config.siteBaseUrl + history?.location?.pathname

  const [name, setName] = useState(null)
  const [domain, setDomain] = useState(null)

  useEffect(() => {
    setName(defaultName())
    setDomain(defaultDomain())
  }, [organization?.id])

  const [updateOrganization, { loading: saving }] = useMutation(UPDATE_ORGANIZATION, {
    variables: {
      id: organization?.id,
      name,
      domain
    }
  })

  const [createCustomerPortalSession, { loading }] = useLazyQuery(CREATE_CUSTOMER_PORTAL_SESSION, {
    fetchPolicy: 'no-cache',
    variables: {
      returnUrl,
    },
    onCompleted: ({ createCustomerPortalSession }) => {
      if (createCustomerPortalSession) {
        window.location.replace(createCustomerPortalSession)
      }
    }
  })

  const defaultName = () => organization?.name || null

  const defaultDomain = () => organization?.domain || null

  const canSave = () => {
    return (defaultName() !== name
        || defaultDomain() !== domain
      ) && name
      && domain
  }

  const onNameChange = ({ target: { value }}) => {
    setName(value)
  }

  const onDomainChange = ({ target: { value }}) => {
    setDomain(value)
  }

  const onSave = () => {
    updateOrganization().catch(e => {
      //Todo: display message to use that there was a problem saving
      console.error(e)
      // Set fields (state) back to defaults
      setName(defaultName())
      setDomain(defaultDomain())
    }).then(res => {
      console.log(res)
    })
  }

  const plan = getPlan(user)

  const promotions = user?.lastOpenedProject?.organization?.plan?.promotions || []

  const renderPromotion = promotion => {
    const start = promotion.start ? new Date(promotion.start) : null
    const end = promotion.start ? new Date(promotion.end) : null
    const now = new Date()

    if (now && now.getTime() < start.getTime() || end && now > end.getTime()) {
      return null
    }

    return (
      <Box marginTop={'0rem'}>
        <Box fontWeight="400" component="small">
          <ListItem
            component="d"
          >
            <b style={{ color: theme.palette.success.dark }}>{promotion.name}</b>{end ? ` until ${end.toLocaleDateString()}` : ''}
          </ListItem>
        </Box>
      </Box>
    )
  }

  const onBillingButtonClicked = async () => {
    if (plan === null) {
      history.push({
        pathname: '/upgrade-plan',
        search: '?origin=' + history?.location?.pathname
      })
    } else {
      await createCustomerPortalSession()
    }
  }

  return (
    <Card
      classes={{
        root: classes.cardRoot + " " + classes.cardRootSecondary,
      }}
    >
      <CardHeader
        subheader={
          <Grid
            container
            component={Box}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs="auto">
              <Box
                component={Typography}
                variant="h3"
                marginBottom="0!important"
              >
                Organization Details
              </Box>
            </Grid>
            <Grid item xs="auto">
              <Box
                justifyContent="flex-end"
                display="flex"
                flexWrap="wrap"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!canSave() || user?.readOnly}
                  onClick={onSave}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
        classes={{ root: classes.cardHeaderRoot }}
      />

      <CardContent classes={{ root: classes.organizationDetailsCardContent }}>
        { !organization?.id ? <Box classes={{ root: classes.centerContent }}><CircularProgress classes={ classes.centerCardContent }/></Box> :
          <>
            <div className={classes.plLg4}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <FormGroup>
                    <FormLabel>Name</FormLabel>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <Box
                        disabled={user?.readOnly}
                        paddingLeft="0.75rem"
                        paddingRight="0.75rem"
                        component={FilledInput}
                        onChange={onNameChange}
                        value={name}
                        autoComplete="off"
                        type="text"
                        placeholder="My Organization"
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormGroup>
                    <FormLabel>Domain</FormLabel>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <Box
                        disabled={user?.readOnly}
                        paddingLeft="0.75rem"
                        paddingRight="0.75rem"
                        component={FilledInput}
                        onChange={onDomainChange}
                        value={domain}
                        autoComplete="off"
                        type="text"
                        placeholder="myorganization"
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                {userRole === 'OWNER' ? (
                  <Grid item xs={12} lg={6}>
                    <Button color="primary" variant="contained" disabled={loading} onClick={onBillingButtonClicked}>
                      {plan === null ? 'Upgrade Plan' : 'Manage Billing'}
                    </Button>
                  </Grid>
                ) : null}
                { promotions.map(promotion => renderPromotion(promotion)) }
              </Grid>

            </div>
          </>
        }
      </CardContent>
    </Card>
  )
}

export default OrganizationSetup
