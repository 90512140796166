import React, {useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import AuthFooter from "components/Footers/AuthFooter.js";
import componentStyles from "assets/theme/layouts/auth.js";
import NewProjectStepper from "../views/admin/NewProjectSetup/NewProjectStepper";
import NewProjectHeader from "../components/Headers/NewProjectHeader";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@apollo/client";
import {ME} from "../queries";
import equal from "deep-equal";
import {setUserData} from "../views/auth/actions";

const useStyles = makeStyles(componentStyles);

const NewProject = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const user = useSelector(state => state.auth?.user)
  const classes = useStyles();
  const mainContent = React.useRef(null);

  useQuery(ME, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data) {
        return
      }
      const me = data.me
      if (!equal(me, user)) {
        dispatch(setUserData(me))
      }
      if (!(!!me.projects.length)) {
        history.push('/setup/1')
      }
    },
    onError: (e) => {
      if(e.toString().includes('Invalid login token')) {
        localStorage.removeItem('loginToken')
        history.push('/auth/login', { prevPath: location.pathname })
      }
    },
  })

  useEffect(() => {
    document.body.classList.add(classes.bgDefault)
    const loginToken = localStorage.getItem('loginToken')
    if (!loginToken) history.push('/auth/login')
    return () => {
      document.body.classList.remove(classes.bgDefault)
    };
  },[]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  return (
    <>
      <div className="main-content" ref={mainContent}>
        <NewProjectHeader />
        {/* Page content */}
        <Container
          component={Box}
          maxWidth="xl"
          marginTop="-8rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Box component={Grid} container justifyContent="center">
            <NewProjectStepper {...props} />
          </Box>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default NewProject;
