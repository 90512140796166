import React, { useEffect, useState } from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FilledInput from "@material-ui/core/FilledInput"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InputAdornment from "@material-ui/core/InputAdornment"

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import componentStyles from 'assets/theme/views/auth/register.js'
import AccountTree from '@material-ui/icons/AccountTree'
import {Select} from '../../../components'
import moment from 'moment-timezone'

import { UPSERT_FIRST_PROJECT } from '../../../mutations'

import { useMutation } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import {CircularProgress} from '@material-ui/core'
import {setProject} from '../actions'
import {selectAuthorization, selectOrganization, selectProject} from '../selectors'

import {Autocomplete} from '@material-ui/lab'

const useStyles = makeStyles(componentStyles)

const ProjectStep = ({ onBackClick = () => {}, onNextClick = () => {} }) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const user = useSelector(state => state.auth?.user)
  const organization = useSelector(selectOrganization)
  const authorization = useSelector(selectAuthorization)
  const project = useSelector(selectProject)

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': 'Project Step'
    })
  },[])

  const [createOrUpdateProject, { data, loading, error }] = useMutation(UPSERT_FIRST_PROJECT, {
    fetchPolicy: 'no-cache',
    variables: {
      organizationId: organization?.id,
      projectId: project?.id || -1,
      userId: user?.id,
      authorizationId: authorization?.id,
      name: project?.name,
      timezone: project?.timezone
    },
    onCompleted: async (data) => {
      dispatch(setProject(data?.updateOneOrganization.projects?.length && data.updateOneOrganization.projects[0]))
    }
  })

  const isValid = () => {
    return !loading && project?.name && project.name !== ''
      && project?.timezone
  }

  const handleNext = () => {
    createOrUpdateProject().then(() => {
      onNextClick()
    })
  }

  return (
    <>
      <Box
        color={theme.palette.gray[600]}
        textAlign="center"
        marginBottom="1.5rem"
        marginTop=".5rem"
        fontSize="1rem"
      >
        <Box fontWeight="400" component="small">
          Now you're ready to setup your first project!
        </Box>
      </Box>
      <FormControl
        variant="filled"
        component={Box}
        width="100%"
        marginBottom="1.5rem!important"
      >
        <FilledInput
          value={project?.name || ''}
          onChange={o => {
            dispatch(setProject({
              ...project,
              name: o.target.value
            }))
          }}
          autoComplete="off"
          type="text"
          placeholder="Project Name*"
          startAdornment={
            <InputAdornment position="start">
              <AccountTree />
            </InputAdornment>
          }
        />
        <Box marginTop={'0.5rem'} color={theme.palette.gray[600]} fontSize="80%" fontWeight="400" component="small">
          We recommend giving your project the same name you give your template page you duplicated.
        </Box>
      </FormControl>
      <FormControl
        variant="filled"
        component={Box}
        width="100%"
        marginBottom="1rem!important"
      >
        <Select
          options={moment.tz.names()}
          getOptionLabel={option => option}
          renderOption={option => option}
          disableClearable={true}
          label={'Timezone'}
          value={project?.timezone ? project.timezone : null}
          onChange={(event, value) => {
            dispatch(setProject({
              ...project,
              timezone: value
            }))
          }}
        />
        <Box marginTop={'0.5rem'} color={theme.palette.gray[600]} fontSize="80%" fontWeight="400" component="small">
          Your project's timezone will be used to determine when it is a new day within your analytics, such as the next day of your burndown.
        </Box>
        <Box marginTop={'0.5rem'} color={theme.palette.gray[600]} fontSize="80%" fontWeight="400" component="small">
          If your team is in multiple timezones, we recommend selecting one closest to most of your project managers.
        </Box>
      </FormControl>
      <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
        <Button onClick={onBackClick} className={classes.backButton}>Back</Button>
        <Button disabled={!isValid()} variant="contained" color="primary" onClick={handleNext}>
          Next
          { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Box>
    </>
  )
}

export default ProjectStep
