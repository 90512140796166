import React, { useEffect } from 'react'
// @material-ui/core components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FilledInput from "@material-ui/core/FilledInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import LocationOn from "@material-ui/icons/LocationOn";
import School from "@material-ui/icons/School";
import Requirements from './components/Requirements'
import Sprints from './components/Sprints'

// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/profile.js";
import boxShadows from "assets/theme/box-shadow.js";

import { makeStyles, useTheme, Select, FormControl, MenuItem, InputLabel } from '@material-ui/core'

const useStyles = makeStyles(componentStyles);

const Databases = () => {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': 'Databases'
    })
  },[])

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Requirements/>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Sprints/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Databases;
