import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {REQUEST_PASSWORD_RESET, SIGN_IN} from '../../mutations'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from '@material-ui/lab/Alert'
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import { setUserData } from './actions'
import { useDispatch } from 'react-redux'
import {validateEmail} from "../../utils/inputValidation";

const useStyles = makeStyles(componentStyles);

function ForgotPassword() {
  const history = useHistory()
  const dispatch = useDispatch()

  const loginToken = localStorage.getItem('loginToken')
  if (loginToken) history.push('/index')

  const classes = useStyles();
  const theme = useTheme();

  const [email, setEmail] = useState(null)
  const [message, setMessage] = useState(null)

  const [requestPasswordReset, { loading: mutationLoading, error }] = useMutation(REQUEST_PASSWORD_RESET, {
    variables: {
      email,
    },
    onCompleted: (data) => {
      setMessage('If the provided email is registered with us, you should receive a link to reset your password shortly.')
    },
    onError: (err) => {
      setMessage(err)
    },
  })

  const submitResetRequest = async (e) => {
    if (e.keyCode === 13) await onSubmit()
  }

  const onSubmit = async () => {
    await requestPasswordReset()
  }

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent classes={{ root: classes.cardContent }}>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FilledInput
                autoComplete="off"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={submitResetRequest}
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
              />
            </FormControl>
            {message && <Alert severity={error ? 'error' : 'success'} style={{alignItems: 'center'}}>
              {message}
            </Alert>}
            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
              <Button color="primary" variant="contained" disabled={mutationLoading || !validateEmail(email)} onClick={onSubmit}>
                Send password reset link
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Grid container component={Box} marginTop="1rem">
          <Grid item xs={6} component={Box} textAlign="left">
            <a
              onClick={() => history.push('/auth/login')}
              className={classes.footerLinks}
            >
              Back to Login
            </a>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ForgotPassword;
