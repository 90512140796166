import React, { useEffect, useState } from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from "@material-ui/core/Box"
import Grid from '@material-ui/core/Grid'
import FormControl from "@material-ui/core/FormControl"
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import componentStyles from 'assets/theme/views/auth/register.js'
import {Select} from '../../../components'
import { UPSERT_FIRST_SPRINT_TABLE } from '../../../mutations'
import {useLazyQuery, useMutation} from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import {CircularProgress} from '@material-ui/core'
import { setSprintDatabase as _setSprintDatabase, setSprintProperties as _setSprintProperties} from '../actions'
import { setSprintDatabase as setNewSprintDatabase, setSprintProperties as setNewSprintProperties } from '../../admin/NewProjectSetup/actions'
import {
  selectOrganization,
  selectProject,
  selectSprintDatabase,
  selectSprintProperties
} from '../selectors'
import {NOTION_DATABASE_PROPERTIES, NOTION_DATABASES} from '../../../queries'
import FormGroup from '@material-ui/core/FormGroup'
import {
  selectNewProject,
  selectNewProjectOrganization,
  selectNewProjectSprintDatabase,
  selectNewProjectSprintProperties
} from "../../admin/NewProjectSetup/selectors";

const useStyles = makeStyles(componentStyles)

const SprintStep = ({ onBackClick = () => {}, onNextClick = () => {}, isOrgSetup, newProject }) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [quickSetupErrorMessage, setQuickSetupErrorMessage] = useState(null)

  const database = isOrgSetup ? useSelector(selectSprintDatabase) : useSelector(selectNewProjectSprintDatabase)
  const properties = isOrgSetup ? useSelector(selectSprintProperties) : useSelector(selectNewProjectSprintProperties)
  const organization = isOrgSetup ? useSelector(selectOrganization) : useSelector(selectNewProjectOrganization)
  const project = isOrgSetup ? useSelector(selectProject) : useSelector(selectNewProject)

  const setSprintDatabase = isOrgSetup ? _setSprintDatabase : setNewSprintDatabase
  const setSprintProperties = isOrgSetup ? _setSprintProperties : setNewSprintProperties

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': newProject ? 'New Project: Sprint Step' : 'Sprint Step'
    })
  },[])

  const [getNotionDatabases, { data: quickSetupData, loading: quickSetupLoading, error }] = useLazyQuery(NOTION_DATABASES, {
    variables: {
      projectId: project?.id,
    },
    fetchPolicy: 'network-only',
    onError: err => {
      console.log(err.networkError)
    },
    onCompleted: data => {
      console.log(data)
    }
  })

  const [createOrUpdateSprintTable, { loading }] = useMutation(UPSERT_FIRST_SPRINT_TABLE, {
    fetchPolicy: 'no-cache',
    variables: {
      projectId: project?.id,
      organizationId: organization?.id,
      notionDatabaseId: database?.id,
      notionDatabaseTitle: database?.title,
      notionDateRangeId: properties?.dateRange?.id,
      notionDateRangeTitle: properties?.dateRange?.title,
      notionDateRangeType: properties?.dateRange?.type,
      notionPlannedEffortId: properties?.totalEffort?.id,
      notionPlannedEffortTitle: properties?.totalEffort?.title,
      notionPlannedEffortType: properties?.totalEffort?.type,
      notionBacklogId: properties?.backlog?.id,
      notionBacklogTitle: properties?.backlog?.title,
      notionBacklogType: properties?.backlog?.type,
      notionStateId: properties?.state?.id,
      notionStateTitle: properties?.state?.title,
      notionStateType: properties?.state?.type,
    },
    onError: err => {
      console.log(err)
    }
  })

  useEffect(() => {
    console.log(quickSetupData)
  }, [quickSetupData])

  const isValid = () => {
    return !loading && !quickSetupLoading && database?.id
      && database?.title
      && properties?.dateRange?.id
      && properties?.dateRange?.type
      && properties?.dateRange?.title
      && properties?.totalEffort?.id
      && properties?.totalEffort?.title
      && properties?.totalEffort?.type
      && properties?.backlog?.id
      && properties?.backlog?.type
      && properties?.backlog?.title
      && properties?.state?.id
      && properties?.state?.type
      && properties?.state?.title
  }

  const handleNext = () => {
    createOrUpdateSprintTable().then(() => {
      onNextClick()
    }).catch(e => {
      console.log(e)
    })
  }

  const handleQuickSetup = async () => {
    setQuickSetupErrorMessage(null)
    getNotionDatabases()
  }

  useEffect(() => {

    if (quickSetupData?.notionDatabases?.results?.length) {
      const notionDatabases = quickSetupData.notionDatabases.results
      const sprintDatabases = notionDatabases.filter(nd => {
        const plainTextTitle = nd?.title?.length ? nd.title[0]?.plain_text : null
        const lower = plainTextTitle?.toLowerCase().trim()
        return lower === 'sprints'
      })
      if (sprintDatabases?.length === 1) {
        const sprintDatabase = sprintDatabases[0]
        dispatch(setSprintDatabase({
          title: sprintDatabase.title[0]?.plain_text,
          id: sprintDatabase.id
        }))
        const sprintProps = {
          dateRange: null,
          totalEffort: null,
          backlog: null,
          state: null
        }
        let count = 0
        sprintDatabase.properties?.forEach(property => {
          if (property.name.toLowerCase().trim() === 'date range' && property.type === 'date') {
            sprintProps.dateRange = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++
          } else if ((property.name.toLowerCase().trim() === 'total effort' || property.name.toLowerCase().trim() === 'effort' || property.name.toLowerCase().trim() === 'total points' || property.name.toLowerCase().trim() === 'planned effort') && property.type === 'rollup') {
            sprintProps.totalEffort = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++
          } else if ((property.name.toLowerCase().trim() === 'backlog' || property.name.toLowerCase().trim() === 'sprint backlog') && property.type === 'relation') {
            sprintProps.backlog = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++
          } else if ((property.name.toLowerCase().trim() === 'state' || property.name.toLowerCase().trim() === 'status') && property.type === 'formula') {
            sprintProps.state = {
              id: property.id,
              title: property.name,
              type: property.type
            }
            count++
          }
        })
        if (count < 4) {
          setQuickSetupErrorMessage('Could not find every property. Please select the missing properties from your sprint database manually.')
        }
        dispatch(setSprintProperties(sprintProps))
      } else if (sprintDatabases?.length > 1) {
        setQuickSetupErrorMessage(`More than one database titled 'Sprints' was found in your workspace. Try temporarily changing the names of the databases you don't want, or select the one you want manually below.` )
      } else if (!sprintDatabases?.length) {
        setQuickSetupErrorMessage(`No databases titled 'Sprints' where found in your workspace. Make sure you've given access to the right page, or if you recently authorized Notion in an earlier step please wait a few minutes and try again.` )
      }
    } else if (quickSetupData?.notionDatabases) {
      setQuickSetupErrorMessage(`No databases where found in your workspace. If you recently authorized Notion in an earlier step, you may need to wait a few minutes and try again.` )
    }
  }, [quickSetupData])

  const mapPropertyOptions = options => options?.map(o => ({
    title: o.name,
    id: o.id,
    type: o.type
  }))

  const onDatabaseChange = (event, value) => {
    dispatch(setSprintDatabase(value))
    dispatch(setSprintProperties({
      dateRange: null,
      totalEffort: null,
      backlog: null,
      state: null
    }))
  }

  const onDateRangeChange = (event, value) => {
    dispatch(setSprintProperties({
      ...properties,
      dateRange: value
    }))
  }

  const onTotalEffortChange = (event, value) => {
    dispatch(setSprintProperties({
      ...properties,
      totalEffort: value
    }))
  }

  const onBacklogChange = (event, value) => {
    dispatch(setSprintProperties({
      ...properties,
      backlog: value
    }))
  }

  const onStateChange = (event, value) => {
    dispatch(setSprintProperties({
      ...properties,
      state: value
    }))
  }

  console.log(error)

  return (
    <>
      <Box
        color={theme.palette.gray[600]}
        textAlign="center"
        marginBottom="1.5rem"
        marginTop=".5rem"
        fontSize="1rem"
      >
        <Box fontWeight="400" component="small">
          Now lets map the properties of your sprint database. If you are using a quick setup template, NotionOps can find the mappings for you.
        </Box>


        <Box textAlign="center" marginTop="1.5rem">
          { quickSetupLoading ?
            <CircularProgress size={38}/>
            :
            <Button onClick={handleQuickSetup} color={'primary'}>
              Quick Setup
            </Button>
          }

        </Box>
        {quickSetupErrorMessage ? <Box
          marginTop={'1rem'}
          fontWeight="700"
          color={theme.palette.error.main}
        >
          {quickSetupErrorMessage}
        </Box> : null }

      </Box>
      <Box color={theme.palette.gray[600]} marginBottom={'1rem'}>
        *You may need to wait a few minutes for your databases to show up below.
      </Box>
      <FormControl
        variant="filled"
        component={Box}
        width="100%"
        marginBottom="1rem!important"
      >
        <Select
          query={NOTION_DATABASES}
          selectResponse={data => data.notionDatabases}
          selectResults={response => response.results}
          variables={{
            projectId: project?.id
          }}
          mapOptions={options => options?.map(o => ({
            title: o.title[0]?.plain_text,
            id: o.id
          }))}
          getOptionLabel={option => `${option.title} – ${option.id}`}
          showId={true}
          getOptionSelected={(option, value) => option.id === value.id}
          label={'Sprint Database'}
          onChange={onDatabaseChange}
          value={database}
          disableClearable={true}
        />
        <Box marginTop={'0.5rem'}>
          <Tooltip title={'The database in Notion that holds your sprints. If you have multiple databases with the same name, we recommend temporarily changing the one you want to a unique name so you can find it here.'}>
            <HelpOutlineIcon/>
          </Tooltip>
        </Box>
      </FormControl>
      <Box
        component={Typography}
        variant="h6"
        color={theme.palette.gray[600] + "!important"}
        paddingTop="2.25rem"
        paddingBottom="0.25rem"
        fontSize=".75rem!important"
        letterSpacing=".04em"
        classes={{root: classes.typographyRootH6}}
      >
        Property Setup
      </Box>
      <Grid container>
        <Grid item xs={12} lg={4}>
          <FormGroup>

            <FormControl
              variant="filled"
              component={Box}
              width="100%"
            >

              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'date')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'Date Range'}
                onChange={onDateRangeChange}
                value={properties?.dateRange}
                disableClearable={true}
              />
              <Tooltip title={'The date property for the start and end dates of a sprint.'}>
                <Box marginTop={'0.5rem'}>
                  <HelpOutlineIcon/>
                </Box>
              </Tooltip>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormGroup>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
            >
              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'rollup')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'Total Effort'}
                onChange={onTotalEffortChange}
                value={properties?.totalEffort}
                disableClearable={true}
              />
              <Box marginTop={'0.5rem'}>
                <Tooltip title={'A rollup property that contains the total story points in a sprint backlog.'}>
                  <HelpOutlineIcon/>
                </Tooltip>
              </Box>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormGroup>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
            >
              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'relation')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'Sprint Backlog'}
                onChange={onBacklogChange}
                value={properties?.backlog}
                disableClearable={true}
              />
              <Box marginTop={'0.5rem'}>
                <Tooltip title={'A relation property to the requirements database which contains the backlog of a sprint.'}>
                  <HelpOutlineIcon/>
                </Tooltip>
              </Box>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormGroup>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <Select
                query={NOTION_DATABASE_PROPERTIES}
                selectResponse={data => data.notionDatabaseProperties.filter(p => p.type === 'formula')}
                variables={{
                  projectId: project?.id,
                  databaseId: database?.id
                }}
                mapOptions={mapPropertyOptions}
                getOptionLabel={option => `${option.title} – ${option.id}`}
                showId={true}
                getOptionSelected={(option, value) => option.id === value.id}
                label={'State'}
                onChange={onStateChange}
                value={properties?.state}
                disableClearable={true}
              />
              <Box marginTop={'0.5rem'}>
                <Tooltip title={'A formula property that calculates the state of a sprint: Planned, Current, or Past'}>
                  <HelpOutlineIcon/>
                </Tooltip>
              </Box>
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>
      <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
        <Button onClick={onBackClick} className={classes.backButton}>Back</Button>
        <Button disabled={!isValid()} variant="contained" color="primary" onClick={handleNext}>
          Next
          { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Box>
    </>
  )
}

export default SprintStep
