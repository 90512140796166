import React, {useEffect, useState} from 'react'
import {
  Box, Card,
  CircularProgress,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useTheme,
  Checkbox, FormControlLabel,
} from "@material-ui/core";
import { KeyboardDatePicker } from '@material-ui/pickers'
import styles from './styles'
import { isDarkMode, NotionColorsLight, NotionColorsDark } from "../../../styles";
import {ResponsiveLine} from "@nivo/line";
import RefreshButton from "../../../../components/Buttons/RefreshButton";
import CopyEmbedUrlButton from "../../../../components/Buttons/CopyEmbedUrlButton";
import {useApolloClient, useQuery} from '@apollo/client'
import {CUMULATIVE_FLOW} from "../../../../queries";
import Button from "@material-ui/core/Button";
import {setActiveModal} from "../../../../components/Modals/actions";
import {useDispatch} from "react-redux";
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(({ renderOnSite }) => (styles({renderOnSite})))

const CumulativeFlow = ({ location, projectNumber: _projectNumber }) => {
  let pathname
  if (location) pathname = location.pathname

  const history = useHistory()
  const theme = useTheme()

  let renderOnSite = !pathname
  theme.renderOnSite = renderOnSite
  const classes = useStyles(theme)

  const [showCopyButton, setShowCopyButton] = useState(true)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [refetching, setRefetching] = useState(false)
  const [byPoints, setByPoints] = useState(true)
  const [projectNumber, setProjectNumber] = useState(_projectNumber)
  const [allStatuses, setAllStatuses] = useState([])
  const [checkedStatuses, setCheckedStatuses] = useState([])
  const [minYValue, setMinYValue] = useState(0)
  const [tickValues, setTickValues] = useState(undefined)
  const [datesWithData, setDatesWithData] = useState([])
  const [initialLoad, setInitialLoad] = useState(true)
  const dispatch = useDispatch()

  const client = useApolloClient()

  const { data, refetch, error, loading } = useQuery(CUMULATIVE_FLOW, {
    variables: {
      projectNumber: projectNumber,
      startDate,
      endDate,
    },
    fetchPolicy: 'cache-and-network',
    skip: !projectNumber,
    onCompleted: (data) => {
      if (!initialLoad) {
        const statuses = data.cumulativeFlow.statuses.map((status, index) => {
          const existing = allStatuses.find(s => s.id === status.id)
          return {
            ...status,
            index,
            isChecked: existing ? existing.isChecked : true,
          }
        })
        setAllStatuses(statuses)
        setCheckedStatuses(statuses.filter(status => status.isChecked))
        setRefetching(false)
      }
    },
    onError: async () => {
      if (data) {
        client.resetStore().catch(e => {
          console.log(e)
        })
      }
      setRefetching(false)
      setInitialLoad(false)
    },
  })

  useEffect(() => {
    if (data && initialLoad) {
      const statuses = data.cumulativeFlow?.statuses?.map((status, index) => {
        return {
          ...status,
          index,
          isChecked: true,
        }
      })
      if (statuses?.length) {
        setAllStatuses(statuses)
        setCheckedStatuses(statuses)
      }
      setInitialLoad(false)
    }
  }, [data])

  useEffect(() => {
    if(pathname) {
      setShowCopyButton(false)
      const parts = pathname.split('/')
      const projectNumber = parts[parts.length - 1]
      setProjectNumber(projectNumber)
    }
  }, [])

  const onRefresh = () => {
    setRefetching(true)
    refetch().finally(() => {
      setRefetching(false)
    })
  }

  const getChartData = () => {
    if(allStatuses.length === 0 || checkedStatuses.length === 0) {
      return []
    }

    let _minYValue = byPoints ? Math.min(...checkedStatuses[0].data.map(p => p.points)) : Math.min(...checkedStatuses[0].data.map(p => p.count))
    _minYValue = Math.max(0, _minYValue)
    if(_minYValue !== minYValue) {
      setMinYValue(_minYValue)
    }
    const dates = []
    const rv = checkedStatuses?.map(status => {
      return {
        id: status.id,
        color: status.color,
        data: status.data.map(d => {
          if(!dates.includes(d.date)) {
            dates.push(d.date)
          }
          return {
            x: d.date,
            y: byPoints ? d.points : d.count
          }
        })
      }
    })

    if(JSON.stringify(dates) !== JSON.stringify(datesWithData)) {
      setDatesWithData(dates)
    }

    return rv
  }

  useEffect(() => {
    if(datesWithData.length > 10 && tickValues !== undefined) {
      setTickValues(undefined)
    } else if (datesWithData.length <= 5 && datesWithData.length > 0 && tickValues !== datesWithData.length) {
      setTickValues('every day')
    } else if(datesWithData.length <= 10 && datesWithData.length > 0 && tickValues !== datesWithData.length) {
      setTickValues(datesWithData.length)
    }
  }, [datesWithData])

  const chartData = getChartData()
  const sumOfChartDataLengths = chartData.reduce((sum, status) => sum + status.data.length, 0)
  const dataAvailable = allStatuses.length > 0 && sumOfChartDataLengths > 0
  const statusSelected = allStatuses.filter(status => status.isChecked).length > 0

  let notice = false
  let showViewErrorBtn = false
  let originCode, originMessage, title

  if (error) {
    const gqlError = error.graphQLErrors[0]
    originCode = gqlError?.extensions?.code

    showViewErrorBtn = originCode !== 'UPGRADE_PLAN'

    try {
      const errorNotification = JSON.parse(gqlError?.message)

      notice = errorNotification?.error
      originMessage = errorNotification?.message
      title = errorNotification?.title
    } catch (e) {
      originMessage = gqlError?.message

      if (!originCode) {
        originCode = gqlError?.name ? gqlError?.name : gqlError?.prototype?.name
      }

      if (originCode === 'BAD_USER_INPUT') {
        notice = gqlError.message
      } else if (originCode === 'UPGRADE_PLAN') {
        const path = gqlError?.extensions?.exception?.upgradePlanUrl
        notice = (
          <Box className={classes.upgradePlanContainer}>
            {gqlError.message}
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.viewBtn}
              onClick={() => {
                if (renderOnSite) {
                  history.push(path)
                } else {
                  window.location.replace(path)
                }
              }}
            >
              Upgrade Plan
            </Button>
          </Box>
        )
      } else {
        notice = 'An error occurred while loading the cumulative flow data for this sprint.'
      }
    }
  }  else if(!dataAvailable && statusSelected) {
    notice = 'No cumulative flow data exists yet.'
  } else if (!statusSelected) {
    notice = 'Select at least one status to display a cumulative flow chart.'
  }

  const onClickStatusHelp = () => {
    window.open('https://oosstudio.notion.site/Why-are-my-cumulative-flow-statuses-out-of-order-c639d2586bfa42a69edf964b817b6d99', '_blank')
  }

  const isChecked = (_status) => {
    const status = allStatuses.find(status => status.id === _status.id)
    return status.isChecked
  }

  const onCheckStatus = (_status) => {
    const newStatuses = allStatuses
    const status = newStatuses.find(status => status.id === _status.id)
    status.isChecked = !status.isChecked
    setAllStatuses(newStatuses)
    setCheckedStatuses(newStatuses.filter(status => status.isChecked))
  }

  const mapColor = (color) => {
    if(isDarkMode() && !renderOnSite) {
      return NotionColorsDark[color] || NotionColorsDark.default
    } else {
      return NotionColorsLight[color] || NotionColorsLight.default
    }
  }

  const getActiveColors = () => {
    return allStatuses.filter(s => s.isChecked).map(status => mapColor(status.color))
  }

  const onChangeStartDate = async (date) => {
    setStartDate(date)
    setRefetching(true)
  }

  const onChangeEndDate = (date) => {
    setEndDate(date)
    setRefetching(true)
  }

  const WrapperComponent = renderOnSite ? React.Fragment : ({children}) => <Card className={classes.card}>{children}</Card>

  const showInitialOverlay = (!checkedStatuses.length && !notice && loading) || (initialLoad && !checkedStatuses.length)
  const showTransparentOverlay = refetching

  return (
    <div className={classes.container}>
      { showInitialOverlay ? <CircularProgress className={classes.refetchProgressOverlay} /> : (
        <WrapperComponent>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.header}>
                  <Box
                    component={Typography}
                    variant="h5"
                    marginBottom="0!important"
                    marginTop="0!important"
                    className={classes.textUppercase}
                  >
                    Cumulative Flow
                  </Box>
                  {showCopyButton && <CopyEmbedUrlButton className={classes.copyButton} type={'cumulative'} projectNumber={_projectNumber} />}
                  <Box className={classes.iconWrapper}>
                    <RefreshButton onClick={onRefresh} />
                  </Box>
                </div>
                <Box className={classes.formWrapper}>
                  <FormControl border={0} className={classes.formControl}>
                    <Select
                      border={0}
                      className={classes.select}
                      autoWidth={true}
                      value={byPoints}
                      onChange={event => setByPoints(event.target.value)}
                    >
                      <MenuItem value={true}>Story points</MenuItem>
                      <MenuItem value={false}>Item count</MenuItem>
                    </Select>
                    <KeyboardDatePicker
                      autoOk={true}
                      disableToolbar
                      variant="inline"
                      format="MM/DD/yyyy"
                      margin="normal"
                      label="From date"
                      value={startDate}
                      className={classes.dateInput}
                      onChange={onChangeStartDate}
                      shouldDisableDate={date => date > new Date()}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <KeyboardDatePicker
                      autoOk={true}
                      disableToolbar
                      variant="inline"
                      format="MM/DD/yyyy"
                      margin="normal"
                      label="To date"
                      className={classes.dateInput}
                      value={endDate}
                      onChange={onChangeEndDate}
                      shouldDisableDate={date => date > new Date()}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </FormControl>
                </Box>
                <FormControl className={classes.checkboxWrapper}>
                  {allStatuses.slice().reverse().map((status) => {
                    return <FormControlLabel control={<Checkbox style={{ color: mapColor(status.color === 'default' ? 'defaultSecondary' : status.color) }} onChange={() => onCheckStatus(status)} checked={isChecked(status)}/>} label={status.id}/>
                  })}
                </FormControl>
                <div onClick={onClickStatusHelp} className={classes.statusHelpText}>Statuses out of order?</div>
              </Grid>
            </Grid>
            {notice ?
              (
                <div className={classes.error}>
                  {notice}
                  {showViewErrorBtn ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.viewBtn}
                      onClick={() => dispatch(setActiveModal({
                        code: originCode,
                        data: {
                          errorCode: originCode,
                          title: title ? title : 'Error Calculating Cumulative Flow',
                          message: originMessage,
                          type: 'ERROR',
                        },
                      }))}
                    >
                      View
                    </Button>
                  ) : null}
                </div>
              ):
              <div className={classes.chartWrapper}>
                {showTransparentOverlay && <CircularProgress className={classes.refetchProgressOverlay}/>}
                <ResponsiveLine
                  enableArea={true}
                  colors={getActiveColors()}
                  colorBy={'index'}
                  theme={{
                    textColor: theme.palette.text.secondary,
                    fontSize: theme.typography.fontSize,
                    axis: {
                      ticks: {
                        line: {
                          stroke: theme.palette.divider,
                          strokeWidth: 1
                        }
                      },
                      domain: {
                        line: {
                          stroke: theme.palette.divider,
                          strokeWidth: 0,
                        }
                      },
                      text: {
                        fontSize: 50,
                      }
                    },
                    grid: {
                      line: {
                        stroke: theme.palette.divider
                      }
                    },
                    labels: {
                      text: {
                        fill: theme.palette.divider,
                        fontSize: 50,
                      }
                    },
                    legends: {
                      text: {
                        fontSize: theme.typography.h6.fontSize,
                      }
                    },
                    tooltip: {
                      container: {
                        background: theme.palette.background.default
                      },
                    }
                  }}
                  data={chartData}
                  margin={{top: 10, right: 15, bottom: 75, left: 55}}
                  yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, }}
                  xScale={{
                    type: "time",
                    format: "%Y/%m/%d",
                    useUTC: false,
                  }}
                  xFormat="time:%Y/%m/%d"
                  axisTop={null}
                  axisRight={null}
                  markers={null}
                  areaBaselineValue={minYValue}
                  areaOpacity={1}
                  axisBottom={{
                    format: "%b %d",
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 10,
                    tickRotation: -60,
                    legendOffset: 36,
                    legendPosition: 'middle',
                    tickValues,
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 20,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: 'middle'
                  }}
                  enablePoints={false}
                  enableGridX={false}
                  enableSlices={'x'}
                  pointSize={5}
                  pointColor={{ theme: 'background' }}
                  pointBorderWidth={1}
                  pointBorderColor={{ from: 'serieColor' }}
                  pointLabelYOffset={-12}
                  pointLabel={'y'}
                  useMesh={true}
                  enablePointLabel={true}
                />
              </div>}
          </div>
        </WrapperComponent>
      )}
    </div>
  )
}

export default CumulativeFlow
