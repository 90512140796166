const componentStyles = (theme) => ({
  bgDefault: {
    backgroundColor: theme.palette.dark.main,
  },
  container: {
    overflowX: 'hidden',
  },
});

export default componentStyles;
