import React, { useEffect, useState } from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import Avatar from '@material-ui/core/Avatar'
import { usePrevious } from '../../../hooks/usePrevious'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import config from '../../../config'
import {useMutation, useQuery} from '@apollo/client'
import {CREATE_NOTION_AUTHORIZATION, UPDATE_PROJECT} from '../../../mutations'
import { useHistory, useLocation } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {CircularProgress} from '@material-ui/core'
import componentStyles from '../../../assets/theme/views/auth/register'
import { setAuthorization } from '../actions'
import {selectAuthorization, selectOrganization} from '../selectors'
import {selectNewProject, selectNewProjectOrganization} from "../../admin/NewProjectSetup/selectors";
import {GET_AUTH} from "../../../queries";
import {setProject} from "../../admin/NewProjectSetup/actions";

const useStyles = makeStyles(componentStyles)

const NotionAuthStep = ({ onNextClick, onBackClick, isOrgSetup, newProject }) => {
  const theme = useTheme()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const authorization = useSelector(selectAuthorization)

  const [localAuth, setLocalAuth] = useState(authorization)

  const code = new URLSearchParams(location.search).get('code')
  const previousCode = usePrevious(code)
  const errorCode = new URLSearchParams(location.search).get('error')
  const state = new URLSearchParams(location.search).get('state')

  const organization = isOrgSetup ? useSelector(selectOrganization) : useSelector(selectNewProjectOrganization)
  const organizationId = organization?.id

  const project = useSelector(selectNewProject)

  const redirectPath = isOrgSetup ? '/setup/1' : '/new-project/2'
  const redirectUri = encodeURIComponent(config.siteBaseUrl + redirectPath)
  const oauthState = encodeURIComponent(JSON.stringify({pid: project?.id}))

  useEffect(() => {
    gtag('event', 'page_view', {
      'page_location': window.location.href,
      'page_title': newProject ? 'New Project: Notion Auth Step' : 'Notion Auth Step'
    })
  },[])

  const [createAuthorization, { loading }] = useMutation(CREATE_NOTION_AUTHORIZATION, {
    variables: {
      organizationId,
      clientId: '3b4a5f1c-2fe5-4078-a456-c9c61bcd2e58',
      code,
      redirectUri: config.siteBaseUrl + redirectPath,
    },
    onCompleted: async (data) => {
      dispatch(setAuthorization(data?.createNotionAuthorization))
      setLocalAuth(data?.createNotionAuthorization)
      console.log(data)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const [updateProject, { loading: updateProjectLoading }] = useMutation(UPDATE_PROJECT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: project?.id,
      authorization: {
        connect: {
          id: localAuth?.id,
        },
      },
      name: project?.name,
      timezone: project?.timezone
    },
    onError: err => {
      console.log(err)
    },
    onCompleted: async (data) => {
      console.log(data)
      dispatch(setProject(data?.updateOneProject))
    }
  })

  const { loading: getAuthLoading} = useQuery(GET_AUTH, {
    variables: {
      orgId: organizationId,
    },
    onCompleted: (data) => {
      setAuthorization(data?.notionAuthorizations[0])
      setLocalAuth(data?.notionAuthorizations[0])
    }
  })


  useEffect(async () => {
    if (code && organizationId && code !== previousCode) {
      history.replace({ pathname: location.pathname, search: '' })
      await createAuthorization()
    }
    if (errorCode) {
      history.replace({ pathname: location.pathname, search: '' })
    }
  }, [errorCode, code, organizationId, state])

  const onNext = () => {
    if(isOrgSetup) {
      if(onNextClick) {
        onNextClick()
      }
    } else {
      console.log(project)
      updateProject().then(() => {
        if(onNextClick) {
          onNextClick()
        }
      })
    }
  }

  return (
    <>
      <Box
        color={theme.palette.gray[600]}
        textAlign="center"
        marginBottom="1.5rem"
        marginTop=".5rem"
        fontSize="1rem"
      >
        <Box fontWeight="400" component="small">
          {
            isOrgSetup ?
            'Click below to authorize your Notion workspace with NotionOps. When setting up access, be sure to include the page you duplicated from the template gallery in the previous step.'
              : 'Click below to update your Notion workspace authorization to grant permission to the page of your new project.'
          }
        </Box>
        <Box textAlign="center" marginTop="1.5rem" marginBottom="4rem">
          { loading || getAuthLoading ?
            <CircularProgress size={38}/>
            : localAuth ?
              <>
              <Box alignItems="center" display="flex" justifyContent={'center'}>
                <Box
                  component={Avatar}
                  marginRight="1rem"
                  alt="..."
                  src={localAuth.workspaceIcon}
                />
                <Box marginRight={'1rem'} display="flex" alignItems="flex-start">
                  <Box fontSize=".875rem" component="span">
                    {localAuth.workspaceName}
                  </Box>
                </Box>
              </Box>
              <Box marginTop={'1.5rem'}>
                <Button size={'small'} color={'primary'} disabled={!organizationId} href={`https://api.notion.com/v1/oauth/authorize?client_id=3b4a5f1c-2fe5-4078-a456-c9c61bcd2e58&redirect_uri=${redirectUri}&response_type=code&state=${oauthState}`}>
                  Change
                </Button>
              </Box>
              </>
            :
            <Button disabled={!organizationId} href={`https://api.notion.com/v1/oauth/authorize?client_id=3b4a5f1c-2fe5-4078-a456-c9c61bcd2e58&redirect_uri=${redirectUri}&response_type=code&owner=user&state=${oauthState}`} color={'primary'} endIcon={<ArrowForwardIcon/>}>
              Authorize with Notion
            </Button>
          }
        </Box>
      </Box>
      <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
        <Button onClick={onBackClick} className={classes.backButton}>Back</Button>
        <Button disabled={!localAuth} variant="contained" color="primary" onClick={onNext}>
          Next
        </Button>
      </Box>
    </>
  )
}

export default NotionAuthStep
