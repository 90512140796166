import { CONFETTI } from "./action"

const initState = {
  run: false,
}

const confetti = (state = initState, action) => {
  switch(action.type) {
    case CONFETTI:
      return {
        ...state,
        run: action.confetti,
      }
    default:
      return state
  }
}

export default confetti