import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import NotionTemplateStep from './steps/NotionTemplateStep'
import NotionAuthStep from './steps/NotionAuthStep'
import ProjectStep from './steps/ProjectStep'
import SprintStep from './steps/SprintStep'
import RequirementStep from './steps/RequirementStep'

import componentStyles from 'assets/theme/views/auth/register.js'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setStep } from './actions'
import { selectActiveStep, selectAuthorization, selectProject, selectSprintDatabase, selectSprintProperties } from './selectors'
import {useMutation, useQuery} from '@apollo/client'

import {CREATE_NOTIFICATION, UPDATE_LAST_OPENED_PROJECT} from '../../mutations'

import { Stepper, Step, StepLabel } from '@material-ui/core'
import { runConfetti } from "../admin/Confetti/action";
import {setActiveModal} from '../../components/Modals/actions'
import { HAS_PAYMENT_METHOD, HAS_SUBSCRIPTION } from '../../queries'

const useStyles = makeStyles(componentStyles);

function getSteps() {
  return ['Duplicate Template', 'Connect Notion', 'Create Project', 'Setup Sprints', 'Setup Requirements'];
}

const NewOrgStepper = (props) => {
  const classes = useStyles();
  const steps = getSteps();
  const history = useHistory()
  const dispatch = useDispatch()

  const activeStep = useSelector(selectActiveStep)

  const project = useSelector(selectProject)
  const user = useSelector(state => state.auth.user)
  const authorization = useSelector(selectAuthorization)
  const sprintDatabase = useSelector(selectSprintDatabase)
  const sprintProperties = useSelector(selectSprintProperties)

  const [updateLastOpenedProject, { loading }] = useMutation(UPDATE_LAST_OPENED_PROJECT, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      'Me'
    ],
    variables: {
      userId: user?.id,
      projectId: project?.id
    }
  })

  useQuery(HAS_PAYMENT_METHOD, {
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    onCompleted: ({ hasPaymentMethod }) => {
      if (!hasPaymentMethod) {
        history.push('/auth/setup-billing')
      }
    },
    onError: e => {
      if(e.toString().includes('Invalid login token')) {
        localStorage.removeItem('loginToken')
        history.push('/auth/login', { prevPath: location.pathname + location.search })
      }
    }
  })

  useQuery(HAS_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    onCompleted: ({ hasSubscription }) => {
      if (!hasSubscription) {
        history.push('/auth/setup-billing')
      }
    }
  })

  const notificationParams = {
    code: 'first_project_setup',
    title: 'Congrats on setting up your first project!',
    message: 'We\'re so excited to have you on board. Check out our documentation pages for tutorial videos and other useful info.',
    shownTo: [user?.id]
  }

  const [createNotification] = useMutation(CREATE_NOTIFICATION, {
    variables: {
      type: 'SUCCESS',
      level: 'USER',
      params: notificationParams,
    },
    onError: err => {
      console.log(err)
    }
  })

  useEffect(() => {
    if (user?.lastOpenedProject?.id && !user?.lastOpenedProject?.deleted && user?.lastOpenedProject?.requirementTable && user?.lastOpenedProject?.sprintTable) {
      history.push({ pathname: '/admin/index', search: location.search })
    }
  }, [user])

  const handleNext = () => {
    if (activeStep + 1 >= steps.length) {
      updateLastOpenedProject().then(() => {
        createNotification()
        dispatch(setActiveModal({code: notificationParams?.code, data: notificationParams}))
        dispatch(runConfetti(true))
      })
    } else {
      history.push({ pathname: `${activeStep + 1}`, search: location.search })
      dispatch(setStep(activeStep + 1))
    }
  }

  const handleBack = () => {
    history.push({ pathname: `${activeStep - 1}`, search: location.search })
    dispatch(setStep(activeStep - 1))
  }

  useEffect(() => {
    let step = activeStep
    if (user?.id) {
      switch (activeStep) {
        case 2:
          if (!authorization?.id) {
            step = 1
            history.push({ pathname: '/setup/1', search: location.search })
          }
          break
        case 3:
          if (!project?.id) {
            step = 2
            history.push({ pathname: '/setup/2', search: location.search })
          }
          break
        case 4:
          if (!sprintDatabase || !sprintProperties?.dateRange || !sprintProperties?.totalEffort || !sprintProperties?.backlog || !sprintProperties?.state) {
            step = 3
            history.push({ pathname: '/setup/3', search: location.search })
          }
          break
      }
      dispatch(setStep(step))
    }
  }, [user?.id, activeStep])

  useEffect(() => {
    let step = 0

    if (!!props.location.pathname) {
      const path = props.location.pathname
      const value = Number(path.substring(path.lastIndexOf('/') + 1, path.length))
      if (value && !isNaN(value)) {
        if (value <= 0) {
          step = 0
        } else if (value > steps.length - 1) {
          step = steps.length - 1
        } else {
          step = Number(value)
        }
        if (step !== value) {
          history.push({ pathname: `/setup/${step}`, search: location.search })
        }
      } else {
        history.push('/setup/0')
      }
    }
    dispatch(setStep(step))
  }, [props?.location?.pathname])

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <NotionTemplateStep onNextClick={handleNext}/>
      case 1:
        return <NotionAuthStep onBackClick={handleBack} onNextClick={handleNext} isOrgSetup />
      case 2:
        return <ProjectStep onBackClick={handleBack} onNextClick={handleNext}/>
      case 3:
        return <SprintStep onBackClick={handleBack} onNextClick={handleNext} isOrgSetup />
      case 4:
        return <RequirementStep submitting={loading} onBackClick={handleBack} onNextClick={handleNext} isOrgSetup />
      default:
        return '';
    }
  }

  return (
    <>
      <Grid item xs={12} lg={6} md={8}>
        <Card classes={{ root: classes.cardRoot }}>
          <Stepper style={{ backgroundColor: 'transparent'}} activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <CardContent classes={{ root: classes.cardContent }}>
            {getStepContent(activeStep)}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default NewOrgStepper
