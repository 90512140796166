import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {Step, StepLabel, Stepper, CircularProgress} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core/styles";
import componentStyles from "../../../assets/theme/views/auth/register";
import EnterProjectDetailsStep from "./components/EnterProjectDetailsStep";
import {useHistory, useLocation} from "react-router-dom";
import {resetState, setOrganization, setProject, setShowSpinner, setStep} from "./actions";
import NotionTemplateStep from "../../setup/steps/NotionTemplateStep";
import {
  selectNewProject,
  selectNewProjectActiveStep,
  selectNewProjectSprintDatabase,
  selectNewProjectSprintProperties
} from "./selectors";
import SprintStep from "../../setup/steps/SprintStep";
import RequirementStep from "../../setup/steps/RequirementStep";
import {useMutation} from "@apollo/client";
import {UPDATE_LAST_OPENED_PROJECT} from "../../../mutations";
import NotionAuthStep from "../../setup/steps/NotionAuthStep";
import {setUserData} from "../../auth/actions";
import {initNewProjectSetupData} from "../../../utils/projectSetup";

const STEPS = ['Project Details', 'Duplicate Template', 'Update Authorization', 'Setup Sprints', 'Setup Requirements']
const useStyles = makeStyles(componentStyles);

const NewProjectStepper = () => {
  const classes = useStyles()

  const [projectIdInPath, setProjectIdInPath] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const [replacedWithState, setReplacedWithState] = useState(false)

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const project = useSelector(selectNewProject)
  const activeStep = useSelector(selectNewProjectActiveStep)
  const user = useSelector(state => state.auth?.user)
  const sprintDatabase = useSelector(selectNewProjectSprintDatabase)
  const sprintProperties = useSelector(selectNewProjectSprintProperties)

  const [updateLastOpenedProject, { loading }] = useMutation(UPDATE_LAST_OPENED_PROJECT, {
    refetchQueries: [
      'Me'
    ],
    variables: {
      userId: user?.id,
      projectId: project?.id
    },
    onCompleted: (data) => {
      dispatch(setUserData(data?.updateOneUser))
      history.push('/admin/index')
      dispatch(resetState())
    },
    onError: err => {
      console.log(err)
    }
  })

  useEffect(() => {
    if (user?.lastOpenedProject?.organization) {
      dispatch(setOrganization(user?.lastOpenedProject?.organization))
    }
  }, [user?.lastOpenedProject])

  useEffect(() => {
    if(!project?.id && !showSpinner && (location?.pathname?.includes('pid') || replacedWithState)) {
      setShowSpinner(true)
    } else if(showSpinner){
      setShowSpinner(false)
    }
  }, [project?.id])

  useEffect(() => {
    if(location?.search) {
      setShowSpinner(true)
    }
  }, [location?.search])

  useEffect(() => {
    let step = activeStep
    if (user?.id) {
      switch (activeStep) {
        case 1:
          if(!project?.name && !project?.timezone) {
            step = 0
            if(project?.id) {
              history.push({ pathname: `/new-project/pid${project?.id}/0`, search: location.search })
            } else {
              history.push({ pathname: '/new-project/0', search: location.search })
            }
          }
          break
        case 2:
          if(!project?.name && !project?.timezone) {
            step = 0
            if(project?.id) {
              history.push({ pathname: `/new-project/pid${project?.id}/0`, search: location.search })
            } else {
              history.push({ pathname: '/new-project/0', search: location.search })
            }
          }
          break
        case 3:
          if (!project?.id) {
            step = 2
            history.push({ pathname: '/new-project/0', search: location.search })
          }
          break
        case 4:
          console.log('4')
          if (!sprintDatabase || !sprintProperties?.dateRange || !sprintProperties?.totalEffort || !sprintProperties?.backlog || !sprintProperties?.state) {
            step = 3
            if(project?.id) {
              history.push({ pathname: `/new-project/pid${project?.id}/0`, search: location.search })
            } else {
              history.push({ pathname: '/new-project/0', search: location.search })
            }
          }
          break
      }
      dispatch(setStep(step))
    }
  }, [user?.id, activeStep])

  useEffect(() => {
    if(projectIdInPath && user?.id && !project?.id) {
      const selectedProject = user?.projects?.find(p => p.id === projectIdInPath)
      const path = location.pathname
      const value = Number(path.substring(path.lastIndexOf('/') + 1, path.length))
      history.push({ pathname: `/new-project/pid${projectIdInPath}/${value}`, search: location.search })

      const organization = user.organizations.find(o => o.projects.find(p => p.id === selectedProject?.id))
      const notionAuthorization = organization?.notionAuthorizations.find(auth => auth.projects.find(p => p.id === selectedProject.id))
      const sprintTable = notionAuthorization?.projects.find(p => p.id === selectedProject.id)?.sprintTable
      const requirementTable = notionAuthorization?.projects.find(p => p.id === selectedProject.id)?.requirementTable
      const projectToSetup = {
        ...selectedProject,
        organization,
        notionAuthorization,
        sprintTable,
        requirementTable,
      }

      if(!notionAuthorization) {
        history.push({ pathname: `/new-project/pid${projectIdInPath}/2`, search: location.search })
      } else if (!sprintTable) {
        history.push({ pathname: `/new-project/pid${projectIdInPath}/3`, search: location.search })
      } else if(!requirementTable) {
        history.push({ pathname: `/new-project/pid${projectIdInPath}/4`, search: location.search })
      } else {
        history.push({ pathname: `/new-project/pid${projectIdInPath}`, search: location.search })
      }

      initNewProjectSetupData(projectToSetup, dispatch)
    }
  }, [user?.id, user?.projects, projectIdInPath])

  useEffect(() => {
    console.log(project)
  }, [project])

  useEffect( () => {
    let step = 0
    if (!!location.pathname) {
      const path = location.pathname
      const value = Number(path.substring(path.lastIndexOf('/') + 1, path.length))
      const projectIdMatches = path.match(/\/pid(.*)\//)
      if(projectIdMatches?.length > 0) {
        setProjectIdInPath(Number(projectIdMatches[1]))
      }
      if (value !== null && value !== undefined && !isNaN(value)) {
        if (value <= 0) {
          step = 0
        } else if (value > STEPS.length - 1) {
          step = STEPS.length - 1
        } else {
          step = Number(value)
        }
        if(project?.id) {
          history.push({ pathname: `/new-project/pid${project?.id}/${step}`, search: location.search })
        } else {
          let foundProjectInState = false
          if(location?.search) {
            const stateMatches = location.search.match(/state=(.*)/)
            if(stateMatches?.length > 0) {
              const stateObject = JSON.parse(decodeURIComponent(stateMatches[1]))
              if(stateObject?.pid) {
                foundProjectInState = true
                setReplacedWithState(true)
                history.push({ pathname: `/new-project/pid${stateObject.pid}/${step}`, search: location.search })
              }
            }
            if(!foundProjectInState) {
              history.push(`/new-project/${step}`)
            }
          }
        }
      } else {
        if(project?.id) {
          history.push(`/new-project/pid${project?.id}/0`)
        } else {
          history.push({ pathname: '/new-project/0', search: location.search })
        }
      }
    }
    dispatch(setStep(step))
  }, [location?.pathname])

  const handleNext = () => {
    const newStep = activeStep + 1
    console.log(newStep, STEPS.length)
    if (newStep >= STEPS.length) {
      updateLastOpenedProject()
    } else {
      history.push(`${newStep}`)
      dispatch(setStep(newStep))
    }
  }

  const handleBack = () => {
    const newStep = activeStep - 1
    history.push(`${newStep}`)
    dispatch(setStep(newStep))
  }

  const getStepContent = stepIndex => {
    switch(stepIndex) {
      case 0:
        return <EnterProjectDetailsStep onNextClick={handleNext} />
      case 1:
        return <NotionTemplateStep newProject onBackClick={handleBack} onNextClick={handleNext} />
      case 2:
        return <NotionAuthStep newProject onBackClick={handleBack} onNextClick={handleNext} />
      case 3:
        return <SprintStep newProject onBackClick={handleBack} onNextClick={handleNext} />
      case 4:
        return <RequirementStep newProject submitting={loading} onBackClick={handleBack} onNextClick={handleNext} />
      default:
        return ''
    }
  }

  return (
    <>
      <Grid item xs={12} lg={6} md={8}>
        <Card classes={{ root: classes.cardRoot }}>
          <Stepper style={{ backgroundColor: 'transparent'}} activeStep={activeStep} alternativeLabel>
            {STEPS.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {showSpinner ? (
            <CardContent classes={{root: classes.cardContent}}>
              <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', height: 200}}>
                <CircularProgress />
              </div>
            </CardContent>
          ) : (
            <CardContent classes={{root: classes.cardContent}}>
              {getStepContent(activeStep)}
            </CardContent>
          )}
        </Card>
        <div style={{ width: '100%', display: 'flex', marginTop: 15, justifyContent: 'flex-end'}}>
          <a
            onClick={() => history.replace({pathname: '/admin/index'})}
            style={{
              cursor: 'pointer',
            }}
            className={classes.footerLinks}
          >
            Go back to admin site
          </a>
        </div>
      </Grid>
    </>
  )
}

export default NewProjectStepper
