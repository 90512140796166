import React from 'react'
import {Badge, Box, Container, Typography} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PlanSprints from "../../../assets/img/product/NotionOps_KeyValues-01.png"
import TrackPerformance from "../../../assets/img/product/NotionOps_KeyValues-02.png"
import OnTimeLandscape from "../../../assets/img/product/NotionOps_KeyValues-03.png"

import componentStyles from '../../../assets/theme/views/product/home'
import {makeStyles} from "@material-ui/core/styles"
import AngledBackground from "./AngledBackground";

const useStyles = makeStyles(componentStyles)

const Values = () => {
  const classes = { ...useStyles() }
  return (
    <>
      <AngledBackground />
      <Box className={classes.container}>
        <Grid container className={classes.content}>
          <Grid item xs={12} md={4}>
            <Grid item>
              <Box className={classes.iconContainer}>
                <Box
                  maxWidth={100}
                  component="img"
                  height="auto"
                  src={PlanSprints}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.textContainer}>
                <Typography className={classes.firstWord}>
                  Plan
                </Typography>
                <Typography className={classes.secondWord}>
                  sprints
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid item>
              <Box className={classes.iconContainer}>
                <Box
                  maxWidth={100}
                  component="img"
                  height="auto"
                  src={TrackPerformance}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.textContainer}>
                <Typography className={classes.firstWord}>
                  Track
                </Typography>
                <Typography className={classes.secondWord}>
                  performance
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid item>
              <Box className={classes.iconContainer}>
                <Box
                  maxWidth={100}
                  component="img"
                  height="auto"
                  src={OnTimeLandscape}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.textContainer}>
                <Typography className={classes.firstWord}>
                  Deliver
                </Typography>
                <Typography className={classes.secondWord}>
                  on-time
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Values