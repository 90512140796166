import React, {useEffect, useState} from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import componentStyles from "../../assets/theme/components/notifications"
import Typography from "@material-ui/core/Typography"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import DialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CardMedia from "@material-ui/core/CardMedia"
import Box from "@material-ui/core/Box"
import CongratsGif from '../../assets/gifs/notifications/Congrats_Animation.gif'
import {useDispatch, useSelector} from "react-redux";
import {selectUserNotification} from "../../views/auth/selectors";
import {useMutation} from "@apollo/client";
import {MARK_NOTIFICATION_READ} from "../../mutations";
import {setActiveModal} from './actions'
import {usePrevious} from '../../hooks/usePrevious'

const useStyles = makeStyles(componentStyles)

const CongratsNotification = () => {
  const classes = useStyles()
  const buttonRoute = 'https://oosstudio.notion.site/Help-Documentation-30b5d99d677548aa90fb15b82a134904'
  const dispatch = useDispatch()

  const firstProjectNotification = useSelector(selectUserNotification('first_project_setup'))
  const prevRead = usePrevious(firstProjectNotification?.read)
  const activeModal = useSelector(state => state.modals?.activeModal)

  const [markNotificationRead] = useMutation(MARK_NOTIFICATION_READ, {
    onError: err => {
      console.log(err)
    },
  })

  const open = activeModal?.code === 'first_project_setup'

  useEffect(() => {
    if(firstProjectNotification) {
      if (!firstProjectNotification.read && (prevRead === undefined || prevRead === true)) {
        dispatch(setActiveModal({code: firstProjectNotification?.code, data: firstProjectNotification}))
      }
    }
  }, [firstProjectNotification])

  const handleClose = () => {
    markNotificationRead({
      variables: {
        id: firstProjectNotification?.id
      }
    })
    dispatch(setActiveModal(null))
  }

  return (
    <Container>
      <Dialog open={open} onClose={handleClose} className={classes.container}>
        <DialogContent>
          <IconButton onClick={handleClose} className={classes.exitIcon}>
            <CloseRoundedIcon />
          </IconButton>
          <Box className={classes.box}>
            <CardMedia component="img" src={CongratsGif} className={classes.gif} />
            <Box className={classes.box}>
              <Typography className={classes.notificationTitle}>
                Congrats on setting up your first project!
              </Typography>
            </Box>
            <Box className={classes.box}>
              <Typography className={classes.notificationContent}>
                We're so excited to have you on board! Check out our documentation pages for tutorial videos and other useful info.
              </Typography>
            </Box>
            <Box className={classes.box}>
              <Button onClick={handleClose} href={buttonRoute} className={classes.notificationButton}>
                Help & Documentation
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  )
}

export default CongratsNotification
